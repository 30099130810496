import moment from 'moment-timezone';

export const formatDate = (dateString: string, isTzSeen = false): string => {
  const date = moment.utc(dateString);
  const timezone = moment.tz.guess();
  const dateWithTimezone = date.tz(timezone);
  const formattedDate = dateWithTimezone.format('MM/DD/YYYY hh:mm A (z)');
  const formattedDateWithoutTimeZone = dateWithTimezone.format('MM/DD/YYYY hh:mm A');
  return isTzSeen ? formattedDate : formattedDateWithoutTimeZone;
};

export const formatDateWithoutTZ = (dateString: string, isTzSeen = false): string => {
  const date = moment.utc(dateString);
  const timezone = moment.tz.guess();
  const dateWithTimezone = date.tz(timezone);
  const formattedDate = dateWithTimezone.format('MM/DD/YYYY');
  const formattedDateWithoutTimeZone = dateWithTimezone.format('MM/DD/YYYY');
  return isTzSeen ? formattedDate : formattedDateWithoutTimeZone;
};


export const getCaseDate = (appointmentDate: string, patientState: string): string => {
  const date = moment.utc(appointmentDate).tz(patientState);

  if (date) {
    return date.format('dddd, MMMM D, YYYY');
  }

  return '';
};

export const getCaseTime = (appointmentDate: string, patientState: string): string => {
  const date = moment.utc(appointmentDate).tz(patientState);

  if (date) {
    return date.format('h:mm A z');
  }

  return '';
};

export const getFormatDate = (dateString: string, patientState: string): string => {
  const date = moment.utc(dateString).tz(patientState);
  if (date) {
    return date.format('MM/DD/YYYY hh:mm A');
  }

  return '';
};

export const getFormatDateFullDate = (dateString: string, patientState: string): string => {
  const date = moment.utc(dateString).tz(patientState);
  if (date.isValid()) {
    return date.format('dddd, MMMM D, YYYY, h:mmA z');
  }

  return '';
};

export const getFormattedDateSmileScan = (dateString: string, patientTimezone: string): string => {
  const date = moment.utc(dateString).tz(patientTimezone);

  if (date) {
    return date.format('dddd, MMMM D, YYYY');
  }

  return '';
};

export const getFormattedTimeSmileScan = (dateString: string, patientTimezone: string): string => {
  const date = moment.utc(dateString).tz(patientTimezone);

  if (date) {
    return date.format('h:mm A z');
  }

  return '';
};

export const formatEmail = (email: string): string => {
  const atIndex = email.lastIndexOf('@');
  if (atIndex === -1 || email.length <= 5) {
    throw new Error('Invalid email format.');
  }
  const maskedLocalPart = 'X'.repeat(atIndex);
  const domain = email.slice(atIndex + 1);
  const maskedFormat = `${maskedLocalPart}@${domain}`;
  return maskedFormat;
};
