import { useAppSelector } from '@brands/hooks/useReduxHook';
import { getOneCase } from '@brands/services/cases/getCaseById';
import { Address } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectOrganization } from '@brands/store/selectors/organization';
import { updatePaymentAvailability } from '@brands/Utils/getFriendlyName';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import SelectInput from '../../../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../../../Components/Inputs/TextField/TextField';
import { config } from '../../../../config/config';
import { CaseType, ICase } from '../../../../services/cases/types/ICase';
import { addPaymentProfile } from '../../../../services/identity/addPaymentProfile';
import { PaymentProfile } from '../../../../services/identity/types/PaymentProfile';
import { updatePaymentProfile } from '../../../../services/identity/updatePaymentProfile';
import { profileSelectStyles } from '../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { getSecondaryColor } from '../../../../Utils/getPrimaryColor';
import { stateOptions } from '../../../../Utils/selectOptions';
import { updateDataPayment } from '../../../MyProfile/Patient/utils/utilsFunctions';
import { PatientFormFiveSubmit } from '../../../PatientForms/PatientForm/utils/types';
import styles from './styles.module.scss';
import { validationSchema } from './validationSchema';

const { locationId } = config.square;
const appId = config.square.applicationId;

const MAX_RETRIES = 3; // Set the maximum number of retry attempts
const RETRY_INTERVAL = 2000; // Set the interval between retry attempts in milliseconds

type Card = {
  attach: (containerId: string) => Promise<any>;
  tokenize: () => Promise<{
    token: string;
    status: string;
    errors?: JSON;
    details?: {
      card: any; // Replace "any" with the type of the card object
      method: string;
      billing: any; // Replace "any" with the type of the billing object
    };
  }>;
  addEventListener: (eventName: string, callback: () => void) => void;
};

interface IModal {
  setOpenModal: (arg0: boolean) => void;
  thisCase: ICase;
  reloadAll: () => Promise<void>;
  refreshAll: () => Promise<void>;
}

const EditCreditCardModal = ({ setOpenModal, thisCase }: IModal): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [disablePaymentButton, setDisablePaymentButton] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [card, setCard] = useState<Card>();
  const { userInfo } = useAppSelector(selectAuth);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const [cardInitialized, setCardInitialized] = useState(false); // Track card initialization
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<PatientFormFiveSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema()),
  });
  const onInvalid = (err: any): void => {
    const error = Object.values(err)[0] as any;
    if (error) {
      toast.error(error.message as string, { className: 'fs-unmask' });
    }
  };
  const squareDiv = document.getElementById('card-container');

  useEffect(() => {
    const initSquare = (): void => {
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }
      const payments = window.Square.payments(appId, locationId);

      const retryInitialize = async (retryCount: number): Promise<void> => {
        try {
          const paymentCard = await payments.card();

          if (document.getElementById('card-container')?.childNodes.length === 0) {
            await paymentCard.attach('#card-container');
          }

          // Only set cardInitialized to true when paymentCard.status is 1
          if (paymentCard.status === 1) {
            setCard(paymentCard); // Set card here
            setCardInitialized(true);
          }

          paymentCard.addEventListener('errorClassAdded', () => {
            setDisablePaymentButton(true);
          });
          paymentCard.addEventListener('errorClassRemoved', () => {
            setDisablePaymentButton(false);
          });
        } catch (error: unknown) {
          if (retryCount < MAX_RETRIES) {
            // Retry the initialization after a delay
            setTimeout(() => retryInitialize(retryCount + 1), RETRY_INTERVAL);
          } else {
            displayErrorDetails(error);
          }
        }
      };

      retryInitialize(0); // Start the initialization with 0 retries
      setPaymentStatus('');
    };

    initSquare();
  }, [squareDiv]);
  const onSubmit = async (data: PatientFormFiveSubmit): Promise<void> => {
    const updatedCase = await getOneCase(thisCase.id);
    if (updatedCase.type !== 'video_call_scheduled' && thisCase.type !== CaseType.phone_call_scheduled) {
      toast.error(
        'You are no longer able to update the payment for this consultation because the consultation type has changed.',
        { className: 'fs-unmask' },
      );
    } else if (updatePaymentAvailability(updatedCase)) {
      setLoading(true);
      setDisablePaymentButton(true);
      let paymentId;
      if (
        card &&
        cardInitialized &&
        currentOrganization.payment_options.find((option: string) => option === 'Credit Card')
      ) {
        try {
          const tokenResult = await card.tokenize();
          if (tokenResult.status === 'OK') {
            const dataWithUpdatedPayment = updateDataPayment(
              data,
              tokenResult,
              userInfo,
              true,
              updatedCase.patient.address as Address,
              Number(userInfo?.id),
              Number(
                userInfo?.id === updatedCase.patient.id ? updatedCase.patient.id : updatedCase.patient.guardian_id,
              ),
            );
            const res = await addPaymentProfile(dataWithUpdatedPayment as PaymentProfile);
            paymentId = res.id!;
            await updatePaymentProfile(updatedCase.id, {
              payment_profile_id: paymentId,
            });
            setOpenModal(false);
          } else {
            toast.error('Please Enter your Credit Card information correctly', { className: 'fs-unmask' });
          }
        } catch (error: unknown) {
          displayErrorDetails(error);
          setOpenModal(false);
        } finally {
          setDisablePaymentButton(false);
          setLoading(false);
        }
      } else {
        toast.error("You can't update your payment information.", { className: 'fs-unmask' });
        setDisablePaymentButton(false);
        setLoading(false);
      }
    } else {
      toast.error("You can't update your payment information up to 1 hour before your scheduled appointment.", {
        className: 'fs-unmask',
      });
    }
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <div className={styles.body}>
            <p>Do you want to update this appointment credit card?</p>
            <div className={styles.subTitle}>Credit Card Information</div>
            <div className={styles.creditCardInfoDiv}>
              <div className={`row ${styles.row}`}>
                <div className="col-md-12">
                  <div className={styles.inputWrap}>
                    <div>
                      <span>Name on Card</span>
                      <span className={styles.redText}> *</span>
                    </div>
                    <TextField
                      {...register('creditCardFullName')}
                      errors={errors}
                      name="creditCardFullName"
                      value={getValues('creditCardFullName')}
                      onChange={(e) => {
                        setValue('creditCardFullName', e.target.value, { shouldValidate: true });
                      }}
                      wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                      inputClass={styles.profileInputClass}
                      isUnderlined={false}
                    />
                  </div>
                </div>
              </div>
              <div className={`row ${styles.creditCardContainer} p-row`}>
                <div>
                  <span
                    style={{
                      color: getSecondaryColor(),
                      opacity: '0.5',
                      fontSize: '16px',
                    }}
                  >
                    Credit Card Information
                  </span>
                  <span style={{ color: '#FA5151' }}> *</span>
                </div>
                <div id="form-container">
                  <div id="card-container" />
                  <span> {paymentStatus} </span>
                  <div className={`${styles.invalidFeedback} invalid-feedback`}>{errors.creditCardNumber?.message}</div>
                </div>
              </div>
              <hr />
              <div className={styles.subTitle}>Billing Address</div>
              <div className={`row ${styles.row}`}>
                <div className="col-md-12">
                  <div className={styles.inputWrap}>
                    <div>
                      <span>Street Address</span>
                      <span className={styles.redText}> *</span>
                    </div>
                    <TextField
                      {...register('creditCardAddress1')}
                      errors={errors}
                      name="creditCardAddress1"
                      value={getValues('creditCardAddress1')}
                      onChange={(e) => {
                        setValue('creditCardAddress1', e.target.value, { shouldValidate: true });
                      }}
                      wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                      inputClass={styles.profileInputClass}
                      isUnderlined={false}
                    />
                  </div>
                </div>
              </div>
              <div className={`row ${styles.row}`}>
                <div className="col-md-12">
                  <div className={styles.inputWrap}>
                    <span>Apt, suite, building (optional)</span>
                    <TextField
                      {...register('creditCardAddress2')}
                      errors={errors}
                      name="creditCardAddress2"
                      value={getValues('creditCardAddress2')}
                      onChange={(e) => {
                        setValue('creditCardAddress2', e.target.value, { shouldValidate: true });
                      }}
                      wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                      inputClass={styles.profileInputClass}
                      isUnderlined={false}
                    />
                  </div>
                </div>
              </div>
              <div className={`row ${styles.row}`}>
                <div className="col-md-4">
                  <div className={styles.inputWrap}>
                    <div>
                      <span>City</span>
                      <span className={styles.redText}> *</span>
                    </div>
                    <TextField
                      {...register('creditCardCity')}
                      errors={errors}
                      name="creditCardCity"
                      value={getValues('creditCardCity')}
                      onChange={(e) => {
                        setValue('creditCardCity', e.target.value, { shouldValidate: true });
                      }}
                      wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                      inputClass={styles.profileInputClass}
                      isUnderlined={false}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.inputWrap} data-validate="Choose State">
                    <div>
                      <span>State</span>
                      <span className={styles.redText}> *</span>
                    </div>
                    <SelectInput
                      {...register('creditCardState')}
                      containerClass="mt-0"
                      name="creditCardState"
                      options={stateOptions}
                      style={profileSelectStyles}
                      errors={errors}
                      selectedValue={getValues('creditCardState')}
                      onChange={(value: any) => {
                        setValue('creditCardState', value?.value as string, { shouldValidate: true });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.inputWrap}>
                    <div>
                      <span>Zip Code</span>
                      <span className={styles.redText}> *</span>
                    </div>
                    <TextField
                      {...register('creditCardZipCode')}
                      errors={errors}
                      name="creditCardZipCode"
                      value={getValues('creditCardZipCode')}
                      onChange={(e) => {
                        setValue('creditCardZipCode', e.target.value, { shouldValidate: true });
                      }}
                      wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                      inputClass={styles.profileInputClass}
                      isUnderlined={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.modalButtons}>
              <button disabled={loading || disablePaymentButton} type="submit">
                Update
              </button>
              <button
                type="button"
                onClick={() => {
                  setOpenModal(false);
                }}
                id="cancelBtn"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EditCreditCardModal;
