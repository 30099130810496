/* eslint-disable @typescript-eslint/naming-convention */
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import { Organization } from '../../../../../services/identity/types/UserProfile';
import {
  checkFutureDate,
  checkValidDate,
  isValidAddress,
  isValidDate,
  isValidEmail,
  isValidName,
  isValidZipCode,
} from '../../../../../Utils/validation';

export function atLeastOneFieldNotEmpty(fields: (string | undefined)[]): boolean {
  return fields.some((field) => !!field);
}

export const validationSchema = (addSecondaryInsurance: boolean, isQuickAdd: boolean): any => {
  return Yup.object().shape({
    profile_img: Yup.string(),
    first_name: Yup.string().test('test-name', 'Enter Valid First Name', (value: any) => {
      if (isQuickAdd) {
        return value && isValidName(value);
      }
      if (value && !isValidName(value)) {
        return false;
      }
      return true;
    }),
    middle_name: Yup.string().test('test-name', 'Enter Valid Middle Name', (value: any) => {
      if (value && !isValidName(value)) {
        return false;
      }
      return true;
    }),
    last_name: Yup.string().test('test-name', 'Enter Valid Last Name', (value: any) => {
      if (isQuickAdd) {
        return value && isValidName(value);
      }
      if (value && !isValidName(value)) {
        return false;
      }
      return true;
    }),
    date_of_birth: Yup.string()
      .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value: any) => {
        if (isQuickAdd) {
          const values = value?.split('/') || [];
          return value && isValidDate(value) ? true : value && checkValidDate(values[0], values[1], values[2]);
        }
        if (value && !isValidDate(value)) {
          return false;
        }
        if (value) {
          const values = value?.split('/');
          if (!checkValidDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      })
      .test('test-future', 'The Date of Birth can not be in the future.', (value: any) => {
        if (value) {
          const values = value?.split('/');
          if (!checkFutureDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      }),
    language: Yup.array(),
    address1: Yup.string().test(
      'test-name',
      'Valid Address is required when at least one Address field is provided',
      function () {
        const { parent } = this;
        const { address1, address2, city, state, zip_code } = parent as any;
        if (atLeastOneFieldNotEmpty([address1, address2, city, state, zip_code])) {
          return !!address1 && isValidAddress(address1);
        }
        return true;
      },
    ),
    address2: Yup.string(),
    city: Yup.string().test(
      'test-required',
      'City is required when at least one Address field is provided',
      function () {
        const { parent } = this;
        const { address1, address2, city, state, zip_code } = parent as any;
        if (atLeastOneFieldNotEmpty([address1, address2, city, state, zip_code])) {
          return !!city;
        }
        return true;
      },
    ),
    state: Yup.string().test(
      'test-required',
      'State is required when at least one Address field is provided',
      function () {
        const { parent } = this;
        const { address1, address2, city, state, zip_code } = parent as any;
        if (atLeastOneFieldNotEmpty([address1, address2, city, state, zip_code])) {
          return !!state;
        }
        return true;
      },
    ),
    zip_code: Yup.string().test(
      'test-name',
      'Valid Zip Code is required when at least one Address field is provided',
      function () {
        const { parent } = this;
        const { address1, address2, city, state, zip_code } = parent as any;
        if (atLeastOneFieldNotEmpty([address1, address2, city, state, zip_code])) {
          return !!zip_code && isValidZipCode(zip_code);
        }
        return true;
      },
    ),
    phone_number: Yup.string().test('test-name', 'Enter Valid Phone Number', (value: any) => {
      if (value && !isValidPhoneNumber(value)) {
        return false;
      }
      return true;
    }),
    email: isQuickAdd
      ? Yup.string().test('test-name', 'Enter Valid Email', (value: any) => {
          if (value && !isValidEmail(value)) {
            return false;
          }
          return true;
        })
      : Yup.string()
          .required('Email is required')
          .test('test-name', 'Enter Valid Email', (value: any) => {
            if (value && !isValidEmail(value)) {
              return false;
            }
            return true;
          }),
    organization: isQuickAdd
      ? Yup.array()
      : Yup.array().min(1, 'Organization is required').required('Organization is required'),
    insurance_through: Yup.string(),
    insurance_carrier_id: Yup.number().when(['insurance_through'], {
      is: (val: string) => !!val && val !== 'none',
      then: Yup.number().required('Insurance carrier is required'),
      otherwise: Yup.number(),
    }),
    iPhoneNumber: Yup.string().when(['insurance_through'], {
      is: (val: string) => !!val && val !== 'none',
      then: Yup.string().test('test-name', 'Enter Valid Phone Number', (value: any) => {
        if (!value || !isValidPhoneNumber(value)) {
          return false;
        }
        return true;
      }),
      otherwise: Yup.string(),
    }),
    member_id: Yup.string().when(['insurance_through'], {
      is: (val: string) => !!val && val !== 'none',
      then: Yup.string()
        .required('Member ID is required')
        .test('no-empty-space', 'Member ID cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    group_number: Yup.string().when(['insurance_through'], {
      is: (val: string) => !!val && val !== 'none',
      then: Yup.string()
        .required('Group Number is required')
        .test('no-empty-space', 'Group number cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    insurance_address: Yup.string().when(['insurance_through'], {
      is: (val: string) => !!val && val !== 'none',
      then: Yup.string()
        .required('Insurance Address is required')
        .test('no-empty-space', 'Insurance Address cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    insurance_zip_code: Yup.string().when(['insurance_through'], {
      is: (val: string) => !!val && val !== 'none',
      then: Yup.string()
        .required('Insurance Zip Code is required')
        .test('no-empty-space', 'Insurance Zip Code cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    insurance_city: Yup.string().when(['insurance_through'], {
      is: (val: string) => !!val && val !== 'none',
      then: Yup.string()
        .required('Insurance City is required')
        .test('no-empty-space', 'Insurance City cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    insurance_state: Yup.string().when(['insurance_through'], {
      is: (val: string) => !!val && val !== 'none',
      then: Yup.string()
        .required('Insurance State is required')
        .test('no-empty-space', 'Insurance State cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    iFirstName: Yup.string(),
    iMiddleName: Yup.string(),
    iLastName: Yup.string(),
    iDateOfBirth: Yup.string()
      .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value: any) => {
        if (value && !isValidDate(value)) {
          return false;
        }
        if (value) {
          const values = value?.split('/');
          if (!checkValidDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      })
      .test('test-future', 'The Date of Birth can not be in the future.', (value: any) => {
        if (value) {
          const values = value?.split('/');
          if (!checkFutureDate(values[0], values[1], values[2])) {
            return false;
          }
        }
        return true;
      }),
    iAddress1: Yup.string(),
    iCity: Yup.string(),
    iState: Yup.string(),
    iZipCode: Yup.string().test('test-name', 'Not valid zipcode', (value: any) => {
      if (value && !isValidZipCode(value)) {
        return false;
      }
      return true;
    }),
    state_funded_coverage_status: Yup.string().when('insurance_through', {
      is: (val: string) => val && val.trim() !== '' && val !== 'none',
      then: Yup.string().required(
        'Do you currently have insurance coverage through a state-funded program such as Medicaid?****',
      ),
      otherwise: Yup.string(),
    }),
    secondary_insurance_carrier_id: Yup.number().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && addSecondaryInsurance,
      then: Yup.number()
        .required('Secondary Insurance carrier ID is required')
        .test('notZero', 'Secondary Insurance carrier ID is required', function (value) {
          return value !== 0;
        }),
      otherwise: Yup.number(),
    }),
    secondary_member_id: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Member ID is required'),
      otherwise: Yup.string(),
    }),
    secondary_group_number: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Group Number is required'),
      otherwise: Yup.string(),
    }),
    secondary_insurance_address: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Address is required')
        .test('no-empty-space', 'Secondary Insurance Address cannot be empty spaces', (value) => {
          if (value) {
            return !/^\s+$/.test(value);
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_insurance_city: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance City is required'),
      otherwise: Yup.string(),
    }),
    secondary_insurance_state: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance State is required'),
      otherwise: Yup.string(),
    }),
    secondary_insurance_zip_code: Yup.string()
      .when(['secondary_insurance_through'], {
        is: (val: string) => val !== 'none' && addSecondaryInsurance,
        then: Yup.string().required('Secondary Insurance ZipCode is required'),
      })
      .test('test-name', 'Not valid zipcode', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      }),
    secondary_iPhoneNumber: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance phone number is required')
        .test('test-name', 'Enter Valid Phone Number', (value) => {
          if (value && !isValidPhoneNumber(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_iFirstName: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Holder First Name is required')
        .test('test-name', 'Enter Valid First Name', (value) => {
          if (value && !isValidName(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_iMiddleName: Yup.string(),
    secondary_iLastName: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Holder Last Name is required')
        .test('test-name', 'Enter Valid Last Name', (value) => {
          if (value && !isValidName(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_iDateOfBirth: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Holder Date of birth is required')
        .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value) => {
          if (value && !isValidDate(value)) {
            return false;
          }
          if (value) {
            const datevalues = value?.split('/');
            if (!checkValidDate(datevalues[0], datevalues[1], datevalues[2])) {
              return false;
            }
          }
          return true;
        })
        .test('test-future', 'The Secondary Date of Birth can not be in the future.', (value) => {
          if (value) {
            const datevalues = value?.split('/');
            if (!checkFutureDate(datevalues[0], datevalues[1], datevalues[2])) {
              return false;
            }
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    secondary_iAddress1: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance Holder Address is required'),
      otherwise: Yup.string(),
    }),
    secondary_iCity: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance Holder city Required'),
      otherwise: Yup.string(),
    }),
    secondary_iState: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string().required('Secondary Insurance Holder state Required'),
      otherwise: Yup.string(),
    }),
    secondary_iZipCode: Yup.string().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && val !== 'myself' && addSecondaryInsurance,
      then: Yup.string()
        .required('Secondary Insurance Holder ZipCode is required')
        .test('test-name', 'Not valid zipcode', (value) => {
          if (value && !isValidZipCode(value)) {
            return false;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
    organizations: Yup.array()
      .min(1, 'Organization is required')
      .test('has-practice-or-division', 'Each organization must have at least one practice or division', (value) => {
        if (!value) {
          return true; // Allow null or undefined values
        }

        const organizations = value as Organization[]; // Assuming your organization array is of type Organization[]

        const hasPracticeOrDivision = organizations.every(
          (org) => org.divisions?.length > 0 || org.practices?.length > 0,
        );
        return hasPracticeOrDivision;
      }),
  });
};
