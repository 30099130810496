type InputDevices = {
  audioInputDevices: MediaDeviceInfo[];
  videoInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
  hasAudioInputDevices: boolean;
  hasVideoInputDevices: boolean;
};

export async function getDeviceInfo(): Promise<InputDevices> {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoInputDevices = devices.filter((device) => device.kind === 'videoinput');

  // Define common translations for "Front" and "Back" cameras in multiple languages
  const frontKeywords = ['front', 'face', 'facetime', 'ön', '前', 'delantera', '前置', 'frontal', '앞', '前端'];
  const backKeywords = ['back', 'rear', 'arka', '后', 'trasera', 'wide', 'rück', 'posterior', '뒤', 'задняя'];

  // Preferred back camera names in different languages
  const preferredBackNames = [
    'back camera', // English
    'arka kamera', // Turkish
    'cámara trasera', // Spanish
    'caméra arrière', // French
    'rückkamera', // German
    'fotocamera posteriore', // Italian
    'câmera traseira', // Portuguese
    '后摄像头', // Chinese Simplified
    '後置攝像頭', // Chinese Traditional
    '背面カメラ', // Japanese
    '후면 카메라', // Korean
    'задняя камера', // Russian
  ];

  // Separate front and back cameras
  const frontCameras: MediaDeviceInfo[] = [];
  const backCameras: MediaDeviceInfo[] = [];

  // groupId was unreliable for grouping cameras. Instead, we now categorize cameras based on their features (Front vs. Back)
  videoInputDevices.forEach((device) => {
    const label = device.label.toLowerCase();

    if (frontKeywords.some((keyword) => label.includes(keyword))) {
      frontCameras.push(device);
    } else if (backKeywords.some((keyword) => label.includes(keyword))) {
      backCameras.push(device);
    }
  });

  // Select the first Front Camera found
  const selectedCameras: MediaDeviceInfo[] = [];
  if (frontCameras.length > 0) {
    selectedCameras.push(frontCameras[0]);
  }

  // Prioritize Back Camera selection
  let selectedBackCamera: MediaDeviceInfo | null = null;

  // First, check if a preferred name exists
  selectedBackCamera =
    backCameras.find((device) => preferredBackNames.some((name) => device.label.toLowerCase().includes(name))) ||
    backCameras[0]; // If no preferred name, fallback to the first found

  if (selectedBackCamera) {
    selectedCameras.push(selectedBackCamera);
  }

  return {
    audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
    videoInputDevices: selectedCameras,
    audioOutputDevices: devices.filter((device) => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.some((device) => device.kind === 'audioinput'),
    hasVideoInputDevices: selectedCameras.length > 0,
  };
}

export async function isPermissionDenied(name: 'camera' | 'microphone'): Promise<boolean> {
  const permissionName = name as PermissionName;

  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name: permissionName });
      return result.state === 'denied';
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();
