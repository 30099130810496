/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
import Button from '@brands/Components/Button/Button';
import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import ProgressBar from '@brands/Components/ProgressBar/ProgressBar';
import { config } from '@brands/config/config';
import SubmitCCConfirmationModal from '@brands/Dashboard/Dashboard/DashboardModals/SubmitCCConfirmationModal';
import { useOrganizationFlags, useWindowSize } from '@brands/hooks';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { CasePayload, createNewCase } from '@brands/services/cases/createNewCase';
import { CaseType } from '@brands/services/cases/types/ICase';
import { getPatientInsurance } from '@brands/services/identity/getPatientInsurance';
import { getPricings, Pricings } from '@brands/services/identity/getPricings';
import { listDependents } from '@brands/services/identity/listDependents';
import { Insurance, UserProfile } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectOrganizationPrices } from '@brands/store/selectors/organizationPrices';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { setOrganizationPrices } from '@brands/store/slices/organizationPricingSlice';
import {
  clearPageState,
  setDependentId,
  setIsEditInsurance,
  setIsFromSelectPatient,
} from '@brands/store/slices/pageStateSlice';
import { FormValues, setExternalForms, setForm, setSelectedPatient } from '@brands/store/slices/patientFormSlice';
import { setSelectedPatientByAdmin } from '@brands/store/slices/selectedPatientSlice';
import { setCaseId } from '@brands/store/slices/smileScanCaseSlice';
import { calculatPatientAge } from '@brands/Utils/calculateCaseDue';
import { checkExternalFormAnswers } from '@brands/Utils/checkExternalForms';
import { customStylesDashboard } from '@brands/Utils/customStyles';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { getRelationshipName } from '@brands/Utils/getFriendlyName';
import { DEFAULT_PATIENT_LOCATION_STATE } from '@brands/Utils/localStorage';
import { truncateString } from '@brands/Utils/truncateString';
import React, { Fragment, useEffect, useState } from 'react';
import { isMobile as mobileDetect } from 'react-device-detect';
import { IoMdAddCircle } from 'react-icons/io';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import modalClose from '../../../../assets/icons/close.svg';
import Container from '../../../../Components/Container/Container';
import styles from './styles.module.scss';

type UserObject = {
  patient: UserProfile | null;
  patientFullName: string;
  patientInsurance: Insurance[] | null;
  id: string | null;
};

export const VideoCaseNotAvailableModal = ({ onClose }: { onClose: () => void }): JSX.Element => {
  const navigate = useNavigate();
  const backToDashboard = (): void => {
    navigate('/dashboard');
  };
  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      style={customStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
      className={styles.wrapSuccess}
      shouldCloseOnOverlayClick={false}
    >
      <button
        type="button"
        className={styles.modalClose}
        aria-label="Close modal"
        onClick={onClose}
        style={{ backgroundImage: `url(${modalClose})` }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 32,
          padding: 40,
        }}
      >
        <div style={{ fontSize: 20 }}>We are not currently able to offer live video call option in your state</div>
        <Button type="button" onClick={backToDashboard} style={{ width: 240 }}>
          Back to Dashboard
        </Button>
      </div>
    </Modal>
  );
};

const SelectPatient = (): JSX.Element => {
  const screenSize = useWindowSize();
  const { values } = useAppSelector(selectPatientForm);
  const isMobile = screenSize.width < 768;
  const { currentOrganization } = useAppSelector(selectOrganization);
  const organizationPrices = useAppSelector(selectOrganizationPrices);
  const currentState = useAppSelector(selectCurrentPatientState);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const routeCaseType = searchParams.get('case_type');
  const routeCurrentUserId = searchParams.get('current_user_id');
  const { state } = useLocation();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [submitCCConfirmation, setSubmitCCConfirmation] = useState(false);
  const caseType: string = !routeCaseType
    ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string)
    : routeCaseType;
  const currentOrganizationPrices = organizationPrices?.currentOrganizationPrices?.find(
    (currentOrganizationPrise: Pricings) => currentOrganizationPrise.case_types.includes(caseType as string),
  );

  const isPaymentRequired = currentOrganization.payment_options.length > 0;
  const [patientOptions, setPatientOptions] = useState<UserObject[]>([]);
  const [selectedPatientId, setSelectedPatientId] = useState<string>(state?.currentUserId || routeCurrentUserId || '');
  const [loading, setLoading] = useState(false);
  const { userInfo } = useAppSelector(selectAuth);
  const [hasFullProfile, setHasFullProfile] = useState(false);

  const { videoCaseEnabled } = useOrganizationFlags(currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE);

  const checkExternalForms = async (selectedPatientId: number): Promise<string[]> => {
    const [checkProfile, checkMedicalHistory, checkMedicalConditionsFormAnswer, checkDentalHistoryFormAnswer] =
      await checkExternalFormAnswers(
        userInfo,
        patientOptions.find((option) => option.id === selectedPatientId.toString())?.patient || null,
      );

    const formsToSet: string[] = [];

    if (!checkProfile) {
      formsToSet.push('patient_profile');
    }
    if (!checkMedicalHistory) {
      formsToSet.push('patient_medical_history');
    }
    if (!checkMedicalConditionsFormAnswer) {
      formsToSet.push('patient_medical_conditions');
    }
    if (!checkDentalHistoryFormAnswer) {
      formsToSet.push('patient_dental_history');
    }

    dispatch(setExternalForms(formsToSet));
    return formsToSet;
  };

  const fetchData = (selectedPatientId: number): Promise<string[]> => {
    setFetchLoading(true);
    return checkExternalForms(selectedPatientId);
  };

  const createCaseForThisRequirement = async (patientId: number, caseType: string): Promise<void> => {
    const payload: CasePayload = {
      type: caseType,
      status: 'new',
      patient_id: Number(userInfo.id),
      organization_id: currentOrganization.id,
      provider_id: undefined,
      payment_profile_id: null,
      video_conference_date: null,
      patient_current_state: currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE,
      dependent_id: Number(userInfo.id) === Number(patientId) ? null : patientId,
      case_intake_questions: {
        is_dental_emergency: false,
        in_pain: false,
        pain_description: undefined,
        pain_time: undefined,
        pain_level: undefined,
        additional_info: '',
      },
    };
    const caseRes = await createNewCase(payload);
    dispatch(setCaseId(caseRes.id.toString()));
    if (caseType === 'smile_scan') {
      let prefix = '';
      if (config.iot.prefix === 'develop') {
        prefix = 'dev-';
      } else if (config.iot.prefix === 'qa') {
        prefix = 'qa-';
      }
      const url = `https://selfcheck.toothlens.com/tpscan/done/${prefix}${caseRes.id}`;
      if (isMobile || mobileDetect) {
        window.location.href = url;
      } else {
        setFetchLoading(false);
        navigate('/smile-scan-link', {
          state: {
            caseType,
            patientState: userInfo.address?.state,
            userOrganization: currentOrganization.id,
            patientId: userInfo.id,
          },
        });
      }
    } else {
      setSubmitCCConfirmation(!submitCCConfirmation);
    }
  };

  const onSubmit = async (selectedPatient: UserObject): Promise<void> => {
    if (selectedPatient.patientFullName === 'Myself') {
      dispatch(setIsFromSelectPatient(true));
      dispatch(setDependentId(null));
      navigate('/create-your-profile', {
        state: { caseType },
      });
    } else if (selectedPatient.patientFullName === 'A Dependent') {
      dispatch(setIsFromSelectPatient(true));
      dispatch(setDependentId('1'));
      navigate('/create-your-profile', {
        state: { caseType },
      });
    } else {
      dispatch(
        setSelectedPatient({
          value: userInfo.id === selectedPatient.patient?.id ? userInfo.id : selectedPatient.patient?.id,
          label: userInfo.id === selectedPatient.patient?.id ? userInfo.full_name : selectedPatient.patientFullName,
        }),
      );
      dispatch(setSelectedPatientByAdmin({ selectedPatient: selectedPatient.patient }));

      if (selectedPatient.id) {
        const data = await fetchData(Number(selectedPatient.id));
        if (data.length !== 0 && data.includes('patient_profile')) {
          dispatch(setIsFromSelectPatient(true));
          dispatch(
            setDependentId(
              userInfo.id === selectedPatient.patient?.id ? null : (selectedPatient.patient?.id?.toString() as string),
            ),
          );
          return navigate('/my-profile', {
            state: {
              caseType,
            },
          });
        }
      }

      if (
        isPaymentRequired &&
        (currentOrganizationPrices?.amount?.toString() !== '0' || currentOrganizationPrices?.collect_insurance_info)
      ) {
        navigate('/payment-information', {
          state: { caseType },
        });
      } else if (caseType === 'video_call_scheduled') {
        navigate('/select-date-time', {
          state: {
            caseType: 'video_call_scheduled',
            insuranceCarrierId: '',
            customInsuranceCarrier: '',
            userOrganization: currentOrganization.id,
            patientId: userInfo.id,
          },
        });
      } else if (caseType === CaseType.phone_call_scheduled) {
        navigate('/select-date-time', {
          state: {
            caseType: CaseType.phone_call_scheduled,
            insuranceCarrierId: '',
            customInsuranceCarrier: '',
            userOrganization: currentOrganization.id,
            patientId: userInfo.id,
          },
        });
      } else if (caseType === CaseType.oral_health_coaching && currentOrganizationPrices?.amount.toString() === '0') {
        navigate('/select-date-time', {
          state: {
            caseType: CaseType.oral_health_coaching,
            insuranceCarrierId: '',
            customInsuranceCarrier: '',
            userOrganization: currentOrganization.id,
            patientId: userInfo.id,
          },
        });
      } else if (currentOrganizationPrices?.amount.toString() === '0' && caseType === 'second_opinion') {
        navigate('/upload-image', {
          state: {
            caseType,
            patientState: userInfo.address?.state,
            userOrganization: currentOrganization.id,
            patientId: userInfo.id,
          },
        });
      } else if (caseType === CaseType.care_coordination && currentOrganizationPrices?.amount?.toString() === '0') {
        createCaseForThisRequirement(selectedPatient.patient?.id || 0, CaseType.care_coordination);
      } else if (caseType === 'smile_scan') {
        createCaseForThisRequirement(selectedPatient.patient?.id || 0, CaseType.smile_scan);
      } else {
        navigate('/select-needs', {
          state: {
            caseType,
            patientState: userInfo.address?.state,
            userOrganization: currentOrganization.id,
            patientId: userInfo.id,
          },
        });
      }
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  dispatch(setIsFromSelectPatient(false));
  dispatch(setDependentId(null));

  useEffect(() => {
    if (state?.currentUserId) setSelectedPatientId(state?.currentUserId || '');
    if (routeCurrentUserId) setSelectedPatientId(routeCurrentUserId || '');
  }, [state?.currentUserId, routeCurrentUserId]);

  useEffect(() => {
    (async () => {
      dispatch(clearPageState());

      if (routeCaseType) {
        const formPayload: FormValues[] = [
          {
            form_question_id: 20,
            value: {
              caseType: 'video_call_scheduled',
            },
          },
        ];

        dispatch(setForm(formPayload));
      }

      const fetchUserInfo = async (): Promise<void> => {
        try {
          setLoading(true);
          const [, , , , checkEssentialProfileFormAnswer] = await checkExternalFormAnswers(userInfo, null);
          setHasFullProfile(checkEssentialProfileFormAnswer);

          if (checkEssentialProfileFormAnswer) {
            const listOfDependents = await listDependents(userInfo.id, { include_deleted: false });
            const insurancePromises = listOfDependents.map(async (dependent) => {
              const insurance = caseType !== CaseType.smile_scan ? await getPatientInsurance(dependent.id) : [];

              return {
                patient: dependent,
                patientFullName: dependent.full_name,
                patientInsurance: insurance,
                id: dependent.id?.toString(),
              };
            });

            const dependentUserObjects = await Promise.all(insurancePromises);

            const userInsurance = caseType !== CaseType.smile_scan ? await getPatientInsurance() : [];

            const options = [
              {
                patient: userInfo,
                patientFullName: userInfo.full_name,
                patientInsurance: userInsurance,
                id: userInfo.id?.toString(),
              },
              ...dependentUserObjects,
            ];

            setPatientOptions(options);
          } else {
            setPatientOptions([
              {
                patient: null,
                patientFullName: 'Myself',
                patientInsurance: null,
                id: userInfo.id?.toString(),
              },
              {
                patient: null,
                patientFullName: 'A Dependent',
                patientInsurance: null,
                id: '0',
              },
            ]);
          }
          setLoading(false);
        } catch (error: unknown) {
          displayErrorDetails(error);
        }
      };

      await fetchUserInfo();

      const handleVisibilityChange = (): void => {
        if (document.visibilityState === 'visible' && caseType === CaseType.smile_scan) {
          window.location.reload();
        }
      };

      const getPricingsFn = async (): Promise<void> => {
        setLoading(true);
        try {
          const pricings = await getPricings();
          dispatch(setOrganizationPrices({ currentOrganizationPrices: pricings }));
        } catch (error: unknown) {
          displayErrorDetails(error);
        }
        setLoading(false);
      };

      if (!organizationPrices?.currentOrganizationPrices) {
        await getPricingsFn();
      }

      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    })();
  }, []);

  const initializeComponent = (): void => {
    window.location.reload();
  };

  const handleVisibilityChange = (): void => {
    if (document.visibilityState === 'visible' && caseType === CaseType.smile_scan) {
      initializeComponent();
    }
  };

  document.addEventListener('visibilitychange', handleVisibilityChange);

  return !videoCaseEnabled && (caseType === 'video_call_scheduled' || caseType === CaseType.phone_call_scheduled) ? (
    <VideoCaseNotAvailableModal onClose={() => navigate('/dashboard')} />
  ) : (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        overflow: 'hidden',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width < 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2', paddingTop: screenSize.width > 768 ? '40px' : '16px' }}
    >
      <div className={`fs-unmask ${styles.patientFormContainer}`}>
        <ProgressBar
          currentStep={0}
          totalSteps={0}
          hideStepNumbers
          onBack={() => (caseType !== 'smile_scan' ? navigate('/dashboard') : navigate('/smile-scan'))}
        />
        <div
          className={`${styles.formTitle} fs-unmask mt-3 ${caseType === 'smile_scan' && styles.mobileSmileScanHeader}`}
        >
          {caseType === 'smile_scan'
            ? 'Who is this AI SmileScan for?'
            : caseType !== 'oral_health_coaching'
            ? 'Who needs to talk to a dentist today?'
            : 'Who needs to talk to an oral health coach today?'}
        </div>
        {!loading ? (
          <form
            className={styles.formContainer}
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(patientOptions.find((option) => option.id === selectedPatientId)!);
            }}
          >
            <div className={styles.wrapper}>
              <div className={styles.formContent}>
                <div className={styles.buttonsContainer}>
                  {patientOptions.map((element) => (
                    <Fragment key={element.patient?.id}>
                      <input
                        type="radio"
                        className="d-none fs-unmask"
                        value={element.id as string}
                        id={element.id as string}
                        onClick={() => {
                          setSelectedPatientId(element.id as string);
                        }}
                      />
                      <div>
                        <label
                          className={`${styles.mobileLabel} ${
                            selectedPatientId === element.id ? styles.selectedClass : styles.btn
                          }`}
                          htmlFor={element.id || ''}
                        >
                          <div className={styles.labelWrap}>
                            <span className="fs-exclude">{element.patientFullName}</span>
                            {element.patientFullName !== 'Myself' && element.patientFullName !== 'A Dependent' && (
                              <span className="fs-exclude">
                                {userInfo.id === element.patient?.id
                                  ? 'You, '
                                  : getRelationshipName(
                                      element.patient?.guardian_relationship as string,
                                      element.patient?.basic_info?.gender || '',
                                    )}
                                {element.patient?.basic_info?.date_of_birth &&
                                  element.patient?.basic_info?.date_of_birth !== '' &&
                                  `${calculatPatientAge(
                                    element.patient?.basic_info?.date_of_birth as string,
                                  )} years old`}
                              </span>
                            )}
                            {caseType !== CaseType.smile_scan &&
                              element.patientFullName !== 'Myself' &&
                              element.patientFullName !== 'A Dependent' &&
                              element.patientInsurance !== null &&
                              element.patientInsurance?.length > 0 && (
                                <div className={`fs-exclude ${styles.insuranceInfo}`}>
                                  <span className={styles.label}>Insurance Plan: </span>
                                  <span className={`fs-exclude ${styles.insuranceList}`}>
                                    {element.patientInsurance?.map((insurance, index) => (
                                      <span key={index}>
                                        {truncateString(
                                          (insurance.insurance_membership?.insurance_carrier?.name as string) || '',
                                          25,
                                        )}{' '}
                                        {insurance.insurance_membership?.member_id}
                                      </span>
                                    ))}
                                  </span>
                                  <button
                                    className={styles.editBtn}
                                    type="button"
                                    onClick={() => {
                                      dispatch(setIsFromSelectPatient(true));
                                      dispatch(
                                        setDependentId(
                                          userInfo.id === element.patient?.id
                                            ? null
                                            : (element.patient?.id?.toString() as string),
                                        ),
                                      );
                                      dispatch(setIsEditInsurance(true));
                                      dispatch(
                                        setSelectedPatientByAdmin({
                                          selectedPatient: patientOptions.find(
                                            (option) => option.id === element.patient?.id?.toString(),
                                          )?.patient as UserProfile,
                                        }),
                                      );
                                      navigate('/my-profile', {
                                        state: {
                                          caseType: values.find((item) => item.form_question_id === 20)?.value
                                            .caseType as string,
                                        },
                                      });
                                    }}
                                  >
                                    Edit
                                  </button>
                                </div>
                              )}
                          </div>
                          <div
                            className={`${styles.bntIcon} ${
                              selectedPatientId === element.id ? styles.primaryColor : ''
                            }`}
                          />
                        </label>
                      </div>
                    </Fragment>
                  ))}
                  {hasFullProfile && (
                    <Button
                      className={`fs-unmask ${styles.addBtn}`}
                      type="button"
                      onClick={() => {
                        dispatch(setIsFromSelectPatient(true));
                        dispatch(setDependentId('0'));
                        navigate('/create-your-profile', {
                          state: {
                            caseType,
                          },
                        });
                      }}
                    >
                      Add a Dependent
                      <IoMdAddCircle className={styles.addIcon} />
                    </Button>
                  )}
                  <Button
                    type="submit"
                    onTouchStart={
                      isMobile || mobileDetect
                        ? (e) => {
                            e.preventDefault();
                            onSubmit(patientOptions.find((option) => option.id === selectedPatientId)!);
                          }
                        : undefined
                    }
                    className={`${styles.bntSubmit} fs-unmask`}
                    style={{
                      width: caseType === 'smile_scan' && (isMobile || mobileDetect) ? '90%' : '245px',
                      height: '46px',
                      borderRadius: caseType === 'smile_scan' && (isMobile || mobileDetect) ? '200px' : '10px',
                    }}
                    disabled={selectedPatientId == '' || loading || fetchLoading}
                  >
                    {caseType === CaseType.care_coordination &&
                    currentOrganizationPrices?.amount?.toString() === '0' &&
                    patientOptions.some(
                      (element) => element.patientFullName !== 'Myself' && element.patientFullName !== 'A Dependent',
                    )
                      ? 'Finish'
                      : 'Next'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <span>
            <Loading dotted />
          </span>
        )}
        {submitCCConfirmation && <SubmitCCConfirmationModal />}
      </div>
    </Container>
  );
};
export default SelectPatient;
