import React, { Dispatch, FC, memo, SetStateAction } from 'react';

import { useScreenShareParticipant, useVideoContext, useWindowSize } from '../../hooks';
import MainParticipant from '../MainParticipant/MainParticipant';
import MenuBar from '../MenuBar/MenuBar';
import ParticipantAudioTracks from '../ParticipantAudioTracks/ParticipantAudioTracks';
import ParticipantList from '../ParticipantList/ParticipantList';
import VideoConversationDetail from '../VideoConversation/VideoConversationDetail';
import VideoRoomSidenav from '../VideoRoomSidenav/VideoRoomSidenav';
import styles from './styles.module.scss';

interface IRoomProps {
  participantsOnScreen: boolean;
  setParticipantsOnScreen: Dispatch<SetStateAction<boolean>>;
  endCurrentCall?: boolean;
  uploadFile?: (file: File) => Promise<void>;
  showVideoThumbnail?: boolean;
}

const CurrentRoom: FC<IRoomProps> = ({
  participantsOnScreen,
  setParticipantsOnScreen,
  endCurrentCall,
  uploadFile,
  showVideoThumbnail,
}) => {
  const { room } = useVideoContext();
  const { width } = useWindowSize();
  const localParticipant = room?.localParticipant;
  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;
  const [chatOnScreen, setChatOnScreen] = React.useState(false);
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;

  return (
    <div className={styles.roomContainer}>
      {room !== undefined && (
        <div className={styles.bodyAndFooterContainer}>
          <ParticipantAudioTracks />
          <div
            className={`${styles.bodyContainer} ${chatOnScreen && width <= 768 ? 'd-none' : ''} ${
              showVideoThumbnail && styles.thumbnailWrap
            }`}
            style={{ width: chatOnScreen || participantsOnScreen ? (width > 768 ? '75%' : '100%') : '100%' }}
          >
            <MainParticipant />
            {!isRemoteParticipantScreenSharing && <ParticipantList showVideoThumbnail={showVideoThumbnail} />}
            {!showVideoThumbnail && (
              <MenuBar
                chatOnScreen={chatOnScreen || participantsOnScreen}
                setChatOnScreen={setChatOnScreen}
                participantsOnScreen={participantsOnScreen}
                setParticipantsOnScreen={setParticipantsOnScreen}
                endCurrentCall={endCurrentCall}
                uploadFile={uploadFile}
              />
            )}
          </div>
          <div
            className={styles.videoConversationDetail}
            style={{
              width: chatOnScreen ? (width > 768 ? '25%' : '100%') : '0%',
              display: !chatOnScreen ? 'none' : '',
            }}
          >
            <VideoConversationDetail setChatOnScreen={setChatOnScreen} />
          </div>
          {!isMobile && (
            <div
              className={styles.videoParticipantsDetail}
              style={{
                width: participantsOnScreen ? (width > 768 ? '25%' : '100%') : '0%',
                display: !participantsOnScreen ? 'none' : '',
              }}
            >
              <VideoRoomSidenav
                participantsOnScreen={participantsOnScreen}
                setParticipantsOnScreen={setParticipantsOnScreen}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

CurrentRoom.defaultProps = {
  endCurrentCall: false,
  uploadFile: undefined,
  showVideoThumbnail: false,
};

export default memo(CurrentRoom);
