/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unsafe-optional-chaining */
import { listClinicalData } from '@brands/services/clinical_data/listClinicalData';
import { MedicalHistory } from '@brands/services/clinical_data/types/types';
import { getMyTags } from '@brands/services/tags/getMyTags';
import { selectAuth } from '@brands/store/selectors/auth';
import { insuranceThroughOptions } from '@brands/Utils/selectOptions';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { AiOutlineCheckCircle, AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai';
import { BiArrowBack, BiTargetLock } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';
import Modal from 'react-modal';
import { CSSObjectWithLabel } from 'react-select';

import MultiselectInput from '../../../Components/Inputs/MultiselectInput/MultiselectInput';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import { useWindowSize } from '../../../hooks';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { getOneCase } from '../../../services/cases/getCaseById';
import { getUserAvatar } from '../../../services/identity/getUserAvatar';
import { Insurance, UserProfile, UserRoleName } from '../../../services/identity/types/UserProfile';
import { getTagsByUserId } from '../../../services/tags/getTagsByUserId';
import { listAllTags } from '../../../services/tags/listAllTags';
import { setUserTag } from '../../../services/tags/setUserTag';
import { customStylesDashboard, multiSelectCustomStyles } from '../../../Utils/customStyles';
import { displayErrorDetails } from '../../../Utils/displayError';
import { getSecondary } from '../../../Utils/getPrimaryColor';
import styles from './styles.module.scss';

interface ProfileCardModalProps {
  isOpen: boolean;
  toggleModal: (bool: boolean) => void;
  patientIdentity: UserProfile | null;
  insuranceDetails?: any;
  caseId?: number;
}

interface Option {
  readonly label: string;
  readonly value: string;
  readonly isNew?: boolean;
}

interface Tag {
  readonly label: string;
  readonly value: string;
}

const profileCardsMultiSelectStyles = {
  ...multiSelectCustomStyles,
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '100%',
    paddingLeft: '0px',
    paddingRight: '0px',
    flex: 'none',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
};

const PatientProfileCardModal: FC<ProfileCardModalProps> = ({
  isOpen,
  toggleModal,
  patientIdentity,
  insuranceDetails,
  caseId,
}) => {
  const { userInfo } = useAppSelector(selectAuth);
  const [profileCardTags, setProfileCardTags] = React.useState<Option[]>([]);
  const [messageHistoryVisible, setMessageHistoryVisible] = React.useState<boolean>(false);
  const [medicalHistoryVisible, setMedicalHistoryVisible] = React.useState<boolean>(false);
  const [appointmentHistoryVisible, setAppointmentHistoryVisible] = React.useState<boolean>(false);
  const [addTagsInput, setAddTagsInput] = React.useState(false);
  const [conditionTags, setConditionTags] = React.useState<Tag[]>([]);
  const [values, setValues] = React.useState<any>([]);
  const [tagsList, setTagsList] = useState<Option[]>([]);
  const isMobile = useWindowSize().width < 992;
  const [loading, setLoading] = useState<boolean>(false);
  const mobileCustomStylesDashboard = {
    ...customStylesDashboard,
    overlay: {
      ...customStylesDashboard.overlay,
      overflowY: 'scroll',
    },
    content: {
      ...customStylesDashboard.content,
      transform: `translate(-50%, ${medicalHistoryVisible ? '-5%' : appointmentHistoryVisible ? '10%' : '-25%'})`,
      width: '90%',
    },
  };

  const [patientIdentityFromCase, setPatientIdentity] = useState<UserProfile | null>(null);
  const insurances: Insurance[] = patientIdentity?.patient_profile?.insurances ?? [];
  const [patientInsurance, setPatientInsurance] = useState<Insurance | undefined>(insurances[insurances.length - 1]);

  const selectedInsurance = insuranceDetails?.insured_by ?? patientInsurance?.insured_by;

  const insuranceDisplay =
    insuranceThroughOptions.find((insuranceDetail) => insuranceDetail.value === selectedInsurance) || '-';

  const initIdentity = async (): Promise<void> => {
    try {
      if (caseId && patientIdentity?.email === undefined) {
        const thisCase = await getOneCase(caseId);
        if (thisCase) {
          setPatientIdentity(thisCase.patient);
          if (thisCase.insurance) setPatientInsurance(thisCase.insurance);
        }
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    initIdentity();
  }, []);

  const handleSubmit = async (e: any): Promise<any> => {
    e.preventDefault();
    const newTags = values.map((tag: Option) => ({ label: tag.label, value: tag.value, isNew: true }));
    await newTags.map((newTag: any) =>
      setUserTag(patientIdentity?.id || 0, Number(newTag.value), {
        answer: true,
        note: 'yes',
      }),
    );
    setProfileCardTags((prevTags) => [...prevTags, ...values]);
    setAddTagsInput(false);
    setValues([]);
  };

  const removeTag = (tag: Option): void => {
    const newTags = profileCardTags.filter((t) => t.value !== tag.value);
    setConditionTags(newTags);
    setProfileCardTags(newTags);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else if (document.body.classList.contains('modal-open') && !isOpen) {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);
  const closeModal = (): void => {
    toggleModal(false);
  };
  const goBack = (): void => {
    setMedicalHistoryVisible(false);
    setMessageHistoryVisible(false);
    setAppointmentHistoryVisible(false);
  };

  const [avatarIcon, setAvatarIcon] = useState(false);
  const handleAvatarError = (): void => {
    setAvatarIcon(true);
  };

  const [medicalHistory, setMedicalHistory] = useState<MedicalHistory | null>(null);

  const getFormAnswers = async (): Promise<void> => {
    const { medical_history } = await listClinicalData(patientIdentity?.id || 0);
    setMedicalHistory(medical_history);
  };

  const fetchOptions = async (): Promise<void> => {
    const response = await listAllTags();
    const options: Option[] = response
      .filter((item: any) => !item.patient_owned)
      .map((item: any) => ({
        value: item.id,
        label: item.name,
        patient_owned: item.patient_owned,
      }));
    setTagsList(options);
  };

  useEffect(() => {
    fetchOptions();
    getFormAnswers();
  }, []);

  const fetchUserTags = async (): Promise<void> => {
    if (patientIdentity?.id) {
      try {
        let response;
        if (userInfo.role.name === UserRoleName.Patient) {
          response = await getMyTags();
        } else if (userInfo.role.name === UserRoleName.Dependent) {
          response = await getMyTags(patientIdentity?.id);
        } else {
          response = await getTagsByUserId(Number(patientIdentity?.id));
        }
        if (response.length !== 0) {
          const options: Option[] = response
            .filter((tag: any) => tag.answer)
            .map((item: any) => ({
              value: item.tag.description,
              label: item.tag.description === 'Conditions not listed' ? item.note : '',
            }));
          setProfileCardTags(options);
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchUserTags();
      setLoading(false);
    })();
  }, [patientIdentity?.id, conditionTags]);

  const displayPhoneNumber = useMemo(() => {
    if (patientIdentity?.phone_number) {
      const phoneNumber = parsePhoneNumber(patientIdentity.phone_number, 'US');
      if (phoneNumber) {
        return phoneNumber.formatNational();
      }
    } else if (patientIdentity?.guardian?.phone_number) {
      const phoneNumber = parsePhoneNumber(patientIdentity?.guardian?.phone_number, 'US');
      if (phoneNumber) {
        return phoneNumber.formatNational();
      }
    } else if (patientIdentityFromCase?.phone_number) {
      const phoneNumber = parsePhoneNumber(patientIdentityFromCase.phone_number, 'US');
      if (phoneNumber) {
        return phoneNumber.formatNational();
      }
    } else if (patientIdentityFromCase?.guardian?.phone_number) {
      const phoneNumber = parsePhoneNumber(patientIdentityFromCase?.guardian?.phone_number, 'US');
      if (phoneNumber) {
        return phoneNumber.formatNational();
      }
    }

    return '';
  }, [patientIdentity, patientIdentityFromCase]);

  const displayEmail = useMemo(() => {
    if (patientIdentity?.email) {
      return patientIdentity?.email;
    }
    if (patientIdentity?.guardian?.email) {
      return patientIdentity?.guardian?.email;
    }
    if (patientIdentityFromCase?.email) {
      return patientIdentityFromCase?.email;
    }
    if (patientIdentityFromCase?.guardian?.email) {
      return patientIdentityFromCase?.guardian?.email;
    }

    return '';
  }, [patientIdentity, patientIdentityFromCase]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={isMobile ? mobileCustomStylesDashboard : customStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
    >
      {loading ? (
        <div className={styles.loadingBarContainer}>
          <Loading dotted />
        </div>
      ) : (
        <>
          <div
            className={styles.profileCard}
            style={{
              display: medicalHistoryVisible || appointmentHistoryVisible || messageHistoryVisible ? 'none' : '',
            }}
          >
            <button
              type="button"
              className={`fs-unmask ${styles.modalClose}`}
              data-micromodal-close=""
              onClick={closeModal}
              aria-label="Close modal"
            >
              <AiOutlineCloseCircle className={styles.icon} />
            </button>
            <div className={styles.titleContainer}>
              <div className={styles.profileContainer}>
                <div
                  className={`${styles.imageContainer} ${
                    (patientIdentityFromCase && patientIdentityFromCase?.status === 'Active') ||
                    (patientIdentity && patientIdentity?.status === 'Active')
                      ? ''
                      : styles.greyOutStyle
                  }`}
                >
                  {!avatarIcon ? (
                    <img
                      alt="Avatar"
                      onError={handleAvatarError}
                      className={`fs-exclude ${styles.providerImage}`}
                      src={getUserAvatar(patientIdentity?.id || 0)}
                    />
                  ) : (
                    <CgProfile className='fs-exclude' />
                  )}
                </div>
                <div className={styles.textContainer}>
                  <div
                    className={`${styles.title} fs-exclude ${
                      (patientIdentityFromCase && patientIdentityFromCase?.status === 'Active') ||
                      (patientIdentity && patientIdentity?.status === 'Active')
                        ? ''
                        : styles.greyOutStyle
                    }`}
                  >
                    {patientIdentity?.full_name === undefined
                      ? patientIdentityFromCase?.full_name
                      : patientIdentity?.full_name}
                  </div>
                  <div className={styles.details}>
                    <div className={`${styles.date}`}>
                      B. Date:{' '}
                      <span className='fs-exclude'>{patientIdentity?.basic_info !== undefined
                        ? moment(patientIdentity?.basic_info?.date_of_birth).format('MM/DD/YYYY')
                        : moment(patientIdentityFromCase?.basic_info?.date_of_birth).format('MM/DD/YYYY')}</span>
                    </div>
                    <div className={`fs-exclude ${styles.gender}`}>
                      {patientIdentity?.basic_info !== undefined
                        ? patientIdentity?.basic_info?.gender
                        : patientIdentityFromCase?.basic_info?.gender}{' '}
                      -{' '}
                      {patientIdentity?.basic_info !== undefined
                        ? patientIdentity?.basic_info?.pronouns
                        : patientIdentityFromCase?.basic_info?.pronouns}
                    </div>
                    <div className={`fs-exclude ${styles.state}`}>
                      <BiTargetLock className={styles.icon} />
                      {patientIdentity?.basic_info !== undefined
                        ? patientIdentity?.address?.state
                        : patientIdentityFromCase?.address?.state}{' '}
                      -{' '}
                      {patientIdentity?.basic_info !== undefined
                        ? patientIdentity?.address?.zip_code
                        : patientIdentityFromCase?.address?.zip_code}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column w-100">
                <div className={styles.insuranceProfile}>
                  <div className={styles.insurancePlan}>
                    <div className={`fs-unmask ${styles.label}`}>Language(s)</div>
                    <div className={`fs-mask ${styles.value}`}>
                      {patientIdentity?.basic_info !== undefined
                        ? patientIdentity?.basic_info?.languages
                            ?.map((language) => typeof language !== 'string' && language.name)
                            .join(', ')
                        : patientIdentityFromCase?.basic_info?.languages
                            ?.map((language) => typeof language !== 'string' && language.name)
                            .join(', ')}
                    </div>
                  </div>
                  <div className={styles.insurancePlan}>
                    <div className={`fs-unmask ${styles.label}`}>Insurance By</div>
                    <div className={`fs-exclude ${styles.value}`}>
                      {typeof insuranceDisplay === 'string'
                        ? insuranceDisplay
                        : insuranceDisplay && 'label' in insuranceDisplay
                        ? insuranceDisplay.label
                        : '-'}
                    </div>
                  </div>
                  <div className={styles.brandAssociation}>
                    <div className={`fs-unmask ${styles.label}`}>Organization</div>
                    <div className={`fs-exclude ${styles.value}`}>
                      {patientIdentity?.basic_info !== undefined
                        ? patientIdentity?.organizations?.map((organization) => organization.name).join(', ')
                        : patientIdentityFromCase?.organizations?.map((organization) => organization.name).join(', ')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.profileCardBody}>
              {userInfo?.role?.name !== UserRoleName.CareAdvisor ? (
                <div className={`${styles.medicalInformations} ${styles.nonCAView}`}>
                  <div
                    className={`${styles.insuranceProfile} ${styles.profileDetails}`}
                    style={{ paddingTop: '0rem', paddingLeft: '0px' }}
                  >
                    <div className={styles.language}>
                      <div className={`fs-unmask ${styles.label}`}>Phone Number</div>
                      <div className={`fs-exclude ${styles.value}`}>{displayPhoneNumber}</div>
                    </div>
                    <div className={styles.language}>
                      <div className={`fs-unmask ${styles.label}`}>Email Address</div>
                      <div className={`fs-exclude ${styles.value}`}>{displayEmail}</div>
                    </div>
                    <div className={`${styles.language} ${styles.address}`}>
                      <div className={`fs-unmask ${styles.label}`}>Address</div>
                      <div className={`fs-exclude ${styles.value}`}>
                        {patientIdentity?.basic_info !== undefined
                          ? `${patientIdentity?.address?.address1} ${
                              patientIdentity?.address?.address2 ? `, ${patientIdentity?.address?.address2}` : ''
                            }, ${patientIdentity?.address?.city}, ${patientIdentity?.address?.state}, ${
                              patientIdentity?.address?.zip_code
                            }`
                          : `${patientIdentityFromCase?.address?.address1}${
                              patientIdentityFromCase?.address?.address2
                                ? `, ${patientIdentityFromCase?.address?.address2}`
                                : ''
                            }, ${patientIdentityFromCase?.address?.city}, ${patientIdentityFromCase?.address?.state}, ${
                              patientIdentityFromCase?.address?.zip_code
                            }`}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={`fs-unmask ${styles.title}`}>Medical and Dental Information</div>
                    <div className={`fs-exclude ${styles.tags}`}>
                      {profileCardTags.map((tag) => (
                        <div
                          className={`fs-exclude ${styles.tag}`}
                          style={{
                            color: tag.isNew ? getSecondary() : '',
                            borderColor: tag.isNew ? getSecondary() : '',
                          }}
                          key={tag.value}
                        >
                          <div className={`fs-exclude ${styles.label}`}>
                            <AiOutlineClose
                              style={{ display: tag.isNew ? '' : 'none', cursor: 'pointer' }}
                              onClick={() => removeTag(tag)}
                            />{' '}
                            {tag.label || tag.value}
                          </div>
                        </div>
                      ))}
                      <form onSubmit={handleSubmit} style={{ display: addTagsInput ? '' : 'none', width: '85%' }}>
                        <MultiselectInput
                          containerClass="mt-0"
                          name="tags"
                          placeholder="Stroke, Pain, Bleeding"
                          style={profileCardsMultiSelectStyles}
                          options={tagsList}
                          selectedValue={values}
                          onChange={(value) => value && setValues(value)}
                        />
                        <div className={styles.saveTags}>
                          <button type="submit">
                            <AiOutlineCheckCircle className={styles.icon} />
                            Save the tags
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className={styles.question}>
                      <div className={`fs-unmask ${styles.label}`}>Are you allergic to any medication?</div>
                      <div className={`fs-exclude ${styles.value}`}>{medicalHistory?.medications_allergies}</div>
                    </div>
                    <div className={styles.question}>
                      <div className={`fs-unmask ${styles.label}`}>Are you currently taking any medications?</div>
                      <div className={`fs-exclude ${styles.value}`}>{medicalHistory?.medications}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.medicalInformations}>
                  <div
                    className={`${styles.insuranceProfile} ${styles.profileDetails}`}
                    style={{ paddingTop: '0rem', paddingLeft: '0px' }}
                  >
                    <div className={styles.language}>
                      <div className={`fs-unmask ${styles.label}`}>Phone Number</div>
                      <div className={`fs-exclude ${styles.value}`}>{displayPhoneNumber}</div>
                    </div>
                    <div className={styles.language}>
                      <div className={`fs-unmask ${styles.label}`}>Email Address</div>
                      <div className={`fs-exclude ${styles.value}`}>{displayEmail}</div>
                    </div>
                    <div className={`${styles.language} ${styles.address}`}>
                      <div className={`fs-unmask ${styles.label}`}>Address</div>
                      <div className={`fs-exclude ${styles.value}`}>
                        {patientIdentity?.basic_info !== undefined
                          ? `${patientIdentity?.address?.address1} ${
                              patientIdentity?.address?.address2 ? `, ${patientIdentity?.address?.address2}` : ''
                            }, ${patientIdentity?.address?.city}, ${patientIdentity?.address?.state}, ${
                              patientIdentity?.address?.zip_code
                            }`
                          : `${patientIdentityFromCase?.address?.address1}${
                              patientIdentityFromCase?.address?.address2
                                ? `, ${patientIdentityFromCase?.address?.address2}`
                                : ''
                            }, ${patientIdentityFromCase?.address?.city}, ${patientIdentityFromCase?.address?.state}, ${
                              patientIdentityFromCase?.address?.zip_code
                            }`}
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.insuranceProfile}
                    style={{ paddingTop: '0rem', paddingLeft: '0px', justifyContent: 'space-between' }}
                  >
                    <div className={styles.language}>
                      <div className={styles.insuranceWidth}>
                        <div className={`fs-unmask ${styles.label}`}>Insurance</div>
                        <div className={`fs-exclude ${styles.value}`}>
                          {insuranceDetails !== null
                            ? insuranceDetails?.insurance_membership?.insurance_carrier?.name
                            : patientInsurance?.insurance_membership?.insurance_carrier?.name}
                        </div>
                      </div>
                    </div>
                    <div className={styles.language}>
                      <div className={styles.insuranceWidth}>
                        <div className={`fs-unmask ${styles.label}`}>Insurance Member ID</div>
                        <div className={`fs-exclude ${styles.value}`}>
                          {insuranceDetails !== null
                            ? insuranceDetails?.insurance_membership?.member_id
                            : patientInsurance?.insurance_membership?.member_id}
                        </div>
                      </div>
                    </div>
                    <div className={styles.language}>
                      <div className={styles.insuranceWidth}>
                        <div className={`fs-unmask ${styles.label}`}>Insurance Group Number</div>
                        <div className={`fs-exclude ${styles.value}`}>
                          {insuranceDetails !== null
                            ? insuranceDetails?.insurance_membership?.group_number
                            : patientInsurance?.insurance_membership?.group_number}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {userInfo?.role?.name === UserRoleName.CareAdvisor && (
                <div className={styles.medicalInformations}>
                  <div className={`fs-unmask ${styles.title}`}>Medical and Dental Information</div>
                  <div className={`fs-exclude ${styles.tags}`}>
                    {profileCardTags.map((tag) => (
                      <div
                        className={`fs-exclude ${styles.tag}`}
                        style={{ color: tag.isNew ? getSecondary() : '', borderColor: tag.isNew ? getSecondary() : '' }}
                        key={tag.value}
                      >
                        <div className={`fs-exclude ${styles.label}`}>
                          <AiOutlineClose
                            style={{ display: tag.isNew ? '' : 'none', cursor: 'pointer' }}
                            onClick={() => removeTag(tag)}
                          />{' '}
                          {tag.label || tag.value}
                        </div>
                      </div>
                    ))}
                    <form onSubmit={handleSubmit} style={{ display: addTagsInput ? '' : 'none', width: '85%' }}>
                      <MultiselectInput
                        containerClass="mt-0"
                        name="tags"
                        placeholder="Stroke, Pain, Bleeding"
                        style={profileCardsMultiSelectStyles}
                        options={tagsList}
                        selectedValue={values}
                        onChange={(value) => value && setValues(value)}
                      />
                      <div className={styles.saveTags}>
                        <button type="submit">
                          <AiOutlineCheckCircle className={styles.icon} />
                          Save the tags
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className={styles.question}>
                    <div className={`fs-unmask ${styles.label}`}>Are you allergic to any medication?</div>
                    <div className={`fs-exclude ${styles.value}`}>{medicalHistory?.medications_allergies}</div>
                  </div>
                  <div className={styles.question}>
                    <div className={`fs-unmask ${styles.label}`}>Are you currently taking any medications?</div>
                    <div className={`fs-exclude ${styles.value}`}>{medicalHistory?.medications}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={styles.profileCard}
            style={{
              padding: !isMobile ? '2rem 8rem' : '',
              height: !isMobile ? '631px' : '',
              display: medicalHistoryVisible ? '' : 'none',
              position: 'relative',
              gap: '2rem',
              backgroundColor: '#F0F0F0',
            }}
          >
            <button
              type="button"
              className={styles.modalClose}
              data-micromodal-close=""
              onClick={closeModal}
              aria-label="Close modal"
            >
              <AiOutlineCloseCircle className={styles.icon} />
            </button>
            <button type="button" className={styles.modalGoBack} data-micromodal-close="" onClick={goBack}>
              <BiArrowBack className={styles.icon} /> Go Back
            </button>
            <div
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
              }}
            >
              Medical History
            </div>
          </div>
          <div
            className={styles.profileCard}
            style={{
              padding: !isMobile ? '2rem 2rem' : '3rem 0rem',
              paddingBottom: !isMobile ? '' : '0rem',
              height: !isMobile ? '631px' : '',
              display: appointmentHistoryVisible ? '' : 'none',
              position: 'relative',
              gap: '0rem',
              backgroundColor: '#F0F0F0',
            }}
          >
            <button
              type="button"
              className={styles.modalClose}
              data-micromodal-close=""
              onClick={closeModal}
              aria-label="Close modal"
            >
              <AiOutlineCloseCircle className={styles.icon} />
            </button>
            <button type="button" className={styles.modalGoBack} data-micromodal-close="" onClick={goBack}>
              <BiArrowBack className={styles.icon} /> Go Back
            </button>
            <div
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                display: isMobile ? 'none' : '',
              }}
            >
              Appointment History
            </div>
            {/* <Appointments isProfileCard /> */}
          </div>
          <div
            className={styles.profileCard}
            style={{
              padding: !isMobile ? '0rem 0rem' : '3rem 0rem',
              paddingBottom: !isMobile ? '' : '0rem',
              height: !isMobile ? '631px' : '600px',
              display: messageHistoryVisible ? '' : 'none',
              position: 'relative',
              gap: '0rem',
              backgroundColor: '#F0F0F0',
            }}
          >
            <button
              type="button"
              className={styles.modalClose}
              data-micromodal-close=""
              onClick={closeModal}
              aria-label="Close modal"
            >
              <AiOutlineCloseCircle className={styles.icon} />
            </button>
            <button type="button" className={styles.modalGoBack} data-micromodal-close="" onClick={goBack}>
              <BiArrowBack className={styles.icon} /> Go Back
            </button>
            <div
              style={{
                display: isMobile ? 'none' : 'flex',
                width: '100%',
                flexShrink: 0,
              }}
            >
              <div
                style={{
                  width: '220px',
                  backgroundColor: 'white',
                  height: '400%',
                  flexShrink: 0,
                }}
              />
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  width: '100%',
                  paddingTop: '1rem',
                }}
              >
                Message History
              </div>
            </div>
            {/* <Messages isProfileCard /> */}
          </div>
        </>
      )}
    </Modal>
  );
};

PatientProfileCardModal.defaultProps = {
  insuranceDetails: null,
  caseId: 0,
};

Modal.setAppElement('#root');

export default PatientProfileCardModal;
