/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { setForm } from '@brands/store/slices/patientFormSlice';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { yupResolver } from '@hookform/resolvers/yup';
import { heicTo } from 'heic-to';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '../../../../Components/Button/Button';
import Container from '../../../../Components/Container/Container';
import ProgressBar from '../../../../Components/ProgressBar/ProgressBar';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import useWindowSize from '../../../../hooks/useWindowSize';
import { truncateFilename } from '../../../../Utils/truncateString';
import AddDependentModal from '../../../MyProfile/Patient/Sections/AddDependentModal/AddDependentModal';
import camera from './assets/camera-icon.svg';
import imageDrag from './assets/image-icon.svg';
import Camera from './Components/Camera';
import styles from './SecondOpinion/styles.module.scss';

type ImageUploadSubmit = {
  images: UploadedFile[];
};

interface UploadedFile {
  name: string;
  dataURL: string;
}

const ImageUploadSecondOpinion = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { values, selectedPatient } = useAppSelector(selectPatientForm);
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const { state } = useLocation();
  const [openCamera, setOpenCamera] = useState(false);
  const [showAddDependentModal, setShowAddDependentModal] = useState<boolean>(false);
  const [images, setImages] = useState<UploadedFile[]>(
    (values.find((item) => item.form_question_id === 17)?.value.images as UploadedFile[]) || [],
  );

  const onSubmit = (data: ImageUploadSubmit): void => {
    const payload = [...values!];
    const filteredPayload = payload.filter((item) => item.form_question_id !== 17);

    filteredPayload.push({
      form_question_id: 17,
      value: { ...data },
    });

    dispatch(setForm(filteredPayload));
    navigate('/medical-information-summary', {
      state: {
        caseType: state ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) : '',
        patientId: selectedPatient?.value || '',
        shouldSeeLastModal: false,
        fromPatientIntake: true,
      },
    });
  };

  const onBack = (): void => {
    dispatch(setForm([...values!]));
    navigate(-1);
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(error.message as string, { className: 'fs-unmask' });
    }
  };

  const validationSchema = Yup.object().shape({
    images:
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report'
        ? Yup.array()
            .min(2, 'At least 2 images are required to upload.')
            .max(10, 'Only 10 images are allowed to upload.')
            .required('At least 2 images are required to upload.')
        : (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
        ? Yup.array()
            .min(1, 'At least 1 attachment is required to upload.')
            .max(10, 'Only 10 attachments are allowed to upload.')
            .required('At least 1 attachment is required to upload.')
        : Yup.array().max(10, 'Only 10 images are allowed to upload.').nullable(),
  });

  const { setValue, register, handleSubmit } = useForm<ImageUploadSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onCloseCamera = (): void => {
    setOpenCamera(false);
  };

  const onPhotoTaken = (uri: string): void => {
    setImages([...images, { dataURL: uri, name: 'Capture.png' }]);
  };

  const addImages = (files: File[]): void => {
    const newImages = files.filter(
      (file) =>
        file.type.startsWith('image/') ||
        file.type === 'application/pdf' ||
        file.type === 'application/msword' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type === 'application/vnd.ms-powerpoint' ||
        file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    );
    if (images.length + files.length > 10) {
      alert(
        (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report'
          ? 'Only 1-10 files are allowed to upload.'
          : 'Max 10 files are allowed to upload.',
      );
      return;
    }
    const imagesArray: UploadedFile[] = [];
    for (let i = 0; i < newImages.length; i++) {
      const file = newImages[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', () => {
        imagesArray.push({ name: file.name, dataURL: reader.result as string });
        if (imagesArray.length === files.length) {
          setImages(uniqBy([...images, ...imagesArray], 'dataURL'));
        }
      });
    }
  };

  const handleDragOver = (event: any): void => {
    event.preventDefault();
  };

  const handleDrop = async (event: React.DragEvent<HTMLImageElement>): Promise<void> => {
    event.preventDefault();

    const files = Array.from(event.dataTransfer.files);

    const convertedFiles = await Promise.all(
      files.map(async (file) => {
        if (file.type === 'image/heic') {
          try {
            const jpegBlob = await heicTo({
              blob: file,
              type: 'image/jpeg',
              quality: 0.85,
            });

            return new File([jpegBlob], file.name.replace(/\.heic$/i, '.jpeg'), {
              type: 'image/jpeg',
            });
          } catch (error) {
            displayErrorDetails(`Error converting HEIC to JPEG: ${error}`);
            return file;
          }
        }

        return file;
      }),
    );

    addImages(convertedFiles);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = Array.from(event.target.files as unknown as File[]);

    const convertedFiles = await Promise.all(
      files.map(async (file) => {
        if (file.type === 'image/heic') {
          try {
            const jpegBlob = await heicTo({
              blob: file,
              type: 'image/jpeg',
              quality: 0.85,
            });

            return new File([jpegBlob], file.name.replace(/\.heic$/i, '.jpeg'), {
              type: 'image/jpeg',
            });
          } catch (error) {
            displayErrorDetails(`Error converting HEIC to JPEG: ${error}`);
            return file;
          }
        }

        return file;
      }),
    );

    addImages(convertedFiles);
  };

  const deleteMedia = (mediaName: string): void => {
    const selectedMedia = images.find((image) => image.dataURL === mediaName);
    if (selectedMedia) {
      const idx = images.indexOf(selectedMedia);
      setImages(images.filter((image, index) => index !== idx));
    }
  };

  useEffect(() => {
    const payload = [...values!];
    const filteredPayload = payload.filter((item) => item.form_question_id !== 17);

    if (images.length > 0) {
      setValue('images', images);

      filteredPayload.push({
        form_question_id: 17,
        value: { images },
      });
    } else {
      setValue('images', []);
      filteredPayload.push({
        form_question_id: 17,
        value: { images: [] },
      });
    }

    dispatch(setForm(filteredPayload));
  }, [images]);

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2' }}
    >
      <div className={styles.uploadImageContainer}>
        <div className={`${styles.formTitle} d-flex mt-3`}>
          {(values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report' ||
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
            ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report'
              ? 'Image upload *'
              : 'Attachment Upload'
            : 'Image upload'}
        </div>
        <ProgressBar currentStep={3} totalSteps={4} onBack={onBack} hideStepNumbers />
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <div className={styles.wrapper}>
            <div className={styles.formContent}>
              <span className={styles.header}>
                {(values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
                  ? 'Please upload up to 10 of your recent reports, medical scans, doctors notes and other records that will help the dentist complete the consultation (at least one attachment is required)'
                  : 'Choose 1-10 images of your mouth and teeth, focusing on any problem areas'}
              </span>
              <div className={styles.draggingArea} onDragOver={handleDragOver} onDrop={handleDrop}>
                <img className={styles.dragImage} src={imageDrag} alt="Drap here" />
                {screenSize.width > 992 &&
                (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion' ? (
                  <p>Drag attachments here</p>
                ) : (
                  <p>Drag photos here</p>
                )}
              </div>
              {images.length > 0 && (
                <div className={styles.grid}>
                  {images.map((file) => {
                    const isPDF = /^data:application\/pdf/i.test(file.dataURL);
                    const isDoc =
                      /^data:application\/vnd.openxmlformats-officedocument.wordprocessingml.document|^data:application\/msword/i.test(
                        file.dataURL,
                      );
                    const isPpt =
                      /^data:application\/vnd.openxmlformats-officedocument.presentationml.presentation/i.test(
                        file.dataURL,
                      ) || /^data:application\/vnd.ms-powerpoint/i.test(file.dataURL);

                    const isImage = /^data:image/i.test(file.dataURL);
                    return (
                      <div className={styles.imageWrap} key={file.dataURL}>
                        <span className={styles.removeImage} onClick={() => deleteMedia(file.dataURL)}>
                          <IoClose />
                        </span>
                        {isPDF || isDoc || isPpt ? (
                          <p>{truncateFilename(file.name, 20)}</p>
                        ) : isImage ? (
                          <img src={file.dataURL} alt={file.name} />
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className={styles.btnWrap}>
                <label htmlFor="formId" className={`${styles.uploadImageLabel}`}>
                  <input
                    {...register('images')}
                    name=""
                    type="file"
                    id="formId"
                    onChange={handleChange}
                    multiple
                    hidden
                    accept={
                      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                      'second_opinion'
                        ? '.pdf, .doc, .docx, .ppt, .pptx, .png, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .heic'
                        : '.png, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .heic'
                    }
                  />

                  <div className={styles.selectImages}>
                    <span>
                      {isMobile
                        ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                          'second_opinion'
                          ? 'Upload File'
                          : 'Camera roll'
                        : 'Select from computer'}
                    </span>
                  </div>
                </label>
                {isMobile && (
                  <div className={`${styles.selectImages} ${styles.cameraBtn}`} onClick={() => setOpenCamera(true)}>
                    <img className={styles.camera} src={camera} alt="Camera" />
                    Camera
                  </div>
                )}
              </div>
            </div>
            <div className={styles.btnContainer}>
              <Button
                type="submit"
                style={{ width: '245px', height: '46px' }}
                disabled={
                  ((values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report' &&
                    (images.length < 2 || images.length > 10)) ||
                  ((values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                    'second_opinion' &&
                    (images.length === 0 || images.length > 10))
                }
              >
                {(values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report' ||
                (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
                  ? 'Next'
                  : images.length > 0
                  ? 'Next'
                  : 'Skip'}
              </Button>
            </div>
          </div>
        </form>
        {openCamera && <Camera onClose={onCloseCamera} onPhotoTaken={onPhotoTaken} />}
      </div>
      {showAddDependentModal && (
        <AddDependentModal isOpen={showAddDependentModal} setIsOpen={setShowAddDependentModal} isFromSelectNeeds />
      )}
    </Container>
  );
};
export default ImageUploadSecondOpinion;
