import { isSupported } from '@twilio/video-processors';
import React, { FC, useEffect, useRef } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { BsChatSquareText, BsThreeDotsVertical } from 'react-icons/bs';
import { MdLensBlur } from 'react-icons/md';
import { TbScreenShare, TbUsers } from 'react-icons/tb';

import { useWindowSize } from '../../../hooks';
import useVideoContext from '../../../hooks/video-hooks/useVideoContext';
import CameraSwitch from '../VideoInputList/VideoInputList';
import styles from './styles.module.scss';

interface VideoOptionsProps {
  toggleScreenShare: () => void;
  participantsOnScreen: boolean;
  setParticipantsOnScreen: (value: boolean) => void;
  setChatOnScreen: (value: boolean) => void;
  chatOnScreen: boolean;
  isListDisplayed: boolean;
  setIsListDisplayed: (value: boolean) => void;
}

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

const VideoOptions: FC<VideoOptionsProps> = ({
  toggleScreenShare,
  participantsOnScreen,
  setParticipantsOnScreen,
  setChatOnScreen,
  chatOnScreen,
  isListDisplayed,
  setIsListDisplayed,
}) => {
  const { setIsBackgroundSelectionOpen } = useVideoContext();
  const { width } = useWindowSize();
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;

  function useOutsideAlerter(ref: any): void {
    useEffect(() => {
      function handleClickOutside(event: any): void {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsListDisplayed(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className={styles.videoOptionsContainer}
      style={{ width: (isListDisplayed || participantsOnScreen) && width <= 768 ? '100%' : '' }}
      ref={wrapperRef}
    >
      {(isListDisplayed || participantsOnScreen) && width <= 768 ? null : (
        <div>
          <button
            type="button"
            onClick={() => setIsListDisplayed(!isListDisplayed)}
            data-cy-share-screen
            aria-label="Video icon"
            className="fs-unmask"
          >
            <span className={styles.videoIcon}>
              <BsThreeDotsVertical />
            </span>
          </button>
        </div>
      )}
      <div className={`fs-unmask ${styles.optionsList}`} style={{ display: isListDisplayed ? '' : 'none' }}>
        <div className={styles.optionsListText}>
          {(isListDisplayed || participantsOnScreen) && width <= 768 ? (
            <div className={styles.closeButtonContainer} onClick={() => setIsListDisplayed(false)}>
              <span>
                <AiFillCloseCircle size="24px" /> Close
              </span>
            </div>
          ) : null}
          {isScreenShareSupported !== undefined && (
            <div
              onClick={() => {
                toggleScreenShare();
                setParticipantsOnScreen(false);
                setChatOnScreen(false);
                setIsListDisplayed(false);
              }}
              className={`fs-unmask ${styles.listItem}`}
            >
              <span className={styles.listText}>Share Screen</span>
              <span className={styles.listIcon}>
                <TbScreenShare />
              </span>
            </div>
          )}
          <div
            className={`fs-unmask ${styles.listItem}`}
            onClick={() => {
              setParticipantsOnScreen(!participantsOnScreen);
              setChatOnScreen(false);
              setIsListDisplayed(false);
            }}
          >
            <span className={styles.listText}>Participants</span>
            <span className={styles.listIcon}>
              <TbUsers />
            </span>
          </div>
          {false && (
            <div
              className={`fs-unmask ${styles.listItem}`}
              onClick={() => {
                setChatOnScreen(!chatOnScreen);
                setParticipantsOnScreen(false);
                setIsListDisplayed(false);
              }}
              style={{ borderBottom: 'none' }}
            >
              <span className={styles.listText}>Chat</span>
              <span className={styles.listIcon}>
                <BsChatSquareText />
              </span>
            </div>
          )}
          {isSupported && (
            <div
              onClick={() => {
                setIsBackgroundSelectionOpen(true);
                setParticipantsOnScreen(false);
                setChatOnScreen(false);
                setIsListDisplayed(false);
              }}
              className={`fs-unmask ${styles.listItem}`}
            >
              <span className={styles.listText}>Background</span>
              <span className={styles.listIcon}>
                <MdLensBlur />
              </span>
            </div>
          )}
          <CameraSwitch />
        </div>
      </div>
    </div>
  );
};
export default VideoOptions;
