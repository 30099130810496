/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import './styles.css';

import { isAlmostEqualWithTolerance } from '@brands/Dashboard/MyProfile/Patient/utils/utilsFunctions';
import { selectOneReport } from '@brands/store/selectors/oneReport';
import { setMedia } from '@brands/store/slices/oneReportSlice';
import { setUploadFileMethod } from '@brands/store/slices/videoCallCredentialsSlice';
import { formatDate } from '@brands/Utils/formatDate';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Gallery } from 'react-grid-gallery';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BsPencil } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import { RiCloseCircleFill } from 'react-icons/ri';
import { Document, Page } from 'react-pdf';
import { toast } from 'react-toastify';

import { Loading } from '../../../../Components/LoadingSpinner/Loading';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import { ICase } from '../../../../services/cases/types/ICase';
import { RegisterMediaPayload } from '../../../../services/communication/registersConversationMedia';
import { AttachmentNoteState, ImageNoteState } from '../../../../services/forms/types/ImageNote';
import { ImageWithNotes } from '../../../../services/forms/types/ImageWithNotes';
import { UserProfile, UserRoleName } from '../../../../services/identity/types/UserProfile';
import { addOneReportMedia } from '../../../../services/oneReport/addOneReportMedia';
import { confirmOneReportMedia } from '../../../../services/oneReport/confirmOneReportMedia';
import { getOneReportMedia } from '../../../../services/oneReport/getOneReportMedia';
import { registerOneReportMedia } from '../../../../services/oneReport/registerOneReportMedia';
import { IOneReport } from '../../../../services/oneReport/types/IOneReport';
import { IOneReportMedia } from '../../../../services/oneReport/types/IOneReportMedia';
import { updateOneReportMedia } from '../../../../services/oneReport/updateOneReportMedia';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { truncateFilename } from '../../../../Utils/truncateString';
import { OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';
import { heicTo } from 'heic-to';

type ImagesSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  userInfo: UserProfile;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
  images: ImageWithNotes[];
  setImages: React.Dispatch<React.SetStateAction<ImageWithNotes[]>>;
  setEditImageNote: React.Dispatch<React.SetStateAction<ImageNoteState>>;
  setRemoveImage: React.Dispatch<React.SetStateAction<ImageNoteState>>;
  setRemoveAttachment: React.Dispatch<React.SetStateAction<AttachmentNoteState>>;
  updateSelectedImages: (updatedReport: IOneReport) => void;
};

const ImagesSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  setValue,
  errors,
  images,
  setImages,
  setEditImageNote,
  setRemoveImage,
  setRemoveAttachment,
  updateSelectedImages,
}: ImagesSectionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { media } = useAppSelector(selectOneReport);
  const [attachments, setAttachments] = useState<ImageWithNotes[]>([]);
  const [uploadingPhoto, setUploadingPhoto] = useState<boolean>(false);

  const editNote = (media_id: string, media_src: string, readOnly?: boolean): void => {
    setEditImageNote({
      isOpen: true,
      media_id,
      media_src,
      oneReportId: thisCase?.one_report?.id,
      readOnly: readOnly || false,
    });
  };

  const removeReportImage = (media_id: string, media_src: string): void => {
    setRemoveImage({
      isOpen: true,
      media_id,
      media_src,
      oneReportId: thisCase?.one_report?.id,
    });
  };

  const loadImages = async (): Promise<void> => {
    setUploadingPhoto(true);
    if (thisCase?.one_report.media && thisCase?.one_report.media?.length > 0) {
      const sortedOneReportMedias = thisCase.one_report.media.sort((a, b) => Number(a.id) - Number(b.id));
      const imagePromises = sortedOneReportMedias.map(async (mediaOneReport: IOneReportMedia) => {
        const mediaData = await getOneReportMedia(mediaOneReport.media_id!);
        return {
          src: mediaData.url,
          width: 4,
          height: 3,
          filename: mediaData.filename,
          mimetype: mediaData.mimetype,
          created_at: mediaOneReport.created_at,
          customOverlay:
            checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Images') === 'edit' ? (
              <Fragment key={mediaOneReport?.id}>
                <div
                  className="custom-overlay__caption"
                  style={{ zIndex: '1' }}
                  onClick={() => editNote(mediaOneReport.id!, mediaData.url, false)}
                >
                  Edit Note <BsPencil />
                </div>
                <span
                  className="custom-overlay__remove"
                  style={{ zIndex: '9' }}
                  onClick={() => removeReportImage(mediaOneReport.id!, mediaData.url)}
                >
                  <RiCloseCircleFill />
                </span>
              </Fragment>
            ) : checkCollapsibleHeaderColor(thisCase || '', userInfo, 'RemoveMedia') === 'editImages' ? (
              <Fragment key={mediaOneReport?.id}>
                <div
                  className="custom-overlay__caption"
                  onClick={() => editNote(mediaOneReport.id!, mediaData.url, true)}
                >
                  View Note <BsPencil />
                </div>
                <span
                  className="custom-overlay__remove"
                  onClick={() => removeReportImage(mediaOneReport.id!, mediaData.url)}
                >
                  <RiCloseCircleFill />
                </span>
              </Fragment>
            ) : checkCollapsibleHeaderColor(thisCase || '', userInfo, 'ViewNotes') === 'editImages' ? (
              <Fragment key={mediaOneReport?.id}>
                <div
                  className="custom-overlay__caption"
                  onClick={() => editNote(mediaOneReport.id!, mediaData.url, true)}
                >
                  View Note <BsPencil />
                </div>
                <span />
              </Fragment>
            ) : (
              <span />
            ),
          notes: mediaOneReport.note,
          id: mediaOneReport.id,
          isSelected: mediaOneReport.is_selected,
        };
      });

      const resolvedPromises = await Promise.all(imagePromises);

      const imageSrcs = resolvedPromises
        .filter((resolvedPromise) => resolvedPromise.mimetype.startsWith('image/'))
        .map((resolvedPromise) => ({
          src: resolvedPromise.src,
          width: resolvedPromise.width,
          height: resolvedPromise.height,
          filename: resolvedPromise.filename,
          mimetype: resolvedPromise.mimetype,
          customOverlay: resolvedPromise.customOverlay,
          notes: resolvedPromise.notes,
          id: resolvedPromise.id,
          isSelected: resolvedPromise.isSelected,
          created_at: resolvedPromise.created_at,
        }));

      const attachmentSrcs = resolvedPromises
        .filter((resolvedPromise) => !resolvedPromise.mimetype.startsWith('image/'))
        .map((resolvedPromise) => ({
          src: resolvedPromise.src,
          width: resolvedPromise.width,
          height: resolvedPromise.height,
          filename: resolvedPromise.filename,
          mimetype: resolvedPromise.mimetype,
          notes: resolvedPromise.notes,
          id: resolvedPromise.id,
          created_at: resolvedPromise.created_at,
        }));

      setImages(imageSrcs);
      setAttachments(attachmentSrcs);
      setUploadingPhoto(false);
    } else {
      setImages([]);
      setAttachments([]);
      setUploadingPhoto(false);
    }
  };

  useEffect(() => {
    loadImages();
  }, [consultationForm.selectedImages, thisCase.one_report.media?.length]);

  const updateAPI = async (triggerImage: IOneReportMedia): Promise<void> => {
    const updatedReport = await updateOneReportMedia(triggerImage.one_report_id || '', triggerImage.id || '', {
      is_selected: !triggerImage.is_selected,
    });
    setValue(
      'selectedImages',
      updatedReport.media?.filter((image) => image.is_selected),
      { shouldValidate: true },
    );
    setConsultationForm({
      ...consultationForm,
      selectedImages: updatedReport.media?.filter((image) => image.is_selected),
    });
  };

  const handleSelect = async (imageId: string): Promise<any> => {
    setUploadingPhoto(true);
    const selectedImage = images.find((image) => image.id === imageId);

    if (!selectedImage) {
      setUploadingPhoto(false);
      return;
    }

    const updatedCase = await updateOneReportMedia(thisCase?.one_report?.id, selectedImage.id!, {
      is_selected: !selectedImage.isSelected,
    });
    setCase({
      ...thisCase,
      one_report: updatedCase,
    });
    updateSelectedImages(updatedCase);
    setUploadingPhoto(false);
  };

  const handleClick = (index: number): any => {
    const selectedImage = images[index];

    if (!selectedImage.isSelected) {
      setEditImageNote({
        isOpen: true,
        media_src: selectedImage.src,
        media_id: selectedImage.id!,
        oneReportId: thisCase?.one_report?.id,
      });
    } else {
      const getSelectedImage = thisCase?.one_report?.media?.find(
        (oneReportImage) => oneReportImage.id === images[index].id,
      );
      if (getSelectedImage) {
        updateAPI(getSelectedImage);
      }
      const nextImages = images?.map((image, i) => (i === index ? { ...image, isSelected: !image.isSelected } : image));
      setImages(nextImages);
    }
  };

  const removeReportAttachment = (media_id: string, media_name: string): void => {
    setRemoveAttachment({
      isOpen: true,
      media_id,
      media_name,
      oneReportId: thisCase?.one_report?.id,
    });
  };

  const uploadFile = async (file: File): Promise<void> => {
    const payload: RegisterMediaPayload = {
      files: [
        {
          filename: file.name,
          mimetype: file.type,
        },
      ],
    };
    try {
      const res = await registerOneReportMedia(payload);
      const mediaId = res.files[0].upload_id;
      await fetch(res.files[0].upload_url, {
        method: 'PUT',
        body: file,
        headers: { 'content-type': file.type },
      });
      await confirmOneReportMedia(mediaId);
      const imageUrl = URL.createObjectURL(file);
      dispatch(
        setMedia([
          ...(media as IOneReportMedia[]),
          {
            media_id: mediaId,
            note: '',
          },
        ]),
      );
      const updatedOneReport = await addOneReportMedia(thisCase?.one_report.id || '', {
        media_id: mediaId,
        note: '',
      });
      if (thisCase) {
        setCase({
          ...thisCase,
          one_report: updatedOneReport,
        });
      }
      const mediaImage = updatedOneReport.media.find((specificMedia) => specificMedia?.media_id === mediaId);
      const newImages = {
        src: imageUrl,
        width: 4,
        height: 3,
        filename: file.name,
        mimetype: file.type,
        notes: '',
        id: mediaImage?.id,
        isSelected: mediaImage?.is_selected,
        created_at: moment(new Date()).toISOString(),
        customOverlay:
          checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Images') === 'edit' ? (
            <Fragment key={mediaImage?.id}>
              <div
                className="custom-overlay__caption"
                style={{ zIndex: '1' }}
                onClick={() => editNote(mediaImage?.id || '', imageUrl, false)}
              >
                Edit Note <BsPencil />
              </div>
              <span
                className="custom-overlay__remove"
                style={{ zIndex: '9' }}
                onClick={() => removeReportImage(mediaImage?.id || '', imageUrl)}
              >
                <RiCloseCircleFill />
              </span>
            </Fragment>
          ) : checkCollapsibleHeaderColor(thisCase || '', userInfo, 'RemoveMedia') === 'editImages' ? (
            <Fragment key={mediaImage?.id}>
              <div className="custom-overlay__caption" onClick={() => editNote(mediaImage?.id || '', imageUrl, true)}>
                View Note <BsPencil />
              </div>
              <span
                className="custom-overlay__remove"
                onClick={() => removeReportImage(mediaImage?.id || '', imageUrl)}
              >
                <RiCloseCircleFill />
              </span>
            </Fragment>
          ) : checkCollapsibleHeaderColor(thisCase || '', userInfo, 'ViewNotes') === 'editImages' ? (
            <Fragment key={mediaImage?.id}>
              <div className="custom-overlay__caption" onClick={() => editNote(mediaImage?.id || '', imageUrl, true)}>
                View Note <BsPencil />
              </div>
              <span />
            </Fragment>
          ) : (
            <span />
          ),
      };
      if (newImages.mimetype.startsWith('image/')) {
        setImages((prevImages) => [...prevImages, newImages]);
      } else {
        setAttachments([...attachments, newImages]);
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    dispatch(setUploadFileMethod(uploadFile));
  }, []);

  const openFileInNewTab = (fileUrl: string, mimeType: string): void => {
    if (mimeType === 'application/pdf') {
      window.open(fileUrl, '_blank');
    } else {
      const officeOnlineViewerUrl = 'https://view.officeapps.live.com/op/view.aspx';

      const msParams = new URLSearchParams();
      msParams.set('src', fileUrl);

      const viewerUrl = `${officeOnlineViewerUrl}?${msParams.toString()}`;

      window.open(viewerUrl, '_blank');
    }
  };

  const addPhoto = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const { files } = event.currentTarget;
    if (files) {
      setUploadingPhoto(true);

      const processFiles = Array.from(files).map(async (file) => {
        if (file.type === 'image/heic') {
          try {
            const jpegBlob = await heicTo({
              blob: file,
              type: 'image/jpeg',
              quality: 0.85,
            });

            return new File([jpegBlob], file.name.replace(/\.heic$/i, '.jpeg'), {
              type: 'image/jpeg',
            });
          } catch (error: any) {
            toast.error(`Error converting HEIC file: ${error.message}`, { className: 'fs-unmask' });
            return file;
          }
        }

        return file;
      });

      Promise.all(processFiles)
        .then((convertedFiles) => {
          Promise.all(convertedFiles.map(uploadFile))
            .then(() => {
              setUploadingPhoto(false);
            })
            .catch((error) => {
              toast.error(`Error uploading files: ${error.message}`, { className: 'fs-unmask' });
              setUploadingPhoto(false);
            });
        })
        .catch((error) => {
          toast.error(`Error processing files: ${error.message}`, { className: 'fs-unmask' });
          setUploadingPhoto(false);
        });
    }
  };
  return (
    <>
      <div className={styles.collapsibleContentContainer}>
        {thisCase?.status !== 'completed' &&
          thisCase?.status !== 'canceled' &&
          !(
            userInfo.role.name === UserRoleName.CareAdvisor ||
            userInfo.role.name === UserRoleName.Admin ||
            userInfo.role.name === UserRoleName.SuperAdmin ||
            userInfo.role.name === UserRoleName.OrganizationAdmin
          ) &&
          false && (
            <div className={styles.collapsibleContent} style={{ color: errors.selectedImages ? '#BA271A' : '' }}>
              Select two or more primary photos and provide notes
            </div>
          )}
        <div className={styles.collapsibleAddPhoto}>
          <input
            name="selectedImages"
            type="file"
            id="formId"
            onChange={addPhoto}
            hidden
            multiple
            accept={
              thisCase.type === 'second_opinion'
                ? '.pdf, .doc, .docx, .ppt, .pptx, .png, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .heic'
                : '.png, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .heic'
            }
          />
          {thisCase?.status !== 'completed' &&
            thisCase?.status !== 'canceled' &&
            (checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Images') === 'editImages' ||
              checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Images') === 'edit') &&
            (uploadingPhoto ? (
              <Loading dotted />
            ) : (
              <label htmlFor="formId" className={styles.collapsibleAddPhoto}>
                <span className={`fs-unmask ${styles.collapsibleAddPhotoContent}`}>
                  {thisCase.type === 'second_opinion' ? 'Add Attachment' : 'Add Photo'}
                </span>
                <span className={`fs-unmask ${styles.collapsibleAddPhotoIcon}`}>
                  <AiFillPlusCircle />
                </span>
              </label>
            ))}
        </div>
      </div>
      <div
        className={`${styles.collapsibleContentImages} ${
          checkCollapsibleHeaderColor(thisCase || '', userInfo, 'EditImages') === 'editImages' ||
          userInfo.role.name === UserRoleName.CareAdvisor ||
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? styles.noCursor
            : ''
        }`}
      >
        <div className="w-100 h-100">
          <div className={styles.galleryWrapper}>
            {images.map((image) => (
              <div className={`fs-exclude ${styles.imageContainer}`} key={image.id}>
                {image.created_at && !isAlmostEqualWithTolerance(image.created_at, thisCase.created_at) ? (
                  <div className={styles.imageDate}>{formatDate(image.created_at)}</div>
                ) : (
                  <div className={styles.imageDate} />
                )}
                <Gallery
                  enableImageSelection={
                    checkCollapsibleHeaderColor(thisCase || '', userInfo, 'EditImages') !== 'editImages' &&
                    !(checkCollapsibleHeaderColor(thisCase || '', userInfo, 'EditImages') === 'readOnly')
                  }
                  images={[image]}
                  onSelect={() => {
                    handleSelect(image.id!);
                  }}
                  onClick={(e: any) => {
                    handleClick(e);
                  }}
                />
              </div>
            ))}
          </div>
          {attachments?.length > 0 && (
            <div className={styles.grid}>
              {attachments.map((file) => {
                return (
                  <>
                    {file.created_at && !isAlmostEqualWithTolerance(file.created_at, thisCase.created_at) ? (
                      <div className={styles.imageDate}>{formatDate(file.created_at)}</div>
                    ) : (
                      <div className={styles.imageDate} />
                    )}
                    <div className={styles.imageWrap} key={file.id}>
                      <span
                        className={styles.removeImage}
                        onClick={() => removeReportAttachment(file.id!, file.filename!)}
                      >
                        <IoClose />
                      </span>
                      <span className={styles.fileName} onClick={() => openFileInNewTab(file.src, file.mimetype!)}>
                        {truncateFilename(file.filename!, 20)}
                      </span>
                      <a
                        onClick={() => openFileInNewTab(file.src, file.mimetype!)}
                        target="_blank"
                        rel="noopener noreferrer"
                        download={false}
                      >
                        {file.mimetype !== 'application/pdf' ? (
                          <DocViewer
                            documents={[{ uri: file.src, fileType: file.mimetype, fileName: file.filename }]}
                            pluginRenderers={DocViewerRenderers}
                            style={{ height: '160px', width: '314px', display: 'contents', objectFit: 'cover' }}
                            theme={{
                              disableThemeScrollbar: true,
                            }}
                            config={{
                              header: { disableHeader: true, disableFileName: true },
                            }}
                          />
                        ) : (
                          <Document file={file.src} className={`${styles.lozad} ${styles.pdfDocument}`}>
                            <Page className={styles.pdfPage} pageNumber={1} scale={1} />
                          </Document>
                        )}
                      </a>
                    </div>
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ImagesSection;
