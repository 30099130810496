/* eslint-disable max-len */
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';

import Calendar from '../../../../Components/Calendar/Calendar';
import { Loading } from '../../../../Components/LoadingSpinner/Loading';
import useAvailableProviders from '../../../../hooks/useAvailableProviders';
import useCases from '../../../../hooks/useCases';
import useDebounce from '../../../../hooks/useDebounce';
import { ICase } from '../../../../services/cases/types/ICase';
import { IListAvailableProviderParams } from '../../../../services/identity/listAvailableProvidersDate';
import { ProviderFilter } from '../../../ConsultationPage/assets/types';
import DisplaySelectedDates from '../../../PatientForms/PatientForm/utils/DisplaySelectedDate/DisplaySelectedDats';
import ProviderAvailabilityCardCAView from '../../../PatientForms/PatientForm/utils/ProviderAvailabilityCard/ProviderAvailabilityCardCAView';
import { SelectedAppointment } from '../../../PatientForms/PatientForm/utils/types';
import styles from '../styles.module.scss';

interface ProfileCardModalProps {
  relatedCase: ICase | undefined;
  filters: ProviderFilter | undefined;
  thisCase: ICase | undefined;
  setCase: React.Dispatch<React.SetStateAction<ICase>> | undefined;
  setLoading: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  providerId: number | undefined;
  openModal: ((arg0: boolean) => void) | undefined;
  isAssignProvider: boolean;
}

const CareAdvisorView: FC<ProfileCardModalProps> = ({
  relatedCase,
  filters,
  thisCase,
  setCase,
  setLoading,
  providerId,
  openModal,
  isAssignProvider,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [formattedDate, setFormattedDate] = useState<string>('');
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<SelectedAppointment>({
    selectedTimeSlot: '',
    selectedProviderId: undefined,
  });
  const [params, setParams] = useState<IListAvailableProviderParams>({
    date: moment().format('YYYY-MM-DD'),
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    provider_id: providerId,
    ...(filters?.carrier_id && { carrier_id: Number(filters.carrier_id) }),
    ...(filters?.language && { language: filters.language.map((item) => item.label) }),
    ...(filters?.specialty && { specialty: filters.specialty.map((item) => item.value) }),
    ...(thisCase && { case_id: thisCase.id }),
    ...(filters?.license_states
      ? { license_states: filters.license_states.map((item) => item.label.split(' - ')[0]) }
      : { license_states: [relatedCase?.patient_current_state || 'NY'] }),
    sort_by: 'created_at',
    sort_direction: 'asc',
    patient_id: thisCase?.patient_id,
  });

  const debouncedParams = useDebounce(params, 500);
  const { providers, dataLoading, refreshProviders } = useAvailableProviders('providers', debouncedParams);

  const caseParams = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
    }),
    [],
  );
  const { editCase } = useCases(caseParams, true);

  const providersWithTimeSlots = useMemo(() => {
    return providers.map((provider) => {
      const timeSlots = provider.sync_time_slots;

      return {
        ...provider,
        timeSlotsNumber: timeSlots.length,
        allSlots: provider.sync_time_slots,
      };
    });
  }, [providers]);

  const sortedProviders = [...providersWithTimeSlots].sort((a, b) => b.timeSlotsNumber - a.timeSlotsNumber);

  useEffect(() => {
    setParams({
      ...params,
      date: moment(selectedDate).format('YYYY-MM-DD'),
    });
  }, [selectedDate]);
  return (
    <div className={styles.profileCardBody}>
      <div className={styles.caView}>
        <div>
          <Calendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setSelectedTimeSlot={setSelectedTimeSlot}
            isCareAdvisorView
            providerID={providerId}
            caseId={thisCase?.id}
            patient_id={Number(thisCase?.patient_id)}
            out_of_network={!thisCase?.insurance_id}
          />
        </div>
        <div>
          {selectedDate && (
            <div className={styles.providerCards}>
              <DisplaySelectedDates date={new Date(selectedDate!)} setFormattedDate={setFormattedDate} />
              {!dataLoading ? (
                sortedProviders
                  .filter((provider) => provider.sync_time_intervals.length > 0)
                  .map((provider) => (
                    <ProviderAvailabilityCardCAView
                      index={provider.id}
                      date={formattedDate}
                      setSelectedTimeSlot={setSelectedTimeSlot}
                      selectedTimeSlot={selectedTimeSlot}
                      allSlots={provider.allSlots}
                      providerSyncIntervals={provider.sync_time_intervals}
                      providerAsyncIntervals={provider.async_time_intervals}
                      refreshProviders={refreshProviders}
                      isAssignProvider={isAssignProvider}
                      thisCase={thisCase}
                      setCase={setCase}
                      setLoading={setLoading}
                      editCase={editCase}
                      providerId={providerId}
                      openModal={openModal}
                    />
                  ))
              ) : (
                <span>
                  <Loading dotted />
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Modal.setAppElement('#root');

export default CareAdvisorView;
