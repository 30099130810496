/* eslint-disable @typescript-eslint/naming-convention */
import CustomDropdown from '@brands/Components/Inputs/CustomDropdown/CustomDropdown';
import PhoneInput from '@brands/Components/Inputs/PhoneInput/PhoneInput';
import TextArea from '@brands/Components/Inputs/TextArea/TextArea';
import TextField from '@brands/Components/Inputs/TextField/TextField';
import { pharmacyValidationSchema } from '@brands/Dashboard/MyProfile/Patient/utils/validationSchema';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { ICase } from '@brands/services/cases/types/ICase';
import { MedicalHistory } from '@brands/services/clinical_data/types/types';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { addOneReportObject } from '@brands/services/oneReport/addOneReportObject';
import { ObjectType, updateOneReportObject } from '@brands/services/oneReport/updateOneReportObject';
import { selectAuth } from '@brands/store/selectors/auth';
import { consultationPharmacySectionSelectStyles } from '@brands/Utils/customStyles';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { checkCollapsibleHeaderColor } from '@brands/Utils/getCollapsibleHeader';
import { Option, stateOptions } from '@brands/Utils/selectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import { E164Number } from 'libphonenumber-js';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { OralHealthReportSubmit, PharmacySubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

type PharmacySectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  medicalHistoryForm: MedicalHistory;
  loading: boolean;
};

const PharmacySection = ({
  consultationForm,
  setConsultationForm,
  medicalHistoryForm,
  thisCase,
  setCase,
  loading,
}: PharmacySectionProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const containerRef = useRef<HTMLDivElement>(null);
  function getEnhancedGivingOptions(options: Option[]): any {
    return [
      {
        value: 'noState',
        label: 'Select a state',
        isDisabled: true,
      },
      ...options,
    ];
  }

  const enhancedGivingOptions = getEnhancedGivingOptions(stateOptions);

  const {
    register,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<PharmacySubmit>({
    mode: 'onChange',
    resolver: yupResolver(pharmacyValidationSchema()),
  });

  const checkAllPharmacyInputsAreEmpty = (): boolean => {
    if (thisCase.one_report.pharmacies) {
      const {
        id,
        pharmacy_name,
        pharmacy_city,
        pharmacy_state,
        pharmacy_street_address,
        pharmacy_phone_number,
        pharmacy_zip_code,
      } = thisCase.one_report.pharmacies;
      if (
        id &&
        pharmacy_name.trim() === '' &&
        pharmacy_city.trim() === '' &&
        pharmacy_state.trim() === '' &&
        pharmacy_street_address.trim() === '' &&
        pharmacy_phone_number.trim() === '' &&
        pharmacy_zip_code.trim() === ''
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const updatePharmacy = async (id: string, nonEmptyPayload: PharmacySubmit): Promise<void> => {
    try {
      const res = await updateOneReportObject(thisCase?.one_report.id || '', ObjectType.Pharmacy, {
        ...nonEmptyPayload,
        id,
      });
      setConsultationForm({
        ...consultationForm,
        pharmacies: res.pharmacies,
      });
      setCase({
        ...thisCase,
        one_report: {
          ...thisCase.one_report,
          pharmacies: res.pharmacies,
        },
      });
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    if (
      userInfo.role.name === UserRoleName.Provider &&
      userInfo.id === Number(thisCase.provider?.id) &&
      medicalHistoryForm
    ) {
      if (Object.keys(medicalHistoryForm).length > 0 && checkAllPharmacyInputsAreEmpty()) {
        Object.keys(medicalHistoryForm).forEach((key) => {
          setValue(key as keyof PharmacySubmit, medicalHistoryForm[key as keyof PharmacySubmit] as string, {
            shouldValidate: !loading,
          });
        });
        setConsultationForm({
          ...consultationForm,
          pharmacies: getValues(),
        });
        updatePharmacy(thisCase.one_report?.pharmacies?.id || '1', getValues());
      } else {
        setConsultationForm({
          ...consultationForm,
          pharmacies: thisCase.one_report.pharmacies,
        });
      }
    }
  }, [medicalHistoryForm]);

  const addPharmacy = async (nonEmptyPayload: PharmacySubmit): Promise<void> => {
    try {
      const res = await addOneReportObject(thisCase?.one_report.id || '', ObjectType.Pharmacy, {
        ...nonEmptyPayload,
      });
      setConsultationForm({
        ...consultationForm,
        pharmacies: res.pharmacies,
      });
      setCase({
        ...thisCase,
        one_report: {
          ...thisCase.one_report,
          pharmacies: res.pharmacies,
        },
      });
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const handlePharmacyOnBlur = async (pharmacy: PharmacySubmit): Promise<void> => {
    const { id } = pharmacy;
    const nonEmptyPayload = {
      ...(pharmacy.pharmacy_name ? { pharmacy_name: pharmacy.pharmacy_name } : { pharmacy_name: '' }),
      ...(pharmacy.pharmacy_street_address
        ? { pharmacy_street_address: pharmacy.pharmacy_street_address }
        : { pharmacy_street_address: '' }),
      ...(pharmacy.pharmacy_city ? { pharmacy_city: pharmacy.pharmacy_city } : { pharmacy_city: '' }),
      ...(pharmacy.pharmacy_state ? { pharmacy_state: pharmacy.pharmacy_state } : { pharmacy_state: '' }),
      ...(pharmacy.pharmacy_zip_code ? { pharmacy_zip_code: pharmacy.pharmacy_zip_code } : { pharmacy_zip_code: '' }),
      ...(pharmacy.pharmacy_phone_number
        ? { pharmacy_phone_number: pharmacy.pharmacy_phone_number }
        : { pharmacy_phone_number: '' }),
    };

    if (id !== undefined) {
      await updatePharmacy(id, nonEmptyPayload);
    } else {
      await addPharmacy(nonEmptyPayload);
    }
  };

  return (
    <div className={styles.descriptionInputs}>
      <div className={styles.selectInput}>
        <div className={`fs-unmask ${styles.title}`}>
          Pharmacy Name
          {Array.isArray(thisCase.one_report.prescriptions) && thisCase.one_report.prescriptions.length > 0 && (
            <span>*</span>
          )}
        </div>
        <TextArea
          {...register(`pharmacy_name`)}
          wrapperStyle={{
            marginTop: '0',
            borderBottom: 'none',
            minHeight: '30px',
            height: 'auto',
          }}
          inputClass={`fs-exclude ${styles.input}`}
          placeholder=""
          isUnderlined={false}
          value={consultationForm?.pharmacies?.pharmacy_name ?? ''}
          onChange={(e) => {
            setValue('pharmacy_name', e.target.value, { shouldValidate: true });
            setConsultationForm({
              ...consultationForm,
              pharmacies: {
                ...consultationForm?.pharmacies,
                pharmacy_name: e.target.value,
              },
            });
          }}
          onBlur={(e: any) => {
            handlePharmacyOnBlur({
              ...consultationForm.pharmacies,
              pharmacy_name: e.target.value,
            });
          }}
          disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
        />
      </div>
      <div className={styles.selectInput}>
        <div className={`fs-unmask ${styles.title}`}>
          Street Address
          {Array.isArray(thisCase.one_report.prescriptions) && thisCase.one_report.prescriptions.length > 0 && (
            <span>*</span>
          )}
        </div>
        <TextField
          {...register('pharmacy_street_address')}
          wrapperStyle={{
            borderBottom: 'none',
          }}
          errors={errors}
          name="pharmacy_street_address"
          value={consultationForm?.pharmacies?.pharmacy_street_address ?? ''}
          onChange={(e) => {
            setValue('pharmacy_street_address', e.target.value, { shouldValidate: true });
            setConsultationForm({
              ...consultationForm,
              pharmacies: {
                ...consultationForm?.pharmacies,
                pharmacy_street_address: e.target.value,
              },
            });
          }}
          onBlur={(e: any) =>
            handlePharmacyOnBlur({
              ...consultationForm.pharmacies,
              pharmacy_street_address: e.target.value,
            })
          }
          wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
          inputClass={styles.input}
          disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
        />
      </div>
      <div className={styles.descriptionInputsFirstGroup}>
        <div className={`${styles.selectInput}`}>
          <div className={`fs-unmask ${styles.title}`}>
            City
            {Array.isArray(thisCase.one_report.prescriptions) && thisCase.one_report.prescriptions.length > 0 && (
              <span>*</span>
            )}
          </div>
          <div className={styles.input}>
            <TextArea
              {...register(`pharmacy_city`)}
              wrapperStyle={{
                marginTop: '0',
                borderBottom: 'none',
                minHeight: '30px',
                height: 'auto',
              }}
              inputClass={`fs-exclude ${styles.input}`}
              placeholder=""
              isUnderlined={false}
              value={consultationForm?.pharmacies?.pharmacy_city ?? ''}
              onChange={(e) => {
                setValue('pharmacy_city', e.target.value, { shouldValidate: true });
                setConsultationForm({
                  ...consultationForm,
                  pharmacies: {
                    ...consultationForm?.pharmacies,
                    pharmacy_city: e.target.value,
                  },
                });
              }}
              onBlur={(e: any) =>
                handlePharmacyOnBlur({
                  ...consultationForm.pharmacies,
                  pharmacy_city: e.target.value,
                })
              }
              disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
            />
          </div>
        </div>
        <div className={`${styles.selectInput}`}>
          <div className={`fs-unmask ${styles.title}`}>
            State
            {Array.isArray(thisCase.one_report.prescriptions) && thisCase.one_report.prescriptions.length > 0 && (
              <span>*</span>
            )}
          </div>
          <div className={styles.input}>
            <CustomDropdown
              {...register(`pharmacy_state`)}
              styles={consultationPharmacySectionSelectStyles}
              placeholder=""
              menuPortalTarget={document.body}
              menuPlacement="auto"
              className="fs-exclude"
              options={enhancedGivingOptions}
              closeMenuOnScroll={(e: any) => e.target.contains(containerRef.current)}
              value={
                !consultationForm?.pharmacies?.pharmacy_state
                  ? enhancedGivingOptions[0]
                  : enhancedGivingOptions.find(
                      (option: Option) => option.value === consultationForm.pharmacies.pharmacy_state,
                    )
              }
              onChange={(value: any) => {
                if (value) {
                  setValue('pharmacy_state', value.value === 'noState' ? '' : value.value, {
                    shouldValidate: true,
                  });
                  if (setConsultationForm) {
                    setConsultationForm({
                      ...consultationForm,
                      pharmacies: {
                        ...consultationForm?.pharmacies,
                        pharmacy_state: value.value,
                      },
                    });
                  }
                  handlePharmacyOnBlur({
                    ...consultationForm.pharmacies,
                    pharmacy_state: value.value,
                  });
                }
              }}
              isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
              isConsultationPharmacySection
            />
          </div>
        </div>
        <div className={`${styles.selectInput}`}>
          <div className={`fs-unmask ${styles.title}`}>
            Zip Code
            {Array.isArray(thisCase.one_report.prescriptions) && thisCase.one_report.prescriptions.length > 0 && (
              <span>*</span>
            )}
          </div>
          <div className={styles.input}>
            <TextArea
              {...register(`pharmacy_zip_code`)}
              wrapperStyle={{
                marginTop: '0',
                borderBottom: 'none',
              }}
              inputClass={`fs-exclude ${styles.input}`}
              placeholder=""
              isUnderlined={false}
              value={consultationForm?.pharmacies?.pharmacy_zip_code ?? ''}
              onChange={(e) => {
                setValue('pharmacy_zip_code', e.target.value, { shouldValidate: true });
                setConsultationForm({
                  ...consultationForm,
                  pharmacies: {
                    ...consultationForm?.pharmacies,
                    pharmacy_zip_code: e.target.value,
                  },
                });
              }}
              onBlur={(e: any) =>
                handlePharmacyOnBlur({
                  ...consultationForm.pharmacies,
                  pharmacy_zip_code: e.target.value,
                })
              }
              disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
            />
          </div>
        </div>
      </div>
      <div className={styles.selectInput}>
        <div className={`fs-unmask ${styles.title}`}>
          Phone Number
          {Array.isArray(thisCase.one_report.prescriptions) && thisCase.one_report.prescriptions.length > 0 && (
            <span>*</span>
          )}
        </div>
        <PhoneInput
          {...register('pharmacy_phone_number')}
          value={(consultationForm?.pharmacies?.pharmacy_phone_number as E164Number) ?? ''}
          id="pharmacy_phone_number"
          onChange={(val) => {
            const updatedValue = val ?? '';
            setValue('pharmacy_phone_number', updatedValue, {
              shouldValidate: true,
            });

            setConsultationForm((prev) => ({
              ...prev,
              pharmacies: {
                ...prev?.pharmacies,
                pharmacy_phone_number: val as string,
              },
            }));
          }}
          onBlur={() =>
            handlePharmacyOnBlur({
              ...consultationForm.pharmacies,
              pharmacy_phone_number: consultationForm.pharmacies.pharmacy_phone_number,
            })
          }
          inputClass={styles.input}
          errors={errors}
          wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
          isUnderlined={false}
          disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
        />
      </div>
    </div>
  );
};

export default PharmacySection;
