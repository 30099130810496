import React, { useEffect, useRef, useState } from 'react';
import { RiCameraSwitchLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import modalClose from '../../../../../assets/icons/close.svg';
import styles from './styles.module.scss';

type CameraProps = {
  onClose: () => void;
  onPhotoTaken: (uri: string) => void;
};

const Camera: React.FC<CameraProps> = ({ onClose, onPhotoTaken }) => {
  const cameraRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');
  const [stream, setStream] = useState<MediaStream | null>(null);

  const checkPermissions = async (): Promise<boolean> => {
    const permissions = await navigator.permissions.query({ name: 'camera' as PermissionName });
    if (permissions.state === 'denied') {
      toast.error('Camera permission is denied. Please enable it in browser settings.');
      return false;
    }
    return true;
  };

  const startCamera = async (): Promise<void> => {
    if (!(await checkPermissions())) {
      onClose();
      return;
    }

    try {
      const constraints: MediaStreamConstraints = {
        video: {
          facingMode: { ideal: facingMode },
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
        audio: false,
      };

      if (navigator.mediaDevices) {
        const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        setStream(mediaStream);
        if (cameraRef.current) {
          cameraRef.current.srcObject = mediaStream;
          await cameraRef.current.play();
        }
      } else {
        toast.warn('getUserMedia not supported on this browser');
      }
    } catch (err) {
      console.error('Camera error:', err);
      toast.error('Please allow camera access', { className: 'fs-unmask' });
      onClose();
    }
  };

  useEffect(() => {
    startCamera();
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [facingMode]);

  const switchCamera = (): void => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  const takePicture = (): void => {
    if (!canvasRef.current || !cameraRef.current) return;

    const context = canvasRef.current.getContext('2d');
    if (!context) return;

    const { videoWidth, videoHeight } = cameraRef.current;
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;
    context.drawImage(cameraRef.current, 0, 0, videoWidth, videoHeight);

    const dataUrl = canvasRef.current.toDataURL('image/png');
    onPhotoTaken(dataUrl);
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    onClose();
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.closeBtn}>
          <button
            type="button"
            className={styles.modalClose}
            style={{ backgroundImage: `url(${modalClose})` }}
            aria-label="Close modal"
            onClick={() => {
              if (stream) {
                stream.getTracks().forEach((track) => track.stop());
              }
              onClose();
            }}
          />
        </div>
        <div className={styles.videoDiv}>
          <button className={styles.switchCamera} onClick={switchCamera} type="button">
            <RiCameraSwitchLine size={24} />
          </button>
          <video playsInline muted autoPlay ref={cameraRef} />
          <canvas ref={canvasRef} width="640" height="480" />
        </div>
        <div className={styles.takePicture}>
          <button className={styles.capture} onClick={takePicture} type="button">
            Take Picture
          </button>
        </div>
      </div>
    </div>
  );
};

export default Camera;
