/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import Button from '@brands/Components/Button/Button';
import Container from '@brands/Components/Container/Container';
import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import { UploadedFile, uploadMediaToCase } from '@brands/Dashboard/MyProfile/Patient/utils/utilsFunctions';
import camera from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/assets/camera-icon.svg';
import Camera from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/Components/Camera';
import { useWindowSize } from '@brands/hooks';
import { addAdditionalInfo } from '@brands/services/cases/addAdditionalInfo';
import { getOneCase } from '@brands/services/cases/getCaseById';
import { ICase } from '@brands/services/cases/types/ICase';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { truncateFilename } from '@brands/Utils/truncateString';
import { yupResolver } from '@hookform/resolvers/yup';
import { uniqBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './styles.module.scss';
import image from './utils/images/image.svg';
import { validationSchema } from './utils/validationSchema';

export interface AddAdditionalInformationFormSubmit {
  notes: string;
  images: string[];
}

const AddAdditionalInformation = (): JSX.Element => {
  const screenSize = useWindowSize();
  const navigate = useNavigate();
  const isMobile = useMemo(() => screenSize.width < 768, [screenSize.width]);
  const routeCaseId = useParams().caseId as string;

  const [openCamera, setOpenCamera] = useState(false);
  const [images, setImages] = useState<UploadedFile[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [thisCase, setCase] = useState<ICase>();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const onSubmit = async (data: AddAdditionalInformationFormSubmit): Promise<void> => {
    setLoading(true);
    try {
      if (data.notes) {
        await addAdditionalInfo(thisCase?.id as number, { additional_info: data.notes });
      }
      if (images.length > 0) {
        await uploadMediaToCase(thisCase as ICase, images);
      }
    } catch (error) {
      displayErrorDetails(error);
    } finally {
      navigate('/appointments');
      setLoading(false);
    }
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(Object.values(errors).length > 1 ? 'Please fill in all fields' : error.message, { className: 'fs-unmask' });
    }
  };

  const { register, handleSubmit, setValue } = useForm<AddAdditionalInformationFormSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onCloseCamera = (): void => {
    setOpenCamera(false);
  };

  const onPhotoTaken = (uri: string): void => {
    setImages([...images, { dataURL: uri, name: 'Capture.png' }]);
  };

  const addImages = (files: File[]): void => {
    const newImages = files.filter(
      (file) =>
        file.type.startsWith('image/') ||
        file.type === 'application/pdf' ||
        file.type === 'application/msword' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type === 'application/vnd.ms-powerpoint' ||
        file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    );
    if (images.length + files.length > 10) {
      alert('You can only upload 10 files');
      return;
    }
    const imagesArray: UploadedFile[] = [];
    for (let i = 0; i < newImages.length; i++) {
      const file = newImages[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', () => {
        imagesArray.push({ name: file.name, dataURL: reader.result as string });
        if (imagesArray.length === files.length) {
          setImages(uniqBy([...images, ...imagesArray], 'dataURL'));
        }
      });
    }
  };

  const handleDragOver = (event: any): void => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLImageElement>): void => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    addImages(files);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const files = Array.from(event.target.files as unknown as File[]);
    addImages(files);
  };

  const deleteMedia = (mediaName: string): void => {
    const selectedMedia = images.find((i) => i.dataURL === mediaName);
    if (selectedMedia) {
      const idx = images.indexOf(selectedMedia);
      setImages(images.filter((_, index) => index !== idx));
    }
  };

  useEffect(() => {
    (async () => {
      await getOneCase(Number(routeCaseId)).then((res) => {
        setCase(res);
      });
    })();
  }, []);

  useEffect(() => {
    if (notes || images.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [notes, images]);
  if (loading || !thisCase) {
    return <Loading dotted fullScreen />;
  }
  return (
    <Container
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        overflow: 'hidden',
      }}
      childrenStyle={{
        zIndex: '2',
        paddingTop: screenSize.width > 768 ? '40px' : '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
      }}
    >
      <div className={styles.addAdditionalInformationContainer}>
        <div className={styles.addAdditionalInformationHeader}>
          <section className={styles.backButtonContainer}>
            <button
              type="button"
              className={`fs-unmask ${styles.backButton}`}
              onClick={() => {
                navigate(-1);
              }}
            >
              <MdKeyboardArrowLeft />
              Back
            </button>
          </section>
          <h2 className={styles.addAdditionalInformationMainTitle}>Additional Information</h2>
        </div>
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <div className={styles.addAdditionalInformationNotes}>
            <label htmlFor="notes" className={`fs-unmask ${styles.addAdditionalInformationTitle}`}>
              Notes for the Provider
            </label>
            <div className={styles.textAreaWrapper}>
              <textarea
                {...register('notes')}
                className={`fs-exclude ${styles.textArea}`}
                onChange={(e) => {
                  setValue('notes', e.target.value);
                  setNotes(e.target.value);
                }}
                onBlur={(e) => {
                  setValue('notes', e.target.value.trim());
                  setNotes(e.target.value.trim());
                }}
                placeholder="Start note…"
              />
            </div>
          </div>
          <div className={styles.addAdditionalInformationImages}>
            <div className={`fs-unmask ${styles.addAdditionalInformationTitle}`}>Upload images or documents for the provider</div>
            <div className={styles.draggingArea} onDragOver={handleDragOver} onDrop={handleDrop}>
              <div className={styles.draggingAreaHeader}>
                <img className={styles.dragImage} src={image} alt="Drap here" />
                <div className={`fs-unmask ${styles.dragTextContainer}`}>
                  {!isMobile && <div >Drag files here or</div>}
                  <div className={styles.uploadImageWrap}>
                    <label htmlFor="formId" className={`${styles.uploadImageLabel}`}>
                      <input
                        {...register('images')}
                        name=""
                        type="file"
                        id="formId"
                        onChange={handleChange}
                        multiple
                        hidden
                        accept={
                          thisCase?.type === 'second_opinion'
                            ? '.pdf, .doc, .docx, .ppt, .pptx, .png, .jpg, .jpeg, .jfif, .pjpeg, .pjp'
                            : '.png, .jpg, .jpeg, .jfif, .pjpeg, .pjp'
                        }
                      />
                      <div className={styles.selectImages}>
                        {isMobile ? 'Upload File' : 'Upload from your computer'}
                      </div>
                    </label>
                    {isMobile && (
                      <div className={`${styles.selectImages} ${styles.cameraBtn}`} onClick={() => setOpenCamera(true)}>
                        <img className={styles.camera} src={camera} alt="Camera" />
                        Camera
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {images.length > 0 && (
                <div className={styles.grid}>
                  {images.map((file) => {
                    const isPDF = /^data:application\/pdf/i.test(file.dataURL);
                    const isDoc =
                      /^data:application\/vnd.openxmlformats-officedocument.wordprocessingml.document|^data:application\/msword/i.test(
                        file.dataURL,
                      );
                    const isPpt =
                      /^data:application\/vnd.openxmlformats-officedocument.presentationml.presentation/i.test(
                        file.dataURL,
                      ) || /^data:application\/vnd.ms-powerpoint/i.test(file.dataURL);

                    const isImage = /^data:image/i.test(file.dataURL);
                    return (
                      <div className={`fs-exclude ${styles.imageWrap}`} key={file.dataURL}>
                        <span className={styles.removeImage} onClick={() => deleteMedia(file.dataURL)}>
                          <IoClose />
                        </span>
                        {isPDF || isDoc || isPpt ? (
                          <p className='fs-exclude'>{truncateFilename(file.name, 20)}</p>
                        ) : isImage ? (
                          <img className='fs-exclude' src={file.dataURL} alt={file.name} />
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <Button type="submit" className={`fs-unmask ${styles.addAdditionalInformationSubmitButton}`} disabled={loading || disabled}>
            Submit
          </Button>
        </form>
        {openCamera && <Camera onClose={onCloseCamera} onPhotoTaken={onPhotoTaken} />}
      </div>
    </Container>
  );
};

export default AddAdditionalInformation;
