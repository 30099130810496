/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import Container from '@brands/Components/Container/Container';
import { config } from '@brands/config/config';
import CareCoordinationImg from '@brands/Dashboard/Dashboard/Patient/utils/care_coordination_case.svg';
import GetStartImg from '@brands/Dashboard/Dashboard/Patient/utils/get_started_design.svg';
import OralHealthCoachingImg from '@brands/Dashboard/Dashboard/Patient/utils/oral_health_coaching.svg';
import ScheduleImg from '@brands/Dashboard/Dashboard/Patient/utils/schedule_or_start_design.png';
import SendNowImg from '@brands/Dashboard/Dashboard/Patient/utils/send_now_design.svg';
import SmileScanMobile from '@brands/Dashboard/Dashboard/Patient/utils/smile_scan_mobile.svg';
import SmileScanStars from '@brands/Dashboard/Dashboard/Patient/utils/smile_scan_star.svg';
import { IListCases, listAllCases } from '@brands/services/cases/listAllCases';
import { completeMobileHandoff } from '@brands/services/communication/completeMobileHandover';
import { getCurrentOrganization } from '@brands/services/identity/getCurrentOrganization';
import { getPricings, Pricings } from '@brands/services/identity/getPricings';
import { getUserAvatar } from '@brands/services/identity/getUserAvatar';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectIsFromHandOff } from '@brands/store/selectors/isFromHandOff';
import { selectShowStateModal } from '@brands/store/selectors/location';
import { selectOrganization } from '@brands/store/selectors/organization';
import { setPatientState } from '@brands/store/slices/currentPatientState';
import { setDependents } from '@brands/store/slices/dependentsSlice';
import { setIsFromHandoff } from '@brands/store/slices/isFromHandOffSlice';
import { setShowStateModal } from '@brands/store/slices/location';
import { setOrganizationPrices } from '@brands/store/slices/organizationPricingSlice';
import { setOrganization } from '@brands/store/slices/organizationSlice';
import { clearPageState } from '@brands/store/slices/pageStateSlice';
import { FormValues, resetForm, setForm, setSelectedPatient } from '@brands/store/slices/patientFormSlice';
import { setSelectedPatientByAdmin } from '@brands/store/slices/selectedPatientSlice';
import { displayErrorDetails } from '@brands/Utils/displayError';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile as mobileDetect } from 'react-device-detect';
import { TbAmbulance } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';

import Button from '../../../Components/Button/Button';
import SelectInput from '../../../Components/Inputs/SelectInput/SelectInput';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import { useOrganizationFlags } from '../../../hooks';
import useCases from '../../../hooks/useCases';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import useWindowSize from '../../../hooks/useWindowSize';
import { CaseType, ICase } from '../../../services/cases/types/ICase';
import { listDependents } from '../../../services/identity/listDependents';
import { selectCommonCustomStyles } from '../../../Utils/customStyles';
import { DEFAULT_PATIENT_LOCATION_STATE, setCurrentPatientState } from '../../../Utils/localStorage';
import { Option, stateOptions } from '../../../Utils/selectOptions';
import OpenCaseListModal from '../Components/OpenCaseListModal/OpenCaseListModal';
import EmergencyModal from '../DashboardModals/EmergencyModal';
import StateModal from '../DashboardModals/StateModal/StateModal';
import PatientEducation from './components/PatientEducation';
import UpcomingServices from './components/UpcomingServices';
import styles from './styles.module.scss';
import { selectSidebarState } from '@brands/store/selectors/sidebarState';

const Dashboard = (): JSX.Element => {
  const screenSize = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(selectAuth);
  const showStateModal = useAppSelector(selectShowStateModal);

  const [selectedCase, setSelectedCase] = useState({
    selectedCaseType: '',
    selectedCasePrice: '',
  });

  const { currentOrganization } = useAppSelector(selectOrganization);
  const [emergencyModal, openEmergencyModal] = useState(false);
  const [pricingList, setPricingList] = useState<Pricings[]>([]);
  const [numberTypeOfCases, setNumberTypeOfCases] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [displayingCases, setDisplayingCases] = useState<ICase[]>([]);
  const currentState: string = useAppSelector(selectCurrentPatientState);
  const isFromHandoffMobile = useAppSelector(selectIsFromHandOff);
  const isMobile = screenSize.width < 768;
  const [state, setState] = useState<SingleValue<Option> | string>(
    currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE,
  );
  const [openCases, setOpenCases] = useState<ICase[]>([]);
  const [openCasesModal, toggleOpenCasesModal] = useState<string | null>(null);

  const targetRef = useRef<HTMLDivElement>(null);

  const scrollToTargetDiv = (): void => {
    targetRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const params = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      patient_id: Number(userInfo.id),
      include_vc_stats: true,
      type: [
        CaseType.one_report,
        CaseType.second_opinion,
        CaseType.video_call_scheduled,
        CaseType.phone_call_scheduled,
        CaseType.care_coordination,
        CaseType.oral_health_coaching,
      ],
      status: [
        'new',
        'accepted_care_advisor',
        'accepted_provider',
        'in_progress_provider',
        'in_progress_care_advisor',
        'completed_by_provider',
      ],
      include_dependents_cases: true,
    }),
    [userInfo.id],
  );

  const { videoCaseEnabled, asyncCaseEnabled, secondOpinionCaseEnabled } = useOrganizationFlags(state as string);
  const { cases, dataLoading, reloadAll } = useCases(params, true);

  const resetPatientIntakeForm = (): void => {
    dispatch(resetForm());
    localStorage.setItem('selectedPatientId', '');
    dispatch(setIsFromHandoff(false));
  };

  const getOpenCases = async (): Promise<IListCases> => {
    const openCaseParams = {
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      patient_id: Number(userInfo.id),
      status: [
        'new',
        'accepted_care_advisor',
        'accepted_provider',
        'in_progress_provider',
        'in_progress_care_advisor',
        'completed_by_provider',
      ],
      type: [
        CaseType.one_report,
        CaseType.second_opinion,
        CaseType.video_call_scheduled,
        CaseType.phone_call_scheduled,
        CaseType.care_coordination,
      ],
      limit: 100,
      include_dependents_cases: true,
    };

    const queryStr = await Promise.all(
      Object.keys(openCaseParams).map((key) => {
        const value = openCaseParams[key as keyof typeof openCaseParams];
        if (Array.isArray(value)) {
          return value.map((v) => `${key}=${v}`).join('&');
        }
        return `${key}=${value}`;
      }),
    );

    const openCaseList = await listAllCases(queryStr.join('&'));

    await Promise.all(
      (openCaseList.nodes = openCaseList.nodes.reduce((acc: ICase[], current: ICase) => {
        if (
          (current.type === 'video_call_scheduled' || current.type === 'phone_call_scheduled') &&
          (current.status === 'in_progress_provider' || current.status === 'in_progress_care_advisor')
        ) {
          return acc;
        }
        return [...acc, current];
      }, [])),
    );

    return openCaseList;
  };

  const startPatientForm = async (getCases = true): Promise<void> => {
    if (getCases) {
      const { nodes: openCaseList } = await getOpenCases();
      if (openCaseList?.length > 0) {
        setOpenCases(openCaseList);
        toggleOpenCasesModal('video_call_scheduled');
        return;
      }
    }
    setIsButtonDisabled(true);
    const formPayload: FormValues[] = [
      {
        form_question_id: 20,
        value: {
          caseType: 'video_call_scheduled',
        },
      },
    ];

    dispatch(setForm(formPayload));
    navigate('/select-patient', {
      state: {
        caseType: 'video_call_scheduled',
      },
    });
    setIsButtonDisabled(false);
  };

  const startOneReport = async (getCases = true): Promise<void> => {
    if (getCases) {
      const { nodes: openCaseList } = await getOpenCases();
      if (openCaseList?.length > 0) {
        setOpenCases(openCaseList);
        toggleOpenCasesModal('one_report');
        return;
      }
    }
    setIsButtonDisabled(true);
    const formPayload: FormValues[] = [
      {
        form_question_id: 20,
        value: {
          caseType: 'one_report',
        },
      },
    ];

    dispatch(setForm(formPayload));
    navigate('/select-patient', { state: { caseType: 'one_report' } });
    setIsButtonDisabled(false);
  };

  const startCareCoordinationCase = async (getCases = true): Promise<void> => {
    if (getCases) {
      const { nodes: openCaseList } = await getOpenCases();
      if (openCaseList?.length > 0) {
        setOpenCases(openCaseList);
        toggleOpenCasesModal(CaseType.care_coordination);
        return;
      }
    }
    setIsButtonDisabled(true);
    const formPayload: FormValues[] = [
      {
        form_question_id: 20,
        value: {
          caseType: CaseType.care_coordination,
        },
      },
    ];

    dispatch(setForm(formPayload));
    navigate('/select-patient', { state: { caseType: CaseType.care_coordination } });
    setIsButtonDisabled(false);
  };

  const startOralHealthCoachingCase = async (getCases = true): Promise<void> => {
    if (getCases) {
      const { nodes: openCaseList } = await getOpenCases();
      if (openCaseList?.length > 0) {
        setOpenCases(openCaseList);
        toggleOpenCasesModal(CaseType.oral_health_coaching);
        return;
      }
    }
    setIsButtonDisabled(true);
    const formPayload: FormValues[] = [
      {
        form_question_id: 20,
        value: {
          caseType: CaseType.oral_health_coaching,
        },
      },
    ];

    dispatch(setForm(formPayload));
    navigate('/select-patient', { state: { caseType: CaseType.care_coordination } });
    setIsButtonDisabled(false);
  };

  const startSecondOpinionCase = async (getCases = true): Promise<void> => {
    if (getCases) {
      const { nodes: openCaseList } = await getOpenCases();
      if (openCaseList?.length > 0) {
        setOpenCases(openCaseList);
        toggleOpenCasesModal('second_opinion');
        return;
      }
    }
    setIsButtonDisabled(true);

    const formPayload: FormValues[] = [
      {
        form_question_id: 15,
        value: {
          dental_emergency: 'no',
          sensitive_teeth: 'no',
          second_opinion: 'yes',
          improve_smile: 'no',
          improve_gum_health: 'no',
          grind_teeth_or_snore: 'no',
          something_else: 'no',
        },
      },
      {
        form_question_id: 16,
        value: {
          inPain: 'no',
          pain_description: ['dull'],
          painLevel: 'noPain',
          daysInPain: '',
        },
      },
      {
        form_question_id: 20,
        value: {
          caseType: 'second_opinion',
        },
      },
    ];

    dispatch(setForm(formPayload));
    // navigate('/upload-image', { state: { caseType: 'second_opinion' } });
    navigate('/select-patient', { state: { caseType: 'second_opinion' } });
    setIsButtonDisabled(false);
  };

  const getPricingsFn = async (): Promise<void> => {
    setLoading(true);
    try {
      const currentOrg = await getCurrentOrganization();
      const pricings = await getPricings();
      setPricingList(pricings);
      const count = pricings.filter(
        (item) => item.code !== 'messaging' && item.code !== 'smile_scan' && item.label !== 'Phone Call',
      ).length;

      dispatch(setOrganization({ currentOrganization: currentOrg }));
      setNumberTypeOfCases(count);
      dispatch(setOrganizationPrices({ currentOrganizationPrices: pricings }));
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    setLoading(false);
  };

  const onCloseStateModal = (): void => {
    dispatch(setShowStateModal({ showStateModal: false }));
    setState(currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE);
  };

  useEffect(() => {
    setState(currentState[userInfo.id]);
  }, [currentState[userInfo.id]]);

  const completeMobileHandoffFn = async (): Promise<void> => {
    try {
      await completeMobileHandoff();
      dispatch(setIsFromHandoff(false));
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    getPricingsFn();
    resetPatientIntakeForm();
    if ((isMobile || mobileDetect) && isFromHandoffMobile) {
      // Complete Handoff
      completeMobileHandoffFn();
    }
    (async () => {
      if (userInfo.id) {
        dispatch(
          setSelectedPatient({
            value: userInfo.id.toString(),
            label: 'Myself',
          }),
        );
        dispatch(clearPageState());
        dispatch(setSelectedPatientByAdmin({ selectedPatient: userInfo }));
        const listOfDependents = await listDependents(userInfo.id, { include_deleted: true });
        if (listOfDependents.length > 0) {
          dispatch(
            setDependents(
              listOfDependents
                .slice()
                .sort((a, b) => b.id - a.id)
                .map((dependent) => ({
                  value: dependent.id.toString(),
                  label: dependent.full_name,
                  status: dependent.status,
                  image: getUserAvatar(Number(dependent.id)),
                })),
            ),
          );
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (cases) {
      setDisplayingCases(cases);
    }
  }, [cases]);

  const renderComingSoon = (): JSX.Element => (
    <div className={`fs-unmask ${styles.comingSoon}`}>We are not currently able to offer this option in your state</div>
  );

  if (loading) {
    return <Loading fullScreen />;
  }

  return (
    <Container
      patientDashboard
      childClassName={styles.dashboard}
      containerStyle={{
        justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
        overflowY: screenSize.width >= 1289 ? 'hidden' : 'scroll',
      }}
    >
      <div className={styles.dashboardContainer}>
        <UpcomingServices
          displayingCases={displayingCases}
          dataLoading={dataLoading}
          reloadAll={reloadAll}
          scrollToTargetDiv={scrollToTargetDiv}
        />
        <div className={styles.stateContainer} ref={targetRef}>
          <p className={`${styles.yourStateTitle} fs-unmask`}>Current location for patient</p>
          <SelectInput
            name="state"
            options={stateOptions}
            selectorClass={styles.selector}
            style={selectCommonCustomStyles}
            containerClass={`${styles.selectorContainer} fs-mask`}
            selectedValue={state}
            onChange={(value) => {
              setState(value?.value || DEFAULT_PATIENT_LOCATION_STATE);
              setCurrentPatientState(userInfo.id, value?.value);
              dispatch(setPatientState({ userId: userInfo.id, state: value?.value || DEFAULT_PATIENT_LOCATION_STATE }));
            }}
          />
        </div>
        {config.organization.name === 'default' ? (
          <div className={styles.smileScanDiv}>
            <div className={styles.smileScanImages}>
              <img className={`fs-unmask ${styles.smileScanStars}`} src={SmileScanStars} alt="SmileScanStars" />
              <img className={`fs-unmask ${styles.smileScanMobile}`} src={SmileScanMobile} alt="SmileScanMobile" />
            </div>
            <div className={styles.smileScanTextDiv}>
              <p className={`fs-unmask ${styles.smileScanTextHeader}`}>FREE SmileScan™</p>
              <span className={`fs-unmask ${styles.smileScanText}`}>
                Use artificial intelligence to detect possible oral health concerns.
              </span>
            </div>
            <Button
              onClick={() => navigate('/smile-scan')}
              className={`fs-unmask ${styles.dashboardBtn}`}
              id="smile_scan_get_started"
            >
              Get Started
            </Button>
          </div>
        ) : (
          <div
            className={`fs-unmask ${styles.titleContainer}`}
            style={{ paddingTop: displayingCases.length > 0 ? '0px' : '40px' }}
          >
            <span>
              THE DENTAL CARE YOU NEED{' '}
              <span className={styles.extraBold}>
                <br />
                WHEN YOU NEED IT
              </span>
            </span>
          </div>
        )}
        <div className={styles.cardsContainer}>
          {pricingList.find(
            (price) =>
              price.case_types[0] === CaseType.video_call_instant ||
              price.case_types[0] === CaseType.video_call_scheduled,
          ) && (
            <div className={`${styles.dashboardCard} ${styles.first}`}>
              <div className={`${styles.cardWrap} ${numberTypeOfCases > 4 ? styles.cardWrapWidth : ''}`}>
                <div className={styles.flexColumn}>
                  <div className={styles.cardHeader}>
                    <div
                      className={`${styles.cardImage} ${styles.scheduleOrStart}`}
                      style={{
                        width: '114px',
                        height: '99.5px',
                        backgroundImage: `url(${ScheduleImg})`,
                      }}
                    />
                    <div className={styles.cardHeaderContent}>
                      <span className={`fs-unmask ${styles.cardTitle}`}>Talk LIVE with a Dentist</span>
                      <hr />
                      <div className={styles.cardHeaderPrice} style={{ justifyContent: 'center' }}>
                        {pricingList.find(
                          (price) =>
                            price.case_types[0] === 'video_call_instant' ||
                            price.case_types[0] === 'video_call_scheduled',
                        )?.amount &&
                        pricingList.find(
                          (price) =>
                            price.case_types[0] === 'video_call_instant' ||
                            price.case_types[0] === 'video_call_scheduled',
                        )?.amount !== 0 ? (
                          <span style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className={`fs-unmask ${styles.text}`}>Typically covered by your insurance</span>
                            <span className={`${styles.price} ${styles.selfPay} fs-unmask`}>
                              <span className={styles.dollarSign} style={{ fontSize: '12px', fontWeight: '400' }}>
                                $
                              </span>
                              {(() => {
                                const priceInCents = pricingList.find(
                                  (price) =>
                                    price.case_types[0] === 'video_call_instant' ||
                                    price.case_types[0] === 'video_call_scheduled',
                                )?.amount;

                                if (priceInCents === undefined) {
                                  return null;
                                }

                                const priceInDollars = priceInCents / 100;
                                return priceInDollars === 0
                                  ? 'Free'
                                  : priceInDollars % 1 === 0
                                  ? `${priceInDollars.toFixed(0)} Self Pay`
                                  : `${priceInDollars.toFixed(2)} Self Pay`;
                              })()}
                            </span>
                          </span>
                        ) : (
                          <>
                            <span className={`fs-unmask ${styles.price}`}>Free</span>
                            {config.organization.name === 'health-plan-san-mateo' && (
                              <span className={`fs-unmask ${styles.text}`}>Typically covered by your insurance</span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.cardDetais} ${numberTypeOfCases > 4 ? styles.cardDetaisPadding : ''}`}>
                    <span className="fs-unmask">
                      Schedule a live video consultation when it’s convenient for you. Discuss your oral health concerns
                      and get an Oral Health Report within 1 business day of your appointment.
                    </span>
                  </div>
                  <div className={styles.cardFooter} id="main-talk-live-button">
                    {videoCaseEnabled ? (
                      <Button
                        onClick={() => {
                          const priceInCents = pricingList.find(
                            (price) =>
                              price.case_types[0] === 'video_call_instant' ||
                              price.case_types[0] === 'video_call_scheduled',
                          )?.amount;

                          if (priceInCents === undefined) {
                            return null;
                          }

                          const priceInDollars = priceInCents / 100;
                          setSelectedCase({
                            selectedCaseType: CaseType.video_call_scheduled,
                            selectedCasePrice: priceInDollars.toString(),
                          });
                          startPatientForm();
                        }}
                        className={`fs-unmask ${styles.dashboardBtn}`}
                        disabled={isButtonDisabled}
                        id="video_case"
                      >
                        Schedule Now
                      </Button>
                    ) : (
                      renderComingSoon()
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {pricingList.find((price) => price.case_types[0] === CaseType.one_report) && (
            <div className={`${styles.dashboardCard} ${styles.second}`}>
              <div className={`${styles.cardWrap} ${numberTypeOfCases > 4 ? styles.cardWrapWidth : ''}`}>
                <div className={styles.flexColumn}>
                  <div className="d-flex flex-column">
                    <div className={styles.cardHeader} style={{ backgroundColor: 'rgb(255, 247, 236)' }}>
                      <div
                        className={`${styles.cardImage} ${styles.sendNow}`}
                        style={{ width: '97.13px', height: '120.51px', backgroundImage: `url(${SendNowImg})` }}
                      />
                      <div className={styles.cardHeaderContent}>
                        <span className={`fs-unmask ${styles.cardTitle}`}>Send Photos for Review</span>
                        <hr />
                        <div className={styles.cardHeaderPrice} style={{ justifyContent: 'center' }}>
                          {pricingList.find((price) => price.case_types[0] === 'one_report')?.amount &&
                          pricingList.find((price) => price.case_types[0] === 'one_report')?.amount !== 0 ? (
                            <span style={{ display: 'flex', flexDirection: 'column' }}>
                              <span className={`fs-unmask ${styles.text}`}>Typically covered by your insurance</span>
                              <span className={`${styles.price} ${styles.selfPay} fs-unmask`}>
                                <span className={styles.dollarSign} style={{ fontSize: '12px', fontWeight: '400' }}>
                                  $
                                </span>
                                {(() => {
                                  const priceInCents = pricingList.find(
                                    (price) => price.case_types[0] === CaseType.one_report,
                                  )?.amount;

                                  if (priceInCents === undefined) {
                                    return null;
                                  }

                                  const priceInDollars = priceInCents / 100;
                                  return priceInDollars === 0
                                    ? 'Free'
                                    : priceInDollars % 1 === 0
                                    ? `${priceInDollars.toFixed(0)} Self Pay`
                                    : `${priceInDollars.toFixed(2)} Self Pay`;
                                })()}
                              </span>
                            </span>
                          ) : (
                            <>
                              <span className={`fs-unmask ${styles.price}`}>Free</span>
                              {config.organization.name === 'health-plan-san-mateo' && (
                                <span className={`fs-unmask ${styles.text}`}>Typically covered by your insurance</span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.cardDetais} ${numberTypeOfCases > 4 ? styles.cardDetaisPadding : ''}`}>
                    <span className="fs-unmask">
                      Upload photos and other health information to assist in diagnosing an issue. After the dentist
                      reviews your documentation, you will receive your Oral Health Report.
                    </span>
                  </div>
                  <div className={styles.cardFooter} id="main-send-photos-button">
                    {asyncCaseEnabled ? (
                      <Button
                        onClick={() => {
                          const priceInCents = pricingList.find(
                            (price) => price.case_types[0] === CaseType.one_report,
                          )?.amount;

                          if (priceInCents === undefined) {
                            return null;
                          }

                          const priceInDollars = priceInCents / 100;
                          setSelectedCase({
                            selectedCaseType: CaseType.one_report,
                            selectedCasePrice: priceInDollars.toString(),
                          });
                          startOneReport();
                        }}
                        className={`fs-unmask ${styles.dashboardBtn}`}
                        disabled={isButtonDisabled}
                        id="async_case"
                      >
                        Send Now
                      </Button>
                    ) : (
                      renderComingSoon()
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {pricingList.find((price) => price.case_types[0] === CaseType.second_opinion) && (
            <div className={`${styles.dashboardCard} ${styles.third}`}>
              <div className={`${styles.cardWrap} ${numberTypeOfCases > 4 ? styles.cardWrapWidth : ''}`}>
                <div className={styles.flexColumn}>
                  <div className={`${styles.cardHeader} ${styles.secondOpinionColor}`}>
                    <div
                      className={`${styles.cardImage} ${styles.getStarted}`}
                      style={{ width: '120.83px', height: '100px', backgroundImage: `url(${GetStartImg})` }}
                    />
                    <div className={`${styles.cardHeaderContent}`}>
                      <span className={`fs-unmask ${styles.cardTitle}`}>Get a Second Opinion</span>
                      <hr />
                      <div className={styles.cardHeaderPrice}>
                        {pricingList.find((price) => price.case_types[0] === 'second_opinion')?.amount &&
                        pricingList.find((price) => price.case_types[0] === 'second_opinion')?.amount !== 0 ? (
                          <span className={`${styles.price} fs-unmask`}>
                            <span className={styles.dollarSign}>$</span>
                            {(() => {
                              const priceInCents = pricingList.find(
                                (price) => price.case_types[0] === 'second_opinion',
                              )?.amount;

                              if (priceInCents === undefined) {
                                return null;
                              }

                              const priceInDollars = priceInCents / 100;
                              return priceInDollars === 0
                                ? 'Free'
                                : priceInDollars % 1 === 0
                                ? `${priceInDollars.toFixed(0)}`
                                : `${priceInDollars.toFixed(2)}`;
                            })()}
                          </span>
                        ) : (
                          <>
                            <span className={`fs-unmask ${styles.price}`}>Free</span>
                            {config.organization.name === 'health-plan-san-mateo' && (
                              <span className={`fs-unmask ${styles.text}`}>Typically covered by your insurance</span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.cardDetais} ${numberTypeOfCases > 4 ? styles.cardDetaisPadding : ''}`}>
                    <span className="fs-unmask">
                      Submit relevant images and the proposed treatment plan for our dentist's review. You will receive
                      an Oral Health Report with advice on how to proceed.
                    </span>
                  </div>
                  <div className={styles.cardFooter} id="main-second-opinion-button">
                    {secondOpinionCaseEnabled ? (
                      <Button
                        onClick={() => {
                          const priceInCents = pricingList.find(
                            (price) => price.case_types[0] === 'second_opinion',
                          )?.amount;

                          if (priceInCents === undefined) {
                            return null;
                          }

                          const priceInDollars = priceInCents / 100;
                          setSelectedCase({
                            selectedCaseType: CaseType.second_opinion,
                            selectedCasePrice: priceInDollars.toString(),
                          });
                          startSecondOpinionCase();
                        }}
                        disabled={isButtonDisabled}
                        className={`fs-unmask ${styles.dashboardBtn}`}
                        id="second_opinion_case"
                      >
                        Get Started
                      </Button>
                    ) : (
                      renderComingSoon()
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={numberTypeOfCases > 4 && styles.rowDiv}>
            {currentOrganization?.care_advisors_enabled &&
              pricingList.find((price) => price.case_types[0] === CaseType.care_coordination) && (
                <div className={`${styles.dashboardCard} ${styles.forth}`}>
                  <div className={`${styles.cardWrap} ${numberTypeOfCases > 4 ? styles.cardWrapWidth : ''}`}>
                    <div className={styles.flexColumn}>
                      <div className={`${styles.cardHeader} ${styles.careCoordinationColor}`}>
                        <div
                          className={`${styles.cardImage} ${styles.getStarted}`}
                          style={{ width: '120.83px', height: '100px', backgroundImage: `url(${CareCoordinationImg})` }}
                        />
                        <div className={styles.cardHeaderContent}>
                          <span className={`fs-unmask ${styles.cardTitle}`}>Care Coordination</span>
                          <hr />
                          <div className={styles.cardHeaderPrice}>
                            {pricingList.find((price) => price.case_types[0] === 'care_coordination')?.amount &&
                            pricingList.find((price) => price.case_types[0] === 'care_coordination')?.amount !== 0 ? (
                              <span className={`fs-unmask ${styles.price}`}>
                                <span className={styles.dollarSign}>$</span>
                                {(() => {
                                  const priceInCents = pricingList.find(
                                    (price) => price.case_types[0] === 'care_coordination',
                                  )?.amount;

                                  if (priceInCents === undefined) {
                                    return null;
                                  }

                                  const priceInDollars = priceInCents / 100;
                                  return priceInDollars === 0
                                    ? 'Free'
                                    : priceInDollars % 1 === 0
                                    ? `${priceInDollars.toFixed(0)}`
                                    : `${priceInDollars.toFixed(2)}`;
                                })()}
                              </span>
                            ) : (
                              <>
                                <span className={`fs-unmask ${styles.price}`}>Free</span>
                                {config.organization.name === 'health-plan-san-mateo' && (
                                  <span className={`fs-unmask ${styles.text}`}>
                                    Typically covered by your insurance
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.cardDetais} ${numberTypeOfCases > 4 ? styles.cardDetaisPadding : ''}`}>
                        <span className="fs-unmask">
                          Get assistance with scheduling an in-office dental appointment nearby or understanding your
                          benefits.
                        </span>
                      </div>
                      <div className={styles.cardFooter} id="main-second-opinion-button">
                        {secondOpinionCaseEnabled ? (
                          <Button
                            onClick={() => {
                              const priceInCents = pricingList.find(
                                (price) => price.case_types[0] === 'care_coordination',
                              )?.amount;
                              if (priceInCents === undefined) {
                                return null;
                              }

                              const priceInDollars = priceInCents / 100;
                              setSelectedCase({
                                selectedCaseType: CaseType.care_coordination,
                                selectedCasePrice: priceInDollars.toString(),
                              });
                              startCareCoordinationCase();
                            }}
                            disabled={isButtonDisabled}
                            className={`fs-unmask ${styles.dashboardBtn}`}
                            id="care_coordination_case"
                          >
                            Get Started
                          </Button>
                        ) : (
                          renderComingSoon()
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {pricingList.find((price) => price.case_types[0] === CaseType.oral_health_coaching) && (
              <div className={`${styles.dashboardCard} ${styles.fifth}`}>
                <div className={`${styles.cardWrap} ${numberTypeOfCases > 4 ? styles.cardWrapWidth : ''}`}>
                  <div className={styles.flexColumn}>
                    <div className={`${styles.cardHeader} ${styles.oralHealthCoachingColor}`}>
                      <div
                        className={`${styles.cardImage} ${styles.getStarted}`}
                        style={{
                          width: '120.83px',
                          height: '100px',
                          marginLeft: '40px',
                          backgroundImage: `url(${OralHealthCoachingImg})`,
                        }}
                      />
                      <div className={styles.cardHeaderContent}>
                        <span className={`fs-unmask ${styles.cardTitle}`}>Oral Health Coaching</span>
                        <hr />
                        <div className={styles.cardHeaderPrice}>
                          {pricingList.find((price) => price.case_types[0] === CaseType.oral_health_coaching)?.amount &&
                          pricingList.find((price) => price.case_types[0] === CaseType.oral_health_coaching)?.amount !==
                            0 ? (
                            <span className={`fs-unmask ${styles.price}`}>
                              <span className={styles.dollarSign}>$</span>
                              {(() => {
                                const priceInCents = pricingList.find(
                                  (price) => price.case_types[0] === CaseType.oral_health_coaching,
                                )?.amount;

                                if (priceInCents === undefined) {
                                  return null;
                                }

                                const priceInDollars = priceInCents / 100;
                                return priceInDollars === 0
                                  ? 'Free'
                                  : priceInDollars % 1 === 0
                                  ? `${priceInDollars.toFixed(0)}`
                                  : `${priceInDollars.toFixed(2)}`;
                              })()}
                            </span>
                          ) : (
                            <>
                              <span className={`fs-unmask ${styles.price}`}>Free</span>
                              {config.organization.name === 'health-plan-san-mateo' && (
                                <span className={`fs-unmask ${styles.text}`}>Typically covered by your insurance</span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.cardDetais} ${numberTypeOfCases > 4 ? styles.cardDetaisPadding : ''}`}>
                      <span className="fs-unmask">
                        Get help with questions that don’t require a dentist appointment.
                      </span>
                    </div>
                    <div className={styles.cardFooter} id="main-second-opinion-button">
                      {secondOpinionCaseEnabled ? (
                        <Button
                          onClick={() => {
                            const priceInCents = pricingList.find(
                              (price) => price.case_types[0] === CaseType.oral_health_coaching,
                            )?.amount;

                            if (priceInCents === undefined) {
                              return null;
                            }

                            const priceInDollars = priceInCents / 100;
                            setSelectedCase({
                              selectedCaseType: CaseType.oral_health_coaching,
                              selectedCasePrice: priceInDollars.toString(),
                            });
                            startOralHealthCoachingCase();
                          }}
                          disabled={isButtonDisabled}
                          className={`fs-unmask ${styles.dashboardBtn}`}
                          id="care_coordination_case"
                        >
                          Schedule Now
                        </Button>
                      ) : (
                        renderComingSoon()
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <PatientEducation />
        {config.organization.name === 'default' && (
          <div className={`fs-unmask ${styles.smileScanNote}`}>Connect from any device, no app needed.</div>
        )}
        <div className={`fs-unmask ${styles.cardHeaderCallYou}`} onClick={() => openEmergencyModal(true)}>
          <TbAmbulance size={20} />
          Do you have an emergency?
        </div>
        <div className={styles.operatingHours}>
          <span className={`fs-unmask ${styles.title}`}>
            <span>Request a phone consultation</span>
            <span>{isMobile || mobileDetect ? <a href="tel:844-240-1883">844-240-1883</a> : '844-240-1883'}</span>
          </span>
          <span className="fs-unmask ">
            Operating hours: 8 am - 11 pm ET (excluding weekends and bank holidays). If contacted outside of these
            times, a member of our care team will follow-up during business hours.
          </span>
        </div>
      </div>
      {emergencyModal && <EmergencyModal setOpenModal={openEmergencyModal} />}
      <StateModal isOpen={showStateModal} onClose={onCloseStateModal} />
      <OpenCaseListModal
        isOpen={openCasesModal}
        toggleModal={toggleOpenCasesModal}
        openCases={openCases}
        startOneReport={startOneReport}
        startPatientForm={startPatientForm}
        selectedCase={selectedCase}
        startSecondOpinionCase={startSecondOpinionCase}
        startCareCoordinationCase={startCareCoordinationCase}
        startOralHealthCoachingCase={startOralHealthCoachingCase}
      />
    </Container>
  );
};
export default React.memo(Dashboard);
