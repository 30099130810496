/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */

import AddressSection from '@brands/Dashboard/Dashboard/SuperAdmin/Components/AddressSection/AddressSection';
import { CaseType } from '@brands/services/cases/types/ICase';
import { Pricings } from '@brands/services/identity/getPricings';
import { getUserById } from '@brands/services/identity/getUserById';
import { updateUserClientTag } from '@brands/services/identity/updateUserClientTag';
import { updateUserEmail } from '@brands/services/identity/updateUserEmail';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectOrganizationPrices } from '@brands/store/selectors/organizationPrices';
import { selectPageState } from '@brands/store/selectors/pageState';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setUser } from '@brands/store/slices/authSlice';
import { setDependentId, setIsFromSelectPatient } from '@brands/store/slices/pageStateSlice';
import { setExternalForms } from '@brands/store/slices/patientFormSlice';
import { setPatientMyProfile } from '@brands/store/slices/patientMyProfileSlice';
import { setSelectedPatientByAdmin } from '@brands/store/slices/selectedPatientSlice';
import { checkExternalFormAnswers } from '@brands/Utils/checkExternalForms';
import { UnifiedProfileSubmit } from '@brands/Utils/types';
import { validateAddress, ValidationModalInterface } from '@brands/Utils/validateAddress';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { E164Number, parsePhoneNumber } from 'libphonenumber-js';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MultiValue, SingleValue } from 'react-select';
import { toast } from 'react-toastify';

import Button from '../../../../../Components/Button/Button';
import MultiselectInput from '../../../../../Components/Inputs/MultiselectInput/MultiselectInput';
import PhoneInput from '../../../../../Components/Inputs/PhoneInput/PhoneInput';
import SelectInput from '../../../../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../../../../Components/Inputs/TextField/TextField';
import ProfileImage from '../../../../../Components/ProfileImage/ProfileImage';
import SelectButtons from '../../../../../Components/ProfileSelectButtons/SelectButtons';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useReduxHook';
import { RegisterMediaPayload } from '../../../../../services/communication/registersConversationMedia';
import { confirmAvatarMedia } from '../../../../../services/identity/confirmAvatarMedia';
import { createBasicInformation } from '../../../../../services/identity/createBasicInformation';
import { deleteAvatar } from '../../../../../services/identity/deleteAvatar';
import { registerAvatarMedia } from '../../../../../services/identity/registerAvatarMedia';
import { saveBasicInformation } from '../../../../../services/identity/saveBasicInformation';
import {
  Address,
  BasicInfo,
  ClientTag,
  Division,
  Marker,
  Organization,
  Practice,
  UserProfile,
  UserRoleName,
} from '../../../../../services/identity/types/UserProfile';
import { UpdateMe, updateMe } from '../../../../../services/identity/updateMe';
import { updateUserDivision } from '../../../../../services/identity/updateUserDivision';
import { updateUserOrganization } from '../../../../../services/identity/updateUserOrganization';
import { updateUserPractice } from '../../../../../services/identity/updateUserPractice';
import { dateAutoSlashes } from '../../../../../Utils/checkAndSetDate';
import { multiSelectCommonCustomStylesLarge, selectCommonCustomStylesLarge } from '../../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../../Utils/displayError';
import { genderIdentity, genderOptions, pronounsOptions } from '../../../../../Utils/selectButtonOptions';
import { Option, OptionLanguage } from '../../../../../Utils/selectOptions';
import styles from '../../../styles.module.scss';
import { MyProfileSubmit } from '../../utils/types';
import { areArraysEqual, updateData } from '../../utils/utilsFunctions';
import { profileValidationSchema } from '../../utils/validationSchema';

type EditProfileProps = {
  setImage: React.Dispatch<React.SetStateAction<string | undefined>>;
  image: string;
  myProfileForm: MyProfileSubmit;
  setMyProfileForm: React.Dispatch<React.SetStateAction<MyProfileSubmit>>;
  setIsEditProfile: React.Dispatch<React.SetStateAction<boolean>>;
  setSelection: React.Dispatch<React.SetStateAction<[number | null, number | null] | null>>;
  setCurrentAvatarURL: React.Dispatch<React.SetStateAction<string>>;
  currentAvatarURL: string;
  thisUser: UserProfile | null;
  setThisUser: React.Dispatch<React.SetStateAction<UserProfile>>;
  avatarAction: string;
  setAvatarAction: React.Dispatch<React.SetStateAction<string>>;
  reviewProfile: boolean | undefined;
  id: string | undefined;
  languageOptions: OptionLanguage[];
  organizationOptions: Option[];
  allOrganization: Organization[];
  clientTagOptions: Option[];
  allClientTags: ClientTag[];
  validationResponseId: string;
  setValidationResponseId: React.Dispatch<React.SetStateAction<string>>;
};

const EditProfile = ({
  setImage,
  image,
  myProfileForm,
  setMyProfileForm,
  setIsEditProfile,
  setSelection,
  setCurrentAvatarURL,
  currentAvatarURL,
  thisUser,
  setThisUser,
  avatarAction,
  setAvatarAction,
  reviewProfile,
  id,
  languageOptions,
  organizationOptions,
  allOrganization,
  clientTagOptions,
  allClientTags,
  validationResponseId,
  setValidationResponseId,
}: EditProfileProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(selectAuth);
  const { values } = useAppSelector(selectPatientForm);
  const [fetchLoading, setFetchLoading] = useState(false);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const organizationPrices = useAppSelector(selectOrganizationPrices);
  const { selectedPatient } = useAppSelector(selectPatient);
  const [showValidationAddressModal, setShowValidationAddressModal] = useState<ValidationModalInterface>({
    isOpen: false,
    title: '',
    whatYouEntered: '',
    recommended: false,
    recommendedAddress: '',
    onClose: undefined,
    onConfirmChange: undefined,
    onKeepCurrentAddress: undefined,
  });
  const [avatarPayload, setAvatarPayload] = useState<RegisterMediaPayload | null>(null);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [key, setKey] = useState(0);
  const [guardianPatient, setGuardianPatient] = useState<UserProfile>();
  const { isFromSelectPatient, isEditInsuranceFlag } = useAppSelector(selectPageState);

  const navigate = useNavigate();

  const phoneInputRef = useRef<HTMLInputElement>(null);

  const isAdminRole = useMemo(
    () =>
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin,
    [userInfo.role.name],
  );

  let currentOrganizationPrices: any;
  let isPaymentRequired: boolean;

  if (isFromSelectPatient) {
    currentOrganizationPrices = organizationPrices?.currentOrganizationPrices?.find(
      (currentOrganizationPrise: Pricings) =>
        currentOrganizationPrise.case_types.includes(
          values.find((item) => item.form_question_id === 20)?.value.caseType as string,
        ),
    );
    isPaymentRequired = currentOrganization?.payment_options?.length > 0;
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<MyProfileSubmit>({
    mode: 'onChange',
    resolver: yupResolver(profileValidationSchema(reviewProfile, isAdminRole, isFromSelectPatient)),
  });
  const triggerProfileAvatar = React.useCallback(
    (avatarUrl: string) => {
      setValue('profile_img', avatarUrl, { shouldValidate: true });
      setMyProfileForm({
        ...getValues(),
        profile_img: avatarUrl,
      });
    },
    [getValues, setMyProfileForm, setValue],
  );
  const onCloseConfirmationModal = (): void => {
    setShowValidationAddressModal({
      isOpen: false,
      title: '',
      whatYouEntered: '',
      recommended: false,
      recommendedAddress: '',
      onClose: undefined,
      onConfirmChange: undefined,
      onKeepCurrentAddress: undefined,
    });
  };

  const checkExternalForms = async (selectedPatientId: number, updatedUser: UserProfile): Promise<string[]> => {
    const [checkProfile, checkMedicalHistory, checkMedicalConditionsFormAnswer, checkDentalHistoryFormAnswer] =
      await checkExternalFormAnswers(
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? guardianPatient ?? (updatedUser as UserProfile)
          : userInfo,
        updatedUser,
      );

    const formsToSet: string[] = [];

    if (!checkProfile) {
      formsToSet.push('patient_profile');
    }
    if (!checkMedicalHistory) {
      formsToSet.push('patient_medical_history');
    }
    if (!checkMedicalConditionsFormAnswer) {
      formsToSet.push('patient_medical_conditions');
    }
    if (!checkDentalHistoryFormAnswer) {
      formsToSet.push('patient_dental_history');
    }

    dispatch(setExternalForms(formsToSet));
    return formsToSet;
  };

  useEffect(() => {
    const getGuardianPAtient = async (): Promise<void> => {
      if (
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
      ) {
        if (selectedPatient.guardian_id !== null) {
          const guardianResponse = await getUserById(selectedPatient?.guardian_id as number);
          setGuardianPatient(guardianResponse);
        }
      }
    };
    if (isFromSelectPatient) {
      getGuardianPAtient();
    }
  }, [selectedPatient]);

  const fetchData = (selectedPatientId: number, updatedUser: UserProfile): Promise<string[]> => {
    setFetchLoading(true);
    return checkExternalForms(selectedPatientId, updatedUser);
  };

  const callSaveProfile = async (profileData: UnifiedProfileSubmit): Promise<void> => {
    setIsSavingProfile(true);
    let updatedUser;
    let afterReviewProfileUpdatedUser;
    try {
      if (thisUser?.email !== (profileData as MyProfileSubmit).email) {
        updatedUser = await updateUserEmail(thisUser?.id || 0, {
          new_email: (profileData as MyProfileSubmit).email,
        });
      }
      if (avatarPayload && avatarFile && avatarAction === 'Upload') {
        const res = await registerAvatarMedia(avatarPayload);
        const mediaId = res.files[0].upload_id;
        await fetch(res.files[0].upload_url, {
          method: 'PUT',
          body: avatarFile,
          headers: { 'content-type': avatarFile.type },
        });
        await confirmAvatarMedia(mediaId, Number(thisUser?.id));
        triggerProfileAvatar(res.files[0].upload_url);
      } else if (avatarAction === 'Remove') {
        await deleteAvatar(thisUser?.id || 0);
        triggerProfileAvatar('');
      }

      const dataWithUpdatedDOB = updateData(profileData);
      const { address1, address2, city, state, zip_code } = profileData;
      const userAddress: Address = { address1, address2, city, state, zip_code };
      updatedUser = await updateMe(
        {
          ...(profileData as UpdateMe),
          phone_number: parsePhoneNumber((profileData as MyProfileSubmit).phone_number, 'US')?.format(
            'E.164',
          ) as string,
          address: userAddress as Address,
        },
        thisUser?.id || 0,
      );

      if (thisUser && thisUser.basic_info !== null) {
        updatedUser = await saveBasicInformation(
          {
            ...(dataWithUpdatedDOB as BasicInfo),
            languages: (profileData as MyProfileSubmit).language.map((language) => language.id.toString()),
          },
          thisUser?.id || 0,
        );
      } else {
        updatedUser = await createBasicInformation(
          {
            ...(dataWithUpdatedDOB as BasicInfo),
            languages: (profileData as MyProfileSubmit).language.map((language) => language.id.toString()),
          },
          thisUser?.id || 0,
        );
      }

      const matchingDivision: Division[] = [];
      const matchingPractice: Practice[] = [];

      if (thisUser && reviewProfile) {
        // Create an array to hold the promises for API calls
        const promises: Promise<UserProfile>[] = [];
        const organizationIdList: string[] = [];

        if (
          (profileData as MyProfileSubmit).organizations &&
          !areArraysEqual(thisUser.organizations, (profileData as MyProfileSubmit).organization!) &&
          userInfo.role.name !== UserRoleName.OrganizationAdmin
        ) {
          const updatedOrganizations = (profileData as MyProfileSubmit)?.organizations;

          if (updatedOrganizations) {
            for (const updatedOrganization of updatedOrganizations) {
              organizationIdList.push(updatedOrganization.id as string);
            }
          }

          if (organizationIdList.length > 0) {
            // License exists in the current list, update it
            promises.push(
              updateUserOrganization(thisUser.id, {
                organization_ids: organizationIdList,
              }),
            );
          }
          if (organizationIdList.length === 0) {
            // License exists in the current list, update it
            promises.push(
              updateUserOrganization(thisUser.id, {
                organization_ids: ['dentistry-one'],
              }),
              updateUserDivision(thisUser.id, {
                division_ids: [],
              }),
              updateUserPractice(thisUser.id, {
                practice_ids: [],
              }),
            );
          }
        }

        if ((profileData as MyProfileSubmit).organizations) {
          const matchingDivisionIds: string[] = [];
          const matchingPracticeIds: string[] = [];

          (profileData as MyProfileSubmit)?.organizations?.forEach((selectedOrganization) => {
            selectedOrganization.divisions.forEach((division) => {
              if (division.id) {
                matchingDivisionIds.push(division.id.toString());
                matchingDivision.push(division);
              }
            });

            selectedOrganization.practices.forEach((practice) => {
              if (practice.id) {
                matchingPracticeIds.push(practice.id.toString());
                matchingPractice.push(practice);
              }
            });
          });

          if (matchingDivisionIds.length > 0) {
            promises.push(
              updateUserDivision(thisUser.id, {
                division_ids: matchingDivisionIds,
              }),
            );
          }
          if (matchingPracticeIds.length > 0) {
            promises.push(
              updateUserPractice(thisUser.id, {
                practice_ids: matchingPracticeIds,
              }),
            );
          }
        }

        if (
          getValues('clientTag') &&
          !areArraysEqual(thisUser.markers as Marker[], getValues('clientTag')!) &&
          userInfo.role.name !== UserRoleName.OrganizationAdmin
        ) {
          const updatedClientTags = getValues('clientTag');
          const clientTagIdList: number[] = [];

          if (updatedClientTags) {
            for (const updatedOrganization of updatedClientTags) {
              clientTagIdList.push(updatedOrganization.id as number);
            }
          }

          if (clientTagIdList.length > 0 && organizationIdList.includes('dentistry-one')) {
            promises.push(
              updateUserClientTag(thisUser.id, {
                client_ids: clientTagIdList,
              }),
            );
          } else {
            promises.push(
              updateUserClientTag(thisUser.id, {
                client_ids: [],
              }),
            );
          }
        }

        // Execute all promises concurrently
        afterReviewProfileUpdatedUser = await Promise.all(promises);
      }

      const selectedOrganizationIds = (profileData as MyProfileSubmit).organizations?.map((option) => option.id);
      setThisUser({
        ...updatedUser,
        organizations: allOrganization.filter((org) => selectedOrganizationIds?.includes(org.id)),
        patient_profile: {
          ...updatedUser.patient_profile,
          ...((profileData as MyProfileSubmit).organizations && reviewProfile && { divisions: matchingDivision }),
          ...((profileData as MyProfileSubmit).organizations && reviewProfile && { practices: matchingPractice }),
        },
        ...(afterReviewProfileUpdatedUser &&
        afterReviewProfileUpdatedUser[afterReviewProfileUpdatedUser.length - 1]?.markers
          ? { markers: afterReviewProfileUpdatedUser[afterReviewProfileUpdatedUser.length - 1].markers }
          : { markers: [] }),
      });

      setMyProfileForm({
        ...getValues(),
        organizations: getValues('organizations') || [],
        clientTag: (() => {
          // Check if afterReviewProfileUpdatedUser is defined and has items
          const lastUser = afterReviewProfileUpdatedUser?.[afterReviewProfileUpdatedUser.length - 1];
          // Check if lastUser and markers are defined and markers has items
          const markers = lastUser?.markers;
          if (markers && markers.length > 0) {
            return markers.map((marker) => {
              const clientTag = allClientTags.find((c) => c.code === marker.value);
              return {
                value: marker.value, // Map marker value to Option's value
                label: clientTag?.name || '', // Map clientTag name to Option's label
                id: clientTag?.id, // Optionally include ID if needed for other logic
              } as Option;
            });
          }
          return []; // If markers is undefined or empty, set clientTag to an empty array
        })(),
      });

      if (!reviewProfile) {
        dispatch(
          setPatientMyProfile({
            ...(profileData as MyProfileSubmit),
            isCompleted: true,
            patientId: thisUser?.id || 0,
            organizations: (profileData as MyProfileSubmit).organizations || [],
          }),
        );
        dispatch(setUser({ userInfo: updatedUser }));
      }
      if (
        isFromSelectPatient ||
        (thisUser?.role.name === UserRoleName.Patient && updatedUser.role.name === UserRoleName.Patient)
      ) {
        dispatch(setSelectedPatientByAdmin({ selectedPatient: updatedUser }));
      }
      setIsEditProfile(false);
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      setIsSavingProfile(false);
      if (isFromSelectPatient) {
        fetchData(thisUser?.id || 0, updatedUser as UserProfile);
        dispatch(setIsFromSelectPatient(false));
        dispatch(setDependentId(null));

        if (
          isPaymentRequired &&
          (currentOrganizationPrices?.amount?.toString() !== '0' || currentOrganizationPrices?.collect_insurance_info)
        ) {
          navigate('/payment-information', {
            state: { caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string },
          });
        } else if (
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'video_call_scheduled' ||
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
            CaseType.phone_call_scheduled ||
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
            CaseType.oral_health_coaching
        ) {
          navigate('/select-date-time', {
            state: {
              caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string as string,
              insuranceCarrierId: '',
              customInsuranceCarrier: '',
              userOrganization: currentOrganization.id,
              patientId: userInfo.id,
            },
          });
        } else if (
          currentOrganizationPrices?.amount.toString() === '0' &&
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion'
        ) {
          navigate('/upload-image', {
            state: {
              caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string,
              patientState: userInfo.address?.state,
              userOrganization: currentOrganization.id,
              patientId: userInfo.id,
            },
          });
        } else if (
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
            CaseType.care_coordination &&
          currentOrganizationPrices?.amount?.toString() === '0'
        ) {
          navigate('/select-patient', {
            state: { caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string },
          });
        } else {
          navigate('/select-needs', {
            state: {
              caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string,
              patientState: userInfo.address?.state,
              userOrganization: currentOrganization.id,
              patientId: userInfo.id,
            },
          });
        }
      }
    }
  };

  const updateCurrentAddress = (standardizedAddress: any, postalAddress: any, addressComponents: any): void => {
    const streetNumber = addressComponents.find(
      (addressComponent: any) => addressComponent.componentType === 'street_number',
    );
    const address1 = addressComponents.find((addressComponent: any) => addressComponent.componentType === 'route');
    if (postalAddress) setValue('city', postalAddress.locality);
    if (streetNumber || address1) {
      setValue('address1', `${streetNumber.componentName.text} ${address1.componentName.text}`);
    }
    if (standardizedAddress?.state) setValue('state', standardizedAddress.state);
    if (postalAddress) setValue('zip_code', postalAddress.postalCode);
  };

  const onSubmitProfile = async (profileData: MyProfileSubmit): Promise<void> => {
    await validateAddress(
      profileData,
      getValues as UseFormGetValues<UnifiedProfileSubmit>,
      validationResponseId,
      callSaveProfile,
      setValidationResponseId,
      setShowValidationAddressModal,
      onCloseConfirmationModal,
      updateCurrentAddress,
    );
  };
  const setTextValue = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = e.target;
    setValue(name as keyof MyProfileSubmit, value, { shouldValidate: true });
    setSelection([e.target.selectionStart, e.target.selectionEnd]);
  };

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string, { className: 'fs-unmask' });
    }
  };

  const onCancelEditProfile = (): void => {
    setImage(currentAvatarURL);
    setIsEditProfile(false);
    if (isFromSelectPatient) {
      dispatch(setIsFromSelectPatient(false));
      dispatch(setDependentId(null));
      navigate('/select-patient', {
        state: { caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string },
      });
    }
  };

  const handleSelectChange = (
    value: SingleValue<Option>,
    orgId: string,
    divisionOrPractice: 'divisions' | 'practices',
  ): void => {
    const currentValue = {
      id: value?.id as string,
      name: value?.label || '',
      organization_id: value?.organization_id,
    };
    const divisionsArray: Division[] = [];
    const practicesArray: Practice[] = [];

    const getSelectedOrganizationIndex = getValues('organizations')?.findIndex(
      (organization) => organization.id === orgId,
    );

    if (divisionOrPractice === 'divisions') {
      divisionsArray.push(currentValue);
      setValue(`organizations.${getSelectedOrganizationIndex!}.divisions`, divisionsArray, { shouldValidate: true });
    }

    if (divisionOrPractice === 'practices') {
      practicesArray.push(currentValue);

      setValue(`organizations.${getSelectedOrganizationIndex!}.practices`, practicesArray, { shouldValidate: true });
    }
  };

  useEffect(() => {
    if (Object.keys(myProfileForm).length > 0) {
      Object.keys(myProfileForm).forEach((k: unknown) => {
        setValue(k as keyof MyProfileSubmit, myProfileForm[k as keyof MyProfileSubmit]);
      });
    }
    setKey(key + 1);
    let organizationsArray = myProfileForm.organizations?.map((organization) => ({
      value: organization.id?.toString() || '',
      label: organization.name || '',
      divisions: organization.divisions || [],
      practices: organization.practices || [],
    }));
    organizationsArray = organizationsArray?.filter((org) => organizationOptions.some((opt) => opt.id === org.value));

    setValue('organization', organizationsArray, { shouldValidate: true });
    const newOrganizationsArray = myProfileForm.organizations?.filter((org) =>
      organizationOptions.some((opt) => opt.id === org.id),
    );
    setValue('organizations', newOrganizationsArray, { shouldValidate: true });
    if (myProfileForm.clientTag && myProfileForm.clientTag?.length > 0) {
      setValue('clientTag', myProfileForm.clientTag);
    }
  }, [myProfileForm]);
  useEffect(() => {
    if (image) triggerProfileAvatar(image);
  }, [image, triggerProfileAvatar]);

  return (
    <div className={styles.editProfileContainer}>
      <div className={styles.titleContainer}>
        <span className={`fs-unmask ${styles.title}`}>Profile</span>
      </div>
      <div className={styles.editContainer}>
        <ProfileImage
          reviewProfile={reviewProfile}
          id={id}
          setCurrentAvatarURL={setCurrentAvatarURL}
          setAvatarFile={setAvatarFile}
          setAvatarPayload={setAvatarPayload}
          setAvatarAction={setAvatarAction}
          triggerProfileAvatar={triggerProfileAvatar}
          setImage={setImage}
          image={image}
          errors={errors}
          size="80px"
        />
        <p className={`fs-unmask ${styles.requiredIndicator}`}>
          Required <span>*</span>
        </p>
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmitProfile, onInvalid)} key={key}>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.details)}>
              <div className={`styles.caption ${styles.caption}`}>
                First Name <span>*</span>
              </div>
              <TextField
                {...register('first_name')}
                errors={errors}
                name="first_name"
                value={getValues('first_name')}
                onChange={(e) => setTextValue(e, 'first_name')}
                wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                inputClass={styles.input}
              />
            </div>
            <div className={cn(styles.rowItem, styles.details)}>
              <div className={`styles.caption ${styles.caption}`}>Middle Name</div>
              <TextField
                {...register('middle_name')}
                errors={errors}
                name="middle_name"
                value={getValues('middle_name')}
                onChange={(e) => setTextValue(e, 'middle_name')}
                wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                inputClass={styles.input}
              />
            </div>
            <div className={cn(styles.rowItem, styles.details)}>
              <div className={`styles.caption ${styles.caption}`}>
                Last Name <span>*</span>
              </div>
              <TextField
                {...register('last_name')}
                errors={errors}
                name="last_name"
                value={getValues('last_name')}
                onChange={(e) => setTextValue(e, 'last_name')}
                wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                inputClass={styles.input}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.gender)}>
              <div className={`styles.caption ${styles.caption}`}>
                Gender assigned at birth <span>*</span>
              </div>
              <SelectButtons
                errors={errors}
                setValue={setValue}
                form={myProfileForm}
                value="gender"
                options={genderOptions}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={cn('col-md-6', styles.rowItem, styles.details, styles.half)}>
              <div className={`styles.caption ${styles.caption}`}>
                Gender identity <span>{isAdminRole && !isFromSelectPatient ? '' : '*'}</span>
              </div>
              <SelectInput
                {...register('gender_identity')}
                name="gender_identity"
                options={genderIdentity}
                containerClass={`fs-exclude ${styles.inputWrapper}`}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesLarge}
                errors={errors}
                selectedValue={getValues('gender_identity')}
                onChange={(value) => {
                  if (value) {
                    setValue('gender_identity', value.value, { shouldValidate: true });
                  }
                }}
              />
            </div>
            <div className={cn('col-md-6', styles.rowItem, styles.details, styles.half)}>
              <div className={`styles.caption ${styles.caption}`}>Pronouns</div>
              <SelectInput
                {...register('pronouns')}
                name="pronouns"
                options={pronounsOptions}
                containerClass={`fs-exclude ${styles.inputWrapper}`}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesLarge}
                errors={errors}
                selectedValue={getValues('pronouns')}
                onChange={(value) => {
                  if (value) {
                    setValue('pronouns', value.value, { shouldValidate: true });
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.details, styles.dobContainer)}>
              <div className={`styles.caption ${styles.caption}`}>
                Date of Birth <span>*</span>
              </div>
              <TextField
                {...register('date_of_birth')}
                errors={errors}
                name="date_of_birth"
                placeholder="MM / DD / YYYY"
                value={getValues('date_of_birth')}
                onChange={(e) => dateAutoSlashes(e, 'date_of_birth', setValue)}
                maxLength={10}
                wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                inputClass={styles.input}
                enableCursorPos={false}
              />
            </div>
            <div className={cn(styles.rowItem, styles.details, styles.big, styles.languages)}>
              <div className={`styles.caption ${styles.caption}`}>
                Languages <span>*</span>
              </div>
              <MultiselectInput
                {...register('language')}
                name="language"
                placeholder="Please add any languages you are comfortable speaking during the consult"
                options={languageOptions}
                style={multiSelectCommonCustomStylesLarge}
                containerClass={`fs-exclude ${styles.inputWrapper}`}
                selectorClass={styles.selector}
                errors={errors}
                selectedValue={getValues('language')}
                onChange={(value) => {
                  if (value) {
                    setValue('language', value as MultiValue<OptionLanguage>, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.separator} />
          <div className={styles.row}>
            <AddressSection
              register={register}
              errors={errors}
              getValues={getValues}
              setTextValue={setTextValue}
              setValue={setValue}
              showValidationAddressModal={showValidationAddressModal}
              required
            />
          </div>
          <div className={styles.separator} />
          <div className={styles.row}>
            {reviewProfile && isAdminRole && (
              <div className={styles.rowItem}>
                <div className={`styles.caption ${styles.caption}`}>
                  Email Address <span>*</span>
                </div>
                <TextField
                  {...register('email')}
                  errors={errors}
                  name="email"
                  value={getValues('email')}
                  onChange={(e) => setTextValue(e, 'email')}
                  wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                  inputClass={styles.input}
                />
              </div>
            )}
            <div className={styles.rowItem}>
              <div className={`styles.caption ${styles.caption}`}>
                Phone Number <span>*</span>
              </div>
              <div className={`fs-exclude ${styles.inputWrapper}`} key={key}>
                <PhoneInput
                  {...register('phone_number')}
                  value={getValues('phone_number') as E164Number}
                  id="phone_number"
                  onChange={(val) => {
                    setValue('phone_number', val as string, {
                      shouldValidate: true,
                    });
                  }}
                  inputClass={styles.input}
                  errors={errors}
                  wrapperClassName={`fs-exclude ${styles.inputWrapper}`}
                  ref={phoneInputRef}
                />
              </div>
            </div>
          </div>
          {reviewProfile && (
            <>
              <div className={styles.separator} />
              {userInfo.role.name !== UserRoleName.OrganizationAdmin && (
                <div className={styles.row}>
                  <div className={cn(styles.rowItem, styles.details, styles.big)}>
                    <div className={`styles.caption ${styles.caption}`}>
                      Organization <span>*</span>
                    </div>
                    <MultiselectInput
                      {...register('organization')}
                      name="organization"
                      options={organizationOptions as { id: string; value: string; label: string }[]}
                      containerClass={`fs-exclude ${styles.inputWrapper}`}
                      selectorClass={styles.selector}
                      style={selectCommonCustomStylesLarge}
                      selectedValue={getValues('organization')}
                      errors={errors}
                      onChange={(selectedOptions) => {
                        if (selectedOptions) {
                          const selectedOrganizations = selectedOptions as MultiValue<Option>;
                          const lastSelectedOption = (selectedOptions as MultiValue<Option>).at(-1);
                          const organizationsArray = getValues('organizations') || [];
                          const removedOrganization = organizationsArray.filter(
                            (org) => !selectedOrganizations.some((selectedOrg) => selectedOrg.value === org.id),
                          );

                          if (
                            lastSelectedOption &&
                            organizationsArray &&
                            selectedOrganizations.length > organizationsArray.length
                          ) {
                            organizationsArray.push({
                              id: lastSelectedOption?.id?.toString() as string,
                              name: lastSelectedOption?.label as string,
                              divisions: [],
                              practices: [],
                              payment_options: [],
                            });
                            setValue('organizations', organizationsArray, { shouldValidate: true });
                          } else {
                            const updatedOrganizations = organizationsArray.filter(
                              (org) => !removedOrganization.some((removedOrg) => removedOrg.id === org.id),
                            );

                            setValue('organizations', updatedOrganizations, { shouldValidate: true });
                          }

                          setValue('organization', selectedOrganizations, { shouldValidate: true });
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              {getValues('organizations') &&
                getValues('organizations')?.map((org, index) => {
                  const divisionOrPractice = (() => {
                    const relatedOrg = allOrganization.find((selectedOrg) => selectedOrg.id === org.id);
                    return relatedOrg && relatedOrg.divisions?.length > 0 ? 'divisions' : 'practices';
                  })();

                  return (
                    <div className={styles.row} key={org.id}>
                      <div className={cn(styles.rowItem, styles.details, styles.big)}>
                        <div className={`styles.caption ${styles.caption}`}>
                          {org.name} - {divisionOrPractice === 'divisions' ? 'Division' : 'Practice'} <span>*</span>
                        </div>
                        <SelectInput
                          {...register(`organizations.${index}.${divisionOrPractice}`)}
                          name="additionalSelect"
                          options={
                            allOrganization.some((selectedOrg) => selectedOrg.id === org.id)
                              ? (() => {
                                  const relatedOrg = allOrganization.find((selectedOrg) => selectedOrg.id === org.id);
                                  if (relatedOrg) {
                                    if (relatedOrg[divisionOrPractice]?.length > 0) {
                                      return relatedOrg[divisionOrPractice].map((item) => ({
                                        id: item.id,
                                        value: item.id,
                                        label: item.name,
                                        organization_id: item?.organization_id,
                                      }));
                                    }
                                  }
                                  return [];
                                })()
                              : []
                          }
                          containerClass={`fs-exclude ${styles.inputWrapper}`}
                          selectorClass={styles.selector}
                          style={selectCommonCustomStylesLarge}
                          selectedValue={(() => {
                            const getSelectedOrganizationIndex = getValues('organizations')?.findIndex(
                              (organization) => organization.id === org.id!,
                            );
                            const option = getValues(
                              `organizations.${getSelectedOrganizationIndex!}.${divisionOrPractice}`,
                            )[0];
                            if (option) {
                              return {
                                id: option.id,
                                value: option.id,
                                label: option.name,
                              };
                            }
                            return null;
                          })()}
                          errors={errors}
                          onChange={(value) => {
                            handleSelectChange(value, org.id! as string, divisionOrPractice);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              {userInfo.role.name !== UserRoleName.OrganizationAdmin &&
                getValues('organizations')?.some((org) => org.id === 'dentistry-one') && (
                  <div className={styles.row}>
                    <div className={cn(styles.rowItem, styles.details, styles.big)}>
                      <div className={`styles.caption ${styles.caption}`}>Client Tags</div>
                      <MultiselectInput
                        {...register('clientTag')}
                        name="clientTag"
                        options={clientTagOptions as { id: string; value: string; label: string }[]}
                        containerClass={`fs-exclude ${styles.inputWrapper}`}
                        selectorClass={styles.selector}
                        style={selectCommonCustomStylesLarge}
                        selectedValue={getValues('clientTag')}
                        errors={errors}
                        onChange={(selectedOptions) => {
                          if (selectedOptions) {
                            const selectedClientTags = selectedOptions as MultiValue<Option>;

                            setValue('clientTag', selectedClientTags, { shouldValidate: true });
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
            </>
          )}

          <div className={styles.actionBtnContainer}>
            <Button type="button" className={`fs-unmask ${styles.cancel}`} onClick={onCancelEditProfile}>
              Cancel
            </Button>
            <Button
              type="submit"
              className={`fs-unmask ${styles.submit}`}
              disabled={
                (isEqual(myProfileForm, getValues()) && isEqual(avatarAction, '')) || isSavingProfile || fetchLoading
              }
            >
              {isFromSelectPatient && !isEditInsuranceFlag
                ? 'Continue'
                : isFromSelectPatient && isEditInsuranceFlag && !isEqual(myProfileForm, getValues())
                ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                    CaseType.care_coordination && currentOrganizationPrices?.amount?.toString() === '0'
                  ? 'Save'
                  : 'Continue'
                : 'Save'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
