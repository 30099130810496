import React, { ButtonHTMLAttributes, FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  onClick?: (() => void) | any;
  className?: string;
  style?: React.CSSProperties;
  auth?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FC<Props> = ({
  children,
  type = 'button',
  onClick = () => {},
  className = '',
  style = {},
  auth = false,
  ...rest
}): JSX.Element => {
  return (
    <button
      type={type}
      {...(type === 'button' && { onClick })}
      className={`${styles.generalButton} ${className} ${auth && styles.authBtn}`}
      style={style}
      id={rest.id || undefined}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
