import { useDevices, useVideoContext } from '@brands/hooks';
import { SELECTED_VIDEO_INPUT_KEY } from '@brands/hooks/video-hooks/useLocalTracks';
import React, { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { RiCameraSwitchLine } from 'react-icons/ri';

import styles from './styles.module.scss';

type CameraSwitchProps = {
  isWaitingRoom?: boolean;
};

const CameraSwitch: React.FC<CameraSwitchProps> = ({ isWaitingRoom = false }) => {
  const { videoInputDevices } = useDevices();
  const { replaceTrack } = useVideoContext();
  const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);
  const [isListDisplayed, setIsListDisplayed] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<string>('');

  useEffect(() => {
    if (videoInputDevices.length > 0) {
      setSelectedDevice(selectedVideoDeviceId || videoInputDevices[0].deviceId);
    }
  }, [videoInputDevices]);

  const handleCameraSelect = (deviceId: string): void => {
    setSelectedDevice(deviceId);
    replaceTrack(deviceId);
    setIsListDisplayed(false); // Hide list after selection
  };

  return (
    <div className={styles.cameraSwitchContainer}>
      {isListDisplayed &&
        (videoInputDevices.length > 0 ? (
          <div className={isWaitingRoom ? styles.cameraListForWaitingRoom : styles.cameraList}>
            {videoInputDevices.map((device) => (
              <button
                key={device.deviceId}
                onClick={() => handleCameraSelect(device.deviceId)}
                className={styles.cameraOption}
                type="button"
              >
                {selectedDevice === device.deviceId && <FaCheck size={12} />}{' '}
                {device.label || `Camera ${videoInputDevices.indexOf(device) + 1}`}
              </button>
            ))}
          </div>
        ) : (
          <p>No camera devices found</p>
        ))}
      {isWaitingRoom ? (
        <button
          onClick={() => setIsListDisplayed(!isListDisplayed)}
          type="button"
          className="fs-unmask"
          aria-label="Camera switch"
        >
          <span className={styles.treeDotsIcon}>
            <BsThreeDotsVertical />
          </span>
        </button>
      ) : (
        <button
          onClick={() => setIsListDisplayed(!isListDisplayed)}
          type="button"
          className="fs-unmask"
          aria-label="Camera switch"
        >
          <span className={styles.listText}>Switch Camera</span>
          <span className={styles.listIcon} style={{ marginLeft: '0.5rem' }}>
            <RiCameraSwitchLine />
          </span>
        </button>
      )}
    </div>
  );
};

export default CameraSwitch;
