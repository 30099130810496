/* eslint-disable max-len */
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { CgFileDocument } from 'react-icons/cg';
import { HiOutlineVideoCamera } from 'react-icons/hi';

import Button from '../../../../../Components/Button/Button';
import { CasePayload } from '../../../../../services/cases/createNewCase';
import { CaseType, ICase } from '../../../../../services/cases/types/ICase';
import { displayErrorDetails } from '../../../../../Utils/displayError';
import { SelectedAppointment } from '../types';
import ConfirmChangeCaseTypeModal from './Components/ConfirmChangeCaseTypeModal';
import TimeSlotComponent, { TimeSlot } from './Components/TimeSlotComponent';
import styles from './styles.module.scss';

interface ProviderCard {
  index: number;
  date: string;
  allSlots: string[];
  providerSyncIntervals: TimeSlot[];
  providerAsyncIntervals: TimeSlot[];
  setSelectedTimeSlot: React.Dispatch<React.SetStateAction<SelectedAppointment>>;
  selectedTimeSlot: SelectedAppointment;
  refreshProviders: () => void;
  isAssignProvider: boolean;
  thisCase: ICase | undefined;
  setCase: React.Dispatch<React.SetStateAction<ICase>> | undefined;
  setLoading: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  editCase: (case_id: number, body: CasePayload) => Promise<ICase | null>;
  providerId: number | undefined;
  openModal: ((arg0: boolean) => void) | undefined;
}

const ProviderAvailabilityCardCAView: React.FC<ProviderCard> = ({
  index,
  date,
  allSlots,
  providerSyncIntervals,
  providerAsyncIntervals,
  selectedTimeSlot,
  setSelectedTimeSlot,
  refreshProviders,
  isAssignProvider,
  thisCase,
  setCase,
  setLoading,
  editCase,
  providerId,
  openModal,
}) => {
  const [selectedUTCFormattedDate, setSelectedUTCFormattedDate] = useState<string>('');
  const [selectedFormattedDate, setSelectedFormattedDate] = useState<string>('');
  const [loadingData, setLoadingData] = useState(false);
  const changeCaseTypeAndAssignProvider = async (utcFormattedDate: string, formattedDate: string): Promise<void> => {
    const payload: CasePayload = {
      video_conference_date: utcFormattedDate,
      provider_id: providerId,
      status: 'accepted_provider',
    };

    if (typeof thisCase?.id === 'number' && formattedDate !== '' && setLoading && setCase && providerId) {
      try {
        setLoading(true);
        const updatedCase = await editCase(thisCase.id, payload);
        if (updatedCase) {
          setCase({
            ...thisCase,
            status: updatedCase.status,
            video_conference_date: updatedCase.video_conference_date,
            provider: updatedCase.provider,
            provider_id: updatedCase.provider_id,
            updated_at: updatedCase.updated_at,
            type: updatedCase.type,
            started_at: updatedCase.started_at,
          });
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
      } finally {
        if (openModal) openModal(false);
        setLoading(false);
      }
    }
  };
  const confirmChangeCaseTypeAndAssignProvider = (): void => {
    changeCaseTypeAndAssignProvider(selectedUTCFormattedDate, selectedFormattedDate);
  };
  const [changeCaseModal, setChangeCaseModal] = useState(false);
  const getCurrentTimezone = useMemo(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  }, []);
  const timeZone = getCurrentTimezone;
  const savePatientScheduledDate = (): void => {
    setLoadingData(true);
    // Convert the input string to a Date object in your local timezone
    const localDate = new Date(`${date} ${selectedTimeSlot.selectedTimeSlot}`);

    // Create a new Date object using the UTC time
    const utcDate = new Date(localDate);

    // Convert the UTC date to a string representation in your timezone
    const formattedDate = utcDate.toLocaleString(undefined, { timeZone });

    // Convert the formatted date back to UTC format '2023-08-04T10:30:00Z'
    const utcFormattedDate = new Date(formattedDate).toISOString();
    setSelectedUTCFormattedDate(utcFormattedDate);
    setSelectedFormattedDate(formattedDate);
    if (thisCase?.type === 'video_call_scheduled' || thisCase?.type === CaseType.phone_call_scheduled) {
      changeCaseTypeAndAssignProvider(utcFormattedDate, formattedDate);
    } else {
      setChangeCaseModal(true);
    }
    setLoadingData(false);
  };

  return (
    <>
      <div className={styles.allAvailability}>
        {providerSyncIntervals?.map((availability) => (
          <div className={styles.availabilityRow}>
            <span className={styles.availabilityIconContainer}>
              <span className={styles.availabilityIcon}>
                <HiOutlineVideoCamera />
              </span>
            </span>
            <span className={styles.availabilitySlot}>
              {moment(availability.start_time).format('h:mmA')} - {moment(availability.end_time).format('h:mmA')}
            </span>
            <span className={styles.availabilityTimezone}> {Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
          </div>
        ))}
        {providerAsyncIntervals?.map((availability) => (
          <div className={styles.availabilityRow}>
            <span className={styles.availabilityIconContainer}>
              <span className={styles.availabilityIcon}>
                <CgFileDocument />
              </span>
            </span>
            <span className={styles.availabilitySlot}>
              {moment(availability.start_time).format('h:mmA')} - {moment(availability.end_time).format('h:mmA')}
            </span>
            <span className={styles.availabilityTimezone}> {Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
          </div>
        ))}
      </div>
      {providerSyncIntervals.length > 0 && (
        <div
          className={`${styles.card} ${
            selectedTimeSlot.selectedTimeSlot !== '' && selectedTimeSlot.selectedProviderId === index
              ? styles.selectedBg
              : styles.whiteBg
          }`}
        >
          <div>
            <TimeSlotComponent
              id={index}
              timeSlots={providerSyncIntervals}
              allSlots={allSlots}
              selectedTimeSlot={selectedTimeSlot}
              setSelectedTimeSlot={setSelectedTimeSlot}
              date={date}
              refreshProviders={refreshProviders}
            />
          </div>
          {selectedTimeSlot.selectedTimeSlot !== '' &&
            selectedTimeSlot.selectedProviderId === index &&
            isAssignProvider && (
              <div className={styles.cardFooter}>
                <span className={styles.timeSlotDetails}>
                  <span>{date}</span>
                  <span>{selectedTimeSlot.selectedTimeSlot}</span>
                </span>
                <span>
                  <Button type="button" onClick={() => savePatientScheduledDate()} disabled={loadingData}>
                    Schedule Now
                  </Button>
                </span>
              </div>
            )}
        </div>
      )}
      {changeCaseModal && (
        <ConfirmChangeCaseTypeModal
          setOpenModal={setChangeCaseModal}
          confirmChangeCaseTypeAndAssignProvider={confirmChangeCaseTypeAndAssignProvider}
        />
      )}
    </>
  );
};

export default ProviderAvailabilityCardCAView;
