/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import QuestionAndTextField from '@brands/Components/QuestionAndAnswers/QuestionAndTextField/QuestionAndTextField';
import { addClinicalData } from '@brands/services/clinical_data/addClinicalData';
import { listClinicalData } from '@brands/services/clinical_data/listClinicalData';
import { ClinicalDataQuestion, MedicalConditions } from '@brands/services/clinical_data/types/types';
import { updateClinicalData } from '@brands/services/clinical_data/updateClinicalData';
import { getUserById } from '@brands/services/identity/getUserById';
import { UserProfile, UserRoleName } from '@brands/services/identity/types/UserProfile';
import { setUserTag } from '@brands/services/tags/setUserTag';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setExternalForms } from '@brands/store/slices/patientFormSlice';
import { checkExternalFormAnswers } from '@brands/Utils/checkExternalForms';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import QuestionAndRadioButton from '../../../Components/QuestionAndAnswers/QuestionAndRadioButtonForMed/QuestionAndRadioButton';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import useWindowSize from '../../../hooks/useWindowSize';
import { listAllTags } from '../../../services/tags/listAllTags';
import { AddTagPayload, setTagsToMe } from '../../../services/tags/setTagsToMe';
import { displayErrorDetails } from '../../../Utils/displayError';
import { validationSchema } from '../MedicalHistory/utils/validationSchema';
import styles from '../styles.module.scss';
import { medicalConditionQuestions, medicalConditionQuestionsForTags } from './utils/questions';
import { MedicalConditionProps, MedicalConditionSubmit } from './utils/types';

interface Option {
  id: number;
  description: string;
  patient_owned: boolean;
  name: string;
}

const MedicalConditionDefault: MedicalConditionSubmit = {
  arthritis: false,
  cancer: false,
  dementia_alzheimers: false,
  heart_disease: false,
  dry_mouth: false,
  obesity: false,
  pregnancy_or_breastfeeding: false,
  respiratory_conditions: false,
  acid_reflux: false,
  aids_hiv: false,
  alzheimers_disease: false,
  anaphylaxis: false,
  angina: false,
  artificial_joints: false,
  asthma: false,
  cancer_chemo_radiation: false,
  copd: false,
  diabetes: false,
  drug_addiction: false,
  eating_disorder: false,
  emphysema: false,
  epilepsy: false,
  excessive_bleeding: false,
  excessive_thirst: false,
  fainting_spells: false,
  glaucoma: false,
  heart_attack: false,
  heart_pacemaker: false,
  hemophilia: false,
  hepatitis_a: false,
  hepatitis_b: false,
  hepatitis_c: false,
  high_blood_pressure: false,
  kidney_problems: false,
  low_blood_pressure: false,
  mitral_valve_prolapse: false,
  osteoporosis: false,
  psychiatric_care: false,
  rheumatic_fever: false,
  rheumatism: false,
  sexually_transmitted_disease: false,
  sickle_cell_disease: false,
  stroke: false,
  thyroid_disease: false,
  tuberculosis: false,
  ulcers: false,
  conditions_not_listed: '',
  none_of_the_above: false,
};

const MedicalCondition = ({ isProfileCard }: MedicalConditionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(selectAuth);
  const { selectedPatient, values } = useAppSelector(selectPatientForm);
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const screenSize = useWindowSize();
  const { state } = useLocation();
  const [noneOfTheAboveFlag, setNoneOfTheAboveFlag] = useState<boolean>(false);
  const [showRemainingQuestions, setShowRemainingQuestions] = useState(false);
  const [rendered, setRendered] = useState(true);
  const [medicalConditionForm, setMedicalConditionForm] = useState<MedicalConditionSubmit>(MedicalConditionDefault);
  const [objectId, setObjectId] = useState<string | null>(null);
  const [guardianPatient, setGuardianPatient] = useState<UserProfile>();
  useEffect(() => {
    const getGuardianPAtient = async (): Promise<void> => {
      if (
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
      ) {
        if (currentPatient.guardian_id !== null) {
          const guardianResponse = await getUserById(currentPatient?.guardian_id as number);
          setGuardianPatient(guardianResponse);
        }
      }
    };
    getGuardianPAtient();
  }, [currentPatient]);

  const [tagsList, setTagsList] = useState<Option[]>([]);

  const removePunctuationAndLowerCase = (str: string): string => {
    return str.replace(/[^\w\s]|_/g, '').toLowerCase();
  };

  const createQuestionTagMap = (givenQuestions: ClinicalDataQuestion[]): { [key: string]: number } => {
    const map: { [key: string]: number } = {};
    for (const question of givenQuestions) {
      const matchingTag = tagsList.find(
        (tag) => removePunctuationAndLowerCase(tag.description) === removePunctuationAndLowerCase(question.title),
      );
      if (matchingTag) {
        map[question.id] = matchingTag.id;
      }
    }
    return map;
  };
  const updateTags = async (questionsAnswers: MedicalConditionSubmit): Promise<void> => {
    for (const question in questionsAnswers) {
      if (Object.prototype.hasOwnProperty.call(questionsAnswers, question)) {
        const tagId = createQuestionTagMap(medicalConditionQuestionsForTags)[question];
        const payload: AddTagPayload = {
          answer:
            question === 'conditions_not_listed'
              ? questionsAnswers[question as keyof MedicalConditionSubmit] !== ''
              : (questionsAnswers[question as keyof MedicalConditionSubmit] as boolean),
          note:
            question === 'conditions_not_listed'
              ? (questionsAnswers[question as keyof MedicalConditionSubmit] as string)
              : '',
        };
        try {
          if (tagId) {
            if (
              userInfo.role.name === UserRoleName.Admin ||
              userInfo.role.name === UserRoleName.SuperAdmin ||
              userInfo.role.name === UserRoleName.OrganizationAdmin
            ) {
              await setUserTag(selectedPatient?.value || state.patientId, tagId, payload);
            } else {
              await setTagsToMe(
                tagId,
                payload,
                Number(selectedPatient?.value) !== Number(userInfo.id) ? Number(selectedPatient?.value) : undefined,
              );
            }
          }
        } catch (error: unknown) {
          displayErrorDetails(error);
          throw error; // Move throw inside the catch block
        }
      }
    }
  };
  const navigate = useNavigate();

  const checkExternalForms = async (): Promise<void> => {
    const [checkProfile, checkMedicalHistory, checkMedicalConditionsFormAnswer, checkDentalHistoryFormAnswer] =
      await checkExternalFormAnswers(
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? guardianPatient ?? (currentPatient as UserProfile)
          : userInfo,
        currentPatient,
      );
    const formsToSet = [];

    if (!checkProfile) {
      formsToSet.push('patient_profile');
    }
    if (!checkMedicalHistory) {
      formsToSet.push('patient_medical_history');
    }
    if (!checkMedicalConditionsFormAnswer) {
      formsToSet.push('patient_medical_conditions');
    }
    if (!checkDentalHistoryFormAnswer) {
      formsToSet.push('patient_dental_history');
    }

    dispatch(setExternalForms(formsToSet));
  };

  const onSubmit = async (data: MedicalConditionSubmit): Promise<void> => {
    setRendered(false);
    if (!data.conditions_not_listed) {
      delete data.conditions_not_listed;
    }
    await updateTags(data);
    const currentUserId =
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
        ? currentPatient.guardian_id !== null
          ? (guardianPatient as UserProfile).id
          : (currentPatient as UserProfile).id
        : currentPatient.id;
    data = {
      ...data,
      user_id: currentUserId,
    };
    if (!objectId) {
      await addClinicalData('medical_conditions', data);
    } else {
      await updateClinicalData('medical_conditions', objectId, data);
    }
    await checkExternalForms();
    setRendered(true);
    navigate('/medical-information-summary', {
      state: {
        fromPatientIntake: state?.fromPatientIntake ?? false,
        caseType: state ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) : '',
        isPatientForm: state?.isPatientForm,
        shouldSeeLastModal: state?.shouldSeeLastModal,
        patientId: state?.patientId,
      },
    });
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(error.message as string, { className: 'fs-unmask' });
    }
  };

  const { register, trigger, handleSubmit, setValue } = useForm<MedicalConditionSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(medicalConditionQuestions, false, true, false)),
  });

  useEffect(() => {
    (async () => {
      setRendered(false);

      const fetchOptions = async (): Promise<void> => {
        const response = await listAllTags();
        setTagsList(response);
      };
      fetchOptions();

      const { medical_conditions } = await listClinicalData(selectedPatient?.value);
      if (medical_conditions) {
        setMedicalConditionForm(medical_conditions);
        setObjectId(medical_conditions.id.toString());
      }
      if (medical_conditions) {
        const hasAnsweredQuestions = Object.keys(medical_conditions as MedicalConditions)
          ?.slice(8)
          ?.some(
            (key, index, array) =>
              medical_conditions[key as keyof MedicalConditionSubmit] && index !== array.length - 1,
          );

        setShowRemainingQuestions(hasAnsweredQuestions);
      }

      setRendered(true);
    })();
  }, []);

  useEffect(() => {
    if (medicalConditionForm) {
      Object.keys(medicalConditionForm).forEach((key) => {
        if (key in MedicalConditionDefault) {
          setValue(key as keyof MedicalConditionSubmit, medicalConditionForm[key as keyof MedicalConditionSubmit]);
        }
      });
      trigger();
    }
  }, [medicalConditionForm, setValue, trigger]);

  if (!rendered) {
    return <Loading fullScreen />;
  }
  function handleNoneOfTheAbove(): void {
    if (showRemainingQuestions) setShowRemainingQuestions(false);
    setNoneOfTheAboveFlag(!noneOfTheAboveFlag);
    setMedicalConditionForm(() => ({
      ...MedicalConditionDefault,
      conditions_not_listed: '',
      none_of_the_above: !noneOfTheAboveFlag,
    }));
  }

  function handleOtherClick(): void {
    setShowRemainingQuestions(!showRemainingQuestions);
    setNoneOfTheAboveFlag(false);
    setMedicalConditionForm((prev: any) => ({
      ...prev,
      none_of_the_above: false,
    }));
  }

  return (
    <Container
      isBGEclipse={false}
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        width: isProfileCard ? '100%' : '',
      }}
      isProfileCard={isProfileCard}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2', paddingTop: '40px' }}
    >
      <div className={styles.medicalContainer}>
        <form
          className={styles.formContainer}
          style={{ width: isProfileCard ? '100%' : '', marginRight: isProfileCard ? '0px' : '' }}
          onSubmit={handleSubmit(onSubmit, onInvalid)}
        >
          <div
            className={styles.wrapper}
            style={{ width: isProfileCard ? '734px' : screenSize.width < 992 ? '90%' : '' }}
          >
            <div className={`${styles.formQuestion} fs-unmask`}>
              Do you have, or have you had, any of the following? <span className={styles.redText}> *</span>
            </div>
            <ProgressBar
              currentStep={state?.isPatientForm ? 3 : 2}
              totalSteps={state?.isPatientForm ? 5 : state?.isPatientForm ? 4 : 3}
              hideStepNumbers
            />
            <div className={styles.formContent}>
              {medicalConditionQuestions !== undefined &&
                medicalConditionQuestions.slice(0, 8)?.map((element) => {
                  return (
                    <QuestionAndRadioButton
                      {...register(element.id as keyof MedicalConditionSubmit)}
                      element={element}
                      key={element.id}
                      form={medicalConditionForm}
                      setForm={setMedicalConditionForm}
                      noneOfTheAboveFlag={noneOfTheAboveFlag}
                      noneOfTheAboveQuestionId="none_of_the_above"
                    />
                  );
                })}
              <div
                className={`${styles.row} row ${styles.medicalFormRow} ${
                  showRemainingQuestions ? styles.selectClass : ''
                }`}
                onClick={() => handleOtherClick()}
              >
                <div className={styles.questionAndButtonContainer}>
                  <div className={`col-6 col-sm-3 px-0 ${styles.select}`}>
                    <div className={styles.check}>
                      <input
                        className={`form-check-input ${styles.checkInput} mt-0 ${
                          showRemainingQuestions ? styles.primaryColor : ''
                        }`}
                        type="checkbox"
                        name="other"
                        id="other"
                        value={showRemainingQuestions ? 'no' : 'yes'}
                      />
                    </div>
                  </div>
                  <div className={`${styles.question} col-12 col-sm-8 px-0`}>
                    <div className={`${styles.label}`}>Other</div>
                  </div>
                </div>
              </div>
              {showRemainingQuestions && (
                <>
                  {medicalConditionQuestions.slice(8).map((element) =>
                    element.type !== 'textarea' && element.title !== 'None of the Above' ? (
                      <QuestionAndRadioButton
                        {...register(element.id as keyof MedicalConditionSubmit)}
                        element={element}
                        key={element.id}
                        form={medicalConditionForm}
                        setForm={setMedicalConditionForm}
                        noneOfTheAboveFlag={noneOfTheAboveFlag}
                        noneOfTheAboveQuestionId="none_of_the_above"
                      />
                    ) : element.type === 'textarea' ? (
                      <div className={styles.textAreaContainer}>
                        <QuestionAndTextField
                          {...register(element.id as keyof MedicalConditionSubmit)}
                          element={element}
                          key={element.id}
                          form={medicalConditionForm}
                          setForm={setMedicalConditionForm}
                          medicalCondition
                          noneOfTheAboveFlag={noneOfTheAboveFlag}
                          noneOfTheAboveQuestionId="none_of_the_above"
                        />
                      </div>
                    ) : null,
                  )}
                </>
              )}
            </div>
            <div className={styles.btnContainer} style={{ justifyContent: 'space-between' }}>
              {medicalConditionQuestions !== undefined &&
                medicalConditionQuestions.slice(8)?.map(
                  (element) =>
                    element.title === 'None of the Above' && (
                      <span className={styles.dontKnowClass}>
                        <input
                          className={`form-check-input fs-exclude ${styles.checkInput} mt-0 ${
                            medicalConditionForm[element.id as keyof MedicalConditionSubmit] ? styles.primaryColor : ''
                          }`}
                          type="checkbox"
                          name={`question_${element.id}`}
                          id={`question_${element.id}`}
                          checked={
                            (medicalConditionForm[element.id as keyof MedicalConditionSubmit] as boolean) &&
                            !showRemainingQuestions
                          }
                          onClick={() => handleNoneOfTheAbove()}
                        />
                        None of the above
                      </span>
                    ),
                )}
              <Button type="submit" style={{ width: '245px', height: '46px' }} className='fs-unmask'>
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};
MedicalCondition.defaultProps = {
  isProfileCard: false,
};
export default MedicalCondition;
