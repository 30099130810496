/* eslint-disable func-names */
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import {
  checkFutureDate,
  checkValidDate,
  isValidDate,
  isValidFullName,
  isValidName,
  isValidZipCode,
} from '../../../../../Utils/validation';
import { PatientFormFiveSubmit } from '../../utils/types';

export const validationSchema = (
  patientForm: PatientFormFiveSubmit,
  paymentMethod: string,
  addSecondaryInsurance: boolean,
  isSameAddress: boolean,
): any => {
  return Yup.object().shape({
    insurance_through: Yup.string().test('insurance_through', 'Insurance is required', (value) => {
      return paymentMethod === 'Pay by Insurance' ? value?.trim() !== '' : true;
    }),
    insurance_carrier_id: Yup.number().test('insurance_carrier_id', 'Insurance carrier ID is required', (value) => {
      return patientForm.insurance_through !== 'none' && paymentMethod === 'Pay by Insurance' ? value !== 0 : true;
    }),

    member_id: Yup.string().test('member_id', 'Member ID is required', (value) => {
      return patientForm.insurance_through !== 'none' && paymentMethod === 'Pay by Insurance'
        ? value?.trim() !== ''
        : true;
    }),
    group_number: Yup.string().test('group_number', 'Group Number is required', (value) => {
      return patientForm.insurance_through !== 'none' && paymentMethod === 'Pay by Insurance'
        ? value?.trim() !== ''
        : true;
    }),
    insurance_address: Yup.string().test('insurance_address', 'Insurance Address is required', (value) => {
      return patientForm.insurance_through !== 'none' && paymentMethod === 'Pay by Insurance'
        ? value?.trim() !== ''
        : true;
    }),

    insurance_city: Yup.string().test('insurance_city', 'Insurance City is required', (value) => {
      return patientForm.insurance_through !== 'none' && paymentMethod === 'Pay by Insurance'
        ? value?.trim() !== ''
        : true;
    }),
    insurance_state: Yup.string().test('insurance_state', 'Insurance State is required', (value) => {
      return patientForm.insurance_through !== 'none' && paymentMethod === 'Pay by Insurance'
        ? value?.trim() !== ''
        : true;
    }),
    insurance_zip_code: Yup.string()
      .test('test-name', 'Not valid insurance zipcode', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      })
      .test('insurance_zip_code', 'Insurance Zip Code is required', (value) => {
        return patientForm.insurance_through !== 'none' && paymentMethod === 'Pay by Insurance'
          ? value?.trim() !== ''
          : true;
      }),

    iPhoneNumber: Yup.string()
      .test('iPhoneNumber', 'Insurance phone number is required', (value) => {
        if (patientForm.insurance_through !== 'none' && paymentMethod === 'Pay by Insurance') {
          if (!value) {
            return false;
          }
        }
        return true;
      })
      .test('test-name', 'Enter Valid Phone Number', (value) => {
        if (value && !isValidPhoneNumber(value)) {
          return false;
        }
        return true;
      }),
    iMiddleName: Yup.string(),
    iFirstName: Yup.string()
      .test('test-name', 'Enter Valid First Name', (value) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      })
      .test('iFirstName', 'First Name is required', (value) => {
        return patientForm.insurance_through !== 'myself' &&
          patientForm.insurance_through !== 'none' &&
          paymentMethod === 'Pay by Insurance'
          ? value?.trim() !== ''
          : true;
      }),
    iLastName: Yup.string()
      .test('test-name', 'Enter Valid Last Name', (value) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      })
      .test('iLastName', 'Last Name is required', (value) => {
        return patientForm.insurance_through !== 'myself' &&
          patientForm.insurance_through !== 'none' &&
          paymentMethod === 'Pay by Insurance'
          ? value?.trim() !== ''
          : true;
      }),
    iDateOfBirth: Yup.string()
      .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value: string | undefined) => {
        if (value && !isValidDate(value)) {
          return false;
        }
        if (value) {
          const datevalues = value?.split('/');
          if (!checkValidDate(datevalues[0], datevalues[1], datevalues[2])) {
            return false;
          }
        }
        return true;
      })
      .test('test-future', 'The Date of Birth can not be in the future.', (value: string | undefined) => {
        if (value) {
          const datevalues = value?.split('/');
          if (!checkFutureDate(datevalues[0], datevalues[1], datevalues[2])) {
            return false;
          }
        }
        return true;
      })
      .test('iDateOfBirth', 'Date of Birth is required', (value) => {
        return patientForm.insurance_through !== 'myself' &&
          patientForm.insurance_through !== 'none' &&
          paymentMethod === 'Pay by Insurance'
          ? value?.trim() !== ''
          : true;
      }),
    iAddress1: Yup.string().test('iAddress1', 'Address is required', (value) => {
      return patientForm.insurance_through !== 'myself' &&
        patientForm.insurance_through !== 'none' &&
        paymentMethod === 'Pay by Insurance'
        ? value?.trim() !== ''
        : true;
    }),
    iState: Yup.string().test('iState', 'State is required', (value) => {
      return patientForm.insurance_through !== 'myself' &&
        patientForm.insurance_through !== 'none' &&
        paymentMethod === 'Pay by Insurance'
        ? value?.trim() !== ''
        : true;
    }),
    iZipCode: Yup.string()
      .test('test-name', 'Not valid zipcode', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      })
      .test('iZipCode', 'Zip Code is required', (value) => {
        return patientForm.insurance_through !== 'myself' &&
          patientForm.insurance_through !== 'none' &&
          paymentMethod === 'Pay by Insurance'
          ? value?.trim() !== ''
          : true;
      }),
    state_funded_coverage_status: Yup.string().when('insurance_through', {
      is: (val: string) => {
        return val && val.trim() !== '' && val !== 'none';
      },
      then: Yup.string().required(
        'Do you currently have insurance coverage through a state-funded program such as Medicaid?*',
      ),
      otherwise: Yup.string(),
    }),
    iCity: Yup.string().test('iCity', 'City is required', (value) => {
      return patientForm.insurance_through !== 'myself' &&
        patientForm.insurance_through !== 'none' &&
        paymentMethod === 'Pay by Insurance'
        ? value?.trim() !== ''
        : true;
    }),
    secondary_insurance_through: Yup.string().test(
      'secondary_insurance_through',
      'Secondary Insurance is required',
      (value) => {
        return addSecondaryInsurance && paymentMethod === 'Pay by Insurance' ? value?.trim() !== '' : true;
      },
    ),
    secondary_insurance_carrier_id: Yup.number().when(['secondary_insurance_through'], {
      is: (val: string) => val !== 'none' && addSecondaryInsurance,
      then: Yup.number().required('Secondary Insurance carrier ID is required'),
      otherwise: Yup.number(),
    }),

    secondary_member_id: Yup.string().test('secondary_member_id', 'Secondary Member ID is required', (value) => {
      return patientForm.secondary_insurance_through !== 'none' &&
        addSecondaryInsurance &&
        paymentMethod === 'Pay by Insurance'
        ? value?.trim() !== ''
        : true;
    }),
    secondary_group_number: Yup.string().test(
      'secondary_group_number',
      'Secondary Group Number is required',
      (value) => {
        return patientForm.secondary_insurance_through !== 'none' &&
          addSecondaryInsurance &&
          paymentMethod === 'Pay by Insurance'
          ? value?.trim() !== ''
          : true;
      },
    ),

    secondary_insurance_address: Yup.string().test(
      'secondary_insurance_address',
      'Secondary Insurance Address is required',
      (value) => {
        return patientForm.secondary_insurance_through !== 'none' &&
          addSecondaryInsurance &&
          paymentMethod === 'Pay by Insurance'
          ? value?.trim() !== ''
          : true;
      },
    ),

    secondary_iPhoneNumber: Yup.string()
      .test('secondary_iPhoneNumber', 'Secondary Insurance phone number is required', (value) => {
        if (
          patientForm.secondary_insurance_through !== 'none' &&
          addSecondaryInsurance &&
          paymentMethod === 'Pay by Insurance'
        ) {
          if (!value) {
            return false;
          }
        }
        return true;
      })
      .test('test-name', 'Enter Valid Phone Number', (value) => {
        if (value && !isValidPhoneNumber(value)) {
          return false;
        }
        return true;
      }),
    secondary_iMiddleName: Yup.string(),
    secondary_iFirstName: Yup.string()
      .test('test-name', 'Enter Valid First Name', (value) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      })
      .test('secondary_iFirstName', 'Secondary First Name is required', (value) => {
        return patientForm.secondary_insurance_through !== 'myself' &&
          patientForm.secondary_insurance_through !== 'none' &&
          paymentMethod === 'Pay by Insurance' &&
          addSecondaryInsurance
          ? value?.trim() !== ''
          : true;
      }),
    secondary_iLastName: Yup.string()
      .test('test-name', 'Enter Valid Last Name', (value) => {
        if (value && !isValidName(value)) {
          return false;
        }
        return true;
      })
      .test('secondary_iLastName', 'Secondary Last Name is required', (value) => {
        return patientForm.secondary_insurance_through !== 'myself' &&
          patientForm.insurance_through !== 'none' &&
          paymentMethod === 'Pay by Insurance' &&
          addSecondaryInsurance
          ? value?.trim() !== ''
          : true;
      }),
    secondary_iDateOfBirth: Yup.string()
      .test('test-name', 'Enter Valid Date  MM/DD/YYYY', (value: string | undefined) => {
        if (value && !isValidDate(value)) {
          return false;
        }
        if (value) {
          const datevalues = value?.split('/');
          if (!checkValidDate(datevalues[0], datevalues[1], datevalues[2])) {
            return false;
          }
        }
        return true;
      })
      .test('test-future', 'The Date of Birth can not be in the future.', (value: string | undefined) => {
        if (value) {
          const datevalues = value?.split('/');
          if (!checkFutureDate(datevalues[0], datevalues[1], datevalues[2])) {
            return false;
          }
        }
        return true;
      })
      .test('secondary_iDateOfBirth', 'Secondary Date of Birth is required', (value) => {
        return patientForm.secondary_insurance_through !== 'myself' &&
          patientForm.secondary_insurance_through !== 'none' &&
          paymentMethod === 'Pay by Insurance' &&
          addSecondaryInsurance
          ? value?.trim() !== ''
          : true;
      }),
    secondary_iAddress1: Yup.string().test('secondary_iAddress1', 'Secondary Address is required', (value) => {
      return patientForm.secondary_insurance_through !== 'myself' &&
        patientForm.secondary_insurance_through !== 'none' &&
        paymentMethod === 'Pay by Insurance' &&
        addSecondaryInsurance
        ? value?.trim() !== ''
        : true;
    }),

    secondary_insurance_city: Yup.string().test(
      'secondary_insurance_city',
      'Secondary Address is required',
      (value) => {
        return patientForm.secondary_insurance_through !== 'myself' &&
          paymentMethod === 'Pay by Insurance' &&
          patientForm.secondary_insurance_through !== 'none' &&
          addSecondaryInsurance
          ? value?.trim() !== ''
          : true;
      },
    ),
    secondary_insurance_state: Yup.string().test(
      'secondary_insurance_state',
      'Secondary Address is required',
      (value) => {
        return patientForm.secondary_insurance_through !== 'myself' &&
          paymentMethod === 'Pay by Insurance' &&
          patientForm.secondary_insurance_through !== 'none' &&
          addSecondaryInsurance
          ? value?.trim() !== ''
          : true;
      },
    ),
    secondary_insurance_zip_code: Yup.string()
      .test('Secondary Address is required', 'Not a valid zipcode', (value) => {
        if (
          patientForm.secondary_insurance_through !== 'myself' &&
          paymentMethod === 'Pay by Insurance' &&
          patientForm.secondary_insurance_through !== 'none' &&
          addSecondaryInsurance
        ) {
          if (value && !isValidZipCode(value)) {
            return false;
          }
        }
        return true;
      })
      .test('requiredZipCode', 'Secondary Insurance Zip Code is required', (value) => {
        return patientForm.secondary_insurance_through !== 'myself' &&
          paymentMethod === 'Pay by Insurance' &&
          patientForm.secondary_insurance_through !== 'none' &&
          addSecondaryInsurance
          ? value?.trim() !== ''
          : true;
      }),
    secondary_iState: Yup.string().test('secondary_iState', 'Secondary State is required', (value) => {
      return patientForm.secondary_insurance_through !== 'myself' &&
        patientForm.secondary_insurance_through !== 'none' &&
        paymentMethod === 'Pay by Insurance' &&
        addSecondaryInsurance
        ? value?.trim() !== ''
        : true;
    }),
    secondary_iZipCode: Yup.string()
      .test('test-name', 'Not valid zipcode', (value) => {
        if (value && !isValidZipCode(value)) {
          return false;
        }
        return true;
      })
      .test('secondary_iZipCode', 'Secondary Zip Code is required', (value) => {
        return patientForm.secondary_insurance_through !== 'myself' &&
          patientForm.secondary_insurance_through !== 'none' &&
          paymentMethod === 'Pay by Insurance' &&
          addSecondaryInsurance
          ? value?.trim() !== ''
          : true;
      }),
    secondary_iCity: Yup.string().test('secondary_iCity', 'Secondary City is required', (value) => {
      return patientForm.secondary_insurance_through !== 'myself' &&
        patientForm.secondary_insurance_through !== 'none' &&
        paymentMethod === 'Pay by Insurance' &&
        addSecondaryInsurance
        ? value?.trim() !== ''
        : true;
    }),
    creditCardFullName: Yup.string().test('creditCardFullName', 'Full Name on card is required', function (value) {
      if (paymentMethod === 'No Insurance/Self Pay') {
        if (!value) {
          return false;
        }
        if (!isValidFullName(value)) {
          return this.createError({
            message: 'Please enter a valid Full Name',
          });
        }
      }
      return true;
    }),
    creditCardAddress1: Yup.string().test('creditCardAddress1', 'Billing Address is required', (value) => {
      return !isSameAddress ? true : !!value?.trim();
    }),
    creditCardCity: Yup.string().test('creditCardCity', 'Billing City is required', (value) => {
      return !isSameAddress ? true : !!value?.trim();
    }),
    creditCardState: Yup.string().test('creditCardState', 'Billing State is required', (value) => {
      return !isSameAddress ? true : !!value?.trim();
    }),
    creditCardZipCode: Yup.string()
      .test('validZipCode', 'Not a valid zipcode', (value) => {
        if (isSameAddress) {
          if (value && !isValidZipCode(value)) {
            return false;
          }
        }
        return true;
      })
      .test('requiredZipCode', 'Billing Zip Code is required', (value) => {
        return !isSameAddress ? true : !!value?.trim();
      }),
  }) as any;
};
