import { client } from '../client';
import { Language } from '../identity/types/UserProfile';

export interface UpdateBasicInfoParams {
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: string;
  gender_identity: string;
  date_of_birth: string;
  pronouns: string;
  languages: Language[] | string[];
}

export interface AddAddressParams {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip_code: string;
}

export interface Payload {
  username: string;
  email: string;
  password: string;
  phone_number?: string;
  attributes?: object;
  validation_data?: object;
  profile?: UpdateBasicInfoParams;
  address?: AddAddressParams;
}

export interface SignupResponse {
  message: string;
}
export const signup = async (payload: Payload): Promise<SignupResponse> => {
  const { data } = await client.post<SignupResponse>(`/auth/signup`, payload);

  return data;
};
