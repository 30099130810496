import { toast } from 'react-toastify';

export const toastError = (error: any): void => {
  if (error.response && error.response.data && error.response.data.message) {
    const errorMessage = error.response.data.message;
    toast.error(errorMessage, { className: 'fs-unmask' });
  } else {
    toast.error('An error occurred', { className: 'fs-unmask' });
  }
};
