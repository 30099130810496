/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import SecondaryInsuranceFields from '@brands/Dashboard/Dashboard/Components/InsuranceFields/SecondaryInsuranceFields';
import { stateFundedProgramOptions } from '@brands/Dashboard/PatientForms/PatientForm/utils/types';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPageState } from '@brands/store/selectors/pageState';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { setDependentId, setIsFromSelectPatient } from '@brands/store/slices/pageStateSlice';
import { genderIdentity } from '@brands/Utils/selectButtonOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { E164Number } from 'libphonenumber-js';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../../../Components/Button/Button';
import PhoneInput from '../../../../../Components/Inputs/PhoneInput/PhoneInput';
import SelectInput from '../../../../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../../../../Components/Inputs/TextField/TextField';
import { useInsuranceCarriers } from '../../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useReduxHook';
import { createPatientInsurance } from '../../../../../services/identity/createPatientInsurance';
import { removePatientInsurance } from '../../../../../services/identity/removePatientInsurance';
import { Insurance, UserProfile, UserRoleName } from '../../../../../services/identity/types/UserProfile';
import { checkAndSetDate } from '../../../../../Utils/checkAndSetDate';
import { profileSelectStyles, selectCommonCustomStylesLarge } from '../../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../../Utils/displayError';
import { insuranceThroughOptions, stateOptions } from '../../../../../Utils/selectOptions';
import styles from '../../../styles.module.scss';
import { MyProfileSubmit } from '../../utils/types';
import { updateDataInsurance, updateDataSecondaryInsurance } from '../../utils/utilsFunctions';
import { insuranceValidationSchema } from '../../utils/validationSchema';

type EditInsuranceProps = {
  myProfileForm: any;
  patientInsurance: Insurance[];
  setPatientInsurance: React.Dispatch<React.SetStateAction<Insurance[]>>;
  setIsEditInsurance: React.Dispatch<React.SetStateAction<boolean>>;
  setSelection: React.Dispatch<React.SetStateAction<[number | null, number | null] | null>>;
  loading: boolean;
  thisUser: UserProfile;
  dependentId?: number;
};

const EditInsurance = ({
  myProfileForm,
  patientInsurance,
  setPatientInsurance,
  setIsEditInsurance,
  setSelection,
  loading,
  thisUser,
  dependentId,
}: EditInsuranceProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { values } = useAppSelector(selectPatientForm);
  const { userInfo } = useAppSelector(selectAuth);
  const { selectedPatient } = useAppSelector(selectPatientForm);
  const { isFromSelectPatient } = useAppSelector(selectPageState);
  const [addSecondaryInsurance, setAddSecondaryInsurance] = useState(patientInsurance.length > 1);
  const [isSavingInsurance, setIsSavingInsurance] = useState(false);
  const insuranceCarriers = useInsuranceCarriers();
  const insuranceCarrierOptions = useMemo(() => {
    return insuranceCarriers.map((insuranceCarrier) => ({
      value: insuranceCarrier.id,
      label: insuranceCarrier.name,
    }));
  }, [insuranceCarriers]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<MyProfileSubmit>({
    mode: 'onChange',
    resolver: yupResolver(insuranceValidationSchema(addSecondaryInsurance)),
  });

  const [isSelected, setIsSelected] = React.useState<number | null>(null);

  const isAdminRole = useMemo(
    () =>
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin,
    [userInfo.role.name],
  );

  const onChange = (key: any, value: string | number): void => {
    setValue(key, value, { shouldValidate: true });
  };

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string, { className: 'fs-unmask' });
    }
  };

  useEffect(() => {
    if (!addSecondaryInsurance) {
      setValue('secondary_insurance_through', 'none', { shouldValidate: true });
    }
  }, [addSecondaryInsurance]);

  useEffect(() => {
    if (patientInsurance.length > 1) {
      setAddSecondaryInsurance(true);
    }
  }, [patientInsurance]);

  useEffect(() => {
    if (getValues('state_funded_coverage_status') !== '') {
      setIsSelected(
        Number(
          stateFundedProgramOptions.find((answer) => answer.value === getValues('state_funded_coverage_status'))?.id,
        ),
      );
    }
  }, [getValues('state_funded_coverage_status')]);

  const onSubmitInsurance = async (profileData: MyProfileSubmit): Promise<void> => {
    setIsSavingInsurance(true);
    try {
      let updatedUserInsurance;
      if (profileData.insurance_through !== undefined) {
        if (getValues('insurance_through') === 'none') {
          await removePatientInsurance(
            userInfo.role.name === UserRoleName.Admin ||
              userInfo.role.name === UserRoleName.SuperAdmin ||
              userInfo.role.name === UserRoleName.OrganizationAdmin
              ? Number(selectedPatient?.value)
              : dependentId !== undefined
              ? Number(dependentId)
              : undefined,
          );
          updatedUserInsurance = undefined;
          setPatientInsurance([]);
        } else {
          const insuranceArray: Insurance[] = [];
          const dataWithUpdatedInsurance = updateDataInsurance(profileData);
          insuranceArray.push(dataWithUpdatedInsurance);
          if (addSecondaryInsurance) {
            const dataWithUpdatedSecondaryInsurance = updateDataSecondaryInsurance(profileData);
            insuranceArray.push(dataWithUpdatedSecondaryInsurance);
          }
          updatedUserInsurance = await createPatientInsurance({
            user_id: dependentId
              ? Number(dependentId)
              : userInfo.role.name === UserRoleName.Admin ||
                userInfo.role.name === UserRoleName.SuperAdmin ||
                userInfo.role.name === UserRoleName.OrganizationAdmin
              ? Number(selectedPatient?.value) !== Number(thisUser.id)
                ? Number(thisUser.id)
                : Number(selectedPatient?.value)
              : dependentId !== undefined
              ? Number(dependentId)
              : undefined,
            insurance: insuranceArray,
          });
          setPatientInsurance(updatedUserInsurance);
        }
      }

      setIsEditInsurance(false);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    setIsSavingInsurance(false);
    if (isFromSelectPatient) {
      dispatch(setIsFromSelectPatient(false));
      dispatch(setDependentId(null));
      navigate('/select-patient', {
        state: { caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string },
      });
    }
  };

  const onCancelEditInsurance = (): void => {
    setIsEditInsurance(false);
    if (isFromSelectPatient) {
      dispatch(setIsFromSelectPatient(false));
      dispatch(setDependentId(null));
      if (!isAdminRole) {
        navigate('/select-patient', {
          state: { caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string },
        });
      } else {
        setIsEditInsurance(false);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(myProfileForm).length > 0) {
      Object.keys(myProfileForm).forEach((key: unknown) => {
        setValue(key as keyof MyProfileSubmit, myProfileForm[key as keyof MyProfileSubmit], {
          shouldValidate: !loading,
        });
      });
    }
  }, [myProfileForm, setValue]);

  return (
    <div className={styles.editProfileContainer}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>Insurance Information</span>
      </div>
      <div className={styles.editContainer}>
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmitInsurance, onInvalid)}>
          <div className={styles.row}>
            <div className={cn(styles.rowItem, styles.details, styles.big)}>
              <div className={styles.caption}>
                I'm Insured By <span>*</span>
              </div>
              <SelectInput
                {...register('insurance_through')}
                name="insurance_through"
                options={insuranceThroughOptions}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesLarge}
                errors={errors}
                selectedValue={getValues('insurance_through')}
                onChange={(value) => {
                  if (value) {
                    setValue('insurance_through', value.value, { shouldValidate: true });
                  }
                }}
              />
            </div>
          </div>
          {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
            <>
              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <div className={styles.caption}>
                    Carrier <span>*</span>
                  </div>
                  <SelectInput
                    {...register('insurance_carrier_id')}
                    name="insurance_carrier_id"
                    options={insuranceCarrierOptions}
                    containerClass={styles.inputWrapper}
                    selectorClass={styles.selector}
                    style={selectCommonCustomStylesLarge}
                    errors={errors}
                    selectedValue={getValues('insurance_carrier_id')}
                    onChange={(value) => {
                      if (value) {
                        setValue('insurance_carrier_id', value.value, { shouldValidate: true });
                      }
                    }}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <div className={styles.caption}>
                    Member ID{' '}
                    {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                      <span>*</span>
                    )}
                  </div>
                  <TextField
                    {...register('member_id')}
                    errors={errors}
                    name="member_id"
                    value={getValues('member_id')}
                    onChange={(e) => {
                      setValue('member_id', e.target.value, { shouldValidate: true });
                      setSelection([e.target.selectionStart, e.target.selectionEnd]);
                    }}
                    wrapperClassName={styles.inputWrapper}
                    inputClass={styles.input}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <div className={styles.caption}>
                    Group Number{' '}
                    {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                      <span>*</span>
                    )}
                  </div>
                  <TextField
                    {...register('group_number')}
                    errors={errors}
                    name="group_number"
                    value={getValues('group_number')}
                    onChange={(e) => {
                      setValue('group_number', e.target.value, { shouldValidate: true });
                      setSelection([e.target.selectionStart, e.target.selectionEnd]);
                    }}
                    wrapperClassName={styles.inputWrapper}
                    inputClass={styles.input}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <div className={styles.caption}>
                    Phone number at the back of your insurance card (if multiple, use the provider number){' '}
                    {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                      <span>*</span>
                    )}
                  </div>
                  <PhoneInput
                    {...register('iPhoneNumber')}
                    value={getValues('iPhoneNumber') as E164Number}
                    id="iPhoneNumber"
                    onChange={(val) => {
                      setValue('iPhoneNumber', val as string, {
                        shouldValidate: true,
                      });
                    }}
                    inputClass={styles.input}
                    errors={errors}
                    wrapperClassName={styles.inputWrapper}
                    isUnderlined={false}
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <div className={styles.caption}>
                    Insurance Address
                    {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                      <span>*</span>
                    )}
                  </div>
                  <TextField
                    {...register('insurance_address')}
                    errors={errors}
                    name="insurance_address"
                    value={getValues('insurance_address')}
                    onChange={(e) => {
                      setValue('insurance_address', e.target.value, { shouldValidate: true });
                      setSelection([e.target.selectionStart, e.target.selectionEnd]);
                    }}
                    wrapperClassName={styles.inputWrapper}
                    inputClass={styles.input}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <div className={styles.caption}>
                    Insurance City
                    {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                      <span>*</span>
                    )}
                  </div>
                  <TextField
                    {...register('insurance_city')}
                    errors={errors}
                    name="insurance_city"
                    value={getValues('insurance_city')}
                    onChange={(e) => {
                      setValue('insurance_city', e.target.value, { shouldValidate: true });
                      setSelection([e.target.selectionStart, e.target.selectionEnd]);
                    }}
                    wrapperClassName={styles.inputWrapper}
                    inputClass={styles.input}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <div className={styles.caption}>
                    Insurance State
                    {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                      <span>*</span>
                    )}
                  </div>
                  <SelectInput
                    {...register('insurance_state')}
                    name="insurance_state"
                    options={stateOptions}
                    containerClass={styles.inputWrapper}
                    selectorClass={styles.selector}
                    style={selectCommonCustomStylesLarge}
                    errors={errors}
                    selectedValue={getValues('insurance_state')}
                    onChange={(value) => {
                      if (value) {
                        setValue('insurance_state', value.value, { shouldValidate: true });
                      }
                    }}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowItem}>
                  <div className={styles.caption}>
                    Insurance Zip Code
                    {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                      <span>*</span>
                    )}
                  </div>
                  <TextField
                    {...register('insurance_zip_code')}
                    errors={errors}
                    name="insurance_zip_code"
                    value={getValues('insurance_zip_code')}
                    onChange={(e) => {
                      setValue('insurance_zip_code', e.target.value, { shouldValidate: true });
                      setSelection([e.target.selectionStart, e.target.selectionEnd]);
                    }}
                    wrapperClassName={styles.inputWrapper}
                    inputClass={styles.input}
                    isUnderlined={false}
                  />
                </div>
              </div>
            </>
          )}
          {getValues('insurance_through') &&
            getValues('insurance_through') !== 'myself' &&
            getValues('insurance_through') !== 'none' &&
            getValues('insurance_through') !== undefined && (
              <div className={styles.insuranceThroughContainer}>
                <div className={styles.row}>
                  <div className={`${styles.rowItem} ${styles.oneThird} col-12 col-md-4`}>
                    <div className={styles.caption}>
                      Insurance Holder’s First Name{' '}
                      {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                        <span>*</span>
                      )}
                    </div>
                    <TextField
                      {...register('iFirstName')}
                      errors={errors}
                      name="iFirstName"
                      value={getValues('iFirstName')}
                      onChange={(e) => {
                        setValue('iFirstName', e.target.value, { shouldValidate: true });
                        setSelection([e.target.selectionStart, e.target.selectionEnd]);
                      }}
                      wrapperClassName={styles.inputWrapper}
                      inputClass={styles.input}
                    />
                  </div>
                  <div className={`${styles.rowItem} ${styles.oneThird} col-12 col-md-4`}>
                    <div className={styles.caption}>Insurance Holder’s Middle Name</div>
                    <TextField
                      {...register('iMiddleName')}
                      errors={errors}
                      name="iMiddleName"
                      value={getValues('iMiddleName')}
                      onChange={(e) => {
                        setValue('iMiddleName', e.target.value, { shouldValidate: true });
                        setSelection([e.target.selectionStart, e.target.selectionEnd]);
                      }}
                      wrapperClassName={styles.inputWrapper}
                      inputClass={styles.input}
                    />
                  </div>
                  <div className={`${styles.rowItem} ${styles.oneThird} col-12 col-md-4`}>
                    <div className={styles.caption}>
                      Insurance Holder’s Last Name{' '}
                      {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                        <span>*</span>
                      )}
                    </div>
                    <TextField
                      {...register('iLastName')}
                      errors={errors}
                      name="iLastName"
                      value={getValues('iLastName')}
                      onChange={(e) => {
                        setValue('iLastName', e.target.value, { shouldValidate: true });
                        setSelection([e.target.selectionStart, e.target.selectionEnd]);
                      }}
                      wrapperClassName={styles.inputWrapper}
                      inputClass={styles.input}
                    />
                  </div>
                </div>
                <div className={`${styles.row}`}>
                  <div className={`${styles.rowItem} ${styles.midRow}`}>
                    <div className={styles.caption}>
                      Insurance Holder’s Date of Birth{' '}
                      {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                        <span>*</span>
                      )}
                    </div>
                    <TextField
                      {...register('iDateOfBirth')}
                      errors={errors}
                      name="iDateOfBirth"
                      value={getValues('iDateOfBirth')}
                      onChange={(e) => {
                        setValue('iDateOfBirth', checkAndSetDate(e), { shouldValidate: true });
                        setSelection([e.target.selectionStart, e.target.selectionEnd]);
                      }}
                      maxLength={10}
                      wrapperClassName={styles.inputWrapper}
                      inputClass={styles.input}
                      isUnderlined={false}
                      enableCursorPos={false}
                    />
                  </div>
                  <div className={`${styles.rowItem} ${styles.midRow}`}>
                    <div className={styles.caption}>Insurance Holder’s Gender Identity</div>
                    <SelectInput
                      {...register('iGenderIdentity')}
                      name="iGenderIdentity"
                      options={genderIdentity}
                      containerClass={styles.inputWrapper}
                      selectorClass={styles.selector}
                      style={selectCommonCustomStylesLarge}
                      errors={errors}
                      selectedValue={getValues('iGenderIdentity')}
                      onChange={(value) => {
                        if (value) {
                          setValue('iGenderIdentity', value.value, {
                            shouldValidate: true,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={`${styles.row}`}>
                  <div className={styles.rowItem}>
                    <div className={styles.caption}>
                      Insurance Holder’s Street Address{' '}
                      {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                        <span>*</span>
                      )}
                    </div>
                    <TextField
                      {...register('iAddress1')}
                      errors={errors}
                      name="iAddress1"
                      value={getValues('iAddress1')}
                      onChange={(e) => {
                        setValue('iAddress1', e.target.value, { shouldValidate: true });
                        setSelection([e.target.selectionStart, e.target.selectionEnd]);
                      }}
                      wrapperClassName={styles.inputWrapper}
                      inputClass={styles.input}
                      isUnderlined={false}
                    />
                  </div>
                </div>
                <div className={`${styles.row}`}>
                  <div className={styles.rowItem}>
                    <div className={styles.caption}>Apt, suite, building (optional)</div>
                    <TextField
                      {...register('iAddress2')}
                      errors={errors}
                      name="iAddress2"
                      value={getValues('iAddress2')}
                      onChange={(e) => {
                        setValue('iAddress2', e.target.value, { shouldValidate: true });
                        setSelection([e.target.selectionStart, e.target.selectionEnd]);
                      }}
                      wrapperClassName={styles.inputWrapper}
                      inputClass={styles.input}
                      isUnderlined={false}
                    />
                  </div>
                </div>
                <div className={`${styles.row}`} style={{ flexDirection: 'row' }}>
                  <div className={`${styles.rowItem} ${styles.trimesterRow}`}>
                    <div className={styles.caption}>
                      City{' '}
                      {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                        <span>*</span>
                      )}
                    </div>
                    <TextField
                      {...register('iCity')}
                      errors={errors}
                      name="iCity"
                      value={getValues('iCity')}
                      onChange={(e) => {
                        setValue('iCity', e.target.value, { shouldValidate: true });
                        setSelection([e.target.selectionStart, e.target.selectionEnd]);
                      }}
                      wrapperClassName={styles.inputWrapper}
                      inputClass={styles.input}
                      isUnderlined={false}
                    />
                  </div>
                  <div className={`${styles.rowItem} ${styles.trimesterRow}`} style={{ fontWeight: 400 }}>
                    <div className={styles.caption}>
                      State{' '}
                      {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                        <span>*</span>
                      )}
                    </div>
                    <SelectInput
                      {...register('iState')}
                      containerClass="mt-0"
                      name="iState"
                      options={stateOptions}
                      style={profileSelectStyles}
                      errors={errors}
                      selectedValue={getValues('iState')}
                      onChange={(value) => {
                        setValue('iState', value ? value.value : '', { shouldValidate: true });
                      }}
                    />
                  </div>
                  <div className={`${styles.rowItem} ${styles.trimesterRow}`}>
                    <div className={styles.caption}>
                      Zip Code{' '}
                      {getValues('insurance_through') !== 'none' && getValues('insurance_through') !== undefined && (
                        <span>*</span>
                      )}
                    </div>
                    <TextField
                      {...register('iZipCode')}
                      errors={errors}
                      name="iZipCode"
                      value={getValues('iZipCode')}
                      onChange={(e) => {
                        setValue('iZipCode', e.target.value, { shouldValidate: true });
                        setSelection([e.target.selectionStart, e.target.selectionEnd]);
                      }}
                      wrapperClassName={styles.inputWrapper}
                      inputClass={styles.input}
                      isUnderlined={false}
                    />
                  </div>
                </div>
              </div>
            )}
          {getValues('insurance_through') !== 'none' &&
            getValues('insurance_through') !== '' &&
            getValues('insurance_through') !== undefined && (
              <div className={`${styles.row}`} style={{ flexDirection: 'row' }}>
                <div className={`${styles.rowItem}`}>
                  <div className={styles.caption}>
                    Do you currently have insurance coverage through a state-funded program such as Medicaid?
                    <span> *</span>
                  </div>
                  <div className={styles.optionsDiv}>
                    {stateFundedProgramOptions.map((selection) => (
                      <button
                        className={
                          isSelected === selection.id
                            ? `${styles.multiSelectorInsuranceCoverage} ${styles.buttonSelectedInsuranceCoverage}`
                            : `${styles.multiSelectorInsuranceCoverage}`
                        }
                        value={getValues('state_funded_coverage_status')}
                        {...register('state_funded_coverage_status')}
                        type="button"
                        onClick={() => {
                          setIsSelected(selection.id);
                          setValue('state_funded_coverage_status', selection.value, { shouldValidate: true });
                        }}
                        key={selection.id}
                      >
                        <span>{selection.label}</span>
                        <div className={styles.checkInput} />
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
          {getValues('insurance_through') !== 'none' &&
            getValues('insurance_through') !== '' &&
            getValues('insurance_through') !== undefined && (
              <div className={`${styles.addSecondaryInsurance} form-check`}>
                <input
                  className="form-check-input"
                  style={{ position: 'relative' }}
                  type="checkbox"
                  id="isAdditionalInsurance"
                  checked={addSecondaryInsurance}
                  onChange={(event) => {
                    setAddSecondaryInsurance(event.target.checked);
                  }}
                />
                <label
                  className={`form-check-label ${styles.additionalPeopleCheckLabel}`}
                  htmlFor="isAdditionalInsurance"
                >
                  Add Secondary Insurance
                </label>
              </div>
            )}
          {addSecondaryInsurance &&
            getValues('insurance_through') !== 'none' &&
            getValues('insurance_through') !== undefined && (
              <SecondaryInsuranceFields register={register} errors={errors} getValues={getValues} onChange={onChange} />
            )}
          <div className={styles.actionBtnContainer}>
            <Button type="button" className={styles.cancel} onClick={onCancelEditInsurance}>
              Cancel
            </Button>
            <Button
              type="submit"
              className={styles.submit}
              disabled={
                isEqual(myProfileForm, getValues()) ||
                isSavingInsurance ||
                (addSecondaryInsurance && getValues('secondary_insurance_through') === 'none') ||
                (getValues('insurance_through') === 'none' && patientInsurance.length === 0)
              }
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

EditInsurance.defaultProps = {
  dependentId: undefined,
};

export default EditInsurance;
