/* eslint-disable no-nested-ternary */
import { ClinicalDataQuestion } from '@brands/services/clinical_data/types/types';
import React, { Dispatch, FC, useEffect, useState } from 'react';

import styles from './styles.module.scss';

interface QuestionAndMultiselectProps {
  element: ClinicalDataQuestion;
  form: { [key: string]: any };
  setForm: Dispatch<React.SetStateAction<any>>;
  isEmoji?: boolean;
  isChild?: boolean;
  isParentQuestionActive?: boolean;
}

const QuestionAndMultiselectSideQuestion: FC<QuestionAndMultiselectProps> = React.forwardRef(
  ({ element, form, setForm, isEmoji, isChild, isParentQuestionActive }, ref) => {
    const [isSelected, setIsSelected] = useState<string>(
      form[element.id] === true ? 'Yes' : form[element.id] === false ? 'No' : '',
    );
    const [isEdit, setIsEdit] = useState(false);
    useEffect(() => {
      if (isSelected !== '' && isParentQuestionActive) {
        setForm((prev: any) => ({
          ...prev,
          [element.id]: isSelected === 'Yes',
        }));
      } else {
        setForm((prev: any) => ({
          ...prev,
          [element.id]: undefined,
        }));
      }
    }, [isSelected]);

    return (
      <div
        className={`fs-exclude ${styles.row} ${styles.medicalFormRow} ${isChild && styles.marginTop} ${
          isEmoji && (isSelected || isParentQuestionActive)
            ? styles.selectedClass
            : !isEmoji && isSelected
            ? styles.selectedClass
            : ''
        }`}
        onClick={() => {
          if (isSelected) {
            setIsEdit(!isEdit);
          }
        }}
      >
        <div
          className={styles.questionAndButtonContainer}
          onClick={() => {
            if (isEmoji) {
              if (isSelected === 'Yes') {
                setIsSelected('Yes');
              }
            } else {
              setIsSelected('No');
            }
          }}
        >
          <div className={`${styles.question} col-12 col-sm-8 px-0`}>
            <div className={`${styles.label} mb-2 mb-md-0 fs-unmask`}>
              {element.title}
              {element.is_required ? <span className={styles.redText}> *</span> : ''}
            </div>
          </div>
        </div>
        {isEmoji && (
          <div className={`fs-exclude ${styles.selectSelf}`} onClick={(e) => e.stopPropagation()}>
            <div className={`${styles.radio} fs-exclude`}>
              {element.options &&
                element.options.map((option: string) => (
                  <div className={`${styles.optionDiv} fs-exclude`} key={option}>
                    <label htmlFor={`${element.id}-${option}`} className="fs-exclude">
                      {isEmoji ? option.replace(/\p{Emoji}+/gu, '').trim() : option}
                    </label>
                    <input
                      id={`${element.id}-${option}`}
                      className={`form-check-input ${styles.checkInput} fs-exclude mt-0 ${
                        isSelected === option ? styles.primaryColor : ''
                      }`}
                      value={option}
                      type="radio"
                      onChange={() => {
                        setIsSelected(option);
                      }}
                      checked={isSelected === option}
                      ref={ref as any}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  },
);

QuestionAndMultiselectSideQuestion.defaultProps = {
  isEmoji: false,
  isChild: false,
  isParentQuestionActive: false,
};

export default QuestionAndMultiselectSideQuestion;
