/* eslint-disable no-await-in-loop */
import { selectAuth } from '@brands/store/selectors/auth';
import { setShowVideoThumbnail } from '@brands/store/slices/showVideoThumbnailSlice';
import { setIsVisible } from '@brands/store/slices/videoCallCredentialsSlice';
import React, { FC, useEffect } from 'react';
import { TbPhone } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import useVideoContext from '../../../hooks/video-hooks/useVideoContext';
import { UserRoleName } from '../../../services/identity/types/UserProfile';
import styles from './styles.module.scss';

type EndCallButtonProps = {
  isWaitingRoom?: boolean;
  endCurrentCall?: boolean;
};

const EndCallButton: FC<EndCallButtonProps> = ({ isWaitingRoom, endCurrentCall }) => {
  const { room, localTracks, setBackgroundSettings } = useVideoContext();
  const { userInfo } = useAppSelector(selectAuth);
  let navigate: any;
  const dispatch = useAppDispatch();

  if (userInfo.role.name === UserRoleName.Patient) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    navigate = useNavigate();
  }

  const dispatchSuccessEvent = (event: string, video_room_id: string): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      userId: userInfo.id,
      video_room_id,
    });
  };

  const stopCameraAndTracks = async (): Promise<void> => {
    try {
      // Reset background settings
      setBackgroundSettings({ type: 'none', index: 0 });

      // Unpublish and stop all local tracks
      localTracks.forEach((track) => {
        if (track.kind === 'video') {
          room?.localParticipant.unpublishTrack(track);
          track.stop();
        }
      });

      // Release media devices
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');

      for (const videoDevice of videoDevices) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: videoDevice.deviceId },
        });
        stream.getTracks().forEach((track) => track.stop());
      }
    } catch (error) {
      console.warn('Error stopping video devices:', error);
    }
  };

  const endCall = async (): Promise<void> => {
    dispatch(setIsVisible(false));
    dispatchSuccessEvent('video-room-finished', room?.sid || '');
    dispatch(setShowVideoThumbnail(false));

    // Stop camera and tracks
    await stopCameraAndTracks();

    // Disconnect from the room and navigate if necessary
    if (!isWaitingRoom) {
      room?.disconnect();
      if (userInfo.role.name === UserRoleName.Patient) navigate('/dashboard');
    } else if (userInfo.role.name === UserRoleName.Patient) {
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    (async () => {
      if (endCurrentCall) {
        await endCall();
      }
    })();
  }, [endCurrentCall]);

  return (
    <button type="button" className="fs-unmask" onClick={() => endCall()} aria-label="End Call">
      <span className={`${styles.vcVideoIcon} ${styles.rotate135} ${styles.endCallButton}`}>
        <TbPhone />
      </span>
    </button>
  );
};

EndCallButton.defaultProps = {
  isWaitingRoom: false,
  endCurrentCall: false,
};

export default EndCallButton;
