import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatientMyProfile } from '@brands/store/selectors/patientMyProfile';
import { setPatientMyProfile } from '@brands/store/slices/patientMyProfileSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiArrowLeft } from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';

import Container from '../../Components/Container/Container';
import SelectInput from '../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../Components/Inputs/TextField/TextField';
import InsuranceThrough from '../../Components/InsuranceThrough/InsuranceThrough';
import { Loading } from '../../Components/LoadingSpinner/Loading';
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxHook';
import { selectCustomStyles } from '../../Utils/customStyles';
import { insuranceThroughOptions } from '../../Utils/selectOptions';
import SubmitConfirmationModal from '../Dashboard/DashboardModals/SubmitConfirmationModal';
import { MyProfileSubmit } from '../MyProfile/Patient/utils/types';
import styles from './styles.module.scss';
import { validationSchema } from './utils/validationSchema';

type InsuranceSubmit = {
  payerId: string;
  memberID: string;
  insuranceThrough: string;
  iFirstName: string;
  iMiddleName: string;
  iDateOfBirth: string;
  iGenderIdentity: string;
  iLastName: string;
  iAddress1: string;
  iAddress2: string;
  iZipCode: string;
  iState: string;
} & MyProfileSubmit;

const Insurance = (): JSX.Element => {
  const values = useAppSelector(selectPatientMyProfile);
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(selectAuth);
  const [submitConfirmation] = useState(false);
  const [render, setRender] = useState(false);
  const [insuranceForm, setInsurance] = useState<InsuranceSubmit>(
    values.myProfile.patientId === userInfo.id
      ? (values.myProfile as InsuranceSubmit)
      : ({
          payerId: '',
          memberID: '',
          insuranceThrough: '',
          iFirstName: '',
          iMiddleName: '',
          iDateOfBirth: '',
          iGenderIdentity: '',
          iLastName: '',
          iAddress1: '',
          iAddress2: '',
          iZipCode: '',
          iState: '',
        } as InsuranceSubmit),
  );
  const navigate = useNavigate();
  const goToDashboard = useCallback(() => navigate('/dashboard'), [navigate]);
  const { state } = useLocation() as any;
  const { isInstant } = state || { isInstant: true };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<InsuranceSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = async (data: InsuranceSubmit): Promise<void> => {
    await setInsurance(data);
    // TODO: Send data to server
    // TODO: If success, show success content
    dispatch(
      setPatientMyProfile({
        ...(data as InsuranceSubmit),
        patientId: userInfo?.id,
      }),
    );
  };

  useEffect(() => {
    (async () => {
      await Object.keys(insuranceForm).forEach(async (key: unknown) => {
        await setValue(key as keyof InsuranceSubmit, insuranceForm[key as keyof InsuranceSubmit]);
      });
      setRender(true);
    })();
  }, []);
  if (render) {
    return (
      <Container containerStyle={{ overflow: 'hidden' }}>
        <div className={styles.containerInsurance}>
          <div className={styles.wrapInsurance}>
            <button
              type="button"
              className={styles.modalCloseBtn}
              aria-label="Close modal"
              onClick={() => goToDashboard()}
            />
            <div
              className={styles.backArrowDiv}
              onClick={() => navigate('/dashboard', { state: { instantLiveConsultation: true, liveState: isInstant } })}
            >
              <HiArrowLeft /> Patient Form
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <span className={`${styles.insuranceTitle} p-b-26`}>
                <span className={styles.insuranceTitleWelcome}>Insurance Form</span>
              </span>
              <div className={styles.insuranceDiv}>
                <div className="col-md-4">
                  <TextField
                    {...register('memberID')}
                    errors={errors}
                    name="memberID"
                    placeholder="Member ID"
                    value={getValues('memberID')}
                    onChange={(e) => {
                      setValue('memberID', e.target.value, { shouldValidate: true });
                    }}
                    wrapperStyle={{ marginTop: '3rem' }}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    {...register('payerId')}
                    errors={errors}
                    name="payerId"
                    placeholder="Payer ID"
                    value={getValues('payerId')}
                    onChange={(e) => {
                      setValue('payerId', e.target.value, { shouldValidate: true });
                    }}
                    wrapperStyle={{ marginTop: '3rem' }}
                  />
                </div>
              </div>
              <div className="row">
                <div className={`col-12 d-flex ${styles.insuranceThrough}`}>
                  <p>I am insured through my ..</p>
                  <SelectInput
                    {...register('insuranceThrough')}
                    containerClass={styles.input20}
                    placeholder={insuranceThroughOptions[0].label}
                    name="insuranceThrough"
                    options={insuranceThroughOptions}
                    style={selectCustomStyles}
                    errors={errors}
                    selectedValue={getValues('insuranceThrough')}
                    onChange={(value) => {
                      if (value) {
                        setValue('insuranceThrough', value.value, { shouldValidate: true });
                      }
                    }}
                  />
                </div>
              </div>
              {getValues('insuranceThrough') && getValues('insuranceThrough') !== 'myself' && (
                <InsuranceThrough errors={errors} setValue={setValue} getValues={getValues} register={register} />
              )}
              <div
                className="
                  container-login100-form-btn
                  p-container-login100-form-btn
                  mt-5 flex-nowrap
                  flex-column
                  flex-sm-row
                "
              >
                <div className="wrap-login100-form-btn p-wrap-login100-form-btn me-sm-2 mb-2 mb-sm-0">
                  <div className="p-login100-form-bgbtn" />
                  <button type="submit" className="login100-form-btn p-0">
                    Verify my benefits
                  </button>
                </div>
                <div className="wrap-login100-form-btn p-wrap-login100-form-btn">
                  <div className="login100-form-bgbtn" />
                  <button
                    type="button"
                    className="login100-form-btn p-0"
                    onClick={() => navigate('/payment-page', { state: { isInstant } })}
                  >
                    I don’t have insurance
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {submitConfirmation && <SubmitConfirmationModal />}
      </Container>
    );
  }
  return <Loading fullScreen />;
};

export default Insurance;
