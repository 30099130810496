/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { selectAuth } from '@brands/store/selectors/auth';
import React, { useCallback, useState } from 'react';
import { CgProfile } from 'react-icons/cg';

import { useAppSelector } from '../../../../hooks/useReduxHook';
import { ICase } from '../../../../services/cases/types/ICase';
import { getUserAvatar } from '../../../../services/identity/getUserAvatar';
import { UserProfile, UserRoleName } from '../../../../services/identity/types/UserProfile';
import {
  hasUnreadMessageInPatientCA,
  hasUnreadMessageInPatientProvider,
  hasUnreadMessageInPrivate,
} from '../../../../Utils/getUnreadMessageNotification';
import { truncateString } from '../../../../Utils/truncateString';
import CareAdvisorProfileCardModal from '../../../ProfileCards/CareAdvisor/ProfileCards';
import ProviderProfileCardModal from '../../../ProfileCards/Dentist/ProfileCards';
import PatientProfileCardModal from '../../../ProfileCards/Patient/ProfileCards';
import styles from './styles.module.scss';

type ProfileType = {
  caseRow: ICase;
  role: string;
  displayRole?: boolean;
  showName?: boolean;
  showImage?: boolean;
  maxChars?: number;
  displayEmail?: boolean;
};

const ProfileComponent: React.FC<ProfileType> = ({
  caseRow,
  role,
  displayRole,
  showImage,
  showName,
  maxChars,
  displayEmail,
}) => {
  const [profileCardModal, setProfileCardModal] = useState(false);
  const [providerProfileCardModal, setProviderProfileCardModal] = useState(false);
  const [caProfileCardModal, setCAProfileCardModal] = useState(false);
  const { userInfo } = useAppSelector(selectAuth);
  const [patientIdentity, setPatientIdentity] = useState<UserProfile | null>(null);
  const [providerIdentity, setProviderIdentity] = useState<UserProfile | null>(null);
  const [caIdentity, setCAIdentity] = useState<UserProfile | null>(null);

  const toggleProfile = (roleName: string, roleIdentity: UserProfile): void => {
    if (roleName === UserRoleName.Patient) {
      setProfileCardModal(true);
      setPatientIdentity(roleIdentity);
    } else if (roleName === UserRoleName.Provider) {
      setProviderProfileCardModal(true);
      setProviderIdentity(roleIdentity);
    } else if (roleName === 'CA') {
      setCAProfileCardModal(true);
      setCAIdentity(roleIdentity);
    }
  };

  const getUserAvatarById = useCallback(
    (id: number) => {
      if (caseRow.patient_id === id && caseRow.patient.picture_media_id) {
        return getUserAvatar(caseRow.patient_id);
      }
      if (caseRow.provider_id === id && caseRow.provider.picture_media_id) {
        return getUserAvatar(caseRow.provider_id);
      }
      if (caseRow.care_advisor_id === id && caseRow.care_advisor.picture_media_id) {
        return getUserAvatar(caseRow.care_advisor_id);
      }
      return '';
    },
    [caseRow],
  );

  const [avatarIcon, setAvatarIcon] = useState(false);
  const handleAvatarError = (): void => {
    setAvatarIcon(true);
  };

  return (
    <div className={`${styles.providerInfo}`}>
      {role === UserRoleName.Patient &&
        caseRow.patient &&
        showImage &&
        (!avatarIcon ? (
          <div className={styles.imageContainer}>
            {(hasUnreadMessageInPatientCA(
              caseRow,
              caseRow.patient?.guardian_id === null ? caseRow.patient_id : caseRow.patient.guardian_id!,
            ) ||
              hasUnreadMessageInPatientProvider(
                caseRow,
                caseRow.patient?.guardian_id === null ? caseRow.patient_id : caseRow.patient.guardian_id!,
              )) && <span className={styles.unreasMessage} />}
            <img
              alt="Avatar"
              onError={handleAvatarError}
              className={`${styles.providerImage} fs-exclude`}
              onClick={() => {
                toggleProfile(role, caseRow.patient);
              }}
              src={getUserAvatarById(caseRow.patient_id)}
            />
          </div>
        ) : (
          <div className={styles.imageContainer}>
            {(hasUnreadMessageInPatientCA(
              caseRow,
              caseRow.patient?.guardian_id === null ? caseRow.patient_id : caseRow.patient.guardian_id!,
            ) ||
              hasUnreadMessageInPatientProvider(
                caseRow,
                caseRow.patient?.guardian_id === null ? caseRow.patient_id : caseRow.patient.guardian_id!,
              )) && <span className={styles.unreasMessage} />}
            <CgProfile onClick={() => toggleProfile(role, caseRow.patient)} />
          </div>
        ))}
      {role === UserRoleName.Provider &&
        caseRow.provider &&
        showImage &&
        (!avatarIcon ? (
          <div className={styles.imageContainer}>
            {userInfo.role.name === UserRoleName.Patient
              ? hasUnreadMessageInPatientProvider(caseRow, caseRow.provider_id) && (
                  <span className={styles.unreasMessage} />
                )
              : (hasUnreadMessageInPatientProvider(caseRow, caseRow.provider_id) ||
                  hasUnreadMessageInPrivate(caseRow, caseRow.provider_id)) && <span className={styles.unreasMessage} />}
            <img
              alt="Avatar"
              onError={handleAvatarError}
              className={`${styles.providerImage} ${!showName && styles.customProviderImage} fs-exclude`}
              onClick={() => toggleProfile(role, caseRow.provider!)}
              src={getUserAvatarById(caseRow.provider_id)}
            />
          </div>
        ) : (
          <div className={styles.imageContainer}>
            {userInfo.role.name === UserRoleName.Patient
              ? hasUnreadMessageInPatientProvider(caseRow, caseRow.provider_id) && (
                  <span className={styles.unreasMessage} />
                )
              : (hasUnreadMessageInPatientProvider(caseRow, caseRow.provider_id) ||
                  hasUnreadMessageInPrivate(caseRow, caseRow.provider_id)) && <span className={styles.unreasMessage} />}
            <CgProfile onClick={() => toggleProfile(role, caseRow.provider!)} />
          </div>
        ))}
      {role === 'CA' &&
        caseRow.care_advisor &&
        showImage &&
        (!avatarIcon ? (
          <div className={styles.imageContainer}>
            {userInfo.role.name === UserRoleName.Patient
              ? hasUnreadMessageInPatientCA(caseRow, caseRow.care_advisor_id) && (
                  <span className={styles.unreasMessage} />
                )
              : (hasUnreadMessageInPatientCA(caseRow, caseRow.care_advisor_id) ||
                  hasUnreadMessageInPrivate(caseRow, caseRow.care_advisor_id)) && (
                  <span className={styles.unreasMessage} />
                )}
            <img
              alt="Avatar"
              onError={handleAvatarError}
              className={`fs-exclude ${styles.providerImage}`}
              onClick={() => toggleProfile(role, caseRow.care_advisor!)}
              src={getUserAvatarById(caseRow.care_advisor_id)}
            />
          </div>
        ) : (
          <div className={styles.imageContainer}>
            {userInfo.role.name === UserRoleName.Patient
              ? hasUnreadMessageInPatientCA(caseRow, caseRow.care_advisor_id) && (
                  <span className={styles.unreasMessage} />
                )
              : (hasUnreadMessageInPatientCA(caseRow, caseRow.care_advisor_id) ||
                  hasUnreadMessageInPrivate(caseRow, caseRow.care_advisor_id)) && (
                  <span className={styles.unreasMessage} />
                )}
            <CgProfile onClick={() => toggleProfile(role, caseRow.care_advisor!)} />
          </div>
        ))}
      <div className={styles.mobileInfo}>
        <div className={styles.mobilePatientWrap}>
          {displayRole && role === 'CA' && <div className={styles.mobileHeader}>Care Advisor</div>}
          {displayRole && role === UserRoleName.Patient && <div className={styles.mobileHeader}>Patient</div>}
          {displayRole && role === UserRoleName.Provider && <div className={styles.mobileHeader}>Provider</div>}
          <div className={styles.divWrap}>
            {role === 'CA' &&
              showName &&
              (!caseRow.care_advisor ? (
                <span className={`${styles.empty} fs-exclude`}>N/A</span>
              ) : (
                <span
                  style={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px',
                  }}
                  onClick={() => toggleProfile(role, caseRow.care_advisor!)}
                  className="fs-exclude"
                >
                  {displayEmail
                    ? caseRow.care_advisor?.email
                      ? caseRow.care_advisor.email
                      : ''
                    : caseRow.care_advisor?.full_name
                    ? truncateString(caseRow.care_advisor.full_name, maxChars as number)
                    : ''}
                </span>
              ))}
            {role === UserRoleName.Provider &&
              showName &&
              (!caseRow.provider ? (
                <span className={`${styles.empty} fs-exclude`}>N/A</span>
              ) : (
                <span
                  style={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: !displayRole ? 'unset' : '200px',
                  }}
                  onClick={() => toggleProfile(role, caseRow.provider!)}
                  className="fs-exclude"
                >
                  {displayEmail
                    ? caseRow.provider?.email
                      ? caseRow.provider.email
                      : ''
                    : caseRow.provider?.full_name
                    ? truncateString(caseRow.provider.full_name, !displayRole ? maxChars ?? 30 : (maxChars as number))
                    : ''}
                  {!displayEmail && caseRow?.provider.provider_profile?.credentials ? ', ' : ''}
                  {(!displayEmail && caseRow?.provider.provider_profile?.credentials) || ''}
                </span>
              ))}
            {role === UserRoleName.Patient && (
              <span onClick={() => toggleProfile(role, caseRow.patient)}>
                <span
                  style={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px',
                  }}
                  className={`${styles.patientName} fs-exclude`}
                >
                  {displayEmail
                    ? caseRow.patient?.guardian_id === null
                      ? caseRow.patient.email
                      : caseRow.patient.guardian.email ?? ''
                    : caseRow.patient?.full_name
                    ? truncateString(caseRow.patient.full_name, maxChars as number)
                    : ''}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
      {profileCardModal && (
        <PatientProfileCardModal
          patientIdentity={patientIdentity}
          isOpen={profileCardModal}
          toggleModal={setProfileCardModal}
          caseId={caseRow.id}
        />
      )}
      {providerProfileCardModal && (
        <ProviderProfileCardModal
          isOpen={providerProfileCardModal}
          toggleModal={setProviderProfileCardModal}
          providerIdentity={providerIdentity}
          caseId={caseRow.id}
          patientCurrentStateValue={caseRow.patient_current_state}
        />
      )}
      {caProfileCardModal && (
        <CareAdvisorProfileCardModal
          isOpen={caProfileCardModal}
          toggleModal={setCAProfileCardModal}
          caIdentity={caIdentity}
          caseId={caseRow.id}
        />
      )}
    </div>
  );
};
ProfileComponent.defaultProps = {
  displayRole: true,
  showImage: true,
  showName: true,
  maxChars: 15,
  displayEmail: false,
};
export default ProfileComponent;
