import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import React, { FC } from 'react';

import styles from './styles.module.scss';

interface ProfileContainerProps {
  children: React.ReactNode;
  containerStyle?: object;
  childClassName?: string;
  childrenStyle?: { [key: string]: string };
  isVisibleOnMobile?: boolean;
  isProfileCard?: boolean;
  consultationPage?: boolean;
  scrollableDashboard?: boolean;
  patientDashboard?: boolean;
  isSidenavVisible?: boolean;
  isProvider?: boolean;
}

const Container: FC<ProfileContainerProps> = ({
  children,
  containerStyle,
  childClassName,
  childrenStyle,
  isVisibleOnMobile,
  isProfileCard,
  consultationPage,
  scrollableDashboard,
  patientDashboard,
  isSidenavVisible,
  isProvider,
}): JSX.Element => {
  return (
    <div
      className={`${!isProfileCard ? styles.containerClass : styles.profileCardContainer} ${childClassName}`}
      style={{
        height: '100vh',
        ...containerStyle,
      }}
    >
      {isSidenavVisible && <Sidenav isVisibleOnMobile={isVisibleOnMobile} isProfileCard={isProfileCard} />}
      {patientDashboard && (
        <div
          className={styles.patientDashboardPosition}
          style={{
            ...childrenStyle,
          }}
        >
          {children}
        </div>
      )}
      {consultationPage && (
        <div
          className={styles.childrenPositionTop}
          style={{
            ...childrenStyle,
          }}
        >
          {children}
        </div>
      )}
      {scrollableDashboard && (
        <div
          className={styles.childrenScrollableDiv}
          style={{
            ...childrenStyle,
          }}
        >
          {children}
        </div>
      )}

      {!consultationPage && !patientDashboard && !scrollableDashboard && !isProvider && (
        <div
          className={styles.childrenCunsomPosition}
          style={{
            ...childrenStyle,
          }}
        >
          {children}
        </div>
      )}
      {isProvider && (
        <div
          className={styles.childrenProviderCunsomPosition}
          style={{
            ...childrenStyle,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
Container.defaultProps = {
  containerStyle: {},
  childClassName: '',
  childrenStyle: {},
  isVisibleOnMobile: true,
  isProfileCard: false,
  consultationPage: false,
  scrollableDashboard: false,
  patientDashboard: false,
  isSidenavVisible: true,
  isProvider: false,
};

export default Container;
