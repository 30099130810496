/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { listClinicalData } from '@brands/services/clinical_data/listClinicalData';
import { DentalHistory, MedicalHistory } from '@brands/services/clinical_data/types/types';
import { Insurance } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { pronounsOptions } from '@brands/Utils/selectButtonOptions';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { BsArrowRightCircleFill } from 'react-icons/bs';
import { toast } from 'react-toastify';

import Collapsible from '../../../../Components/Collapsible/Collapsible';
import { useWindowSize } from '../../../../hooks';
import { useAppSelector } from '../../../../hooks/useReduxHook';
import { CaseType, ICase } from '../../../../services/cases/types/ICase';
import { getUserAvatar } from '../../../../services/identity/getUserAvatar';
import { ITags, listAllTags } from '../../../../services/tags/listAllTags';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { getGenderName } from '../../../../Utils/getGenderName';
import {
  hasUnreadMessageInPatientCA,
  hasUnreadMessageInPatientProvider,
} from '../../../../Utils/getUnreadMessageNotification';
import { Option, stateOptions } from '../../../../Utils/selectOptions';
import { Tag } from '../../assets/types';
import styles from '../../styles.module.scss';
import PatientCasesList from '../PatientCasesList/PatientCasesList';

type ConsulationPatientInfoProps = {
  thisCase: ICase;
  profileCardTags: Option[];
  setProfileCardModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMedicalInformationModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConsultationPatientInfo = ({
  thisCase,
  profileCardTags,
  setProfileCardModal,
  setMedicalInformationModal,
}: ConsulationPatientInfoProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const [medicalHistory, setMedicalHistory] = useState<MedicalHistory | null>(null);
  const [dentalHistory, setDentalHistory] = useState<DentalHistory | null>(null);
  const [pharmacyAddress, setPharmacyAddress] = useState<string>('');
  const [pharmacyPhoneNumber, setPharmacyPhoneNumber] = useState<string>('');
  const [conditionTags, setConditionTags] = React.useState<Tag[]>([]);
  const [patientInsurance, setPatientInsurance] = useState<Insurance | undefined>(undefined);
  const displayPhoneNumber = useMemo(() => {
    if (thisCase?.patient?.phone_number) {
      const phoneNumber = parsePhoneNumber(thisCase.patient.phone_number, 'US');
      if (phoneNumber) {
        return phoneNumber.formatNational();
      }
    } else if (thisCase?.patient?.guardian?.phone_number) {
      const phoneNumber = parsePhoneNumber(thisCase?.patient?.guardian?.phone_number, 'US');
      if (phoneNumber) {
        return phoneNumber.formatNational();
      }
    }
    return '';
  }, [thisCase]);

  const displayPharmacyPhoneNumber = (pharmacyPhoneNum: string): string => {
    if (!pharmacyPhoneNum) {
      return '';
    }
    const phoneNumber = parsePhoneNumber(pharmacyPhoneNum, 'US');
    if (phoneNumber) {
      return phoneNumber.formatNational();
    }
    return '';
  };

  const fetchOptions = async (): Promise<void> => {
    const response = await listAllTags();
    const filteredData = response.filter((item: ITags) => !item.patient_owned);
    const options: Option[] = filteredData.map((item: ITags) => ({
      value: item.id,
      label: item.name,
    }));
    setConditionTags(options);
  };

  const getFormAnswers = async (): Promise<void> => {
    try {
      const { medical_history, dental_history } = await listClinicalData(thisCase.patient_id);
      setMedicalHistory(medical_history);
      setDentalHistory(dental_history);

      setPharmacyAddress(
        `${medical_history?.pharmacy_street_address || ''} ${medical_history?.pharmacy_city || ''}, ${
          medical_history?.pharmacy_state || ''
        }, ${medical_history?.pharmacy_zip_code || ''}`,
      );
      setPharmacyPhoneNumber(displayPharmacyPhoneNumber(medical_history?.pharmacy_phone_number || ''));
    } catch (error) {
      toast.error(`Error fetching form answers: ${error}`, { className: 'fs-unmask' });
    }
  };

  useEffect(() => {
    fetchOptions();
    if (thisCase?.medical_history_snapshot && thisCase?.dental_history_snapshot) {
      const medical_history = thisCase?.medical_history_snapshot;

      setMedicalHistory(medical_history);

      setPharmacyAddress(
        `${medical_history?.pharmacy_street_address || ''} ${medical_history?.pharmacy_city || ''}, ${
          medical_history?.pharmacy_state || ''
        }, ${medical_history?.pharmacy_zip_code || ''}`,
      );
      setPharmacyPhoneNumber(displayPharmacyPhoneNumber(medical_history?.pharmacy_phone_number || ''));

      setDentalHistory(thisCase?.dental_history_snapshot);
    } else {
      getFormAnswers();
    }
  }, []);

  useEffect(() => {
    if (thisCase.type === CaseType.care_coordination) {
      const insurances: Insurance[] = thisCase?.patient?.patient_profile?.insurances ?? [];
      setPatientInsurance(insurances[insurances.length - 1]);
    }
  }, [thisCase]);

  return (
    <div className={styles.patientInfoContainer}>
      <div className={styles.patient}>
        <span className={styles.imageContainer} onClick={() => setProfileCardModal(true)}>
          {(hasUnreadMessageInPatientCA(
            thisCase,
            thisCase.patient.guardian_relationship === null ? thisCase.patient_id : thisCase.patient.guardian_id!,
          ) ||
            hasUnreadMessageInPatientProvider(
              thisCase,
              thisCase.patient.guardian_relationship === null ? thisCase.patient_id : thisCase.patient.guardian_id!,
            )) && <span className={styles.unreasMessage} />}
          <img
            alt="Avatar"
            onError={(event: any) => {
              const img = event.target;
              img.src =
                'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
              img.onError = null;
            }}
            className={`fs-exclude ${styles.patientImage}`}
            src={getUserAvatar(Number(thisCase?.patient.id))}
          />
        </span>
        <div className={styles.patientDiv}>
          <span className={`fs-exclude ${styles.patientName}`} onClick={() => setProfileCardModal(true)}>
            {thisCase?.patient.full_name}
          </span>
        </div>
      </div>
      <div className={styles.patient}>
        <div className={styles.detailsWrapper}>
          <div>
            <span className="fs-unmask">Gender assigned at birth</span>
            <p className="fs-exclude">
              {getGenderName(thisCase?.patient?.basic_info?.gender)} -{' '}
              {pronounsOptions.find((option) => option.value === thisCase?.patient?.basic_info?.pronouns)?.label ||
                thisCase?.patient?.basic_info?.pronouns}
            </p>
          </div>
          <div>
            <span className="fs-unmask">DOB</span>
            <p className="fs-exclude">{moment(thisCase?.patient?.basic_info?.date_of_birth).format('MM/DD/YYYY')}</p>
          </div>
          <div>
            <span className="fs-unmask">Location</span>
            <p className="fs-exclude">
              {stateOptions.find((option) => option.value === thisCase?.patient_current_state)?.label.split(' - ')[1]}
            </p>
          </div>
          {!isMobile && (
            <div>
              <span className="fs-unmask">Language(s)</span>
              <p className="fs-mask">
                {thisCase?.patient?.basic_info?.languages
                  ?.map((language) => typeof language !== 'string' && language.name)
                  .join(', ')}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.patient}>
        <div
          className={`${styles.detailsWrapper} ${styles.addressDetails} ${styles.additionalDetailsWrapper}`}
          style={{ paddingTop: '0px' }}
        >
          <div>
            <span className="text-nowrap fs-unmask">Phone Number</span>
            <p className="text-nowrap fs-exclude">{displayPhoneNumber}</p>
          </div>
          <div className={styles.withoutRightBorderOnMobile}>
            <span className="fs-unmask">Email Address</span>
            <p className="fs-exclude">{thisCase?.patient?.email || thisCase?.patient?.guardian?.email}</p>
          </div>
          <div style={{ borderRight: 'none', flex: '2' }}>
            <span className="fs-unmask">Address</span>
            <p className="fs-exclude">
              {thisCase?.patient?.address?.address1} {thisCase?.patient?.address?.address2},
              {thisCase?.patient?.address?.city}, {thisCase?.patient?.address?.state},
              {thisCase?.patient?.address?.zip_code}
            </p>
          </div>
        </div>
      </div>
      {isMobile && (
        <div className={styles.patient}>
          <div className={styles.detailsWrapper} style={{ padding: '0' }}>
            <div>
              <span className="fs-unmask">Language(s)</span>
              <p className="fs-mask">
                {thisCase?.patient?.basic_info?.languages
                  ?.map((language) => typeof language !== 'string' && language.name)
                  .join(', ')}
              </p>
            </div>
          </div>
        </div>
      )}
      <div className={styles.patient}>
        <div className={styles.underlinedDiv}>
          <div className={styles.flexDiv}>
            <div className={styles.flexRowDiv}>
              <span className="fs-unmask">Insurance: </span>
              <p className="fs-exclude">
                {patientInsurance
                  ? patientInsurance.insurance_membership?.insurance_carrier?.name
                  : thisCase?.insurance?.insurance_membership?.insurance_carrier?.name || '-'}
              </p>
            </div>
            <div className={styles.flexRowDiv}>
              <span className="fs-unmask">Insurance Member ID:</span>
              <p className="fs-exclude">
                {' '}
                {patientInsurance
                  ? patientInsurance.insurance_membership?.member_id
                  : thisCase?.insurance?.insurance_membership?.member_id || '-'}
              </p>
            </div>
            <div className={styles.flexRowDiv}>
              <span className="fs-unmask">Insurance Group Number:</span>
              <p className="fs-exclude">
                {patientInsurance
                  ? patientInsurance.insurance_membership?.group_number
                  : thisCase?.insurance?.insurance_membership?.group_number || '-'}
              </p>
            </div>
            <div className={`${styles.flexRowDiv} ${styles.brandViv}`}>
              <span className="fs-unmask">Organization: </span>
              <div className="d-flex">
                <p className={`fs-mask ${styles.brand}`}>{thisCase?.organization.name}</p>
              </div>
            </div>
            {thisCase?.organization.id === 'dentistry-one' && (
              <div className={`${styles.flexRowDiv} ${styles.brandViv}`}>
                <span className="fs-unmask">Client Tags: </span>
                <div className="d-flex">
                  <p className={`fs-mask ${styles.brand}`}>
                    {thisCase?.patient.markers?.map((marker) => marker.value).join(', ')}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isMobile ? (
        <Collapsible
          isMobile={isMobile}
          patientStyle
          headerStyle={styles.pInfoCollapsibleHeader}
          header={<div className={`fs-unmask ${styles.pInfoCollapsibleHeader}`}>Medical and Dental Information</div>}
          thisCaseStatus={thisCase?.status || ''}
          userRole={userInfo.role.name}
          currentStatus={thisCase?.status || ''}
        >
          <div className={styles.patient}>
            <div className={styles.underlinedDiv}>
              <div className={styles.flexCol}>
                <div>
                  <span className="fs-unmask">Medical and Dental Information</span>
                </div>
                <p className="fs-exclude">{profileCardTags.map((tag) => tag.label || tag.value).join(', ')}</p>
                <div className={styles.tags}>
                  {conditionTags.map((tag) => (
                    <div className={styles.tag} key={tag.value}>
                      <div className={styles.label}>{tag.label || tag.value}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.measurementsDiv}>
                <div>
                  <span className={`fs-unmask ${styles.title}`}>Height: </span>{' '}
                  <span className={`fs-exclude ${styles.value}`}>{medicalHistory?.height}</span>
                </div>
                <div>
                  <span className={`fs-unmask ${styles.title}`}>Weight: </span>{' '}
                  <span className={`fs-exclude ${styles.value}`}>{medicalHistory?.weight}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.patient}>
            <div className={styles.underlinedDiv}>
              <div className={`${styles.dental}`}>
                <div className={styles.flexCol}>
                  <div>
                    <span className="fs-unmask">Date of your last dental visit?</span>
                  </div>
                  <span className={`fs-exclude ${styles.dentalAnswer}`}>
                    {dentalHistory?.last_dental_visit === 'dontKnow' ? `Don't Know` : dentalHistory?.last_dental_visit}
                  </span>
                </div>
                <div className={styles.flexCol}>
                  <div>
                    <span className="fs-unmask">Visit type</span>
                  </div>
                  <span className={`fs-exclude ${styles.dentalAnswer}`}>{dentalHistory?.visit_type}</span>
                </div>
                <div className={styles.flexCol}>
                  <div>
                    <span className="fs-unmask">Who did you see?</span>
                  </div>
                  <span className={`fs-exclude ${styles.dentalAnswer}`}>{dentalHistory?.who_did_you_see}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.patient} style={{ maxWidth: '50%' }}>
            <div className={styles.underlinedDiv}>
              <div className={styles.flexCol}>
                <span className="fs-unmask">Medications</span>
                <p className="fs-exclude">{medicalHistory?.medications}</p>
              </div>
            </div>
          </div>
          <div className={styles.patient}>
            <div className={styles.underlinedDiv}>
              <div className={styles.flexCol}>
                <span className="fs-unmask">Allergies</span>
                <p className="fs-exclude">{medicalHistory?.medications_allergies}</p>
              </div>
            </div>
          </div>
          <div className={styles.patient}>
            {((medicalHistory?.pharmacy_name !== '' && medicalHistory?.pharmacy_name !== 'no') ||
              (pharmacyAddress !== '' && !pharmacyAddress.includes('no')) ||
              (pharmacyPhoneNumber !== '' && pharmacyPhoneNumber !== 'no')) && (
              <div className={styles.underlinedDiv}>
                <div className={styles.flexCol}>
                  <div className={styles.pharmacyDiv}>
                    <div className={`fs-unmask ${styles.pharmacyTitle}`}>Pharmacy</div>
                    <div className="fs-exclude">
                      <div>{medicalHistory?.pharmacy_name !== '' && medicalHistory?.pharmacy_name}</div>
                      <div>{pharmacyAddress !== '' && pharmacyAddress}</div>
                      <div>{pharmacyPhoneNumber !== '' && pharmacyPhoneNumber}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Collapsible>
      ) : (
        <>
          <div className={styles.patient}>
            <div className={`${styles.underlinedDiv} ${styles.medicalWrap}`}>
              <div className={`${styles.medical}`}>
                <div className={styles.flexCol}>
                  <div>
                    <span className="fs-unmask">Medical and Dental Information</span>
                  </div>
                  <p className="fs-exclude">{profileCardTags.map((tag) => tag.label || tag.value).join(', ')}</p>
                  <div className={styles.tags}>
                    {conditionTags.map((tag) => (
                      <div className={styles.tag} key={tag.value}>
                        <div className={styles.label}>{tag.label || tag.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.measurementsDiv}>
                  <div>
                    <span className={`fs-unmask ${styles.title}`}>Height: </span>{' '}
                    <span className={`fs-exclude ${styles.value}`}>{medicalHistory?.height}</span>
                  </div>
                  <div>
                    <span className={`fs-unmask ${styles.title}`}>Weight: </span>{' '}
                    <span className={`fs-exclude ${styles.value}`}>{medicalHistory?.weight}</span>
                  </div>
                </div>
              </div>
              <div className={`${styles.dental}`}>
                <div className={styles.flexCol}>
                  <div>
                    <span className="fs-unmask">Date of your last dental visit?</span>
                  </div>
                  <span className={`fs-exclude ${styles.dentalAnswer}`}>
                    {dentalHistory?.last_dental_visit === 'dontKnow' ? `Don't Know` : dentalHistory?.last_dental_visit}
                  </span>
                </div>
                <div className={styles.flexCol}>
                  <div>
                    <span className="fs-unmask">Visit type</span>
                  </div>
                  <span className={`fs-exclude ${styles.dentalAnswer}`}>{dentalHistory?.visit_type}</span>
                </div>
                <div className={styles.flexCol}>
                  <div>
                    <span className="fs-unmask">Who did you see?</span>
                  </div>
                  <span className={`fs-exclude ${styles.dentalAnswer}`}>{dentalHistory?.who_did_you_see}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.patient}>
            <div className={styles.underlinedDiv}>
              <div className={styles.seperator}>
                <div className={styles.flexCol}>
                  <span className="fs-unmask">Medications</span>
                  <p className="fs-exclude">{medicalHistory?.medications}</p>
                </div>
                <div className={styles.flexCol}>
                  <span className="fs-unmask">Allergies</span>
                  <p className="fs-exclude">{medicalHistory?.medications_allergies}</p>
                </div>
              </div>
              {((medicalHistory?.pharmacy_name !== '' && medicalHistory?.pharmacy_name !== 'no') ||
                (pharmacyAddress !== '' && !pharmacyAddress.includes('no')) ||
                (pharmacyPhoneNumber !== '' && pharmacyPhoneNumber !== 'no')) && (
                <div className={styles.pharmacyDiv}>
                  <div className={`fs-unmask ${styles.pharmacyTitle}`}>Pharmacy</div>
                  <div className="fs-exclude">
                    {medicalHistory?.pharmacy_name !== '' && <span>{medicalHistory?.pharmacy_name}</span>}
                    {pharmacyAddress !== '' && <span>{pharmacyAddress}</span>}
                    {pharmacyPhoneNumber !== '' && <span>{pharmacyPhoneNumber}</span>}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div className={`${styles.patient} ${styles.viewMedicalHistory}`} style={{ paddingTop: !isMobile ? '20px' : '' }}>
        <button
          type="button"
          onClick={() => {
            if (thisCase.patient.status === 'Active') {
              setMedicalInformationModal(true);
            } else {
              const error: any = {
                response: {
                  data: {
                    message: `Previous medical/dental information is not available for deleted ${thisCase.patient.role.name.toLowerCase()}`,
                  },
                },
              };
              displayErrorDetails(error);
            }
          }}
          className="fs-unmask"
        >
          View Medical History <BsArrowRightCircleFill className="fs-unmask" />
        </button>
      </div>
      {isMobile ? (
        <Collapsible
          isMobile={isMobile}
          patientStyle
          headerStyle={styles.pInfoCollapsibleHeader}
          header={<div className={styles.pInfoCollapsibleHeader}>Other Consultations for this Patient</div>}
          thisCaseStatus={thisCase?.status || ''}
          userRole={userInfo.role.name}
          currentStatus={thisCase?.status || ''}
        >
          <PatientCasesList patient_id={thisCase.patient_id} isMobile currentCaseId={thisCase.id} />
        </Collapsible>
      ) : (
        <PatientCasesList patient_id={thisCase.patient_id} currentCaseId={thisCase.id} />
      )}
    </div>
  );
};
export default ConsultationPatientInfo;
