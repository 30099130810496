/* eslint-disable import/no-named-as-default */
import { selectAuth } from '@brands/store/selectors/auth';
import React, { FC, memo, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { LocalTrack } from 'twilio-video';

import { useVideoContext } from '../hooks';
import { useAppSelector } from '../hooks/useReduxHook';
import { getVideoDetails } from '../services/communication/getVideoDetails';
import { getVideoToken } from '../services/communication/getVideoToken';
import CurrentRoom from './Room/Room';
import WaitingRoom from './WaitingRoom/WaitingRoom';

interface VideoAppProps {
  endCurrentCall?: boolean;
  uploadFile?: (file: File) => Promise<void>;
  alreadyJoined?: boolean;
  setHasJoinedFlag?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  showVideoThumbnail?: boolean;
}

const VideoApp: FC<VideoAppProps> = ({
  endCurrentCall,
  uploadFile,
  alreadyJoined,
  setHasJoinedFlag,
  showVideoThumbnail,
}) => {
  const { connect, setParticipantsDetails } = useVideoContext();
  const { userInfo } = useAppSelector(selectAuth);
  const [participantsOnScreen, setParticipantsOnScreen] = useState<boolean>(false);
  const [isJoined, setIsJoined] = useState<boolean>(alreadyJoined || false);

  const startVideoRoom = useCallback(
    async (videoSid: string, localTracks: LocalTrack[], dependentId?: string): Promise<void> => {
      try {
        const data: string = await getVideoToken({
          identity: dependentId || userInfo?.id.toString() || '',
          video_room_sid: videoSid,
        });
        await connect(data, [...localTracks]);
        getVideoDetails(videoSid).then((res) => {
          setParticipantsDetails(res.participants);
        });
        if (setHasJoinedFlag) setHasJoinedFlag(true);
      } catch (e: unknown) {
        toast.error('Error while connecting to video room', { className: 'fs-unmask' });
      }
    },
    [userInfo?.id],
  );
  return (
    <div style={{ width: '100%', height: '100%' }}>
      {!isJoined ? (
        <WaitingRoom startVideoRoom={startVideoRoom} setIsJoined={setIsJoined} />
      ) : (
        <CurrentRoom
          participantsOnScreen={participantsOnScreen}
          setParticipantsOnScreen={setParticipantsOnScreen}
          endCurrentCall={endCurrentCall}
          uploadFile={uploadFile}
          showVideoThumbnail={showVideoThumbnail}
        />
      )}
    </div>
  );
};

VideoApp.defaultProps = {
  endCurrentCall: false,
  uploadFile: undefined,
  alreadyJoined: false,
  setHasJoinedFlag: undefined,
  showVideoThumbnail: false,
};

export default memo(VideoApp);
