/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
import SelectInput from '@brands/Components/Inputs/SelectInput/SelectInput';
import { ClinicalDataQuestion } from '@brands/services/clinical_data/types/types';
import { selectCommonCustomStylesLarge } from '@brands/Utils/customStyles';
import { Option } from '@brands/Utils/selectOptions';
import React, { Dispatch, FC, useEffect, useState } from 'react';

import styles from './styles.module.scss';

interface QuestionAndDropdownProps {
  previousElement: ClinicalDataQuestion;
  element: ClinicalDataQuestion;
  form: { [key: string]: any };
  setForm: Dispatch<React.SetStateAction<any>>;
  givingOptions: Option[];
  twoInputs?: boolean;
  givingOptionsTwo?: Option[];
  givingOptionTitle?: string;
  givingOptionTwoTitle?: string;
  dontKnowOption?: boolean;
  fullSize?: boolean;
}

const QuestionAndDropdown: FC<QuestionAndDropdownProps> = React.forwardRef(
  ({
    previousElement,
    element,
    form,
    setForm,
    twoInputs,
    givingOptions,
    givingOptionsTwo,
    givingOptionTitle,
    givingOptionTwoTitle,
    dontKnowOption,
    fullSize,
  }) => {
    const [firstOptionValue, setFirstOptionValue] = useState('');
    const [secondOptionValue, setSecondOptionValue] = useState('');
    const [dontKnow, setDontKnow] = useState(form[element.id] === 'dontKnow');
    useEffect(() => {
      if (!form[element.id]) {
        setFirstOptionValue('');
        twoInputs && setSecondOptionValue('');
      } else {
        const [firstValue, secondValue] = form[element.id].split(',');
        setFirstOptionValue(firstValue.trim());
        if (twoInputs && secondValue) {
          setSecondOptionValue(secondValue.trim());
        }
      }
    }, []);
    useEffect(() => {
      if (firstOptionValue === 'noState') {
        setForm((prev: any) => ({
          ...prev,
          [element.id]: '',
        }));
      } else if (firstOptionValue || secondOptionValue) {
        setForm((prev: any) => ({
          ...prev,
          [element.id]: `${firstOptionValue !== '' && firstOptionValue !== 'no' ? firstOptionValue : ''}${
            twoInputs && secondOptionValue !== '' && secondOptionValue !== 'no'
              ? `, ${secondOptionValue !== '' ? secondOptionValue : ''}`
              : ''
          }`,
        }));
      }
    }, [firstOptionValue, secondOptionValue]);

    useEffect(() => {
      if (element.id && form[element.id]) {
        const [firstValue, secondValue] = form[element.id].split(',');
        if (twoInputs && secondValue) {
          const [firstValue, secondValue] = form[element.id].split(',');
          setFirstOptionValue(firstValue.trim());
          setSecondOptionValue(secondValue.trim());
        } else {
          setFirstOptionValue(form[element.id] !== '' ? (form[element.id] as string) : '');
        }
      }
    }, []);

    function handleDontKnow(questionId: number | string): void {
      setFirstOptionValue('');
      setSecondOptionValue('');
      setForm((prev: any) => ({
        ...prev,
        [questionId]: prev[questionId] === 'dontKnow' ? 'yes' : 'dontKnow',
      }));
      setDontKnow(!dontKnow);
    }

    function getEnhancedGivingOptions(options: Option[]): Option[] {
      if (element.title.startsWith('Pharmacy')) {
        return [{ value: 'noState', label: 'Select a state' }, ...options];
      }
      return options;
    }

    const enhancedGivingOptions = getEnhancedGivingOptions(givingOptions);

    return (
      <div
        className={`${styles.row} row ${styles.medicalFormRow} ${dontKnowOption ? styles.dontKnowOptionDiv : ''} ${
          fullSize ? styles.fullSize : ''
        }`}
      >
        <span
          style={{ fontSize: dontKnowOption ? '24px' : 'none', opacity: givingOptionTitle || fullSize ? '1' : '0.5' }}
          className={`fs-unmask ${styles.spanLabel}`}
        >
          {element.title.startsWith('Pharmacy') ? element.title.replace(/^Pharmacy\s*/, '') : element.title}
          {element.is_required ? <span className={styles.redText}> *</span> : ''}
        </span>
        <div className={styles.inputsDiv}>
          <span className={styles.containerSpan}>
            {givingOptionTitle !== '' && (
              <span className={`fs-unmask`}  style={{ opacity: givingOptionTitle !== '' ? '0.5' : '1' }}>{givingOptionTitle}</span>
            )}
            <SelectInput
              name={`${element.id}-${0}`}
              options={enhancedGivingOptions}
              containerClass={`fs-exclude  ${styles.inputWrapper}`}
              selectorClass={styles.selector}
              style={selectCommonCustomStylesLarge}
              selectedValue={
                element.title.startsWith('Pharmacy')
                  ? firstOptionValue === ''
                    ? enhancedGivingOptions[0]
                    : firstOptionValue
                  : firstOptionValue
              }
              onChange={(value) => {
                setDontKnow(false);
                setFirstOptionValue(value?.value as string);
              }}
            />
          </span>
          {twoInputs && givingOptionsTwo && (
            <span className={styles.containerSpan}>
              {givingOptionTwoTitle !== '' && (
                <span className="fs-unmask" style={{ opacity: givingOptionTwoTitle !== '' ? '0.5' : '1' }}>
                  {givingOptionTwoTitle}
                </span>
              )}
              <SelectInput
                name={previousElement?.id as string}
                options={givingOptionsTwo}
                containerClass={`fs-exclude  ${styles.inputWrapper}`}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesLarge}
                selectedValue={secondOptionValue}
                onChange={(value) => {
                  setDontKnow(false);
                  setSecondOptionValue(value?.value as string);
                }}
              />
            </span>
          )}
          {dontKnowOption && (
            <span className={styles.dontKnowClass}>
              <input
                className={`form-check-input ${styles.checkInput} mt-0 fs-exclude ${
                  dontKnow ? styles.primaryColor : ''
                }`}
                type="checkbox"
                name={element.id as string}
                id={element.id as string}
                checked={dontKnow}
                onClick={() => handleDontKnow(element.id)}
              />
              Don’t Know
            </span>
          )}
        </div>
      </div>
    );
  },
);

QuestionAndDropdown.defaultProps = {
  twoInputs: false,
  givingOptionsTwo: [],
  givingOptionTitle: '',
  givingOptionTwoTitle: '',
  dontKnowOption: false,
  fullSize: false,
};

export default QuestionAndDropdown;
