import TextField from '@brands/Components/Inputs/TextField/TextField';
import Modal from '@brands/Components/Modals/Modal';
import { config } from '@brands/config/config';
import { UserProfile } from '@brands/services/identity/types/UserProfile';
import { updateUserEmail } from '@brands/services/identity/updateUserEmail';
import { userValidation, UserValidationPayload } from '@brands/services/identity/userValidation';
import { isValidEmail } from '@brands/Utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoIosClose } from 'react-icons/io';
import * as Yup from 'yup';

import { toastError } from '../../../../../Utils/toastError';
import styles from './styles.module.scss';

interface AddEmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  thisUser: UserProfile;
  setThisUser: React.Dispatch<React.SetStateAction<UserProfile>>;
  inviteUser: (userId: string) => void;
}

interface AddEmailForm {
  email: string;
}

const AddEmailModal: FC<AddEmailModalProps> = ({ isOpen, onClose, userId, thisUser, setThisUser, inviteUser }) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const userValidate = (type: string, value: string): Promise<boolean> => {
    const payload: UserValidationPayload = {
      org: config.organization_id,
      [type]: value,
    };
    return userValidation(payload);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .test('test-name', 'Enter Valid Email', (value) => {
        if (!isValidEmail(value as string)) {
          return false;
        }
        return true;
      })
      .test('user-validate', 'This email already exists', async (value: any) => {
        if (isValidEmail(value)) {
          const isEmailExists = await userValidate('email', value.toLowerCase());
          return isEmailExists;
        }
        return true;
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
  } = useForm<AddEmailForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: AddEmailForm): Promise<void> => {
    try {
      await updateUserEmail(Number(userId), { new_email: data.email });
      await setThisUser({ ...thisUser, email: data.email });
      await inviteUser(userId);
      onClose();
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    if (errors.email || getValues('email') === '') {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [errors.email, getValues]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.addEmailContent}>
        <IoIosClose className={styles.closeIcon} onClick={onClose} />
        <h3 className={styles.title}>Add Email</h3>
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputWrapper} data-validate="Enter password">
            <div className={styles.inputWrap}>
              <span className="fs-unmask">Email</span>
              <TextField
                {...register('email')}
                errors={errors.email ? errors : {}}
                name="email"
                onChange={(e) => {
                  if (errors.email) clearErrors('email');
                  setValue('email', e.target.value);
                }}
                wrapperStyle={{ marginTop: '0', height: 'fit-content', borderBottom: 'none' }}
                inputClass={`${styles.profileInputClass} fs-exclude`}
                isUnderlined={false}
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.buttonWrapper}>
              <div className={styles.buttonBG} />
              <button type="submit" className={`${styles.formBtn} ${styles.submitBtn}`} disabled={isDisabled}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddEmailModal;
