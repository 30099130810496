/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import './paymentInformation.css';

import SecondaryInsuranceFields from '@brands/Dashboard/Dashboard/Components/InsuranceFields/SecondaryInsuranceFields';
import SubmitCCConfirmationModal from '@brands/Dashboard/Dashboard/DashboardModals/SubmitCCConfirmationModal';
import {
  updateDataInsurance,
  updateDataPayment,
  updateDataSecondaryInsurance,
} from '@brands/Dashboard/MyProfile/Patient/utils/utilsFunctions';
import { CasePayload, createNewCase } from '@brands/services/cases/createNewCase';
import { getDependentById } from '@brands/services/identity/getDependentById';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectOrganizationPrices } from '@brands/store/selectors/organizationPrices';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatientMyProfile } from '@brands/store/selectors/patientMyProfile';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setUser } from '@brands/store/slices/authSlice';
import { setForm } from '@brands/store/slices/patientFormSlice';
import { setPatientMyProfile } from '@brands/store/slices/patientMyProfileSlice';
import { DEFAULT_PATIENT_LOCATION_STATE } from '@brands/Utils/localStorage';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile as mobileDetect } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../../Components/Button/Button';
import Container from '../../../../Components/Container/Container';
import SelectInput from '../../../../Components/Inputs/SelectInput/SelectInput';
import TextField from '../../../../Components/Inputs/TextField/TextField';
import ProgressBar from '../../../../Components/ProgressBar/ProgressBar';
import { config } from '../../../../config/config';
import { useInsuranceCarriers } from '../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import useWindowSize from '../../../../hooks/useWindowSize';
import { CaseType, ICase, Pricing } from '../../../../services/cases/types/ICase';
import { addPaymentProfile } from '../../../../services/identity/addPaymentProfile';
import { createPatientInsurance } from '../../../../services/identity/createPatientInsurance';
import { getPatientInsurance } from '../../../../services/identity/getPatientInsurance';
import { PaymentProfile } from '../../../../services/identity/types/PaymentProfile';
import { Address, Insurance, UserRoleName } from '../../../../services/identity/types/UserProfile';
import { profileSelectStyles } from '../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { getCaseDisclaimer } from '../../../../Utils/getFriendlyName';
import { getSecondaryColor } from '../../../../Utils/getPrimaryColor';
import { stateOptions } from '../../../../Utils/selectOptions';
import InsuranceFields from '../../../Dashboard/Components/InsuranceFields/InsuranceFields';
import { PatientFormFiveSubmit } from '../utils/types';
import amex from './assets/AMEX.svg';
import disCover from './assets/DisCover.svg';
import fsa from './assets/FSA.svg';
import hsa from './assets/HSA.svg';
import jcb from './assets/JCB.svg';
import masterCard from './assets/Mastercard.svg';
import unionPay from './assets/UnionPay.svg';
import visa from './assets/Visa.svg';
import styles from './styles.module.scss';
import { validationSchema } from './utils/validationSchema';

const paymentOptions = ['Pay by Insurance', 'No Insurance/Self Pay'];

const { locationId } = config.square;
const appId = config.square.applicationId;
type Card = {
  attach: (containerId: string) => Promise<any>;
  tokenize: () => Promise<{
    token: string;
    status: string;
    errors?: JSON;
    details?: {
      card: any;
      method: string;
      billing: any;
    };
  }>;
  status: number;
  addEventListener: (eventName: string, callback: () => void) => void;
};

const PaymentInformation = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const [card, setCard] = useState<Card>();
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [submitCCConfirmation, setSubmitCCConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const { currentOrganizationPrices } = useAppSelector(selectOrganizationPrices);
  const [disablePaymentButton, setDisablePaymentButton] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const patientValues = useAppSelector(selectPatientMyProfile);
  const { values, selectedPatient } = useAppSelector(selectPatientForm);
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const { userInfo } = useAppSelector(selectAuth);
  const insuranceCarriers = useInsuranceCarriers();
  const [currentPatientInsurance, setCurrentPatientInsurance] = useState<Insurance[]>([]);
  const [addSecondaryInsurance, setAddSecondaryInsurance] = useState(currentPatientInsurance.length > 1);
  const listInnerRef = useRef<HTMLDivElement>(null);
  const isPaymentRequired = currentOrganization?.payment_options?.length > 0;
  const currentState: string = useAppSelector(selectCurrentPatientState);
  const insuranceCarrierOptions = useMemo(() => {
    return insuranceCarriers.map((insuranceCarrier) => ({
      value: insuranceCarrier.id,
      label: insuranceCarrier.name,
    }));
  }, [insuranceCarriers]);
  const [patientForm, setPatientForm] = useState<PatientFormFiveSubmit>(
    patientValues.myProfile.patientId === userInfo.id
      ? (patientValues.myProfile as PatientFormFiveSubmit)
      : ({
          member_id: '',
          group_number: '',
          insurance_through: '',
          insurance_carrier_id: 0,
          iPhoneNumber: '',
          iFirstName: '',
          iMiddleName: '',
          iDateOfBirth: '',
          iGenderIdentity: '',
          iLastName: '',
          iAddress1: '',
          iAddress2: '',
          iZipCode: '',
          iState: '',
          iCity: '',
          secondary_member_id: '',
          secondary_group_number: '',
          secondary_insurance_through: '',
          secondary_insurance_carrier_id: 0,
          secondary_iPhoneNumber: '',
          secondary_iFirstName: '',
          secondary_iMiddleName: '',
          secondary_iDateOfBirth: '',
          secondary_iGenderIdentity: '',
          secondary_iLastName: '',
          secondary_iAddress1: '',
          secondary_iAddress2: '',
          secondary_iZipCode: '',
          secondary_iState: '',
          secondary_iCity: '',
          secondary_insurance_address: '',
          secondary_insurance_city: '',
          secondary_insurance_state: '',
          secondary_insurance_zip_code: '',
          creditCardFullName: '',
          creditCardNumber: '',
          creditCardAddress1: '',
          creditCardAddress2: '',
          expiryDate: '',
          creditCardZipCode: '',
          cvvNumber: '',
          creditCardState: '',
          creditCardCity: '',
        } as PatientFormFiveSubmit),
  );
  const [dependentAddress, setDependentAddress] = useState<Address>(
    userInfo.role.name === UserRoleName.Patient
      ? userInfo.address
      : (userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin) &&
          currentPatient.address,
  );

  const casePrice = currentOrganizationPrices.find((price: Pricing) =>
    price.case_types.includes(values.find((item) => item.form_question_id === 20)?.value.caseType as string),
  )?.amount;

  const shouldActivateOnlyPayByInsurance = casePrice === 0 && currentOrganization.collect_insurance_info;

  const createCaseForThisRequirement = async (paymentId: number | undefined): Promise<ICase> => {
    const payload: CasePayload = {
      type: 'care_coordination',
      status: 'new',
      patient_id:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentPatient.guardian_id === null
            ? currentPatient?.id
            : currentPatient.guardian_id!
          : Number(userInfo.id),
      organization_id: currentOrganization?.id || 'dentistry-one',
      provider_id: undefined,
      payment_profile_id: isPaymentRequired && paymentId ? Number(paymentId) : null,
      video_conference_date: null,
      patient_current_state:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentState[currentPatient.id] || ''
          : currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE,
      dependent_id:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentPatient.guardian_id === null
            ? null
            : Number(currentPatient?.id)
          : Number(selectedPatient?.value) !== Number(userInfo.id)
          ? Number(selectedPatient?.value)
          : null,

      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      license_states:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? [currentState[currentPatient.id] || '']
          : [currentState[userInfo.id]],
      case_intake_questions: {
        is_dental_emergency: values.find((item) => item.form_question_id === 15)?.value.dental_emergency === 'yes',
        in_pain: values.find((item) => item.form_question_id === 16)?.value.inPain === 'yes',
        pain_description: (values.find((item) => item.form_question_id === 16)?.value.describePain as string[]) || [],
        pain_time: (values.find((item) => item.form_question_id === 16)?.value.daysInPain as string) || '',
        pain_level: (values.find((item) => item.form_question_id === 16)?.value.painLevel as string) || '',
        additional_info: (values.find((item) => item.form_question_id === 18)?.value.anythingElse as string) || '',
      },
    };
    const caseRes = await createNewCase(payload);
    return caseRes;
  };

  useEffect(() => {
    const fetchDependentAddress = async (): Promise<void> => {
      if (
        (userInfo.role.name === UserRoleName.Patient && userInfo.id !== Number(selectedPatient?.value)) ||
        ((userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin) &&
          currentPatient.guardian_id !== null)
      ) {
        const dependent = await getDependentById(
          userInfo.role.name === UserRoleName.Patient
            ? Number(userInfo.id)
            : currentPatient.guardian_id !== null
            ? currentPatient.guardian_id
            : currentPatient.id,
          Number(selectedPatient?.value),
        );
        setDependentAddress(dependent.address);
      }
    };

    if (selectedPatient?.value && userInfo.id !== selectedPatient?.value) fetchDependentAddress();
  }, [selectedPatient]);
  useEffect(() => {
    const fetchUserInsurance = async (): Promise<void> => {
      let userInsurance = [];
      userInsurance = await getPatientInsurance(
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? Number(selectedPatient?.value)
          : Number(selectedPatient?.value) !== Number(userInfo.id)
          ? Number(selectedPatient?.value)
          : undefined,
      );

      if (
        userInsurance.length === 0 &&
        (((userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin) &&
          currentPatient.guardian_id !== null) ||
          (userInfo.role.name === UserRoleName.Patient && Number(selectedPatient?.value) !== Number(userInfo.id)))
      ) {
        userInsurance = await getPatientInsurance(
          userInfo.role.name === UserRoleName.Admin ||
            userInfo.role.name === UserRoleName.SuperAdmin ||
            userInfo.role.name === UserRoleName.OrganizationAdmin
            ? Number(currentPatient.guardian_id)
            : undefined,
        );
      }

      setCurrentPatientInsurance(userInsurance);
      setAddSecondaryInsurance(userInsurance.length > 1);
      setPatientForm({
        ...patientForm,
        insurance_through: userInsurance[0]?.insured_by || '',
        insurance_carrier_id: userInsurance[0]?.insurance_membership?.insurance_carrier_id || 0,
        member_id: userInsurance[0]?.insurance_membership?.member_id || '',
        group_number: userInsurance[0]?.insurance_membership?.group_number || '',
        iPhoneNumber: userInsurance[0]?.insurance_membership?.phone_number || '',
        iFirstName: userInsurance[0]?.insurance_through?.first_name || '',
        iMiddleName: userInsurance[0]?.insurance_through?.middle_name || '',
        iDateOfBirth: userInsurance[0]?.insurance_through?.date_of_birth
          ? moment(userInsurance[0].insurance_through.date_of_birth).format('MM/DD/YYYY')
          : '',
        iGenderIdentity: userInsurance[0]?.insurance_through?.gender_identity || '',
        iLastName: userInsurance[0]?.insurance_through?.last_name || '',
        iAddress1: userInsurance[0]?.insurance_through?.address?.address1 || '',
        iAddress2: userInsurance[0]?.insurance_through?.address?.address2 || '',
        iZipCode: userInsurance[0]?.insurance_through?.address?.zip_code || '',
        iState: userInsurance[0]?.insurance_through?.address?.state || '',
        iCity: userInsurance[0]?.insurance_through?.address?.city || '',
        state_funded_coverage_status: userInsurance[0]?.state_funded_coverage_status || '',
        secondary_insurance_through: userInsurance[1]?.insured_by ? userInsurance[1].insured_by : 'none',
        secondary_member_id: userInsurance[1]?.insurance_membership
          ? userInsurance[1]?.insurance_membership.member_id
          : '',
        secondary_insurance_address: userInsurance[1]?.insurance_membership?.address?.address1 || '',
        secondary_insurance_city: userInsurance[1]?.insurance_membership?.address?.city || '',
        secondary_insurance_state: userInsurance[1]?.insurance_membership?.address?.state || '',
        secondary_insurance_zip_code: userInsurance[1]?.insurance_membership?.address?.zip_code || '',
        secondary_group_number: userInsurance[1]?.insurance_membership
          ? userInsurance[1]?.insurance_membership.group_number
          : '',
        secondary_iFirstName: userInsurance[1]?.insurance_through?.first_name || '',
        secondary_iMiddleName: userInsurance[1]?.insurance_through?.middle_name || '',
        secondary_iLastName: userInsurance[1]?.insurance_through?.last_name || '',
        secondary_iDateOfBirth: userInsurance[1]?.insurance_through
          ? moment(userInsurance[1]?.insurance_through?.date_of_birth).format('MM/DD/YYYY')
          : '',
        secondary_iGenderIdentity: userInsurance[1]?.insurance_through?.gender_identity || '',
        secondary_iAddress1: userInsurance[1]?.insurance_through?.address?.address1 || '',
        secondary_iAddress2: userInsurance[1]?.insurance_through?.address?.address2 || '',
        secondary_iZipCode: userInsurance[1]?.insurance_through?.address?.zip_code || '',
        secondary_iState: userInsurance[1]?.insurance_through?.address?.state || '',
        secondary_iCity: userInsurance[1]?.insurance_through?.address?.city || '',
        secondary_iPhoneNumber: userInsurance[1]?.insurance_membership
          ? userInsurance[1]?.insurance_membership.phone_number
          : '',
        secondary_insurance_carrier_id: userInsurance[1]?.insurance_membership?.insurance_carrier_id,
        insurance_address: userInsurance[0]?.insurance_membership?.address?.address1 || '',
        insurance_city: userInsurance[0]?.insurance_membership?.address?.city || '',
        insurance_state: userInsurance[0]?.insurance_membership?.address?.state || '',
        insurance_zip_code: userInsurance[0]?.insurance_membership?.address?.zip_code || '',
      });
    };
    fetchUserInsurance();
  }, []);

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(error.message as string, { className: 'fs-unmask' });
    }
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<PatientFormFiveSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(patientForm, paymentMethod, addSecondaryInsurance, isSameAddress)),
  });
  useEffect(() => {
    Object.keys(patientForm).forEach((key: unknown) => {
      setValue(key as keyof PatientFormFiveSubmit, patientForm[key as keyof PatientFormFiveSubmit]);
    });
    const selectedCarrierId = values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier;
    if (selectedCarrierId !== undefined && Number(selectedCarrierId) !== 0) {
      setValue('insurance_carrier_id', Number(selectedCarrierId));
    }
  }, [patientForm]);

  const onSubmit = async (data: PatientFormFiveSubmit): Promise<void> => {
    setDisablePaymentButton(true);
    let paymentId;
    let insuranceId;
    let secondaryInsuranceId;
    let dataWithUpdatedInsurance;
    if (paymentMethod === 'Pay by Insurance' && data.insurance_through !== 'none') {
      const insuranceArray: Insurance[] = [];
      dataWithUpdatedInsurance = updateDataInsurance(data);
      insuranceArray.push(dataWithUpdatedInsurance);
      if (addSecondaryInsurance) {
        const dataWithUpdatedSecondaryInsurance = updateDataSecondaryInsurance(data);
        insuranceArray.push(dataWithUpdatedSecondaryInsurance);
      }
      const updatedUserInsurance = await createPatientInsurance({
        user_id:
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? Number(selectedPatient?.value)
            : Number(selectedPatient?.value) !== Number(userInfo.id)
            ? Number(selectedPatient?.value)
            : undefined,
        insurance: insuranceArray,
      });
      insuranceId = updatedUserInsurance[0]?.id || undefined;
      if (addSecondaryInsurance) {
        secondaryInsuranceId = updatedUserInsurance[1].id;
      }
      dispatch(
        setUser({
          userInfo: {
            ...userInfo,
            patient_profile: {
              ...userInfo.patient_profile,
              insurance: updatedUserInsurance,
            },
          },
        }),
      );
    }
    if (
      card &&
      (paymentMethod === 'No Insurance/Self Pay' ||
        (((values.find((item) => item.form_question_id === 20)?.value.caseType as string) === CaseType.second_opinion ||
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
            CaseType.care_coordination) &&
          paymentMethod === 'No Insurance/Self Pay')) &&
      (currentOrganization.payment_options.find((paymentOption: string) => paymentOption === 'Credit Card') ||
        (((values.find((item) => item.form_question_id === 20)?.value.caseType as string) === CaseType.second_opinion ||
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
            CaseType.care_coordination) &&
          paymentMethod === 'No Insurance/Self Pay'))
    ) {
      try {
        const tokenResult = await card.tokenize();
        if (tokenResult.status === 'OK') {
          const dataWithUpdatedPayment = updateDataPayment(
            data,
            tokenResult,
            userInfo,
            isSameAddress,
            dependentAddress,
            Number(currentPatient?.id),
            Number(currentPatient.role.name === 'Dependent' ? currentPatient?.guardian_id : currentPatient?.id),
          );
          const res = await addPaymentProfile(dataWithUpdatedPayment as PaymentProfile);
          paymentId = res.id!;
        } else {
          toast.error('Please Enter your Credit Card information correctly', { className: 'fs-unmask' });
          return;
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
        return;
      } finally {
        setDisablePaymentButton(false);
      }
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'purchase',
      userId: userInfo.id,
      paymentMethod,
      paymentStatus,
    });

    setPatientForm(data);
    dispatch(
      setPatientMyProfile({
        ...(data as PatientFormFiveSubmit),
        patientId:
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? Number(selectedPatient?.value)
            : userInfo?.id,
      }),
    );
    // submitPatientForm(paymentId ?? null, insuranceId ?? null, secondaryInsuranceId ?? null);
    const payload = [...values!];
    const filteredPayload = payload.filter((item) => item.form_question_id !== 19);
    filteredPayload.push({
      form_question_id: 19,
      value: {
        paymentId: paymentId ?? '',
        insuranceId: insuranceId ?? '',
        secondaryInsuranceId: secondaryInsuranceId ?? '',
        addSecondaryInsurance,
        dataWithUpdatedInsurance,
      },
    });
    dispatch(setForm(filteredPayload));

    if (paymentMethod === 'Pay by Insurance' && !(casePrice === 0 && currentOrganization.collect_insurance_info)) {
      navigate('/verify-insurance', {
        state: {
          caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string,
          insuranceCarrierId: data.insurance_carrier_id,
          customInsuranceCarrier: '',
          userOrganization: currentOrganization.id,
          patientId:
            userInfo.role.name === UserRoleName.Admin ||
            userInfo.role.name === UserRoleName.SuperAdmin ||
            userInfo.role.name === UserRoleName.OrganizationAdmin
              ? Number(selectedPatient?.value)
              : userInfo?.id,
        },
      });
    } else if (
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'video_call_scheduled' ||
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
        CaseType.phone_call_scheduled ||
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === CaseType.oral_health_coaching
    ) {
      navigate('/select-date-time', {
        state: {
          caseType: 'video_call_scheduled',
          insuranceCarrierId: data.insurance_carrier_id || '',
          customInsuranceCarrier: '',
          userOrganization: currentOrganization.id,
          patientId:
            userInfo.role.name === UserRoleName.Admin ||
            userInfo.role.name === UserRoleName.SuperAdmin ||
            userInfo.role.name === UserRoleName.OrganizationAdmin
              ? Number(selectedPatient?.value)
              : userInfo?.id,
        },
      });
    } else if (
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'video_call_instant' ||
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report'
    ) {
      navigate('/select-needs', {
        state: {
          caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string,
          patientState: currentPatient.address?.state,
          userOrganization: currentOrganization.id,
          patientId:
            userInfo.role.name === UserRoleName.Admin ||
            userInfo.role.name === UserRoleName.SuperAdmin ||
            userInfo.role.name === UserRoleName.OrganizationAdmin
              ? Number(selectedPatient?.value)
              : userInfo?.id,
        },
      });
    } else if (
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === CaseType.second_opinion
    ) {
      navigate('/upload-image', {
        state: {
          caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string,
          patientState: currentPatient.address?.state,
          userOrganization: currentOrganization.id,
          patientId:
            userInfo.role.name === UserRoleName.Admin ||
            userInfo.role.name === UserRoleName.SuperAdmin ||
            userInfo.role.name === UserRoleName.OrganizationAdmin
              ? Number(selectedPatient?.value)
              : userInfo?.id,
        },
      });
    } else if (
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === CaseType.care_coordination
    ) {
      try {
        setLoading(true);
        const res = await createCaseForThisRequirement(paymentId);
        if (res) {
          setSubmitCCConfirmation(!submitCCConfirmation);
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
      } finally {
        setLoading(false);
      }
    }
    setDisablePaymentButton(false);
  };

  const squareDiv = document.getElementById('card-container');

  const initSquare = async (): Promise<void> => {
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }
    const payments = window.Square.payments(appId, locationId);
    try {
      const paymentCard = await payments.card();
      if (squareDiv?.childNodes.length === 0) {
        await paymentCard.attach('#card-container');
      }
      setCard(paymentCard);
      paymentCard.addEventListener('errorClassAdded', () => {
        setDisablePaymentButton(true);
      });
      paymentCard.addEventListener('errorClassRemoved', () => {
        setDisablePaymentButton(false);
      });
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    (async (): Promise<void> => {
      await initSquare();
      setPaymentStatus('');
    })();
  }, [squareDiv]);

  useEffect(() => {
    const selectedCarrierId = values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier;
    const selectedInsurancePlanId = values.find((item) => item.form_question_id === 14)?.value?.selectedInsurancePlan;
    let selectedCarrier = 0;
    if (selectedCarrierId !== undefined && Number(selectedCarrierId) !== 0) {
      selectedCarrier =
        insuranceCarrierOptions.find((carrier) => carrier.value === Number(selectedCarrierId))?.value || 0;
      setPatientForm({
        ...patientForm,
        insurance_carrier_id: selectedCarrier,
      });
    }
    if (selectedCarrier !== 0 && selectedInsurancePlanId !== undefined && Number(selectedInsurancePlanId) !== 0) {
      setPatientForm({
        ...patientForm,
        insurance_carrier_id: selectedCarrier,
      });
    }
  }, [values]);

  const onChange = (key: any, value: string | number): void => {
    setValue(key, value, { shouldValidate: true });
    setPatientForm({
      ...patientForm,
      [key]: value,
    });
  };

  const getSelectedInsuranceCarrier = (): number => {
    const selectedCarrierId = values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier;
    let selectedCarrier = 0;
    if (selectedCarrierId !== undefined && Number(selectedCarrierId) !== 0) {
      selectedCarrier =
        insuranceCarrierOptions.find((carrier) => carrier.value === Number(selectedCarrierId))?.value || 0;
      return selectedCarrier;
    }
    return 0;
  };

  const validateInsuranceFields = (): boolean => {
    if (
      patientForm.insurance_through === '' ||
      patientForm.insurance_through === 'none' ||
      (patientForm.insurance_carrier_id === 0 && getSelectedInsuranceCarrier() === 0) ||
      patientForm.member_id === '' ||
      patientForm.group_number === '' ||
      patientForm.iPhoneNumber === '' ||
      patientForm.insurance_address === '' ||
      patientForm.insurance_city === '' ||
      patientForm.insurance_state === '' ||
      patientForm.insurance_zip_code === '' ||
      (addSecondaryInsurance &&
        (getValues('secondary_insurance_through') === '' ||
          getValues('secondary_insurance_through') === 'none' ||
          (getValues('secondary_insurance_carrier_id') === 0 && getSelectedInsuranceCarrier() === 0) ||
          getValues('secondary_member_id') === '' ||
          getValues('secondary_group_number') === '' ||
          getValues('secondary_iPhoneNumber') === '' ||
          getValues('secondary_insurance_address') === '' ||
          getValues('secondary_insurance_state') === '' ||
          getValues('secondary_insurance_city') === '' ||
          getValues('secondary_insurance_zip_code') === ''))
    )
      return true;

    return false;
  };

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2' }}
    >
      <div className={styles.patientFormContainer}>
        <div className={`${styles.formTitle} mt-3 fs-unmask`} ref={listInnerRef}>
          Insurance and Billing
        </div>
        <ProgressBar
          currentStep={5}
          totalSteps={5}
          hideStepNumbers
          onBack={() => {
            navigate(
              userInfo.role.name === UserRoleName.Admin ||
                userInfo.role.name === UserRoleName.SuperAdmin ||
                userInfo.role.name === UserRoleName.OrganizationAdmin
                ? `/admin/review-profile/Patient/${currentPatient?.id}}/create-consultation-for`
                : '/select-patient',
            );
          }}
        />
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <div className={styles.wrapper}>
            <div className={styles.formContent}>
              <div className={styles.buttonsContainer}>
                {paymentOptions.map((element) => (
                  <Fragment key={element}>
                    <input
                      type="radio"
                      className="d-none fs-unmask"
                      value={element}
                      id={element}
                      onClick={() => {
                        setPaymentMethod(element);
                      }}
                      disabled={
                        shouldActivateOnlyPayByInsurance
                          ? element !== 'Pay by Insurance'
                          : !(
                              ((values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                                CaseType.second_opinion ||
                                (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                                  CaseType.care_coordination ||
                                (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                                  CaseType.oral_health_coaching) &&
                              element === 'No Insurance/Self Pay'
                            ) &&
                            ((((values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                              CaseType.second_opinion ||
                              (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                                CaseType.care_coordination ||
                              (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                                CaseType.oral_health_coaching) &&
                              element === 'Pay by Insurance') ||
                              currentOrganization.payment_options.find(
                                (paymentOption: string) =>
                                  (element === 'Pay by Insurance' && paymentOption === 'Insurance') ||
                                  (element === 'No Insurance/Self Pay' && paymentOption === 'Credit Card'),
                              ) === undefined)
                      }
                    />
                    <label
                      className={
                        shouldActivateOnlyPayByInsurance
                          ? element === 'Pay by Insurance'
                            ? `fs-unmask ${styles.selectedClass}`
                            : `${styles.disabledLabel} ${styles.btn} fs-unmask`
                          : !(
                              ((values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                                CaseType.second_opinion ||
                                (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                                  CaseType.care_coordination ||
                                (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                                  CaseType.oral_health_coaching) &&
                              element === 'No Insurance/Self Pay'
                            ) &&
                            ((((values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                              CaseType.second_opinion ||
                              (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                                CaseType.care_coordination ||
                              (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                                CaseType.oral_health_coaching) &&
                              element === 'Pay by Insurance') ||
                              currentOrganization.payment_options.find(
                                (paymentOption: string) =>
                                  (element === 'Pay by Insurance' && paymentOption === 'Insurance') ||
                                  (element === 'No Insurance/Self Pay' && paymentOption === 'Credit Card'),
                              ) === undefined)
                          ? `${styles.disabledLabel} ${styles.btn} fs-unmask`
                          : paymentMethod === element
                          ? `fs-unmask ${styles.selectedClass}`
                          : `fs-unmask ${styles.btn}`
                      }
                      htmlFor={element}
                    >
                      {element}
                      <div className={`${styles.bntIcon} ${paymentMethod === element ? styles.primaryColor : ''}`} />
                    </label>
                  </Fragment>
                ))}
              </div>
            </div>
            <div
              className={styles.formContent}
              style={{ display: paymentMethod === 'Pay by Insurance' ? 'flex' : 'none' }}
            >
              {currentOrganization.credit_card_required && (
                <span className={`fs-unmask ${styles.insuranceText}`}>
                  We will process the below insurance information. Please be aware that you might get asked to pay part
                  of, or the full amount of the consultation cost.
                </span>
              )}
              <InsuranceFields
                register={register}
                errors={errors}
                patientForm={patientForm}
                onChange={onChange}
                shouldActivateOnlyPayByInsurance={shouldActivateOnlyPayByInsurance}
              />
              <div className={`${styles.addSecondaryInsurance} form-check`}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="isAdditionalInsurance"
                  checked={addSecondaryInsurance}
                  onChange={(event) => {
                    setAddSecondaryInsurance(event.target.checked);
                  }}
                />
                <label
                  className={`form-check-label ${styles.additionalPeopleCheckLabel} fs-unmask`}
                  htmlFor="isAdditionalInsurance"
                >
                  Add Secondary Insurance
                </label>
              </div>
              {addSecondaryInsurance && (
                <SecondaryInsuranceFields
                  register={register}
                  errors={errors}
                  getValues={getValues}
                  onChange={onChange}
                />
              )}
            </div>
            <div
              className={styles.formContent}
              style={{ display: paymentMethod === 'No Insurance/Self Pay' ? 'flex' : 'none' }}
            >
              <div className={styles.coveredContainer}>
                <div className={styles.priceDetails}>
                  <span className={styles.priceDetailsInfo}>Price of visit:</span>
                  <span className={`fs-unmask ${styles.priceSpan}`}>
                    {' '}
                    <span className={styles.dollarSign}>$</span>
                    {(() => {
                      if (casePrice === undefined) {
                        return null;
                      }

                      const priceInDollars = casePrice / 100;
                      return priceInDollars === 0
                        ? 'Free'
                        : priceInDollars % 1 === 0
                        ? `${priceInDollars.toFixed(0)}`
                        : `${priceInDollars.toFixed(2)}`;
                    })()}
                  </span>
                </div>
                <div className={`fs-unmask ${styles.coverageText}`}>
                  Thank you! You’ve made the important decision to make your oral health a priority. Simply enter your
                  credit card information below and get started now!
                </div>
                <div className={`fs-unmask ${styles.coverageText}`} style={{ fontWeight: 'bolder' }}>
                  Enter your Credit card information below to proceed
                </div>
              </div>
              <div className={`row ${styles.row}`}>
                <div className="col-md-12">
                  <div className={styles.inputWrap}>
                    <div>
                      <span className="fs-unmask">Name on Card</span>
                      <span className={styles.redText}> *</span>
                    </div>
                    <TextField
                      {...register('creditCardFullName')}
                      errors={errors}
                      name="creditCardFullName"
                      value={patientForm.creditCardFullName}
                      onChange={(e) => {
                        setValue('creditCardFullName', e.target.value, { shouldValidate: true });
                        setPatientForm((prev) => ({ ...prev, creditCardFullName: e.target.value }));
                      }}
                      wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                      wrapperClassName="fs-exclude"
                      inputClass={styles.profileInputClass}
                      isUnderlined={false}
                    />
                  </div>
                </div>
              </div>
              <div className={`row ${styles.creditCardContainer} p-row`}>
                <div>
                  <span
                    style={{
                      color: getSecondaryColor(),
                      opacity: '0.5',
                      fontSize: '16px',
                    }}
                    className="fs-unmask"
                  >
                    Credit Card Information
                  </span>
                  <span style={{ color: '#FA5151' }}> *</span>
                </div>
                <div id="form-container">
                  <div id="card-container" className="fs-exclude" />
                  <span> {paymentStatus} </span>
                  <div className={`${styles.invalidFeedback} invalid-feedback`}>{errors.creditCardNumber?.message}</div>
                  <div className={styles.creditCardIcons}>
                    <span>
                      <img src={visa} alt="Visa" />
                    </span>
                    <span>
                      <img src={masterCard} alt="MasterCard" />
                    </span>
                    <span>
                      <img src={amex} alt="AMEX" />
                    </span>
                    <span>
                      <img src={disCover} alt="DisCover" />
                    </span>
                    <span>
                      <img src={jcb} alt="JCB" />
                    </span>
                    <span>
                      <img src={unionPay} alt="UnionPay" />
                    </span>
                    <span>
                      <img src={hsa} alt="HSA" />
                    </span>
                    <span>
                      <img src={fsa} alt="FSA" />
                    </span>
                  </div>
                </div>
              </div>
              <span className={`fs-unmask ${styles.subTitle}`}>Billing Address</span>
              <hr />
              <div className={styles.billingAddressCheck}>
                <input
                  className="form-check-input fs-unmask"
                  type="checkbox"
                  id="isAdditionalInsurance"
                  checked={isSameAddress}
                  onChange={(event) => {
                    setIsSameAddress(event.target.checked);
                  }}
                />
                <label
                  className={`form-check-label ${styles.billingAddressCheckLabel} fs-unmask`}
                  htmlFor="isAdditionalInsurance"
                >
                  Check here if your billing address is different than your home address
                </label>
              </div>
              {isSameAddress ? (
                <>
                  <div className={`row ${styles.row}`}>
                    <div className="col-md-12">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className="fs-unmask">Street Address</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('creditCardAddress1')}
                          errors={errors}
                          name="creditCardAddress1"
                          value={getValues('creditCardAddress1')}
                          onChange={(e) => {
                            setValue('creditCardAddress1', e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName="fs-exclude"
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`row ${styles.row}`}>
                    <div className="col-md-12">
                      <div className={styles.inputWrap}>
                        <span className="fs-unmask">Apt, suite, building (optional)</span>
                        <TextField
                          {...register('creditCardAddress2')}
                          errors={errors}
                          name="creditCardAddress2"
                          value={getValues('creditCardAddress2')}
                          onChange={(e) => {
                            setValue('creditCardAddress2', e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName="fs-exclude"
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`row ${styles.row}`}>
                    <div className="col-md-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className="fs-unmask">City</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('creditCardCity')}
                          errors={errors}
                          name="creditCardCity"
                          value={getValues('creditCardCity')}
                          onChange={(e) => {
                            setValue('creditCardCity', e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName="fs-exclude"
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.inputWrap} data-validate="Choose State">
                        <div>
                          <span className="fs-unmask">State</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <SelectInput
                          {...register('creditCardState')}
                          containerClass="mt-0 fs-exclude"
                          name="creditCardState"
                          options={stateOptions}
                          style={profileSelectStyles}
                          errors={errors}
                          selectedValue={getValues('creditCardState')}
                          onChange={(value) => {
                            setValue('creditCardState', value?.value as string, {
                              shouldValidate: true,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.inputWrap}>
                        <div>
                          <span className="fs-unmask">Zip Code</span>
                          <span className={styles.redText}> *</span>
                        </div>
                        <TextField
                          {...register('creditCardZipCode')}
                          errors={errors}
                          name="creditCardZipCode"
                          value={getValues('creditCardZipCode')}
                          onChange={(e) => {
                            setValue('creditCardZipCode', e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                          wrapperClassName="fs-exclude"
                          inputClass={styles.profileInputClass}
                          isUnderlined={false}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <span>
                  {userInfo.id === Number(selectedPatient?.value)
                    ? `${currentPatient?.address?.address1} ${currentPatient?.address?.city} ${currentPatient?.address?.state} ${currentPatient?.address?.zip_code}`
                    : `${dependentAddress?.address1} ${dependentAddress?.city} ${dependentAddress?.state} ${dependentAddress?.zip_code}`}
                </span>
              )}
            </div>
            <div className={styles.btnContainer} style={{ display: paymentMethod === '' ? 'none' : '' }}>
              <span className={`fs-unmask ${styles.disclaimer}`}>
                DISCLAIMER:{' '}
                {getCaseDisclaimer(values.find((item) => item.form_question_id === 20)?.value.caseType as string)}
              </span>

              <Button
                type="submit"
                style={{ width: '245px', height: '46px' }}
                className="fs-unmask"
                onTouchStart={isMobile || mobileDetect ? handleSubmit(onSubmit, onInvalid) : undefined}
                disabled={
                  disablePaymentButton ||
                  (paymentMethod === 'Pay by Insurance' &&
                    !shouldActivateOnlyPayByInsurance &&
                    validateInsuranceFields()) ||
                  userInfo.status !== 'Active' ||
                  loading
                }
              >
                {(values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
                CaseType.care_coordination
                  ? `Finish and Pay`
                  : `Next`}
              </Button>
            </div>
          </div>

          {submitCCConfirmation && <SubmitCCConfirmationModal />}
        </form>
      </div>
    </Container>
  );
};
export default PaymentInformation;
