/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-nested-ternary */
import Button from '@brands/Components/Button/Button';
import Container from '@brands/Components/Container/Container';
import SelectInput from '@brands/Components/Inputs/SelectInput/SelectInput';
import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import OpenCaseListModal from '@brands/Dashboard/Dashboard/Components/OpenCaseListModal/OpenCaseListModal';
import SubmitCCConfirmationModal from '@brands/Dashboard/Dashboard/DashboardModals/SubmitCCConfirmationModal';
import { useAppDispatch, useAppSelector } from '@brands/hooks/useReduxHook';
import { CasePayload, createNewCase } from '@brands/services/cases/createNewCase';
import { IListCases, listAllCases } from '@brands/services/cases/listAllCases';
import { CaseType, ICase } from '@brands/services/cases/types/ICase';
import { Pricings } from '@brands/services/identity/getPricings';
import { getPricingsByAdmin } from '@brands/services/identity/getPricingsByAdmin';
import { getUserAvatar } from '@brands/services/identity/getUserAvatar';
import { getUserById } from '@brands/services/identity/getUserById';
import { listAllOrganizations } from '@brands/services/identity/listAllOrganizations';
import { Organization, UserProfile, UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectOrganizationPrices } from '@brands/store/selectors/organizationPrices';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setPatientState } from '@brands/store/slices/currentPatientState';
import { setOrganizationPrices } from '@brands/store/slices/organizationPricingSlice';
import { setOrganization } from '@brands/store/slices/organizationSlice';
import { setDependentId, setIsFromSelectPatient } from '@brands/store/slices/pageStateSlice';
import { FormValues, setExternalForms, setForm } from '@brands/store/slices/patientFormSlice';
import { checkExternalFormAnswers } from '@brands/Utils/checkExternalForms';
import { selectCommonCustomStylesSlim } from '@brands/Utils/customStyles';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { getFriendlyName } from '@brands/Utils/getFriendlyName';
import { DEFAULT_PATIENT_LOCATION_STATE } from '@brands/Utils/localStorage';
import { Option, stateOptions } from '@brands/Utils/selectOptions';
import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

const CreateConsultationFor = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(selectAuth);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const { selectedPatient } = useAppSelector(selectPatient);
  const [submitCCConfirmation, setSubmitCCConfirmation] = useState(false);
  const { selectedPatient: currentPatient, values } = useAppSelector(selectPatientForm);
  const [allOrganization, setAllOrganization] = useState<Organization[]>([]);
  const [customizeUserOrganization, setCustomizaUserOrganization] = useState<Organization[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<Option[]>([]);
  const [loadingCalculation, setLoadingCalculation] = useState(false);
  const [loadingUntillGetUserInfo, setLoadingUntillGetUserInfo] = useState(true);
  const currentState: string = useAppSelector(selectCurrentPatientState);

  const [selectedState, setSelectedState] = useState(selectedPatient?.address?.state);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [selectedCase, setSelectedCase] = useState({
    selectedCaseType: '',
    selectedCasePrice: '',
  });
  const [caseOptions, setCaseOptions] = useState<{ case_type: string; amount: number }[]>([]);

  const [openCases, setOpenCases] = useState<ICase[]>([]);
  const [openCasesModal, toggleOpenCasesModal] = useState<string | null>(null);
  const [orgId, setOrgId] = useState('');

  const organizationPrices = useAppSelector(selectOrganizationPrices);

  const fetchOrganizationOptions = async (): Promise<void> => {
    try {
      setLoadingUntillGetUserInfo(true);
      const data = await listAllOrganizations();
      setAllOrganization(data.nodes);
      if (userInfo.role.name === UserRoleName.OrganizationAdmin) {
        data.nodes = data.nodes.filter((org) => org.id === userInfo.organizations[0].id);
        const transformedData = data.nodes.map((organization: Organization) => ({
          value: organization.id,
          label: organization.name,
          id: organization.id,
        }));
        setOrganizationOptions(transformedData);
      } else {
        const transformedData = selectedPatient.organizations.map((organization: Organization) => ({
          value: organization.id,
          label: organization.name,
          id: organization.id,
        }));
        setOrganizationOptions(transformedData);
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    setLoadingUntillGetUserInfo(false);
  };

  const createCaseForThisRequirement = async (): Promise<ICase> => {
    setLoadingCalculation(true);
    const payload: CasePayload = {
      type: 'care_coordination',
      status: 'new',
      patient_id:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? selectedPatient.guardian_id === null
            ? selectedPatient?.id
            : selectedPatient.guardian_id!
          : Number(userInfo.id),
      organization_id: currentOrganization?.id || 'dentistry-one',
      provider_id: undefined,
      payment_profile_id: null,
      video_conference_date: null,
      patient_current_state:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentState[selectedPatient.id] || ''
          : currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE,
      dependent_id:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? selectedPatient.guardian_id === null
            ? null
            : Number(currentPatient?.id)
          : Number(selectedPatient?.value) !== Number(userInfo.id)
          ? Number(selectedPatient?.value)
          : null,

      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      license_states:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? [currentState[selectedPatient.id] || '']
          : [currentState[userInfo.id]],
      case_intake_questions: {
        is_dental_emergency: values.find((item) => item.form_question_id === 15)?.value.dental_emergency === 'yes',
        in_pain: values.find((item) => item.form_question_id === 16)?.value.inPain === 'yes',
        pain_description: (values.find((item) => item.form_question_id === 16)?.value.describePain as string[]) || [],
        pain_time: (values.find((item) => item.form_question_id === 16)?.value.daysInPain as string) || '',
        pain_level: (values.find((item) => item.form_question_id === 16)?.value.painLevel as string) || '',
        additional_info: (values.find((item) => item.form_question_id === 18)?.value.anythingElse as string) || '',
      },
    };
    const caseRes = await createNewCase(payload);
    setLoadingCalculation(false);
    return caseRes;
  };

  useEffect(() => {
    if (
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
    )
      fetchOrganizationOptions();
  }, []);

  const calculateCreateCaseOption = async (organizationId: string, selectedStateTest: string): Promise<void> => {
    setLoadingCalculation(true);
    try {
      let patientRequistOrganization: any;
      if (organizationId !== '') {
        patientRequistOrganization = allOrganization?.find(
          (patientOrganization: Organization) => patientOrganization.id === organizationId,
        );
      }

      const pricings = await getPricingsByAdmin(Number(selectedPatient.id), organizationId);

      dispatch(setOrganization({ currentOrganization: patientRequistOrganization! }));
      dispatch(setOrganizationPrices({ currentOrganizationPrices: pricings }));

      const caseTypeOptions: { case_type: string; amount: number }[] = [];

      pricings.forEach((selectedOrganizationPrice) => {
        const filteredCaseTypes = selectedOrganizationPrice.case_types.filter((caseType) => {
          const org = allOrganization.find(
            (selectedPatientOrganization) => selectedPatientOrganization.id === organizationId,
          );
          const pc = org?.professional_corporations?.find((selectedPC) => selectedPC.state === selectedStateTest);

          return (
            !(caseType === 'messaging' || caseType === 'video_call_instant') &&
            ((pc?.video_case_enabled && caseType.startsWith('video_call')) ||
              (pc?.async_case_enabled && caseType === 'one_report') ||
              (pc?.phone_case_enabled && caseType === 'phone_call_scheduled') ||
              (pc?.second_opinion_case_enabled && caseType === CaseType.care_coordination) ||
              (pc?.second_opinion_case_enabled && caseType === CaseType.oral_health_coaching) ||
              (pc?.second_opinion_case_enabled && caseType === 'second_opinion'))
          );
        });

        filteredCaseTypes.forEach((caseType) => {
          caseTypeOptions.push({
            case_type: caseType,
            amount: selectedOrganizationPrice.amount,
          });
        });
      });

      setCaseOptions(caseTypeOptions);
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      setLoadingCalculation(false);
    }
  };

  useEffect(() => {
    if (selectedPatient) {
      if (userInfo.role.name === UserRoleName.Admin || userInfo.role.name === UserRoleName.SuperAdmin) {
        setCustomizaUserOrganization(selectedPatient.organizations);
      } else if (userInfo.role.name === UserRoleName.OrganizationAdmin) {
        setCustomizaUserOrganization([
          selectedPatient.organizations.find(
            (superAdminOrganization: Organization) => superAdminOrganization.id === userInfo.organizations[0]?.id,
          ) || selectedPatient.organizations[0],
        ]);
      }
    }
  }, [selectedPatient]);

  const checkExternalForms = async (): Promise<string[]> => {
    let guardianResponse;
    if (selectedPatient?.guardian_id !== null) {
      guardianResponse = await getUserById(selectedPatient?.guardian_id as number);
    }

    const [checkProfile, checkMedicalHistory, checkMedicalConditionsFormAnswer, checkDentalHistoryFormAnswer] =
      await checkExternalFormAnswers(guardianResponse ?? (selectedPatient as UserProfile), selectedPatient);
    const formsToSet = [];
    if (!checkProfile) {
      formsToSet.push('patient_profile');
    }
    if (!checkMedicalHistory) {
      formsToSet.push('patient_medical_history');
    }
    if (!checkMedicalConditionsFormAnswer) {
      formsToSet.push('patient_medical_conditions');
    }
    if (!checkDentalHistoryFormAnswer) {
      formsToSet.push('patient_dental_history');
    }

    dispatch(setExternalForms(formsToSet));
    return formsToSet;
  };

  const getOpenCases = async (): Promise<IListCases> => {
    const openCaseParams = {
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      patient_id: Number(selectedPatient?.id),
      status: [
        'new',
        'accepted_care_advisor',
        'accepted_provider',
        'in_progress_provider',
        'in_progress_care_advisor',
        'completed_by_provider',
      ],
      type: [
        CaseType.one_report,
        CaseType.second_opinion,
        CaseType.video_call_scheduled,
        CaseType.phone_call_scheduled,
        CaseType.care_coordination,
      ],
      limit: 100,
      include_dependents_cases: true,
    };

    const queryStr = await Promise.all(
      Object.keys(openCaseParams).map((key) => {
        const value = openCaseParams[key as keyof typeof openCaseParams];
        if (Array.isArray(value)) {
          return value.map((v) => `${key}=${v}`).join('&');
        }
        return `${key}=${value}`;
      }),
    );

    const openCaseList = await listAllCases(queryStr.join('&'));

    await Promise.all(
      (openCaseList.nodes = openCaseList.nodes.reduce((acc: ICase[], current: ICase) => {
        if (
          (current.type === 'video_call_scheduled' || current.type === 'phone_call_scheduled') &&
          (current.status === 'in_progress_provider' || current.status === 'in_progress_care_advisor')
        ) {
          return acc;
        }
        return [...acc, current];
      }, [])),
    );
    return openCaseList;
  };

  const handleOptionClick = async (
    organizationId: string,
    option: {
      selectedCaseType: string;
      selectedCasePrice: string;
    },
    getCases = true,
  ): Promise<void> => {
    dispatch(setPatientState({ userId: selectedPatient?.id, state: selectedState }));
    if (getCases) {
      const { nodes: openCaseList } = await getOpenCases();
      if (openCaseList?.length > 0) {
        setOpenCases(openCaseList);
        toggleOpenCasesModal(option.selectedCaseType);
        setOrgId(organizationId);
        return;
      }
    }

    setLoadingUntillGetUserInfo(true);
    const currentOrganizationPrices = organizationPrices?.currentOrganizationPrices.find(
      (currentOrganizationPrise: Pricings) => currentOrganizationPrise.case_types.includes(option.selectedCaseType),
    );

    const data = await checkExternalForms();
    if (data.length !== 0 && data.includes('patient_profile')) {
      dispatch(setIsFromSelectPatient(true));
      dispatch(setDependentId(selectedPatient?.guardian_id !== null ? selectedPatient.id.toString() : null));

      if (
        option.selectedCaseType === 'video_call_instant' ||
        option.selectedCaseType === 'video_call_scheduled' ||
        option.selectedCaseType === 'one_report' ||
        option.selectedCaseType === CaseType.care_coordination ||
        option.selectedCaseType === CaseType.oral_health_coaching ||
        option.selectedCaseType === CaseType.phone_call_scheduled
      ) {
        const formPayload: FormValues[] = [
          {
            form_question_id: 20,
            value: {
              caseType: option.selectedCaseType,
            },
          },
        ];

        dispatch(setForm(formPayload));
      } else if (option.selectedCaseType === 'second_opinion') {
        const formPayload: FormValues[] = [
          {
            form_question_id: 15,
            value: {
              dental_emergency: 'no',
              sensitive_teeth: 'no',
              second_opinion: 'yes',
              improve_smile: 'no',
              improve_gum_health: 'no',
              grind_teeth_or_snore: 'no',
              something_else: 'no',
            },
          },
          {
            form_question_id: 16,
            value: {
              inPain: 'no',
              describePain: ['dull'],
              painLevel: 'noPain',
              daysInPain: '',
            },
          },
          {
            form_question_id: 20,
            value: {
              caseType: 'second_opinion',
            },
          },
        ];

        dispatch(setForm(formPayload));
      }
      if (selectedPatient.guardian_id !== null) {
        navigate(`/admin/review-profile/Dependent/${selectedPatient.id}`, {
          state: {
            caseType: option.selectedCaseType,
          },
        });
        return;
      }
      navigate(`/admin/review-profile/Patient/${selectedPatient.id}`, {
        state: {
          caseType: option.selectedCaseType,
        },
      });
      return;
    }

    const isPaymentRequired = Number(option.selectedCasePrice) > 0;

    if (
      customizeUserOrganization.length > 1 &&
      option.selectedCaseType !== '' &&
      organizationId !== '' &&
      userInfo.role.name !== UserRoleName.OrganizationAdmin
    ) {
      if (
        option.selectedCaseType === 'video_call_instant' ||
        option.selectedCaseType === 'video_call_scheduled' ||
        option.selectedCaseType === CaseType.phone_call_scheduled ||
        option.selectedCaseType === CaseType.oral_health_coaching
      ) {
        if (option.selectedCaseType === 'video_call_instant') {
          const formPayload: FormValues[] = [
            {
              form_question_id: 20,
              value: {
                caseType: 'video_call_instant',
              },
            },
          ];

          dispatch(setForm(formPayload));
          navigate(
            isPaymentRequired &&
              (currentOrganizationPrices?.amount?.toString() !== '0' ||
                currentOrganizationPrices?.collect_insurance_info)
              ? '/payment-information'
              : '/select-needs',
            {
              state: {
                caseType: 'video_call_instant',
                userOrganization: organizationId,
                patientId: selectedPatient?.id,
              },
            },
          );
        } else if (option.selectedCaseType === 'video_call_scheduled') {
          const formPayload: FormValues[] = [
            {
              form_question_id: 20,
              value: {
                caseType: 'video_call_scheduled',
              },
            },
          ];

          dispatch(setForm(formPayload));
          navigate(
            isPaymentRequired &&
              (currentOrganizationPrices?.amount?.toString() !== '0' ||
                currentOrganizationPrices?.collect_insurance_info)
              ? '/payment-information'
              : '/select-date-time',
            {
              state: {
                caseType: 'video_call_scheduled',
                userOrganization: organizationId,
                patientId: selectedPatient?.id,
              },
            },
          );
        } else if (option.selectedCaseType === CaseType.phone_call_scheduled) {
          const formPayload: FormValues[] = [
            {
              form_question_id: 20,
              value: {
                caseType: CaseType.phone_call_scheduled,
              },
            },
          ];

          dispatch(setForm(formPayload));
          navigate(
            isPaymentRequired &&
              (currentOrganizationPrices?.amount?.toString() !== '0' ||
                currentOrganizationPrices?.collect_insurance_info)
              ? '/payment-information'
              : '/select-date-time',
            {
              state: {
                caseType: CaseType.phone_call_scheduled,
                userOrganization: organizationId,
                patientId: selectedPatient?.id,
              },
            },
          );
        } else if (option.selectedCaseType === CaseType.oral_health_coaching) {
          const formPayload: FormValues[] = [
            {
              form_question_id: 20,
              value: {
                caseType: CaseType.oral_health_coaching,
              },
            },
          ];

          dispatch(setForm(formPayload));
          navigate(
            isPaymentRequired &&
              (currentOrganizationPrices?.amount?.toString() !== '0' ||
                currentOrganizationPrices?.collect_insurance_info)
              ? '/payment-information'
              : '/select-date-time',
            {
              state: {
                caseType: CaseType.oral_health_coaching,
                userOrganization: organizationId,
                patientId: selectedPatient?.id,
              },
            },
          );
        }
      } else if (option.selectedCaseType === CaseType.care_coordination) {
        const formPayload: FormValues[] = [
          {
            form_question_id: 20,
            value: {
              caseType: CaseType.care_coordination,
            },
          },
        ];

        dispatch(setForm(formPayload));
        if (!isPaymentRequired) {
          const res = await createCaseForThisRequirement();
          if (res) {
            setSubmitCCConfirmation(!submitCCConfirmation);
          }
        } else {
          navigate('/payment-information', {
            state: {
              caseType: CaseType.care_coordination,
              patientState: selectedPatient?.address?.state,
              userOrganization: organizationId,
              patientId: selectedPatient?.id,
            },
          });
        }
      } else if (option.selectedCaseType === 'one_report') {
        const formPayload: FormValues[] = [
          {
            form_question_id: 20,
            value: {
              caseType: 'one_report',
            },
          },
        ];

        dispatch(setForm(formPayload));
        navigate(
          isPaymentRequired &&
            (currentOrganizationPrices?.amount?.toString() !== '0' || currentOrganizationPrices?.collect_insurance_info)
            ? '/payment-information'
            : '/select-needs',
          {
            state: {
              caseType: 'one_report',
              patientState: selectedPatient?.address?.state,
              userOrganization: organizationId,
              patientId: selectedPatient?.id,
            },
          },
        );
      } else if (option.selectedCaseType === 'second_opinion') {
        const formPayload: FormValues[] = [
          {
            form_question_id: 15,
            value: {
              dental_emergency: 'no',
              sensitive_teeth: 'no',
              second_opinion: 'yes',
              improve_smile: 'no',
              improve_gum_health: 'no',
              grind_teeth_or_snore: 'no',
              something_else: 'no',
            },
          },
          {
            form_question_id: 16,
            value: {
              inPain: 'no',
              describePain: ['dull'],
              painLevel: 'noPain',
              daysInPain: '',
            },
          },
          {
            form_question_id: 20,
            value: {
              caseType: 'second_opinion',
            },
          },
        ];

        dispatch(setForm(formPayload));
        navigate(
          isPaymentRequired &&
            (currentOrganizationPrices?.amount?.toString() !== '0' || currentOrganizationPrices?.collect_insurance_info)
            ? '/payment-information'
            : '/upload-image',
          {
            state: {
              caseType: 'second_opinion',
              userOrganization: organizationId,
              patientId: selectedPatient?.id,
            },
          },
        );
      }
    } else if (
      customizeUserOrganization.length === 1 &&
      option.selectedCaseType !== 'back' &&
      selectedOrganization !== '' &&
      userInfo.role.name !== UserRoleName.OrganizationAdmin
    ) {
      await checkExternalForms();
      if (
        option.selectedCaseType === 'video_call_instant' ||
        option.selectedCaseType === 'video_call_scheduled' ||
        option.selectedCaseType === CaseType.phone_call_scheduled ||
        option.selectedCaseType === CaseType.oral_health_coaching
      ) {
        if (option.selectedCaseType === 'video_call_instant') {
          const formPayload: FormValues[] = [
            {
              form_question_id: 20,
              value: {
                caseType: 'video_call_instant',
              },
            },
          ];

          dispatch(setForm(formPayload));
          navigate(
            isPaymentRequired &&
              (currentOrganizationPrices?.amount?.toString() !== '0' ||
                currentOrganizationPrices?.collect_insurance_info)
              ? '/payment-information'
              : '/select-needs',
            {
              state: {
                caseType: 'video_call_instant',
                userOrganization: selectedPatient?.organizations[0].id,
                patientId: selectedPatient?.id,
              },
            },
          );
        } else if (option.selectedCaseType === 'video_call_scheduled') {
          const formPayload: FormValues[] = [
            {
              form_question_id: 20,
              value: {
                caseType: 'video_call_scheduled',
              },
            },
          ];

          dispatch(setForm(formPayload));
          navigate(
            isPaymentRequired &&
              (currentOrganizationPrices?.amount?.toString() !== '0' ||
                currentOrganizationPrices?.collect_insurance_info)
              ? '/payment-information'
              : '/select-date-time',
            {
              state: {
                caseType: 'video_call_scheduled',
                userOrganization: selectedPatient?.organizations[0].id,
                patientId: selectedPatient?.id,
              },
            },
          );
        } else if (option.selectedCaseType === CaseType.oral_health_coaching) {
          const formPayload: FormValues[] = [
            {
              form_question_id: 20,
              value: {
                caseType: CaseType.oral_health_coaching,
              },
            },
          ];

          dispatch(setForm(formPayload));
          navigate(
            isPaymentRequired &&
              (currentOrganizationPrices?.amount?.toString() !== '0' ||
                currentOrganizationPrices?.collect_insurance_info)
              ? '/payment-information'
              : '/select-date-time',
            {
              state: {
                caseType: CaseType.oral_health_coaching,
                userOrganization: organizationId,
                patientId: selectedPatient?.id,
              },
            },
          );
        } else {
          const formPayload: FormValues[] = [
            {
              form_question_id: 20,
              value: {
                caseType: CaseType.phone_call_scheduled,
              },
            },
          ];

          dispatch(setForm(formPayload));
          navigate(
            isPaymentRequired &&
              (currentOrganizationPrices?.amount?.toString() !== '0' ||
                currentOrganizationPrices?.collect_insurance_info)
              ? '/payment-information'
              : '/select-date-time',
            {
              state: {
                caseType: CaseType.phone_call_scheduled,
                userOrganization: selectedPatient?.organizations[0].id,
                patientId: selectedPatient?.id,
              },
            },
          );
        }
      } else if (option.selectedCaseType === CaseType.care_coordination) {
        const formPayload: FormValues[] = [
          {
            form_question_id: 20,
            value: {
              caseType: CaseType.care_coordination,
            },
          },
        ];

        dispatch(setForm(formPayload));
        if (!isPaymentRequired) {
          const res = await createCaseForThisRequirement();
          if (res) {
            setSubmitCCConfirmation(!submitCCConfirmation);
          }
        } else {
          navigate('/payment-information', {
            state: {
              caseType: CaseType.care_coordination,
              patientState: selectedPatient?.address?.state,
              userOrganization: organizationId,
              patientId: selectedPatient?.id,
            },
          });
        }
      } else if (option.selectedCaseType === 'one_report') {
        const formPayload: FormValues[] = [
          {
            form_question_id: 20,
            value: {
              caseType: 'one_report',
            },
          },
        ];

        dispatch(setForm(formPayload));
        navigate(
          isPaymentRequired &&
            (currentOrganizationPrices?.amount?.toString() !== '0' || currentOrganizationPrices?.collect_insurance_info)
            ? '/payment-information'
            : '/select-needs',
          {
            state: {
              caseType: 'one_report',
              patientState: selectedPatient?.address?.state,
              userOrganization: selectedPatient?.organizations[0].id,
              patientId: selectedPatient?.id,
            },
          },
        );
      } else if (option.selectedCaseType === 'second_opinion') {
        const formPayload: FormValues[] = [
          {
            form_question_id: 15,
            value: {
              dental_emergency: 'no',
              sensitive_teeth: 'no',
              second_opinion: 'yes',
              improve_smile: 'no',
              improve_gum_health: 'no',
              grind_teeth_or_snore: 'no',
              something_else: 'no',
            },
          },
          {
            form_question_id: 16,
            value: {
              inPain: 'no',
              describePain: ['dull'],
              painLevel: 'noPain',
              daysInPain: '',
            },
          },
          {
            form_question_id: 20,
            value: {
              caseType: 'second_opinion',
            },
          },
        ];

        dispatch(setForm(formPayload));
        navigate(
          isPaymentRequired &&
            (currentOrganizationPrices?.amount?.toString() !== '0' || currentOrganizationPrices?.collect_insurance_info)
            ? '/payment-information'
            : '/upload-image',
          {
            state: {
              caseType: 'second_opinion',
              userOrganization: selectedPatient?.organizations[0].id,
              patientId: selectedPatient?.id,
            },
          },
        );
      }
    } else if (userInfo.role.name === UserRoleName.OrganizationAdmin || customizeUserOrganization.length === 1) {
      await checkExternalForms();
      if (
        option.selectedCaseType === 'video_call_instant' ||
        option.selectedCaseType === 'video_call_scheduled' ||
        option.selectedCaseType === CaseType.phone_call_scheduled ||
        option.selectedCaseType === CaseType.oral_health_coaching
      ) {
        if (option.selectedCaseType === 'video_call_instant') {
          const formPayload: FormValues[] = [
            {
              form_question_id: 20,
              value: {
                caseType: 'video_call_instant',
              },
            },
          ];

          dispatch(setForm(formPayload));
          navigate(
            isPaymentRequired &&
              (currentOrganizationPrices?.amount?.toString() !== '0' ||
                currentOrganizationPrices?.collect_insurance_info)
              ? '/payment-information'
              : '/select-needs',
            {
              state: {
                caseType: 'video_call_instant',
                userOrganization: userInfo.organizations[0].id,
                patientId: selectedPatient?.id,
              },
            },
          );
        } else if (option.selectedCaseType === 'video_call_scheduled') {
          const formPayload: FormValues[] = [
            {
              form_question_id: 20,
              value: {
                caseType: 'video_call_scheduled',
              },
            },
          ];

          dispatch(setForm(formPayload));
          navigate(
            isPaymentRequired &&
              (currentOrganizationPrices?.amount?.toString() !== '0' ||
                currentOrganizationPrices?.collect_insurance_info)
              ? '/payment-information'
              : '/select-date-time',
            {
              state: {
                caseType: 'video_call_scheduled',
                userOrganization: userInfo.organizations[0].id,
                patientId: selectedPatient?.id,
              },
            },
          );
        } else if (option.selectedCaseType === CaseType.oral_health_coaching) {
          const formPayload: FormValues[] = [
            {
              form_question_id: 20,
              value: {
                caseType: CaseType.oral_health_coaching,
              },
            },
          ];

          dispatch(setForm(formPayload));
          navigate(
            isPaymentRequired &&
              (currentOrganizationPrices?.amount?.toString() !== '0' ||
                currentOrganizationPrices?.collect_insurance_info)
              ? '/payment-information'
              : '/select-date-time',
            {
              state: {
                caseType: CaseType.oral_health_coaching,
                userOrganization: organizationId,
                patientId: selectedPatient?.id,
              },
            },
          );
        } else {
          const formPayload: FormValues[] = [
            {
              form_question_id: 20,
              value: {
                caseType: CaseType.phone_call_scheduled,
              },
            },
          ];

          dispatch(setForm(formPayload));
          navigate(
            isPaymentRequired &&
              (currentOrganizationPrices?.amount?.toString() !== '0' ||
                currentOrganizationPrices?.collect_insurance_info)
              ? '/payment-information'
              : '/select-date-time',
            {
              state: {
                caseType: CaseType.phone_call_scheduled,
                userOrganization: userInfo.organizations[0].id,
                patientId: selectedPatient?.id,
              },
            },
          );
        }
      } else if (option.selectedCaseType === CaseType.care_coordination) {
        const formPayload: FormValues[] = [
          {
            form_question_id: 20,
            value: {
              caseType: CaseType.care_coordination,
            },
          },
        ];

        dispatch(setForm(formPayload));
        if (!isPaymentRequired) {
          const res = await createCaseForThisRequirement();
          if (res) {
            setSubmitCCConfirmation(!submitCCConfirmation);
          }
        } else {
          navigate('/payment-information', {
            state: {
              caseType: CaseType.care_coordination,
              patientState: selectedPatient?.address?.state,
              userOrganization: organizationId,
              patientId: selectedPatient?.id,
            },
          });
        }
      } else if (option.selectedCaseType === 'one_report') {
        const formPayload: FormValues[] = [
          {
            form_question_id: 20,
            value: {
              caseType: 'one_report',
            },
          },
        ];

        dispatch(setForm(formPayload));
        navigate(
          isPaymentRequired &&
            (currentOrganizationPrices?.amount?.toString() !== '0' || currentOrganizationPrices?.collect_insurance_info)
            ? '/payment-information'
            : '/select-needs',
          {
            state: {
              caseType: 'one_report',
              patientState: selectedPatient?.address?.state,
              userOrganization: userInfo.organizations[0].id,
              patientId: selectedPatient?.id,
            },
          },
        );
      } else if (option.selectedCaseType === 'second_opinion') {
        const formPayload: FormValues[] = [
          {
            form_question_id: 15,
            value: {
              dental_emergency: 'no',
              sensitive_teeth: 'no',
              second_opinion: 'yes',
              improve_smile: 'no',
              improve_gum_health: 'no',
              grind_teeth_or_snore: 'no',
              something_else: 'no',
            },
          },
          {
            form_question_id: 16,
            value: {
              inPain: 'no',
              describePain: ['dull'],
              painLevel: 'noPain',
              daysInPain: '',
            },
          },
          {
            form_question_id: 20,
            value: {
              caseType: option.selectedCaseType,
            },
          },
        ];

        dispatch(setForm(formPayload));
        navigate(
          isPaymentRequired &&
            (currentOrganizationPrices?.amount?.toString() !== '0' || currentOrganizationPrices?.collect_insurance_info)
            ? '/payment-information'
            : '/upload-image',
          {
            state: {
              caseType: 'second_opinion',
              userOrganization: userInfo.organizations[0].id,
              patientId: selectedPatient?.id,
            },
          },
        );
      }
    }
    setLoadingUntillGetUserInfo(false);
  };

  if (loadingUntillGetUserInfo) {
    return <Loading fullScreen />;
  }

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: 'center',
        overflow: 'hidden',
      }}
      eclipseStyle={{ opacity: 0.2 }}
      childrenStyle={{ zIndex: '2', paddingTop: '40px' }}
    >
      <div className={styles.createConsultationForContainer}>
        <div className={styles.createConsultationFor}>
          <h1>Create a Consultation for</h1>
          <span className={styles.backSpan}>
            <button
              type="button"
              className={styles.backButton}
              onClick={() => {
                navigate(`/admin/review-profile/${selectedPatient.role.name}/${selectedPatient?.id}`);
              }}
            >
              <MdKeyboardArrowLeft />
              Back
            </button>
          </span>
          <span className={styles.br} />
          <div className={styles.patientInfo}>
            <div className={styles.leftDiv}>
              <span>
                <img
                  alt="Avatar"
                  onError={(event: any) => {
                    const img = event.target;
                    img.src =
                      'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                    img.onError = null;
                  }}
                  className={styles.caImage}
                  src={getUserAvatar(Number(selectedPatient?.id))}
                />
              </span>
              <span className={styles.patientInfoName}>
                <span className={styles.title}>Name</span>
                <span className={styles.value}>{selectedPatient?.full_name}</span>
              </span>
            </div>
            <div className={styles.rightDiv}>
              <span className={styles.patientInfoName}>
                <span className={styles.title}>Address</span>
                <span className={styles.value}>
                  {selectedPatient?.address?.address1}, {selectedPatient?.address?.city},{' '}
                  {selectedPatient?.address?.state} {selectedPatient?.address?.zip_code}
                </span>
              </span>
            </div>
          </div>
          <div className={styles.selectContainerDiv}>
            <div>
              <div className={styles.caption}>Select patient state at time of consultation</div>
              <SelectInput
                name="state"
                options={stateOptions}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesSlim}
                selectedValue={selectedState}
                onChange={(value) => {
                  setSelectedState(value?.value);
                  if (selectedOrganization !== '') {
                    calculateCreateCaseOption(selectedOrganization, value?.value);
                  }
                }}
              />
            </div>
            <div>
              <div className={styles.caption}>Select an Organization</div>
              <SelectInput
                name="organizations"
                options={organizationOptions}
                containerClass={styles.inputWrapper}
                selectorClass={styles.selector}
                style={selectCommonCustomStylesSlim}
                selectedValue={selectedOrganization}
                onChange={(value) => {
                  setSelectedOrganization(value?.value);
                  calculateCreateCaseOption(value?.value, selectedState as string);
                }}
              />
            </div>
          </div>
          {loadingCalculation ? (
            <span className={styles.loadingSpan}>
              <Loading dotted />
            </span>
          ) : selectedState !== '' && selectedOrganization !== '' && caseOptions && caseOptions.length > 0 ? (
            <div className={styles.casesTypeContainer}>
              <div className={styles.caption}>Choose a consultation type</div>
              <div className={styles.caseSpan}>
                {caseOptions.map(({ case_type, amount }, index: number) => {
                  const priceInDollars = amount / 100;
                  const formattedPrice =
                    priceInDollars === 0
                      ? ''
                      : priceInDollars % 1 === 0
                      ? `$${priceInDollars.toFixed(0)}`
                      : `$${priceInDollars.toFixed(2)}`;

                  return (
                    case_type !== '' && (
                      <span
                        key={index}
                        onClick={() =>
                          setSelectedCase({
                            selectedCaseType: case_type,
                            selectedCasePrice: priceInDollars.toString(),
                          })
                        }
                        className={case_type === selectedCase.selectedCaseType ? styles.selected : ''}
                      >
                        {getFriendlyName(case_type)}
                        {formattedPrice ? ` - ${formattedPrice}` : ''}
                      </span>
                    )
                  );
                })}
              </div>
            </div>
          ) : selectedState !== '' && selectedOrganization !== '' && caseOptions && caseOptions.length === 0 ? (
            <div className={styles.casesTypeContainer}>
              <div className={styles.caption}>No consultation found</div>
            </div>
          ) : null}
          <div className={styles.btnContainer}>
            <Button
              type="button"
              disabled={
                selectedOrganization === '' ||
                selectedCase.selectedCaseType === '' ||
                loadingCalculation ||
                selectedState === '' ||
                caseOptions.length === 0 ||
                loadingUntillGetUserInfo
              }
              onClick={() => handleOptionClick(selectedOrganization, selectedCase)}
            >
              {!(selectedCase.selectedCaseType === CaseType.care_coordination && selectedCase.selectedCasePrice === '0')
                ? 'Next'
                : 'Finish'}
            </Button>
          </div>
        </div>
      </div>
      <OpenCaseListModal
        isOpen={openCasesModal}
        toggleModal={toggleOpenCasesModal}
        openCases={openCases}
        orgId={orgId}
        selectedCase={selectedCase}
        handleOptionClick={handleOptionClick}
      />
      {submitCCConfirmation && <SubmitCCConfirmationModal />}
    </Container>
  );
};

export default CreateConsultationFor;
