import Button from '@brands/Components/Button/Button';
import Container from '@brands/Components/Container/Container';
import SelectInput from '@brands/Components/Inputs/SelectInput/SelectInput';
import TextField from '@brands/Components/Inputs/TextField/TextField';
import { Spinner } from '@brands/Components/LoadingSpinner/Spinner';
import ProgressBar from '@brands/Components/ProgressBar/ProgressBar';
import { config } from '@brands/config/config';
import { updateDataPayment } from '@brands/Dashboard/MyProfile/Patient/utils/utilsFunctions';
import { useAppDispatch, useAppSelector } from '@brands/hooks/useReduxHook';
import useWindowSize from '@brands/hooks/useWindowSize';
import { CaseType, Pricing } from '@brands/services/cases/types/ICase';
import { addPaymentProfile } from '@brands/services/identity/addPaymentProfile';
import { getDependentById } from '@brands/services/identity/getDependentById';
import { PaymentProfile } from '@brands/services/identity/types/PaymentProfile';
import { Address, Insurance, UserRoleName } from '@brands/services/identity/types/UserProfile';
import { verifyInsurance } from '@brands/services/identity/verifyInsurance';
import { verifyInsuranceByAdmin } from '@brands/services/identity/verifyInsuranceByAdmin';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectOrganizationPrices } from '@brands/store/selectors/organizationPrices';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setForm } from '@brands/store/slices/patientFormSlice';
import { profileSelectStyles } from '@brands/Utils/customStyles';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { getSecondaryColor } from '@brands/Utils/getPrimaryColor';
import { stateOptions } from '@brands/Utils/selectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { isMobile as mobileDetect } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PatientNotCoveredInsuranceSubmit } from '../utils/types';
import styles from './styles.module.scss';
import { validationSchema } from './validationSchema';

const { locationId } = config.square;
const appId = config.square.applicationId;
type Card = {
  attach: (containerId: string) => Promise<any>;
  tokenize: () => Promise<{
    token: string;
    status: string;
    errors?: JSON;
    details?: {
      card: any;
      method: string;
      billing: any;
    };
  }>;
  status: number;
  addEventListener: (eventName: string, callback: () => void) => void;
};

const VerifyInsurance: React.FC = () => {
  const dispatch = useAppDispatch();
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const navigate = useNavigate();
  const [card, setCard] = useState<Card>();
  const organizationPrices = useAppSelector(selectOrganizationPrices);
  const { userInfo } = useAppSelector(selectAuth);
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const { selectedPatient, values } = useAppSelector(selectPatientForm);
  const currentOrganizationPrices = organizationPrices?.currentOrganizationPrices;
  const { currentOrganization } = useAppSelector(selectOrganization);
  const [loading, setLoading] = useState(false);
  const [verifyResult, setVerifyResult] = useState('');
  const [disablePaymentButton, setDisablePaymentButton] = useState(false);
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [dependentAddress, setDependentAddress] = useState<Address>(
    userInfo.role.name === UserRoleName.Patient
      ? userInfo.address
      : (userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin) &&
          currentPatient.address,
  );
  const dataWithUpdatedInsurance = values.find((item) => item.form_question_id === 19)?.value
    ?.dataWithUpdatedInsurance as Insurance;

  useEffect(() => {
    const fetchDependentAddress = async (): Promise<void> => {
      if (
        (userInfo.role.name === UserRoleName.Patient && userInfo.id !== Number(selectedPatient?.value)) ||
        ((userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin) &&
          currentPatient.guardian_id !== null)
      ) {
        const dependent = await getDependentById(
          userInfo.role.name === UserRoleName.Patient
            ? Number(userInfo.id)
            : currentPatient.guardian_id !== null
            ? currentPatient.guardian_id
            : currentPatient.id,
          Number(selectedPatient?.value),
        );
        setDependentAddress(dependent.address);
      }
    };

    if (selectedPatient?.value && userInfo.id !== selectedPatient?.value) fetchDependentAddress();
  }, [selectedPatient]);

  const squareDiv = document.getElementById('card-container');

  const initSquare = async (): Promise<void> => {
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }
    const payments = window.Square.payments(appId, locationId);
    try {
      const paymentCard = await payments.card();
      if (squareDiv?.childNodes.length === 0) {
        await paymentCard.attach('#card-container');
      }
      setCard(paymentCard);
      paymentCard.addEventListener('errorClassAdded', () => {
        setDisablePaymentButton(true);
      });
      paymentCard.addEventListener('errorClassRemoved', () => {
        setDisablePaymentButton(false);
      });
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    (async (): Promise<void> => {
      await initSquare();
      setPaymentStatus('');
    })();
  }, [squareDiv, loading]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<PatientNotCoveredInsuranceSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(isSameAddress, verifyResult)),
  });

  const displayLoadingContent = (): JSX.Element => {
    return (
      <div className={styles.verifyTitle}>
        <div className={`fs-unmask ${styles.spanContainer}`}>
          <span className={styles.customSpan}>One moment while</span>
          <span className={styles.customSpan}>we check your Insurance</span>
        </div>
        <Spinner />
      </div>
    );
  };

  const displayCoveredContent = (): JSX.Element => {
    return (
      <div className={styles.verifyTitle}>
        <ProgressBar currentStep={0} totalSteps={0} hideStepNumbers />
        <div className={styles.coveredContainer}>
          <div className={styles.coverageText}>
            Great News! It looks like your dental plan likely covers the service you selected! We will contact you later
            if a copay or deductible leads to a balance due.
          </div>
        </div>
      </div>
    );
  };

  const displayNotCoveredContent = (): JSX.Element => {
    return (
      <div className={styles.verifyTitle}>
        <span className={styles.customSpan}>Not Covered</span>
        <ProgressBar currentStep={0} totalSteps={0} hideStepNumbers />
        <div className={styles.coveredContainer}>
          <span className={`fs-unmask ${styles.priceSpan}`}>
            {' '}
            <span className={styles.dollarSign}>$</span>
            {(() => {
              const priceInCents = currentOrganizationPrices.find((price: Pricing) =>
                price.case_types.includes(
                  values.find((item) => item.form_question_id === 20)?.value.caseType as string,
                ),
              )?.amount;

              if (priceInCents === undefined) {
                return null;
              }

              const priceInDollars = priceInCents / 100;
              return priceInDollars === 0
                ? 'Free'
                : priceInDollars % 1 === 0
                ? `${priceInDollars.toFixed(0)}`
                : `${priceInDollars.toFixed(2)}`;
            })()}
          </span>
          <div className={styles.coverageText}>
            We’re unable to confirm your insurance coverage at this time. Your plan may not include this service, so we
            recommend checking with your insurance company for details. If you’d like to proceed with a virtual visit,
            please enter your payment information below to continue.
          </div>
        </div>
        <div className={styles.creditCatdInfo}>
          <div className={`row ${styles.row}`}>
            <div className="col-md-12">
              <div className={styles.inputWrap}>
                <div>
                  <span className="fs-unmask">Name on Card</span>
                  <span className={styles.redText}> *</span>
                </div>
                <TextField
                  {...register('creditCardFullName')}
                  errors={errors}
                  name="creditCardFullName"
                  value={getValues('creditCardFullName')}
                  onChange={(e) => {
                    setValue('creditCardFullName', e.target.value, { shouldValidate: true });
                  }}
                  wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                  wrapperClassName="fs-unmask"
                  inputClass={styles.profileInputClass}
                  isUnderlined={false}
                />
              </div>
            </div>
          </div>
          <div className={`row ${styles.creditCardContainer} p-row`}>
            <div>
              <span
                style={{
                  color: getSecondaryColor(),
                  opacity: '0.5',
                  fontSize: '16px',
                }}
                className="fs-unmask"
              >
                Credit Card Information
              </span>
              <span style={{ color: '#FA5151' }}> *</span>
            </div>
            <div id="form-container">
              <div id="card-container" />
              <span> {paymentStatus} </span>
              <div className={`${styles.invalidFeedback} invalid-feedback`}>{errors.creditCardNumber?.message}</div>
            </div>
          </div>
          <span className={`fs-unmask ${styles.subTitle}`}>Billing Address</span>
          <hr />
          <div className={styles.billingAddressCheck}>
            <input
              className="form-check-input fs-unmask"
              type="checkbox"
              id="isAdditionalInsurance"
              checked={isSameAddress}
              onChange={(event) => {
                setIsSameAddress(event.target.checked);
              }}
            />
            <label
              className={`form-check-label fs-unmask ${styles.billingAddressCheckLabel}`}
              htmlFor="isAdditionalInsurance"
            >
              Check here if your billing address is different than your home address
            </label>
          </div>
          {isSameAddress ? (
            <>
              <div className={`row ${styles.row}`}>
                <div className="col-md-12">
                  <div className={styles.inputWrap}>
                    <div>
                      <span className="fs-unmask">Street Address</span>
                      <span className={styles.redText}> *</span>
                    </div>
                    <TextField
                      {...register('creditCardAddress1')}
                      errors={errors}
                      name="creditCardAddress1"
                      value={getValues('creditCardAddress1')}
                      onChange={(e) => {
                        setValue('creditCardAddress1', e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                      wrapperClassName="fs-exclude"
                      inputClass={styles.profileInputClass}
                      isUnderlined={false}
                    />
                  </div>
                </div>
              </div>
              <div className={`row ${styles.row}`}>
                <div className="col-md-12">
                  <div className={styles.inputWrap}>
                    <span className="fs-unmask">Apt, suite, building (optional)</span>
                    <TextField
                      {...register('creditCardAddress2')}
                      errors={errors}
                      name="creditCardAddress2"
                      value={getValues('creditCardAddress2')}
                      onChange={(e) => {
                        setValue('creditCardAddress2', e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                      wrapperClassName="fs-exclude"
                      inputClass={styles.profileInputClass}
                      isUnderlined={false}
                    />
                  </div>
                </div>
              </div>
              <div className={`row ${styles.row}`}>
                <div className="col-md-4">
                  <div className={styles.inputWrap}>
                    <div>
                      <span className="fs-unmask">City</span>
                      <span className={styles.redText}> *</span>
                    </div>
                    <TextField
                      {...register('creditCardCity')}
                      errors={errors}
                      name="creditCardCity"
                      value={getValues('creditCardCity')}
                      onChange={(e) => {
                        setValue('creditCardCity', e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                      wrapperClassName="fs-exclude"
                      inputClass={styles.profileInputClass}
                      isUnderlined={false}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.inputWrap} data-validate="Choose State">
                    <div>
                      <span className="fs-unmask">State</span>
                      <span className={styles.redText}> *</span>
                    </div>
                    <SelectInput
                      {...register('creditCardState')}
                      containerClass="mt-0 fs-exclude"
                      name="creditCardState"
                      options={stateOptions}
                      style={profileSelectStyles}
                      errors={errors}
                      selectedValue={getValues('creditCardState')}
                      onChange={(value) => {
                        setValue('creditCardState', value?.value as string, {
                          shouldValidate: true,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.inputWrap}>
                    <div>
                      <span className="fs-unmask">Zip Code</span>
                      <span className={styles.redText}> *</span>
                    </div>
                    <TextField
                      {...register('creditCardZipCode')}
                      errors={errors}
                      name="creditCardZipCode"
                      value={getValues('creditCardZipCode')}
                      onChange={(e) => {
                        setValue('creditCardZipCode', e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      wrapperStyle={{ marginTop: '0', height: '100%', borderBottom: 'none' }}
                      wrapperClassName="fs-exclude"
                      inputClass={styles.profileInputClass}
                      isUnderlined={false}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <span className="fs-exclude">
              {userInfo.id === Number(selectedPatient?.value)
                ? `${userInfo?.address?.address1} ${userInfo?.address?.city} ${userInfo?.address?.state} ${userInfo?.address?.zip_code}`
                : `${dependentAddress?.address1} ${dependentAddress?.city} ${dependentAddress?.state} ${dependentAddress?.zip_code}`}
            </span>
          )}
        </div>
      </div>
    );
  };

  const onInvalid = (e: any): void => {
    const error = Object.values(e)[0] as any;
    if (error) {
      toast.error(error.message as string, { className: 'fs-unmask' });
    }
  };

  const testInsuranceVerification = async (): Promise<void> => {
    setLoading(true);
    try {
      if (userInfo.role.name === UserRoleName.Patient) {
        await verifyInsurance({
          payer_id: dataWithUpdatedInsurance.insurance_membership?.insurance_carrier_id?.toString() as string,
          member_id: dataWithUpdatedInsurance.insurance_membership?.member_id as string,
          group_number: dataWithUpdatedInsurance.insurance_membership?.group_number as string,
          insured_by: dataWithUpdatedInsurance.insured_by,
          user_id: currentPatient.id,
        });
      } else {
        await verifyInsuranceByAdmin({
          user_id: currentPatient.guardian_id !== null ? currentPatient.guardian_id : currentPatient.id,
          payer_id: dataWithUpdatedInsurance.insurance_membership?.insurance_carrier_id?.toString() as string,
          member_id: dataWithUpdatedInsurance.insurance_membership?.member_id as string,
          group_number: dataWithUpdatedInsurance.insurance_membership?.group_number as string,
          insured_by: dataWithUpdatedInsurance.insured_by,
        });
      }
      setVerifyResult('Success');
    } catch (e) {
      setVerifyResult('Failed');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    testInsuranceVerification();
  }, []);

  const onSubmit = async (data: PatientNotCoveredInsuranceSubmit): Promise<void> => {
    setDisablePaymentButton(true);
    if (verifyResult === 'Failed' && card) {
      try {
        const tokenResult = await card.tokenize();
        if (tokenResult.status === 'OK') {
          const dataWithUpdatedPayment = updateDataPayment(
            data,
            tokenResult,
            userInfo,
            isSameAddress,
            dependentAddress,
            Number(currentPatient?.id),
            Number(currentPatient.role.name === 'Dependent' ? currentPatient?.guardian_id : currentPatient?.id),
          );
          const res = await addPaymentProfile(dataWithUpdatedPayment as PaymentProfile);
          const paymentId = res.id!;
          const payload = [...values!];
          const filteredPayload = payload.filter((item) => item.form_question_id !== 19);
          filteredPayload.push({
            form_question_id: 19,
            value: {
              paymentId: paymentId ?? '',
              insuranceId: '',
              secondaryInsuranceId: '',
              addCC: false,
              addSecondaryInsurance: false,
              dataWithUpdatedInsurance: undefined,
            },
          });
          dispatch(setForm(filteredPayload));
        } else {
          toast.error('Please Enter your Credit Card information correctly', { className: 'fs-unmask' });
          return;
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
        return;
      } finally {
        setDisablePaymentButton(false);
      }
    }
    if (
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'video_call_scheduled' ||
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
        CaseType.phone_call_scheduled ||
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === CaseType.oral_health_coaching
    ) {
      navigate('/select-date-time', {
        state: {
          caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string as string,
          insuranceCarrierId: dataWithUpdatedInsurance.insurance_membership?.insurance_carrier_id,
          customInsuranceCarrier: '',
          userOrganization: currentOrganization.id,
          patientId: userInfo.id,
        },
      });
    } else if (
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'video_call_instant' ||
      (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'one_report'
    ) {
      navigate('/select-needs', {
        state: {
          caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string,
          patientState: userInfo.address?.state,
          userOrganization: currentOrganization.id,
          patientId: userInfo.id,
        },
      });
    } else if ((values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'second_opinion') {
      navigate('/upload-image', {
        state: {
          caseType: values.find((item) => item.form_question_id === 20)?.value.caseType as string,
          patientState: userInfo.address?.state,
          userOrganization: currentOrganization.id,
          patientId: userInfo.id,
        },
      });
    }
    setDisablePaymentButton(false);
  };

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2' }}
    >
      <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <div className={styles.verifyInsuranceFormContainer}>
          {verifyResult !== 'Success' && verifyResult !== 'Failed' && loading && displayLoadingContent()}
          {verifyResult === 'Success' && displayCoveredContent()}
          {verifyResult === 'Failed' && displayNotCoveredContent()}
          {!loading && (
            <div className={styles.footer}>
              <Button
                type="submit"
                className={`fs-unmask ${styles.btn}`}
                disabled={loading || disablePaymentButton}
                onTouchStart={isMobile || mobileDetect ? handleSubmit(onSubmit, onInvalid) : undefined}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      </form>
    </Container>
  );
};

export default VerifyInsurance;
