/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import MultiselectInput from '@brands/Components/Inputs/MultiselectInput/MultiselectInput';
import ScheduleLiveConsultation from '@brands/Dashboard/Dashboard/DashboardModals/ScheduleLiveConsultation';
import { defaultICaseValues } from '@brands/services/cases/types/defaultICaseValues';
import { CaseType, ICase } from '@brands/services/cases/types/ICase';
import { getPricings, Pricings } from '@brands/services/identity/getPricings';
import { Insurance, UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectOrganizationPrices } from '@brands/store/selectors/organizationPrices';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setOrganizationPrices } from '@brands/store/slices/organizationPricingSlice';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { DEFAULT_PATIENT_LOCATION_STATE } from '@brands/Utils/localStorage';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Collapsible from 'react-collapsible';
import { useForm } from 'react-hook-form';
import { BsFilter } from 'react-icons/bs';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';

import Calendar from '../../../../Components/Calendar/Calendar';
import Container from '../../../../Components/Container/Container';
import { Loading } from '../../../../Components/LoadingSpinner/Loading';
import Radio from '../../../../Components/Radio/Radio';
import { useInsuranceCarriers } from '../../../../hooks';
import useAvailableProviders from '../../../../hooks/useAvailableProviders';
import useDebounce from '../../../../hooks/useDebounce';
import { useAppSelector } from '../../../../hooks/useReduxHook';
import useWindowSize from '../../../../hooks/useWindowSize';
import { listAllLanguages } from '../../../../services/identity/listAllLanguages';
import { IListAvailableProviderParams } from '../../../../services/identity/listAvailableProvidersDate';
import { multiSelectCommonWithCommaCustomStylesLargeSelectDateAndTime } from '../../../../Utils/customStyles';
import { Option, OptionLanguage } from '../../../../Utils/selectOptions';
import DisplaySelectedDates from '../utils/DisplaySelectedDate/DisplaySelectedDats';
import ProviderAvailabilityCard from '../utils/ProviderAvailabilityCard/ProviderAvailabilityCard';
import { SelectedAppointment } from '../utils/types';
import styles from './styles.module.scss';
import { ScheduleAppointmentFormSubmit } from './utils/types';
import { validationSchema } from './utils/validationSchema';

const ScheduleAppointment = (): JSX.Element => {
  const { state } = useLocation();

  const { userInfo } = useAppSelector(selectAuth);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { values, selectedPatient } = useAppSelector(selectPatientForm);
  const [loading, setLoading] = useState(false);
  const organizationPrices = useAppSelector(selectOrganizationPrices);
  const RescheduleCase = state?.RescheduleCase ? state.RescheduleCase : searchParams.get('reschedule') || null;
  const [rescheduleSuccessModal, setRescheduleSuccessModal] = useState<{
    openModal: boolean;
    updatedCase: ICase | null;
  }>({
    openModal: false,
    updatedCase: null,
  });
  const [scheduleModal, setScheduleModal] = useState<{
    openModal: boolean;
    currentCase: ICase;
  }>({
    openModal: false,
    currentCase: defaultICaseValues,
  });
  const selectedInsuranceId = (values?.find((item: any) => item.form_question_id === 19)?.value
    .dataWithUpdatedInsurance as Insurance)
    ? (values?.find((item: any) => item.form_question_id === 19)?.value.dataWithUpdatedInsurance as Insurance)
        .insurance_membership?.insurance_carrier_id
    : '';
  const insuranceCarriers = useInsuranceCarriers();
  const [formattedDate, setFormattedDate] = useState<string>('');
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<SelectedAppointment>({
    selectedTimeSlot: '',
    selectedProviderId: undefined,
  });
  const { currentOrganization } = useAppSelector(selectOrganization);
  const isPaymentRequired = currentOrganization.payment_options.length > 0;
  const currentOrganizationPrices = organizationPrices?.currentOrganizationPrices?.find(
    (currentOrganizationPrise: Pricings) =>
      currentOrganizationPrise.case_types.includes(
        RescheduleCase
          ? state.caseType
          : (values.find((item) => item.form_question_id === 20)?.value.caseType as string),
      ),
  );
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 970;
  const navigate = useNavigate();
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const [languageOptions, setLanguageOptions] = useState<OptionLanguage[]>([]);
  const [languageFilter, setLanguageFilter] = useState<MultiValue<Option>>([]);
  const [providersNetwork, selectProvidersNetwork] = useState<string>('InAndOut');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();
  const lastProviderItemRef = useRef<HTMLTableRowElement>(null);
  const currentState: string = useAppSelector(selectCurrentPatientState);
  const storedLocationState =
    userInfo.role.name === UserRoleName.Admin ||
    userInfo.role.name === UserRoleName.SuperAdmin ||
    userInfo.role.name === UserRoleName.OrganizationAdmin
      ? currentState[currentPatient.id] || ''
      : currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE;

  const RescheduleCaseSelectedCarrierId = state?.RescheduleCaseSelectedCarrierId || null;
  const patient_current_state = state?.patient_current_state
    ? state?.patient_current_state
    : searchParams.get('case_state') || null;

  // Initialize the state with the parsed URL parameters
  const [params, setParams] = useState<IListAvailableProviderParams>({
    date: '',
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    license_states: [
      RescheduleCase && patient_current_state
        ? patient_current_state
        : userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
        ? currentState[currentPatient.id] || ''
        : currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE,
    ],

    synchronous: true,
    ...(selectedInsuranceId !== '' && selectedInsuranceId !== undefined
      ? { carrier_id: selectedInsuranceId }
      : RescheduleCaseSelectedCarrierId && RescheduleCase
      ? { carrier_id: RescheduleCaseSelectedCarrierId }
      : {}),
    out_of_network: true,
    patient_id: selectedPatient?.value,
  });
  const debouncedParams = useDebounce(params, 500);
  const { providers, loadNextPage, dataLoading, refreshProviders } = useAvailableProviders(
    (RescheduleCase
      ? state.caseType
      : (values.find((item) => item.form_question_id === 20)?.value.caseType as string)) ===
      CaseType.oral_health_coaching
      ? 'care_advisors'
      : 'providers',
    debouncedParams,
  );

  const loadMore = (): void => {
    setIsLoading(true);
    loadNextPage().then(() => {
      setIsLoading(false);
    });
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
    const lastItem = entries[0];
    if (lastItem.isIntersecting && !isLoading) {
      loadMore();
    }
  };

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(handleIntersection);
    if (lastProviderItemRef.current) observer.current.observe(lastProviderItemRef.current);
  }, [providers]);

  const sortedProvidersWithTimeSlots = useMemo(() => {
    let nextAvailableSet = false;
    let nearestStartTimeIndex = -1;
    let nearestStartTimeDiff = Infinity;

    // Step 1: Sort providers by the number of `sync_time_slots` (from most to least)
    const sortedProviders = [...providers].sort((a, b) => b.sync_time_slots.length - a.sync_time_slots.length);

    // Step 2: Find the provider with the nearest future time slot
    sortedProviders.forEach((provider, index) => {
      if (provider.sync_time_slots.length > 0) {
        const firstSlotStartTime = provider.sync_time_slots[0]; // Earliest slot of the provider
        const startTimeDiff = moment(firstSlotStartTime).diff(moment());

        if (startTimeDiff < nearestStartTimeDiff && startTimeDiff > 0) {
          nearestStartTimeIndex = index;
          nearestStartTimeDiff = startTimeDiff;
        }
      }
    });

    // Step 3: Move the provider with the nearest future time slot to the front
    if (nearestStartTimeIndex !== -1) {
      const nearestStartTimeProvider = sortedProviders.splice(nearestStartTimeIndex, 1)[0];
      sortedProviders.unshift(nearestStartTimeProvider);
    }

    // Step 4: Add `nextAvailable` to the first time slot of the first provider in the sorted list
    return sortedProviders.map((provider, index) => {
      const isNextAvailableProvider = index === 0 && !nextAvailableSet;

      if (isNextAvailableProvider) {
        nextAvailableSet = true;
      }

      const modifiedTimeSlots = provider.sync_time_intervals.map((slot, slotIndex) => ({
        ...slot,
        nextAvailable: isNextAvailableProvider && slotIndex === 0, // Mark only the first slot as `nextAvailable`
      }));

      return {
        ...provider,
        timeSlots: modifiedTimeSlots,
        allSlots: provider.sync_time_slots, // Retain all original time slots
      };
    });
  }, [selectedDate, providers]);

  useEffect(() => {
    setParams({
      ...params,
      date: moment(selectedDate).format('YYYY-MM-DD'),
    });
  }, [selectedDate]);

  const fetchLanguageOptions = async (): Promise<void> => {
    const data = await listAllLanguages();
    const transformedData = data.map((language) => ({
      value: language.locale,
      label: language.name,
      id: language.id,
    }));
    setLanguageOptions(transformedData);
  };

  useEffect(() => {
    (async (): Promise<void> => {
      await fetchLanguageOptions();

      const getPricingsFn = async (): Promise<void> => {
        setLoading(true);
        try {
          const pricings = await getPricings();
          dispatch(setOrganizationPrices({ currentOrganizationPrices: pricings }));
        } catch (error: unknown) {
          displayErrorDetails(error);
        }
        setLoading(false);
      };

      if (!organizationPrices?.currentOrganizationPrices) {
        await getPricingsFn();
      }
    })();
  }, []);

  const onSubmit = (data: ScheduleAppointmentFormSubmit): void => {
    navigate('/select-needs', {
      state: {
        caseType: 'video_call_scheduled',
        insuranceCarrierId: data.insurance_carrier_id || '',
        customInsuranceCarrier: data.custom_carrier || '',
        patientId: selectedPatient?.value,
      },
    });
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(Object.values(errors).length > 1 ? 'Please select a day and time' : (error.message as string), { className: 'fs-unmask' });
    }
  };

  const { handleSubmit } = useForm<ScheduleAppointmentFormSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const renderMobileFilters = (): JSX.Element => {
    const trigger = (
      <div className={styles.trigger}>
        <div className={`fs-unmask ${styles.rightContainer}`}>
          Filter
          <BsFilter className={styles.triggerIcon} />
        </div>
      </div>
    );
    return (
      <Collapsible
        trigger={trigger}
        triggerOpenedClassName={styles.collapsibleTrigger}
        contentOuterClassName={styles.collapsibleFilter}
      >
        <div className={styles.specialitySpan}>
          <MultiselectInput
            name="language"
            placeholder="Language"
            options={languageOptions}
            style={multiSelectCommonWithCommaCustomStylesLargeSelectDateAndTime}
            containerClass={`fs-unmask ${styles.inputWrapper}`}
            selectorClass={styles.selector}
            selectedValue={languageFilter}
            onChange={(value) => {
              if (value) {
                setLanguageFilter(value as MultiValue<Option>);
                setParams({
                  ...params,
                  language: (value as MultiValue<Option>).map((lang) => lang.label),
                });
              }
            }}
          />
        </div>
        {(RescheduleCase
          ? state.caseType
          : (values.find((item) => item.form_question_id === 20)?.value.caseType as string)) !==
          CaseType.oral_health_coaching &&
          (selectedInsuranceId !== '' || (RescheduleCaseSelectedCarrierId && RescheduleCase)) && (
            <>
              <span>
                <Radio
                  checked={providersNetwork === 'InAndOut'}
                  id="inAndOut"
                  name="InAndOut"
                  onChange={() => {
                    selectProvidersNetwork('InAndOut');
                    setParams({ ...params, out_of_network: true });
                  }}
                  text="In and Out of Network Providers"
                  value="InAndOut"
                  className="fs-unmask"
                />
              </span>
              <span>
                <Radio
                  checked={providersNetwork === 'InOnly'}
                  id="inOnly"
                  name="InOnly"
                  onChange={() => {
                    selectProvidersNetwork('InOnly');
                    setParams({ ...params, out_of_network: false });
                  }}
                  text="In Network Only"
                  value="InOnly"
                  className="fs-unmask"
                />
              </span>
            </>
          )}
      </Collapsible>
    );
  };
  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        overflow: 'hidden',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width < 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2', paddingTop: screenSize.width > 768 ? '40px' : '16px' }}
    >
      <div className={styles.scheduleAppointmentContainer}>
        <section className={styles.backButtonContainer}>
          <button
            type="button"
            className={`fs-unmask ${styles.backButton}`}
            onClick={() => {
              if (isMobile && selectedDate) {
                setSelectedDate(null);
              } else if (RescheduleCase) {
                navigate('/appointments');
              } else {
                navigate(
                  isPaymentRequired &&
                    (currentOrganizationPrices?.amount?.toString() !== '0' ||
                      currentOrganizationPrices?.collect_insurance_info)
                    ? '/payment-information'
                    : userInfo.role.name === UserRoleName.Admin ||
                      userInfo.role.name === UserRoleName.SuperAdmin ||
                      userInfo.role.name === UserRoleName.OrganizationAdmin
                    ? `/admin/review-profile/Patient/${currentPatient?.id}}/create-consultation-for`
                    : '/select-patient',
                );
              }
            }}
          >
            <MdKeyboardArrowLeft />
            Back
          </button>
          <div className={`${styles.title} d-flex fs-unmask`}>
            {!isMobile
              ? 'Select a Date & Time'
              : selectedDate === null
              ? 'Select a Date'
              : 'Select a Provider and Time'}
          </div>
        </section>
        {!isMobile && !loading ? (
          <section className={styles.filterSection}>
            <span className={`fs-unmask ${styles.filterTitle}`}>Filter</span>
            <div className={styles.specialitySpan}>
              <MultiselectInput
                name="language"
                placeholder="Language"
                options={languageOptions}
                style={multiSelectCommonWithCommaCustomStylesLargeSelectDateAndTime}
                containerClass={`fs-unmask ${styles.inputWrapper}`}
                selectorClass={styles.selector}
                selectedValue={languageFilter}
                onChange={(value) => {
                  if (value) {
                    setLanguageFilter(value as MultiValue<Option>);
                    setParams({
                      ...params,
                      language: (value as MultiValue<Option>).map((lang) => lang.label),
                    });
                  }
                }}
              />
            </div>
            {(RescheduleCase
              ? state.caseType
              : (values.find((item) => item.form_question_id === 20)?.value.caseType as string)) !==
              CaseType.oral_health_coaching &&
              (selectedInsuranceId !== '' || (RescheduleCaseSelectedCarrierId && RescheduleCase)) && (
                <>
                  <span>
                    <Radio
                      checked={providersNetwork === 'InAndOut'}
                      id="inAndOut"
                      name="InAndOut"
                      onChange={() => {
                        selectProvidersNetwork('InAndOut');
                        setParams({ ...params, out_of_network: true });
                      }}
                      text="In and Out of Network Providers"
                      value="InAndOut"
                      className="fs-unmask"
                    />
                  </span>
                  <span>
                    <Radio
                      checked={providersNetwork === 'InOnly'}
                      id="inOnly"
                      name="InOnly"
                      onChange={() => {
                        selectProvidersNetwork('InOnly');
                        setParams({ ...params, out_of_network: false });
                      }}
                      text="In Network Only"
                      value="InOnly"
                      className="fs-unmask"
                    />
                  </span>
                </>
              )}
          </section>
        ) : isMobile && selectedDate === null ? (
          <>{renderMobileFilters()}</>
        ) : (
          ''
        )}
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <section className={styles.cardsContainer}>
            {(!isMobile || (isMobile && selectedDate === null)) && !loading && (
              <div className={styles.calendar}>
                <Calendar
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  setSelectedTimeSlot={setSelectedTimeSlot}
                  languageFilter={params.language}
                  insuranseFilter={providersNetwork === 'InOnly' ? params.carrier_id : undefined}
                  patient_id={
                    userInfo.role.name === UserRoleName.Admin ||
                    userInfo.role.name === UserRoleName.SuperAdmin ||
                    userInfo.role.name === UserRoleName.OrganizationAdmin
                      ? Number(currentPatient.id)
                      : Number(userInfo.id)
                  }
                  out_of_network={providersNetwork !== 'InOnly'}
                  oralHealthCoaching={
                    (RescheduleCase
                      ? state.caseType
                      : (values.find((item) => item.form_question_id === 20)?.value.caseType as string)) ===
                    CaseType.oral_health_coaching
                  }
                />
              </div>
            )}
            {((!isMobile && selectedDate) || (isMobile && selectedDate)) && !loading && (
              <div className={styles.providerCards}>
                <DisplaySelectedDates date={new Date(selectedDate!)} setFormattedDate={setFormattedDate} />
                {!dataLoading ? (
                  sortedProvidersWithTimeSlots
                    .filter((provider) => provider.timeSlots.length > 0)
                    .map((provider) => (
                      <ProviderAvailabilityCard
                        index={provider.id}
                        provider={provider.user}
                        date={formattedDate}
                        setSelectedTimeSlot={setSelectedTimeSlot}
                        selectedTimeSlot={selectedTimeSlot}
                        timeSlots={provider.timeSlots}
                        allSlots={provider.allSlots}
                        selectedCarrierId={selectedInsuranceId as number}
                        customInsurance={state?.customInsuranceCarrier}
                        insuranceCarriers={insuranceCarriers}
                        patientCurrentState={storedLocationState || 'NY'}
                        refreshProviders={refreshProviders}
                        loading={loading}
                        setLoading={setLoading}
                        setRescheduleSuccessModal={setRescheduleSuccessModal}
                        in_network={provider.in_network}
                        caseType={
                          RescheduleCase
                            ? state.caseType
                            : (values.find((item) => item.form_question_id === 20)?.value.caseType as string)
                        }
                        setScheduleModal={setScheduleModal}
                      />
                    ))
                ) : (
                  <span>
                    <Loading dotted />
                  </span>
                )}
                {!dataLoading && (
                  <span className={styles.lastItemRef} ref={lastProviderItemRef}>
                    <span>{isLoading && <Loading dotted />}</span>
                  </span>
                )}
              </div>
            )}
            {loading && <Loading dotted />}
          </section>
        </form>
        {rescheduleSuccessModal.openModal && (
          <ScheduleLiveConsultation newCase={rescheduleSuccessModal.updatedCase as ICase} isRescheduled />
        )}
        {scheduleModal.openModal && <ScheduleLiveConsultation newCase={scheduleModal.currentCase} />}
      </div>
    </Container>
  );
};
export default ScheduleAppointment;
