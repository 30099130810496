/* eslint-disable no-nested-ternary */
import cn from 'classnames';
import React, { lazy, useEffect, useState } from 'react';
import { CgProfile } from 'react-icons/cg';
import { FaSpinner } from 'react-icons/fa';
import Select, {
  GroupBase,
  OptionProps,
  SelectComponentsConfig,
  SingleValue,
  SingleValueProps,
  StylesConfig,
} from 'react-select';

import { selectCustomStyles } from '../../../Utils/customStyles';
import { getPrimaryColor } from '../../../Utils/getPrimaryColor';
import { Option } from '../../../Utils/selectOptions';
import styles from './styles.module.scss';

type SelectInputProps = {
  containerClass?: string;
  selectorClass?: string;
  isClearable?: boolean;
  name: string;
  placeholder?: string;
  options: Option[];
  style?: StylesConfig<Option, false, GroupBase<Option>>;
  errors?: Partial<any>;
  selectedValue: any;
  isDisabled?: boolean;
  onChange?: (
    value: SingleValue<{
      value: any;
      label: string;
    }>,
  ) => void;
  showNoInsuranceOption?: boolean;
  defaultValue?: Option;
  isSearchable?: boolean;
  components?: Partial<SelectComponentsConfig<Option, false, GroupBase<Option>>> | undefined;
  isLoading?: boolean;
  withImage?: boolean;
};

const SelectInputWithImage = React.forwardRef(
  (
    {
      containerClass,
      selectorClass,
      name,
      placeholder,
      options,
      style,
      errors,
      selectedValue,
      isDisabled,
      isClearable,
      onChange,
      showNoInsuranceOption,
      defaultValue,
      isSearchable,
      components,
      isLoading,
      withImage,
    }: SelectInputProps,
    ref,
  ): JSX.Element => {
    const [errorMessage, setErrorMessage] = React.useState('');
    const optionsList = [...options];
    if (!showNoInsuranceOption) {
      optionsList.shift();
    }

    const getComponents = (withImage: boolean): unknown => ({
      SingleValue: ({ children, ...props }: SingleValueProps<Option>) => {
        const { data } = props;
        const style = {
          fontWeight: '400',
          fontSize: withImage ? '16px' : '14px',
        };

        const [imageError, setImageError] = useState(false);

        const handleImageError = () => {
          setImageError(true);
        };

        const handleImageLoad = () => {
          setImageError(false);
        };

        return (
          <div {...props} className={`${withImage ? styles.flexContainer : ''}`}>
            {withImage && (
              <div className={styles.imageContainer}>
                <div className={styles.dependentImage}>
                  {!imageError ? (
                    <img src={data.image} alt="profile" onError={handleImageError} onLoad={handleImageLoad} />
                  ) : (
                    <CgProfile />
                  )}
                </div>
              </div>
            )}
            <span style={style}>{children}</span>
          </div>
        );
      },
      Option: (props: OptionProps<Option>) => {
        const { isFocused, innerProps, innerRef, label, data } = props;
        const isInactive = data?.status ? data?.status !== 'Active' : false;
        const optionStyles = isFocused
          ? {
              backgroundColor: getPrimaryColor(),
              color: 'white',
            }
          : isInactive
          ? {
              color: 'gray',
            }
          : {};

        const [imageError, setImageError] = useState(data.hasImageError ?? false);
        const [imageLoading, setImageLoading] = useState(true);

        const handleImageError = () => {
          setImageError(true);
          setImageLoading(false);
          props.data.hasImageError = true;
        };

        const handleImageLoad = () => {
          setImageError(false);
          setImageLoading(false);
          props.data.cachedImage = data.image;
          props.data.hasImageError = false;
        };

        useEffect(() => {
          if (data.image) {
            const img = new Image();
            img.src = data.image as string;
            img.addEventListener('load', () => {
              handleImageLoad();
              setImageError(false);
            });
            img.addEventListener('error', handleImageError);
            return () => {
              img.removeEventListener('load', handleImageLoad);
              img.removeEventListener('error', handleImageError);
            };
          }
        }, [data.image]);

        return (
          <div {...innerProps} ref={innerRef} style={optionStyles} className={styles.selectOptions}>
            {withImage && (
              <div className={styles.imageContainer}>
                <div className={styles.dependentImage}>
                  {imageLoading && !data.hasImageError ? (
                    <FaSpinner className={styles.spinner} />
                  ) : !imageError && !data.hasImageError ? (
                    <img
                      src={data.cachedImage || data.image}
                      alt="profile"
                      onError={handleImageError}
                      onLoad={handleImageLoad}
                      loading="lazy"
                    />
                  ) : (
                    <CgProfile />
                  )}
                </div>
              </div>
            )}
            {label}
          </div>
        );
      },
      ...components,
    });

    useEffect(() => {
      if (errors && Object.keys(errors).length > 0 && name) {
        const nameArray = name.split('.');
        if (nameArray.length > 1) {
          setErrorMessage(errors[nameArray[0]]?.[nameArray[1]]?.message);
        } else {
          const propertyIndex = Object.keys(errors).indexOf(name);
          if (propertyIndex !== -1) {
            setErrorMessage(errors[Object.keys(errors)[propertyIndex]].message);
          } else {
            setErrorMessage('');
          }
        }
      } else {
        setErrorMessage('');
      }
    }, [errors && Object.keys(errors).length, name, selectedValue]);

    return (
      <div className={`${styles.inputWrapper} ${containerClass}`} data-validate={`Choose ${name}`}>
        <Select
          className={cn(styles.input100, 'p-0', { 'is-valid': errorMessage }, styles.select, selectorClass)}
          onChange={onChange}
          name={name}
          styles={{
            ...style,
            valueContainer: (provided) => ({
              ...provided,
              display: 'flex',
              paddingLeft: '0px',
              paddingRight: '0px',
              height: '30px',
              width: '70%',
              flex: 'none',
            }),
          }}
          isClearable={isClearable}
          placeholder={placeholder}
          menuPortalTarget={document.body}
          options={optionsList}
          noOptionsMessage={() => <span>Loading options...</span>} // Customizing the "No options" message with JSX
          value={
            selectedValue?.value
              ? optionsList.find((option) => option.value === selectedValue?.value) || null
              : optionsList.find((option) => option.value === selectedValue) || null
          }
          isDisabled={isDisabled}
          components={
            getComponents(withImage ?? false) as
              | Partial<SelectComponentsConfig<Option, false, GroupBase<Option>>>
              | undefined
          }
          ref={ref as any}
          defaultValue={defaultValue}
          isSearchable={isSearchable}
          isLoading={isLoading}
        />
        <span className={styles.focusInput100} />
        {errorMessage && <div className={`${styles.invalidFeedback} invalid-feedback`}>{errorMessage}</div>}
      </div>
    );
  },
);

SelectInputWithImage.defaultProps = {
  containerClass: '',
  placeholder: '',
  style: selectCustomStyles,
  errors: {},
  isDisabled: false,
  onChange: undefined,
  isClearable: false,
  selectorClass: '',
  showNoInsuranceOption: true,
  defaultValue: undefined,
  isSearchable: true,
  components: undefined,
  isLoading: false,
  withImage: false,
};

export default SelectInputWithImage;
