import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MyProfileSidebarState {
  isMyProfileSidebarCollapsed: boolean;
}

const initialState: MyProfileSidebarState = {
  isMyProfileSidebarCollapsed: true,
};

const myProfileSidebarSlice = createSlice({
  name: 'myProfileSidebar',
  initialState,
  reducers: {
    toggleMyProfileSidebar: (state) => {
      state.isMyProfileSidebarCollapsed = !state.isMyProfileSidebarCollapsed;
    },
    setMyProfileSidebarState: (state, action: PayloadAction<boolean>) => {
      state.isMyProfileSidebarCollapsed = action.payload;
    },
  },
});

export const { toggleMyProfileSidebar, setMyProfileSidebarState } = myProfileSidebarSlice.actions;
export default myProfileSidebarSlice.reducer;