import { selectAuth } from '@brands/store/selectors/auth';
import React from 'react';
import { UseFormHandleSubmit, UseFormTrigger } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from '../../../../Components/Button/Button';
import { useAppSelector } from '../../../../hooks/useReduxHook';
import { sendBackToProvider } from '../../../../services/cases/sendBackToProvider';
import { CaseType, ICase } from '../../../../services/cases/types/ICase';
import { UserRoleName } from '../../../../services/identity/types/UserProfile';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

type ConsultationButtonProps = {
  thisCase: ICase;
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectProvidersModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: UseFormHandleSubmit<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  onInvalid: (errorsList: any) => void;
};

const ConsultationButton = ({
  thisCase,
  consultationForm,
  setConsultationForm,
  setCase,
  loading,
  setLoading,
  setSelectProvidersModal,
  handleSubmit,
  trigger,
  onInvalid,
}: ConsultationButtonProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);

  const submitToProvider = async (): Promise<void> => {
    if (typeof thisCase?.id === 'number') {
      if (thisCase) {
        try {
          setLoading(true);
          const updatedCase = await sendBackToProvider(thisCase.id);
          setCase({
            ...thisCase,
            status: updatedCase.status,
            provider: updatedCase.provider,
            provider_id: updatedCase.provider_id,
            updated_at: updatedCase.updated_at,
            one_report: updatedCase.one_report,
          });
          setConsultationForm({
            ...consultationForm,
            educations: updatedCase.one_report.educations,
          });
        } catch (error: unknown) {
          displayErrorDetails(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const sendBackToProviderFn = (data: OralHealthReportSubmit): void => {
    setLoading(true);
    if (consultationForm.caseNotes.trim() !== '') {
      toast.error('Please save your notes before submitting', { className: 'fs-unmask' });
      setLoading(false);
      return;
    }
    localStorage.removeItem('notes');
    setConsultationForm(data);
    switch (thisCase?.status) {
      case 'in_progress_care_advisor': {
        submitToProvider();
        break;
      }
    }
  };

  return (
    <>
      {(thisCase?.status === 'accepted_provider' || thisCase?.status === 'in_progress_provider') &&
        userInfo.id === thisCase.provider_id &&
        userInfo.role.name === UserRoleName.Provider && (
          <div className={styles.customBtn}>
            <div>
              <Button
                type="submit"
                className={`${styles.proviverListBtn} ${styles.assignAprovider } fs-unmask`}
                disabled={loading}
              >
                Submit to Patient
              </Button>
            </div>
          </div>
        )}
      {thisCase.type !== CaseType.care_coordination &&
      thisCase.type !== CaseType.oral_health_coaching &&
        !thisCase?.provider &&
        (thisCase.care_advisor_id === userInfo.id ||
          userInfo.role.name === 'Admin' ||
          userInfo.role.name === 'SuperAdmin') &&
        thisCase.status !== 'canceled' && (
          <div className={styles.customBtn}>
            <div>
              <Button
                className={`${styles.proviverListBtn} ${styles.assignAprovider} fs-unmask`}
                onClick={() => {
                  trigger();
                  if (consultationForm.isAdditionalPeople && consultationForm.additionalPeople === '') {
                    setSelectProvidersModal(false);
                  } else {
                    setSelectProvidersModal(true);
                  }
                }}
                disabled={loading}
              >
                Assign a Provider
              </Button>
            </div>
          </div>
        )}
      {userInfo.id === thisCase.care_advisor_id &&
        thisCase?.status === 'in_progress_care_advisor' &&
        userInfo.role.name === UserRoleName.CareAdvisor && (
          <div className={styles.customBtn}>
            {thisCase.type !== CaseType.care_coordination && thisCase.type !== CaseType.oral_health_coaching  && (
              <div>
                <Button
                  type="button"
                  className={`${styles.proviverListBtn} ${styles.assignAprovider} ${styles.sendBackToProvider} fs-unmask`}
                  disabled={loading}
                  onClick={handleSubmit(sendBackToProviderFn, onInvalid)}
                >
                  Send back to Provider
                </Button>
              </div>
            )}
            <div>
              <Button
                type="submit"
                className={`${styles.proviverListBtn} ${styles.assignAprovider} fs-unmask`}
                disabled={loading}
              >
                Submit to Patient
              </Button>
            </div>
          </div>
        )}
    </>
  );
};
export default React.memo(ConsultationButton);
