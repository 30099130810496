/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-continue */
/* eslint-disable max-len */
import { convertDateFormat, uploadMediaToCase } from '@brands/Dashboard/MyProfile/Patient/utils/utilsFunctions';
import CareAdvisorProfileCardModal from '@brands/Dashboard/ProfileCards/CareAdvisor/ProfileCards';
import { CasePayload, createNewCase } from '@brands/services/cases/createNewCase';
import { rescheduleCase, RescheduleCasePayload } from '@brands/services/cases/rescheduleCase';
import { CaseType, ICase } from '@brands/services/cases/types/ICase';
import { completeMobileHandoff } from '@brands/services/communication/completeMobileHandover';
import { Pricings } from '@brands/services/identity/getPricings';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectIsFromHandOff } from '@brands/store/selectors/isFromHandOff';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectOrganizationPrices } from '@brands/store/selectors/organizationPrices';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setIsFromHandoff } from '@brands/store/slices/isFromHandOffSlice';
import { FormValues, resetForm, resetSelectedPatient, setForm } from '@brands/store/slices/patientFormSlice';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { DEFAULT_PATIENT_LOCATION_STATE } from '@brands/Utils/localStorage';
import React, { useEffect, useState } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { FaCircle } from 'react-icons/fa';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Button from '../../../../../Components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useReduxHook';
import { getUserAvatar } from '../../../../../services/identity/getUserAvatar';
import { InsuranceCarrier } from '../../../../../services/identity/listInsuranceCarriers';
import { Language, UserProfile, UserRoleName } from '../../../../../services/identity/types/UserProfile';
import ProviderProfileCardModal from '../../../../ProfileCards/Dentist/ProfileCards';
import { SelectedAppointment } from '../types';
import TimeSlotComponent, { TimeSlot } from './Components/TimeSlotComponent';
import styles from './styles.module.scss';

interface ProviderCard {
  index: number;
  provider: UserProfile;
  date: string;
  timeSlots: TimeSlot[];
  allSlots: string[];
  setSelectedTimeSlot: React.Dispatch<React.SetStateAction<SelectedAppointment>>;
  selectedTimeSlot: SelectedAppointment;
  selectedCarrierId: number;
  customInsurance: string;
  insuranceCarriers: InsuranceCarrier[];
  patientCurrentState: string;
  refreshProviders: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRescheduleSuccessModal: React.Dispatch<
    React.SetStateAction<{
      openModal: boolean;
      updatedCase: ICase | null;
    }>
  >;
  in_network?: boolean;
  caseType: string;
  setScheduleModal: React.Dispatch<
    React.SetStateAction<{
      openModal: boolean;
      currentCase: ICase;
    }>
  >;
}

const ProviderAvailabilityCard: React.FC<ProviderCard> = ({
  index,
  provider,
  date,
  timeSlots,
  allSlots,
  selectedTimeSlot,
  setSelectedTimeSlot,
  selectedCarrierId,
  customInsurance,
  insuranceCarriers,
  patientCurrentState,
  refreshProviders,
  loading,
  setLoading,
  setRescheduleSuccessModal,
  in_network,
  caseType,
  setScheduleModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { userInfo } = useAppSelector(selectAuth);
  const [searchParams] = useSearchParams();
  const currentState: string = useAppSelector(selectCurrentPatientState);
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const isFromHandoffMobile = useAppSelector(selectIsFromHandOff);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const organizationPrices = useAppSelector(selectOrganizationPrices);
  const RescheduleCase = state?.RescheduleCase ? state.RescheduleCase : searchParams.get('reschedule') || null;
  const RescheduleCaseSelectedCarrierId = state?.RescheduleCaseSelectedCarrierId || null;
  const caseId = state?.caseId ? state.caseId : searchParams.get('caseId') || null;
  const providerId =
    state?.providerId || state?.providerId == 0 ? state.providerId : searchParams.get('providerId') || null;

  const currentOrganizationPrices = organizationPrices?.currentOrganizationPrices?.find(
    (currentOrganizationPrise: Pricings) => currentOrganizationPrise.case_types.includes(caseType as string),
  )?.amount;

  const completeMobileHandoffFn = async (): Promise<void> => {
    try {
      await completeMobileHandoff();
      dispatch(setIsFromHandoff(false));
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const createCaseForThisRequirement = async (): Promise<ICase> => {
    const isPaymentRequired = currentOrganization?.payment_options?.length > 0;
    const payload: CasePayload = {
      type: (values.find((item) => item.form_question_id === 20)?.value.caseType as string) || '',
      status: 'new',
      patient_id:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentPatient.guardian_id === null
            ? currentPatient?.id
            : currentPatient.guardian_id!
          : Number(userInfo.id),
      organization_id: currentOrganization?.id || 'dentistry-one',
      ...((values.find((item) => item.form_question_id === 20)?.value.caseType as string) !==
        CaseType.oral_health_coaching && {
        provider_id:
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === 'video_call_scheduled' ||
          (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
            CaseType.phone_call_scheduled
            ? Number(values.find((item) => item.form_question_id === 14)?.value?.selectedProviderId)
            : undefined,
      }),
      ...((values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
        CaseType.oral_health_coaching && {
        care_advisor_id: Number(values.find((item) => item.form_question_id === 14)?.value?.selectedCareAdvisorId),
      }),
      payment_profile_id: isPaymentRequired
        ? values.find((item) => item.form_question_id === 19)?.value?.paymentId
          ? Number(values.find((item) => item.form_question_id === 19)?.value?.paymentId)
          : null
        : null,
      video_conference_date: (values.find((item) => item.form_question_id === 14)?.value?.selectedTimeSlot as string)
        ? convertDateFormat(`${date} ${selectedTimeSlot.selectedTimeSlot}`)
        : null,
      patient_current_state:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentState[currentPatient.id] || ''
          : currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE,
      case_intake_questions: {
        is_dental_emergency: values.find((item) => item.form_question_id === 15)?.value.dental_emergency === 'yes',
        in_pain: values.find((item) => item.form_question_id === 16)?.value.inPain === 'yes',
        pain_description: (values.find((item) => item.form_question_id === 16)?.value.describePain as string[]) || [],
        pain_time: (values.find((item) => item.form_question_id === 16)?.value.daysInPain as string) || '',
        pain_level: (values.find((item) => item.form_question_id === 16)?.value.painLevel as string) || '',
        additional_info: (values.find((item) => item.form_question_id === 18)?.value.anythingElse as string) || '',
      },
      ...(isPaymentRequired && {
        insurance_id: values.find((item) => item.form_question_id === 19)?.value?.insuranceId
          ? Number(values.find((item) => item.form_question_id === 19)?.value?.insuranceId)
          : null,
      }),
      ...(isPaymentRequired &&
        values.find((item) => item.form_question_id === 19)?.value?.addSecondaryInsurance &&
        values.find((item) => item.form_question_id === 19)?.value?.secondaryInsuranceId !== '' && {
          second_insurance_id: values.find((item) => item.form_question_id === 19)?.value?.addSecondaryInsurance
            ? Number(values.find((item) => item.form_question_id === 19)?.value?.secondaryInsuranceId)
            : undefined,
        }),
      dependent_id:
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentPatient.guardian_id === null
            ? null
            : Number(currentPatient?.id)
          : Number(selectedPatient?.value) !== Number(userInfo.id)
          ? Number(selectedPatient?.value)
          : null,
      ...(values.find((item) => item.form_question_id === 14)?.value?.customInsurance !== '' &&
      Number(values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier) === 0
        ? { custom_carrier: values.find((item) => item.form_question_id === 14)?.value?.customInsurance }
        : Number(values.find((item) => item.form_question_id === 14)?.value?.selectedInsurancePlan) === 0
        ? {
            custom_plan: values.find((item) => item.form_question_id === 14)?.value?.customInsurance,
            custom_carrier: values.find((item) => item.form_question_id === 14)?.value?.selectedCarrier,
          }
        : {}),
      ...(((values.find((item) => item.form_question_id === 20)?.value.caseType as string) === CaseType.one_report ||
        (values.find((item) => item.form_question_id === 20)?.value.caseType as string) === CaseType.second_opinion ||
        (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
          CaseType.video_call_scheduled) && {
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      ...(((values.find((item) => item.form_question_id === 20)?.value.caseType as string) === CaseType.one_report ||
        (values.find((item) => item.form_question_id === 20)?.value.caseType as string) ===
          CaseType.second_opinion) && {
        license_states:
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? [currentState[currentPatient.id] || '']
            : [currentState[userInfo.id]],
      }),
    };
    const caseRes = await createNewCase(payload);
    return caseRes;
  };
  const { selectedPatient } = useAppSelector(selectPatient);
  const { values } = useAppSelector(selectPatientForm);
  const [formValues, setFormValues] = React.useState<FormValues[]>([]);
  const [providerProfileCardModal, setProviderProfileCardModal] = useState(false);
  const [cAProfileCardModal, setCAProfileCardModal] = useState(false);

  const handleReset = (): void => {
    dispatch(resetForm());
    dispatch(resetSelectedPatient());
    dispatch(setIsFromHandoff(false));
  };

  const dispatchSuccessEvent = (serviceType: string): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'thank-you-screen-shown',
      service: serviceType,
      userId: userInfo.id,
    });
  };

  const savePatientScheduledDate = async (): Promise<void> => {
    const payload = [...formValues!];
    const filteredPayload = payload.filter((item) => item.form_question_id !== 14);
    filteredPayload.push({
      form_question_id: 14,
      value: {
        selectedTimeSlot: `${date} ${selectedTimeSlot.selectedTimeSlot}`,
        ...(caseType !== CaseType.oral_health_coaching && { selectedProviderId: index }),
        ...(caseType === CaseType.oral_health_coaching && { selectedCareAdvisorId: index }),
        selectedCarrier: selectedCarrierId || RescheduleCaseSelectedCarrierId,
        customInsurance,
      },
    });
    dispatch(setForm(filteredPayload));

    if (RescheduleCase && caseId && (providerId || providerId == 0)) {
      let updatedCase;
      try {
        setLoading(true);

        const payload: RescheduleCasePayload = {
          video_conference_date: convertDateFormat(`${date} ${selectedTimeSlot.selectedTimeSlot}`) as string,
          clinician_id: index,
        };
        updatedCase = await rescheduleCase(caseId, payload);

        setRescheduleSuccessModal({
          openModal: true,
          updatedCase: updatedCase as ICase,
        });
        setLoading(false);
      } catch (error: unknown) {
        displayErrorDetails(error);
      }
    } else if (state?.isFromUnavailableTimeSlotModal) {
      setLoading(true);
      try {
        const res = await createCaseForThisRequirement();
        if (isFromHandoffMobile) {
          completeMobileHandoffFn();
        }
        if (res) {
          handleReset();
        }
        const imagesValue = values.find((item) => item.form_question_id === 17)?.value.images;
        uploadMediaToCase(res, imagesValue);

        if (res && (res.type === 'video_call_scheduled' || res.type === CaseType.phone_call_scheduled)) {
          dispatchSuccessEvent('talk-live-scheduled');
          return setScheduleModal({
            openModal: true,
            currentCase: res,
          });
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
      } finally {
        setLoading(false);
      }
    } else if (caseType === CaseType.oral_health_coaching) {
      navigate('/medical-information-summary', {
        state: {
          caseType: CaseType.oral_health_coaching,
          patientId: selectedPatient?.value || '',
          shouldSeeLastModal: false,
          fromPatientIntake: true,
        },
      });
    } else
      navigate('/select-needs', {
        state: {
          caseType: 'video_call_scheduled',
          userOrganization: state?.userOrganization,
          patientId: selectedPatient?.value,
        },
      });
  };

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
  }, []);

  return (
    <div
      className={`${styles.card} ${
        selectedTimeSlot.selectedTimeSlot !== '' && selectedTimeSlot.selectedProviderId === index
          ? styles.selectedBg
          : styles.whiteBg
      }`}
    >
      <div className={styles.providerInfo}>
        <div
          className={styles.providerPhoto}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (caseType === CaseType.oral_health_coaching) setCAProfileCardModal(true);
            else setProviderProfileCardModal(true);
          }}
        >
          <img
            alt="Avatar"
            onError={(event: any) => {
              const img = event.target;
              img.src =
                'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
              img.onError = null;
            }}
            className={`fs-exclude ${styles.caImage}`}
            src={getUserAvatar(provider.id)}
          />
        </div>
        <div className={styles.providerDetails}>
          <span
            style={{ cursor: 'pointer' }}
            className={`fs-exclude ${styles.name}`}
            onClick={() => {
              if (caseType === CaseType.oral_health_coaching) setCAProfileCardModal(true);
              else setProviderProfileCardModal(true);
            }}
          >
            {provider?.full_name}
            {provider.provider_profile?.credentials ? ', ' : ''}
            {provider.provider_profile?.credentials || ''}
            <AiFillInfoCircle />
          </span>
          <span className={`fs-mask ${styles.language}`}>
            <span className={styles.title}>Languages</span> -{' '}
            <span>
              {provider.basic_info.languages &&
                provider.basic_info.languages
                  .map((language: Language | string) => {
                    if (typeof language === 'string') {
                      return language;
                    }
                    return language.name;
                  })
                  .join(', ')}
            </span>
          </span>
          {(selectedCarrierId?.toString() !== '' || (RescheduleCase && RescheduleCaseSelectedCarrierId)) && (
            <span className={`fs-exclude ${styles.network}`}>
              <span className={styles.title}>
                {in_network ? <FaCircle className={styles.inNetwork} /> : <FaCircle className={styles.outNetwork} />}
                {in_network ? 'In Network' : 'Out of Network'}
              </span>{' '}
              <span>
                {in_network && provider?.provider_profile?.licenses && (
                  <>
                    {' - '}
                    {insuranceCarriers.find(
                      (carrier) => carrier.id === (selectedCarrierId || RescheduleCaseSelectedCarrierId),
                    )?.name || 'No insurance carriers available'}
                  </>
                )}
              </span>
            </span>
          )}
        </div>
      </div>
      <div>
        <TimeSlotComponent
          id={index}
          timeSlots={timeSlots}
          allSlots={allSlots}
          selectedTimeSlot={selectedTimeSlot}
          setSelectedTimeSlot={setSelectedTimeSlot}
          date={date}
          refreshProviders={refreshProviders}
        />
      </div>
      {selectedTimeSlot.selectedTimeSlot !== '' && selectedTimeSlot.selectedProviderId === index && (
        <div className={styles.cardFooter}>
          <span className={styles.timeSlotDetails}>
            <span>{date}</span>
            <span>{selectedTimeSlot.selectedTimeSlot}</span>
          </span>
          <span>
            <Button type="button" onClick={() => savePatientScheduledDate()} disabled={loading} className="fs-unmask">
              {state?.isFromUnavailableTimeSlotModal
                ? currentOrganizationPrices?.toString() === '0'
                  ? 'Submit'
                  : 'Submit and Pay'
                : 'Schedule Now'}
            </Button>
          </span>
        </div>
      )}
      {providerProfileCardModal && (
        <ProviderProfileCardModal
          isOpen={providerProfileCardModal}
          toggleModal={setProviderProfileCardModal}
          providerIdentity={provider}
          patientCurrentStateValue={patientCurrentState}
        />
      )}
      {cAProfileCardModal && (
        <CareAdvisorProfileCardModal
          isOpen={cAProfileCardModal}
          toggleModal={setCAProfileCardModal}
          caIdentity={provider}
        />
      )}
    </div>
  );
};

export default ProviderAvailabilityCard;
