import { selectAuth } from '@brands/store/selectors/auth';
import { stateOptions } from '@brands/Utils/selectOptions';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg';
import Modal from 'react-modal';

import { useWindowSize } from '../../../hooks';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { getOneCase } from '../../../services/cases/getCaseById';
import { ICase } from '../../../services/cases/types/ICase';
import { getUserAvatar } from '../../../services/identity/getUserAvatar';
import { License, UserProfile, UserRoleName } from '../../../services/identity/types/UserProfile';
import { customStylesDashboard } from '../../../Utils/customStyles';
import { displayErrorDetails } from '../../../Utils/displayError';
import { ProviderFilter } from '../../ConsultationPage/assets/types';
import CareAdvisorView from './components/CareAdvisorView';
import styles from './styles.module.scss';

interface ProfileCardModalProps {
  isOpen: boolean;
  toggleModal: (bool: boolean) => void;
  providerIdentity: UserProfile | null;
  caseId?: number;
  patientCurrentStateValue?: string | undefined;
  filters?: ProviderFilter | undefined;
  thisCase?: ICase;
  setCase?: React.Dispatch<React.SetStateAction<ICase>>;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  openModal?: (arg0: boolean) => void;
  isAssignProvider?: boolean;
}

const ProviderProfileCardModal: FC<ProfileCardModalProps> = ({
  isOpen,
  toggleModal,
  providerIdentity,
  caseId,
  patientCurrentStateValue,
  filters,
  thisCase,
  setCase,
  setLoading,
  openModal,
  isAssignProvider,
}) => {
  const isMobile = useWindowSize().width < 992;
  const { userInfo } = useAppSelector(selectAuth);
  const isCA = userInfo.role.name === UserRoleName.CareAdvisor;
  const mobileCustomStylesDashboard = {
    ...customStylesDashboard,
    overlay: {
      ...customStylesDashboard.overlay,
      overflowY: 'scroll',
      zIndex: '999',
    },
    content: {
      ...customStylesDashboard.content,
      transform: 'translate(-50%, -50%)',
      width: '95%',
      height: 'fit-content',
      maxHeight: '95%',
      zIndex: '999',
    },
  };

  const [providerIdentityFromCase, setProviderIdentity] = useState<UserProfile | null>(null);
  const [patientCurrentState, setPatientCurrentState] = useState(patientCurrentStateValue || '');
  const [relatedCase, setRelatedCase] = useState<ICase>();

  const initIdentity = async (): Promise<void> => {
    try {
      if (caseId && (providerIdentity?.basic_info === undefined || patientCurrentStateValue === undefined || isCA)) {
        const thisCurrentCase = await getOneCase(caseId);
        if (thisCurrentCase) {
          setRelatedCase(thisCurrentCase);
          setProviderIdentity(thisCurrentCase.provider);
          setPatientCurrentState(thisCurrentCase.patient_current_state || '');
        }
      } else if (patientCurrentStateValue) {
        setPatientCurrentState(patientCurrentStateValue);
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const licenseNumberDisplay = useMemo(() => {
    if (providerIdentity?.provider_profile?.licenses && providerIdentity?.provider_profile?.licenses.length > 0) {
      return providerIdentity.provider_profile.licenses.find(
        (license: License) => license.state === patientCurrentState,
      )?.number;
    }
    return providerIdentityFromCase?.provider_profile?.licenses?.find(
      (license: License) => license.state === patientCurrentState,
    )?.number;
  }, [providerIdentity, providerIdentityFromCase]);

  const getLicenseStates = (provider: UserProfile): string | undefined => {
    const nonExpiredLicenses = provider?.provider_profile?.licenses?.filter(
      (license) => new Date(license.expiry_date) > new Date(),
    );
    return nonExpiredLicenses
      ?.map((license) => stateOptions.find((option) => option.value === license.state)?.label.split(' - ')[1])
      .join(', ');
  };

  const licenseStatesDisplay = useMemo(() => {
    if (providerIdentity?.provider_profile?.licenses && providerIdentity?.provider_profile?.licenses.length > 0) {
      return getLicenseStates(providerIdentity);
    }
    return getLicenseStates(providerIdentityFromCase as UserProfile);
  }, [providerIdentity, providerIdentityFromCase]);

  useEffect(() => {
    initIdentity();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else if (document.body.classList.contains('modal-open') && !isOpen) {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);
  const closeModal = (): void => {
    toggleModal(false);
  };

  const [avatarIcon, setAvatarIcon] = useState(false);
  const handleAvatarError = (): void => {
    setAvatarIcon(true);
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={isMobile ? mobileCustomStylesDashboard : customStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
    >
      <div
        className={`${styles.profileCardDentist} ${
          isCA && isAssignProvider ? styles.profileCardDentistCAView : styles.profileCardDentistNormalView
        }`}
      >
        <button
          type="button"
          className={`fs-unmask ${styles.modalClose}`}
          data-micromodal-close=""
          onClick={closeModal}
          aria-label="Close modal"
        >
          <AiOutlineCloseCircle className={styles.icon} />
        </button>
        <div className={styles.titleContainer}>
          <div className={styles.profileContainer}>
            <div className={styles.imageContainer}>
              {!avatarIcon ? (
                <img
                  alt="Avatar"
                  onError={handleAvatarError}
                  className={`fs-exclude ${styles.providerImage}`}
                  src={getUserAvatar(providerIdentity?.id || 0)}
                />
              ) : (
                <CgProfile className="fs-exclude" />
              )}
            </div>
            <div className={styles.textContainer}>
              <div className={`fs-exclude ${styles.title}`}>
                {providerIdentity?.full_name}
                {providerIdentity?.provider_profile?.credentials ? ', ' : ''}
                {providerIdentity?.provider_profile?.credentials || ''}
              </div>
              <div className={styles.details}>
                <div className={`fs-exclude ${styles.gender}`}>
                  {providerIdentity?.basic_info !== undefined
                    ? providerIdentity?.basic_info?.gender
                    : providerIdentityFromCase?.basic_info?.gender}{' '}
                  {providerIdentity?.basic_info?.pronouns || providerIdentityFromCase?.basic_info?.pronouns ? (
                    <span>- </span>
                  ) : (
                    ''
                  )}
                  {providerIdentity?.basic_info !== undefined
                    ? providerIdentity?.basic_info?.pronouns
                    : providerIdentityFromCase?.basic_info?.pronouns}
                </div>
                {false && (
                  <div className={styles.sendMessageButton}>
                    <button type="button">Send Message</button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.bio}>
            <div className={`fs-unmask ${styles.title}`}>Bio</div>
            <div className={`fs-exclude ${styles.content}`}>
              {providerIdentity?.basic_info !== undefined
                ? providerIdentity?.provider_profile?.biography
                : providerIdentityFromCase?.provider_profile?.biography}
            </div>
          </div>
        </div>
        <div className={styles.profileCardBody}>
          <div className={styles.educationLanguage}>
            {userInfo.role.name === UserRoleName.Patient && (
              <div className={styles.providerDetails}>
                <div className={`fs-unmask ${styles.title}`}>License #</div>
                <div className={`fs-exclude ${styles.value}`}>{licenseNumberDisplay}</div>
              </div>
            )}
            <div className={styles.providerDetails}>
              <div className={`fs-unmask ${styles.title}`}>License States</div>
              <div className={`fs-exclude ${styles.value}`}>{licenseStatesDisplay}</div>
            </div>
            <div className={styles.education}>
              <div className={`fs-unmask ${styles.title}`}>Education</div>
              <ul>
                <li className="fs-exclude">
                  {providerIdentity?.basic_info !== undefined
                    ? providerIdentity?.provider_profile?.education?.map((val) => <span>{val}</span>)
                    : providerIdentityFromCase?.provider_profile?.education?.map((val) => <span>{val}</span>)}
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.specialities}>
            <div className={styles.primary}>
              <div className={`fs-unmask ${styles.title}`}>Primary Specialities</div>
              <div className="d-flex gap-1 flex-wrap">
                {providerIdentity?.basic_info !== undefined && providerIdentity?.provider_profile?.primary_specialty
                  ? providerIdentity?.provider_profile?.primary_specialty.map((val) => (
                      <div className={styles.tags}>
                        <div className={`fs-exclude ${styles.tag}`}>
                          <div className={styles.label}>{val}</div>
                        </div>
                      </div>
                    ))
                  : providerIdentityFromCase?.provider_profile?.primary_specialty &&
                    providerIdentityFromCase?.provider_profile?.primary_specialty.map((val) => (
                      <div className={styles.tags}>
                        <div className={`fs-exclude ${styles.tag}`}>
                          <div className={styles.label}>{val}</div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
            <div className={styles.secondary}>
              <div className={`fs-unmask ${styles.title}`}>Secondary Specialities</div>
              <div className="d-flex gap-1 flex-wrap">
                {providerIdentity?.basic_info !== undefined && providerIdentity?.provider_profile?.secondary_specialty
                  ? providerIdentity?.provider_profile?.secondary_specialty.map((val) => (
                      <div className={styles.tags}>
                        <div className={`fs-exclude ${styles.tag}`}>
                          <div className={styles.label}>{val}</div>
                        </div>
                      </div>
                    ))
                  : providerIdentityFromCase?.provider_profile?.secondary_specialty &&
                    providerIdentityFromCase?.provider_profile?.secondary_specialty.map((val) => (
                      <div className={styles.tags}>
                        <div className={`fs-exclude ${styles.tag}`}>
                          <div className={styles.label}>{val}</div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
            <div className={styles.languages}>
              <div className={`fs-unmask ${styles.title}`}>Language</div>
              <div className={styles.tags}>
                {providerIdentity?.basic_info !== undefined
                  ? providerIdentity?.basic_info?.languages?.map((language) => (
                      <div className={`fs-exclude ${styles.tag}`}>
                        <div className={styles.label}>{typeof language !== 'string' && language.name}</div>
                      </div>
                    ))
                  : providerIdentityFromCase?.basic_info?.languages?.map((language) => (
                      <div className={`fs-mask ${styles.tag}`}>
                        <div className={styles.label}>{typeof language !== 'string' && language.name}</div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
        {isCA && relatedCase && isAssignProvider && (
          <CareAdvisorView
            relatedCase={relatedCase}
            filters={filters}
            thisCase={thisCase}
            setCase={setCase}
            setLoading={setLoading}
            providerId={providerIdentity?.id}
            openModal={openModal}
            isAssignProvider={isAssignProvider}
          />
        )}
      </div>
    </Modal>
  );
};

ProviderProfileCardModal.defaultProps = {
  caseId: 0,
  filters: undefined,
  thisCase: undefined,
  setCase: undefined,
  setLoading: undefined,
  openModal: undefined,
  isAssignProvider: false,
  patientCurrentStateValue: undefined,
};

Modal.setAppElement('#root');

export default ProviderProfileCardModal;
