/* eslint-disable no-useless-return */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Video, {
  ConnectOptions,
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
} from 'twilio-video';

import { getDeviceInfo, isPermissionDenied } from '../../Utils';
import useBackgroundSettings from './useBackgroundSettings';
import useConnectionOptions from './useConnectionOptions';
import useRoom from './useRoom';

export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';
export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
};

const useLocalTracks = (): any => {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);
  const onError = useCallback<ErrorCallback>((error) => {
    toast.error(error.message, { className: 'fs-unmask' });
  }, []);
  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      toast.error(error.message, { className: 'fs-unmask' });
      onError(error);
    },
    [onError],
  );
  const connectionOptions: ConnectOptions = useConnectionOptions();
  const { room } = useRoom(onErrorCallback, connectionOptions);
  const [backgroundSettings, setBackgroundSettings] = useBackgroundSettings(videoTrack, room);

  const getLocalAudioTrack = useCallback((deviceId?: string) => {
    const options: CreateLocalTrackOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return Video.createLocalAudioTrack(options).then((newTrack) => {
      setAudioTrack(newTrack);
      return newTrack;
    });
  }, []);

  const getLocalVideoTrack = useCallback(async () => {
    const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

    const { videoInputDevices } = await getDeviceInfo();

    const hasSelectedVideoDevice = videoInputDevices.some(
      (device: any) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
    );

    const options: CreateLocalTrackOptions = {
      ...(DEFAULT_VIDEO_CONSTRAINTS as Record<string, number>),
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId! } }),
    };

    return Video.createLocalVideoTrack(options).then((newTrack) => {
      const localMediaContainer = document.getElementById('local-media');
      localMediaContainer?.appendChild(newTrack.attach());
      setVideoTrack(newTrack);
      return newTrack;
    });
  }, []);

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  useEffect(() => {
    if (!videoTrack || backgroundSettings.type === 'none') {
      return; // Prevents reactivating the camera when track is undefined
    }
    // Existing processor logic
  }, [backgroundSettings, videoTrack]);

  const removeLocalVideoTrack = useCallback(() => {
    if (backgroundSettings) {
      setBackgroundSettings({ type: 'none', index: 0 }); // Reset background settings
    }

    if (room && videoTrack) {
      room.localParticipant.unpublishTrack(videoTrack); // Unpublish track
      videoTrack.mediaStreamTrack.enabled = false; // Disable the track
    }

    if (videoTrack) {
      const { mediaStreamTrack } = videoTrack;
      mediaStreamTrack.stop(); // Stop media stream
      videoTrack.stop(); // Stops Twilio wrapper track
      setVideoTrack(undefined); // Clear reference
    }

    // Stop all tracks and introduce a delay
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch((error) => console.warn('Error stopping media tracks:', error))
      .finally(() => {
        setTimeout(() => {}, 1000);
      });
  }, [videoTrack, backgroundSettings, room, setBackgroundSettings]);

  const getAudioAndVideoTracks = useCallback(
    async (controller: AbortController): Promise<any> => {
      const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } =
        await getDeviceInfo();

      if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve();
      if (isAcquiringLocalTracks || audioTrack || videoTrack) return Promise.resolve();

      setIsAcquiringLocalTracks(true);

      const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
      const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

      const hasSelectedAudioDevice = audioInputDevices.some(
        (device: any) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId,
      );
      const hasSelectedVideoDevice = videoInputDevices.some(
        (device: any) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
      );
      const isCameraPermissionDenied = await isPermissionDenied('camera');
      const isMicrophonePermissionDenied = await isPermissionDenied('microphone');

      const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied;
      const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied;

      const localTrackConstraints = {
        video: shouldAcquireVideo && {
          ...(DEFAULT_VIDEO_CONSTRAINTS as Record<string, number>),
          name: `camera-${Date.now()}`,
          ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId! } }),
        },
        audio:
          shouldAcquireAudio &&
          (hasSelectedAudioDevice ? { deviceId: { exact: selectedAudioDeviceId! } } : hasAudioInputDevices),
      };

      let newVideoTrack: LocalVideoTrack | undefined;
      let newAudioTrack: LocalAudioTrack | undefined;

      try {
        const tracks = await Video.createLocalTracks(localTrackConstraints);
        for (const track of tracks) {
          if (controller.signal.aborted) {
            if (track.kind === 'video' || track.kind === 'audio') {
              track.stop();
            }
          } else if (track.kind === 'video') {
            newVideoTrack = track as LocalVideoTrack;
            setVideoTrack(newVideoTrack);
            window.localStorage.setItem(
              SELECTED_VIDEO_INPUT_KEY,
              newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? '',
            );
          } else if (track.kind === 'audio') {
            newAudioTrack = track as LocalAudioTrack;
            setAudioTrack(newAudioTrack);
          }
        }
        if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
          const error = new Error();
          error.name = 'NotAllowedError';
          throw error;
        }
        if (isCameraPermissionDenied) {
          throw new Error('CameraPermissionsDenied');
        }
        if (isMicrophonePermissionDenied) {
          throw new Error('MicrophonePermissionsDenied');
        }
      } catch (e) {
        toast.error('Unable to acquire camera and microphone.', { className: 'fs-unmask' });
      } finally {
        setIsAcquiringLocalTracks(false);
      }
      return [newAudioTrack, newVideoTrack] as LocalTrack[];
    },
    [isAcquiringLocalTracks, audioTrack, videoTrack],
  );

  const localTracks = useMemo(
    () => [audioTrack, videoTrack].filter((track) => track !== undefined) as (LocalAudioTrack | LocalVideoTrack)[],
    [audioTrack, videoTrack],
  );

  useEffect(() => {
    return () => {
      const videoTracks = localTracks.filter((track) => track.kind === 'audio') as LocalVideoTrack[];
      videoTracks.forEach((track) => track.stop());
    };
  }, [removeLocalAudioTrack]);

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  };
};

export default useLocalTracks;
