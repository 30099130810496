/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable consistent-return */
import QuestionAndDropdown from '@brands/Components/QuestionAndAnswers/QuestionAndDropdown/QuestionAndDropdown';
import QuestionAndTextField from '@brands/Components/QuestionAndAnswers/QuestionAndTextField/QuestionAndTextField';
import { addClinicalData } from '@brands/services/clinical_data/addClinicalData';
import { listClinicalData } from '@brands/services/clinical_data/listClinicalData';
import { updateClinicalData } from '@brands/services/clinical_data/updateClinicalData';
import { getUserById } from '@brands/services/identity/getUserById';
import { UserProfile, UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectOrganization } from '@brands/store/selectors/organization';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setExternalForms } from '@brands/store/slices/patientFormSlice';
import { checkExternalFormAnswers } from '@brands/Utils/checkExternalForms';
import { Option } from '@brands/Utils/selectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import { useWindowSize } from '../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { validationSchema } from '../MedicalHistory/utils/validationSchema';
import styles from '../styles.module.scss';
import { dentalHistoryQuestions, monthOptions, visitOptions } from './utils/questions';
import { DentalHistoryProps, DentalHistorySubmit } from './utils/types';

const DentalHistory = ({ isProfileCard }: DentalHistoryProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const screenSize = useWindowSize();
  const { state } = useLocation();
  const [objectId, setObjectId] = useState<string | null>(null);

  const { selectedPatient } = useAppSelector(selectPatientForm);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const { userInfo } = useAppSelector(selectAuth);
  const { values } = useAppSelector(selectPatientForm);
  const [rendered, setRendered] = React.useState(true);
  const [dentalHistoryForm, setDentalHistoryForm] = useState<DentalHistorySubmit>({});
  const navigate = useNavigate();
  const [guardianPatient, setGuardianPatient] = useState<UserProfile>();

  const yearOptions: Option[] = [];
  for (let year = 2000; year <= 2025; year++) {
    yearOptions.push({ value: String(year), label: String(year) });
  }

  useEffect(() => {
    const getGuardianPAtient = async (): Promise<void> => {
      if (
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
      ) {
        if (currentPatient.guardian_id !== null) {
          const guardianResponse = await getUserById(currentPatient?.guardian_id as number);
          setGuardianPatient(guardianResponse);
        }
      }
    };
    getGuardianPAtient();
  }, [currentPatient]);

  const isPaymentRequired = currentOrganization.payment_options.length > 0;

  const checkExternalForms = async (): Promise<void> => {
    const [checkProfile, checkMedicalHistory, checkMedicalConditionsFormAnswer, checkDentalHistoryFormAnswer] =
      await checkExternalFormAnswers(
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? guardianPatient ?? (currentPatient as UserProfile)
          : userInfo,
        currentPatient,
      );
    const formsToSet = [];

    if (!checkProfile) {
      formsToSet.push('patient_profile');
    }
    if (!checkMedicalHistory) {
      formsToSet.push('patient_medical_history');
    }
    if (!checkMedicalConditionsFormAnswer) {
      formsToSet.push('patient_medical_conditions');
    }
    if (!checkDentalHistoryFormAnswer) {
      formsToSet.push('patient_dental_history');
    }

    dispatch(setExternalForms(formsToSet));
  };

  const onSubmit = async (data: DentalHistorySubmit): Promise<void | null> => {
    setRendered(false);
    const currentUserId =
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
        ? currentPatient.guardian_id !== null
          ? (guardianPatient as UserProfile).id
          : (currentPatient as UserProfile).id
        : currentPatient.id;
    const updatedData = {
      ...data,
      user_id: currentUserId,
    };
    if (!objectId) {
      await addClinicalData('dental_history', updatedData);
    } else {
      await updateClinicalData('dental_history', objectId, updatedData);
    }
    await checkExternalForms();

    setRendered(true);
    if (values.find((item) => item.form_question_id === 20)?.value.caseType as string) {
      if (state.isPatientForm || state?.fromPatientIntake) {
        return navigate('/medical-information-summary', {
          state: {
            fromPatientIntake: state?.fromPatientIntake ?? false,
            caseType: state ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) : '',
            isPatientForm: state?.isPatientForm,
            shouldSeeLastModal: state?.shouldSeeLastModal,
            patientId: state?.patientId,
          },
        });
      }
    } else if (state?.fromSummary || state?.fromPatientIntake) {
      return navigate('/medical-information-summary', {
        state: {
          fromPatientIntake: state?.fromPatientIntake ?? false,
          caseType: state ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) : '',
          isPatientForm: state?.isPatientForm,
          shouldSeeLastModal: state?.shouldSeeLastModal,
          patientId: state?.patientId,
        },
      });
    }
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(error.message as string, { className: 'fs-unmask' });
    }
  };

  const { register, handleSubmit, setValue } = useForm<DentalHistorySubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(dentalHistoryQuestions, false, false, true)),
  });

  useEffect(() => {
    (async () => {
      setRendered(false);
      const { dental_history } = await listClinicalData(selectedPatient?.value);
      if (dental_history) {
        setDentalHistoryForm(dental_history);
        setObjectId(dental_history.id.toString());
      }
      setRendered(true);
    })();
  }, []);

  useEffect(() => {
    if (dentalHistoryForm) {
      Object.keys(dentalHistoryForm).forEach((key: unknown) =>
        setValue(key as keyof DentalHistorySubmit, dentalHistoryForm[key as keyof DentalHistorySubmit], {
          shouldValidate: true,
        }),
      );
    }
  }, [dentalHistoryForm]);

  if (!rendered) {
    return <Loading fullScreen />;
  }

  return (
    <Container
      isBGEclipse={false}
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        width: isProfileCard ? '100%' : '',
      }}
      isProfileCard={isProfileCard}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2', paddingTop: '40px' }}
    >
      <div className={styles.medicalContainer}>
        <div className={`${styles.formTitle} d-flex mt-3 fs-unmask`} style={{ display: isProfileCard ? 'none' : '' }}>
          {Number(selectedPatient?.value) !== Number(userInfo.id) ? 'Their Dental History' : 'Dental History'}
        </div>
        <ProgressBar
          currentStep={state?.isPatientForm ? 4 : 3}
          totalSteps={state?.isPatientForm && isPaymentRequired ? 5 : state?.isPatientForm ? 4 : 3}
          hideStepNumbers
        />
        <form
          className={styles.formContainer}
          style={{ width: isProfileCard ? '100%' : '', marginRight: isProfileCard ? '0px' : '' }}
          onSubmit={handleSubmit(onSubmit, onInvalid)}
        >
          <div
            className={styles.wrapper}
            style={{ width: isProfileCard ? '734px' : screenSize.width < 992 ? '90%' : '' }}
          >
            <div className={styles.formContent}>
              {dentalHistoryQuestions.map((element, index) => {
                if (element.type === 'select') {
                  if (element.title === 'Date of your last dental visit') {
                    return (
                      <QuestionAndDropdown
                        {...register(element.id as keyof DentalHistorySubmit)}
                        element={element}
                        previousElement={dentalHistoryQuestions[index - 1]}
                        key={element.id}
                        form={dentalHistoryForm}
                        setForm={setDentalHistoryForm}
                        givingOptions={monthOptions}
                        twoInputs
                        givingOptionsTwo={yearOptions as Option[]}
                        givingOptionTitle="Month"
                        givingOptionTwoTitle="Year"
                        dontKnowOption
                      />
                    );
                  }
                  return (
                    <QuestionAndDropdown
                      {...register(element.id as keyof DentalHistorySubmit)}
                      element={element}
                      previousElement={dentalHistoryQuestions[index - 1]}
                      key={element.id}
                      form={dentalHistoryForm}
                      setForm={setDentalHistoryForm}
                      givingOptions={visitOptions}
                      fullSize
                    />
                  );
                }
                return (
                  <QuestionAndTextField
                    {...register(element.id as keyof DentalHistorySubmit)}
                    element={element}
                    key={element.id}
                    form={dentalHistoryForm}
                    setForm={setDentalHistoryForm}
                    questionTitle="Name"
                  />
                );
              })}
            </div>
            <div className={styles.btnContainer}>
              <Button type="submit" style={{ width: '245px', height: '46px' }} className='fs-unmask'>
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};
DentalHistory.defaultProps = {
  isProfileCard: false,
};
export default DentalHistory;
