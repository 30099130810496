/* eslint-disable no-param-reassign */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import QuestionAndDropdown from '@brands/Components/QuestionAndAnswers/QuestionAndDropdown/QuestionAndDropdown';
import QuestionAndMultiselectSideQuestion from '@brands/Components/QuestionAndAnswers/QuestionAndMultiselectSideQuestion/QuestionAndMultiselectSideQuestion';
import QuestionAndPhoneInput from '@brands/Components/QuestionAndAnswers/QuestionAndPhoneInput/QuestionAndPhoneInput';
import QuestionAndTextField from '@brands/Components/QuestionAndAnswers/QuestionAndTextField/QuestionAndTextField';
import { OptionCdts } from '@brands/Dashboard/ConsultationPage/assets/types';
import { addClinicalData } from '@brands/services/clinical_data/addClinicalData';
import { listClinicalData } from '@brands/services/clinical_data/listClinicalData';
import { updateClinicalData } from '@brands/services/clinical_data/updateClinicalData';
import { getUserById } from '@brands/services/identity/getUserById';
import { UserProfile, UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { setExternalForms } from '@brands/store/slices/patientFormSlice';
import { checkExternalFormAnswers } from '@brands/Utils/checkExternalForms';
import { stateOptions } from '@brands/Utils/selectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import QuestionAndMultiselect from '../../../Components/QuestionAndAnswers/QuestionAndMultiselectForMed/QuestionAndMultiselect';
import QuestionAndRadioButton from '../../../Components/QuestionAndAnswers/QuestionAndRadioButtonForMed/QuestionAndRadioButton';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import useWindowSize from '../../../hooks/useWindowSize';
import styles from '../styles.module.scss';
import { heightOptions, inchOptions, medicalHistoryQuestions } from './utils/questions';
import { MedicalHistoryProps, MedicalHistorySubmit } from './utils/types';
import { validationSchema } from './utils/validationSchema';

const MedicalHistory = ({ isProfileCard }: MedicalHistoryProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { selectedPatient, values } = useAppSelector(selectPatientForm);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userInfo } = useAppSelector(selectAuth);
  const screenSize = useWindowSize();
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const [rendered, setRendered] = React.useState(true);
  const [medicalHistoryForm, setMedicalHistoryForm] = useState<MedicalHistorySubmit>({
    under_care_of_physician: false,
    conditions_treated: null,
    taking_medication: false,
    medications: null,
    allergic_to_medications: false,
    medications_allergies: null,
    using_substances: [],
    interested_in_stopping: null,
    height: '',
    weight: '',
    pharmacy_name: null,
    pharmacy_street_address: null,
    pharmacy_city: null,
    pharmacy_state: null,
    pharmacy_zip_code: null,
    pharmacy_phone_number: null,
  });
  const [objectId, setObjectId] = useState<string | null>(null);
  const poundOptions: OptionCdts[] = [];

  for (let i = 2; i <= 350; i++) {
    poundOptions.push({
      value: `${i}lb`,
      label: `${i}lb`,
    });
  }

  const [guardianPatient, setGuardianPatient] = useState<UserProfile>();
  useEffect(() => {
    const getGuardianPatient = async (): Promise<void> => {
      if (
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
      ) {
        if (currentPatient.guardian_id !== null) {
          const guardianResponse = await getUserById(currentPatient?.guardian_id as number);
          setGuardianPatient(guardianResponse);
        }
      }
    };
    getGuardianPatient();
  }, [currentPatient, userInfo.role.name]);

  const checkExternalForms = async (): Promise<void> => {
    const [checkProfile, checkMedicalHistory, checkMedicalConditionsFormAnswer, checkDentalHistoryFormAnswer] =
      await checkExternalFormAnswers(
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? guardianPatient ?? (currentPatient as UserProfile)
          : userInfo,
        currentPatient,
      );
    const formsToSet = [];

    if (!checkProfile) {
      formsToSet.push('patient_profile');
    }
    if (!checkMedicalHistory) {
      formsToSet.push('patient_medical_history');
    }
    if (!checkMedicalConditionsFormAnswer) {
      formsToSet.push('patient_medical_conditions');
    }
    if (!checkDentalHistoryFormAnswer) {
      formsToSet.push('patient_dental_history');
    }

    dispatch(setExternalForms(formsToSet));
  };

  const onSubmit = async (data: MedicalHistorySubmit): Promise<void> => {
    setRendered(false);
    const currentUserId =
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
        ? currentPatient.guardian_id !== null
          ? (guardianPatient as UserProfile).id
          : (currentPatient as UserProfile).id
        : currentPatient.id;
    data = {
      ...data,
      user_id: currentUserId,
    };
    if (!objectId) {
      await addClinicalData('medical_history', data);
    } else {
      await updateClinicalData('medical_history', objectId, data);
    }
    await checkExternalForms();
    setRendered(true);
    navigate('/medical-information-summary', {
      state: {
        fromPatientIntake: state?.fromPatientIntake ?? false,
        caseType: state ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) : '',
        isPatientForm: state?.isPatientForm,
        shouldSeeLastModal: state?.shouldSeeLastModal,
        patientId: state?.patientId,
      },
    });
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(error.message as string, { className: 'fs-unmask' });
    }
  };

  const { register, handleSubmit, setValue } = useForm<MedicalHistorySubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(medicalHistoryQuestions, true, false, false)),
  });

  useEffect(() => {
    (async () => {
      setRendered(false);
      const res = await listClinicalData(selectedPatient?.value);
      if (res.medical_history) {
        setMedicalHistoryForm(res.medical_history);
        setObjectId(res.medical_history.id.toString());
      }
      setRendered(true);
    })();
  }, [selectedPatient?.value]);

  useEffect(() => {
    Object.keys(medicalHistoryForm).forEach((k: unknown) =>
      setValue(k as keyof MedicalHistorySubmit, medicalHistoryForm[k as keyof MedicalHistorySubmit], {
        shouldValidate: true,
      }),
    );
  }, [medicalHistoryForm, setValue]);

  if (!rendered || !medicalHistoryQuestions || medicalHistoryQuestions.length === 0) {
    return <Loading fullScreen />;
  }
  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        width: isProfileCard ? '100%' : '',
      }}
      isProfileCard={isProfileCard}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2', paddingTop: '40px' }}
    >
      <div className={styles.medicalContainer}>
        <div className={`${styles.formTitle} d-flex mt-3 fs-unmask`} style={{ display: isProfileCard ? 'none' : '' }}>
          {Number(selectedPatient?.value) !== Number(userInfo.id)
            ? 'Their Medical History'
            : 'Your Medical Information'}
        </div>
        <ProgressBar
          currentStep={state?.isPatientForm ? 2 : 1}
          totalSteps={state?.isPatientForm ? 5 : state?.isPatientForm ? 4 : 3}
          hideStepNumbers
        />
        <form
          className={styles.formContainer}
          style={{ width: isProfileCard ? '100%' : '', marginRight: isProfileCard ? '0px' : '' }}
          onSubmit={handleSubmit(onSubmit, onInvalid)}
        >
          <div
            className={styles.wrapper}
            style={{ width: isProfileCard ? '734px' : screenSize.width < 992 ? '90%' : '' }}
          >
            <div className={styles.formContent}>
              {medicalHistoryQuestions !== undefined &&
                medicalHistoryQuestions.map((element, index) => {
                  if (element.type === 'select') {
                    if (element.title !== 'Height' && element.title !== 'Weight') {
                      return (
                        <QuestionAndMultiselect
                          {...register(element.id as keyof MedicalHistorySubmit)}
                          element={element}
                          subElement={medicalHistoryQuestions[index + 1]}
                          key={element.id}
                          form={medicalHistoryForm}
                          setForm={setMedicalHistoryForm}
                          isEmoji
                        />
                      );
                    }
                  } else if (
                    element.type === 'checkbox' &&
                    element.id === 'interested_in_stopping' &&
                    medicalHistoryForm.using_substances.length > 0
                  ) {
                    return (
                      <QuestionAndMultiselectSideQuestion
                        {...register(element.id as keyof MedicalHistorySubmit)}
                        element={element}
                        form={medicalHistoryForm}
                        key={element.id}
                        setForm={setMedicalHistoryForm}
                        isChild
                        isEmoji
                        isParentQuestionActive
                      />
                    );
                  }
                  if (
                    element.type !== 'checkbox' &&
                    element.type !== 'select' &&
                    element.title &&
                    !element.title.startsWith('Pharmacy')
                  )
                    return (
                      <QuestionAndRadioButton
                        {...register(element.id as keyof MedicalHistorySubmit)}
                        element={element}
                        key={element.id}
                        form={medicalHistoryForm}
                        setForm={setMedicalHistoryForm}
                      />
                    );
                  return null;
                })}
            </div>
            <br />
            <div className={styles.measurments}>
              {medicalHistoryQuestions !== undefined &&
                medicalHistoryQuestions.map((element, index) => {
                  if (element.type === 'select' && (element.title === 'Height' || element.title === 'Weight')) {
                    if (element.title === 'Height') {
                      return (
                        <QuestionAndDropdown
                          {...register('height')}
                          previousElement={medicalHistoryQuestions[index - 1]}
                          element={element}
                          key={element.id}
                          form={medicalHistoryForm}
                          setForm={setMedicalHistoryForm}
                          givingOptions={heightOptions}
                          twoInputs
                          givingOptionsTwo={inchOptions}
                        />
                      );
                    }
                    return (
                      <QuestionAndDropdown
                        {...register('weight')}
                        previousElement={medicalHistoryQuestions[index - 1]}
                        element={element}
                        key={element.id}
                        form={medicalHistoryForm}
                        setForm={setMedicalHistoryForm}
                        givingOptions={poundOptions}
                      />
                    );
                  }
                  return null;
                })}
            </div>
            <br />
            <div className={styles.pharmacy}>
              <span className={styles.pharmacyTitle}>Pharmacy</span>
              {medicalHistoryQuestions !== undefined &&
                medicalHistoryQuestions.map((element) => {
                  if (
                    element.title &&
                    element.title.startsWith('Pharmacy') &&
                    (element.title === 'Pharmacy Name' || element.title === 'Pharmacy Street Address')
                  )
                    return (
                      <QuestionAndTextField
                        {...register(element.id as keyof MedicalHistorySubmit)}
                        element={element}
                        key={element.id}
                        form={medicalHistoryForm}
                        setForm={setMedicalHistoryForm}
                      />
                    );
                  return null;
                })}
            </div>
            <div className={styles.pharmacyAddress}>
              {medicalHistoryQuestions !== undefined &&
                medicalHistoryQuestions.map((element, index) => {
                  if (
                    element.title &&
                    element.title.startsWith('Pharmacy') &&
                    (element.title === 'Pharmacy City' ||
                      element.title === 'Pharmacy State' ||
                      element.title === 'Pharmacy Zip Code')
                  )
                    if (element.title === 'Pharmacy City' || element.title === 'Pharmacy Zip Code') {
                      return (
                        <QuestionAndTextField
                          {...register(element.id as keyof MedicalHistorySubmit)}
                          element={element}
                          key={element.id}
                          form={medicalHistoryForm}
                          setForm={setMedicalHistoryForm}
                        />
                      );
                    } else if (element.title === 'Pharmacy State') {
                      return (
                        <QuestionAndDropdown
                          {...register(element.id as keyof MedicalHistorySubmit)}
                          previousElement={medicalHistoryQuestions[index - 1]}
                          element={element}
                          key={element.id}
                          form={medicalHistoryForm}
                          setForm={setMedicalHistoryForm}
                          givingOptions={stateOptions}
                        />
                      );
                    }
                  return null;
                })}
            </div>
            <div className={styles.pharmacyPhone}>
              {medicalHistoryQuestions !== undefined &&
                medicalHistoryQuestions.map((element) => {
                  if (
                    element.title &&
                    element.title.startsWith('Pharmacy') &&
                    element.title === 'Pharmacy Phone Number'
                  )
                    return (
                      <QuestionAndPhoneInput
                        {...register(element.id as keyof MedicalHistorySubmit)}
                        element={element}
                        key={element.id}
                        form={medicalHistoryForm}
                        setForm={setMedicalHistoryForm}
                      />
                    );
                  return null;
                })}
            </div>
            <div className={styles.btnContainer}>
              <Button type="submit" style={{ width: '245px', height: '46px' }} className="fs-unmask">
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};
MedicalHistory.defaultProps = {
  isProfileCard: false,
};
export default MedicalHistory;
